import { useEffect } from 'react';
import { showLoading } from '../../utils/Loading';
import './PageNotFound.scss';

export default function PageNotFound() {
  const errorMessage = "Lost? Redirecting to RMS…"
  const envName = process.env.REACT_APP_ENV_NAME;
  
  // This use effect is used to set loading false and redirect to the particular rms website based on the present environment that we are in

  useEffect(() => {
    setTimeout(() => {
      showLoading(false);
    }, 1000);

    setTimeout(() => {
      var baseUrlPrefix = '';
      if (envName === 'Dev' || envName === 'Local') baseUrlPrefix = 'devapps';
      else if (envName === 'Prod') baseUrlPrefix = 'apps';
      else baseUrlPrefix ='testapps';

      let baseUrl = `https://rms.${baseUrlPrefix}.alcoa.com`;
      window.location.href = baseUrl
    }, 3000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <div className="container">
      <div className="error-image">
      </div>
      <h1 className="h1 text-primary" style={{fontSize:'large'}}>Page Not Found!</h1>
      <div>
        {errorMessage}
      </div> 
    </div>
  )
}