import { Coord } from "../utils/Coord";

export class Geometry {
    /**@type {base64String}additivePath */
    ap = '';
    /**@type {int}additivePathLength*/
    al = 0;
    /**@type {arrayBase64String}subtractivePaths*/
    sPs = [];
    /**@type {int}centroid*/
    c = 0;
    /**@type {int}Scale*/
    s = new Coord(1, 1);
    /**@type {int}area*/
    a = 0;
    /**@type {int}tessellationSourceIndex*/
    tsi = 0;

    /**
     *
     * @param {base64String} additivePath
     * @param {int} additivePathLength
     * @param {arrayBase64String}subtractivePaths
     * @param {*} centroid,
     * @param {*} scale
     * @param {*} area
     * @param {*} tessellationSourceIndex
     */

    constructor(additivePath, additivePathLength, subtractivePaths, centroid, scale, area, tessellationSourceIndex) {
        this.ap = additivePath;
        this.al = additivePathLength;
        this.sPs = subtractivePaths;
        this.c = centroid;
        this.s = scale;
        this.a = area;
        this.tsi = tessellationSourceIndex;
    }
}
