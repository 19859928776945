import React, { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFullScreenMode } from '../../redux/reducer/FullScreenReducer';
import { ActionType } from '../../utils/Action';
import { getRelativeCoords } from '../../utils/Helper';
import { TransformControls } from '../transformControls/TransformControls';
import './Viewport.scss';

const Viewport = React.forwardRef(
    (
        {
            onInputOnce = (x, y) => {},
            onInputStart = (x, y) => {},
            onInputEnd = (x, y) => {},
            onInputUpdate = (x, y) => {},
            imageData,
            leftChild = <></>,
            rightChild = <></>,
            canvasOverlayChild = <></>,
            idleMessage = '',
        },
        ref
    ) => {
        const [imageWidth, setImageWidth] = useState(0);
        const [imageHeight, setImageHeight] = useState(0);
        const [imageAvailable, setImageAvailable] = useState(false);
        const [zoomFactor, setZoomFactor] = useState(1);
        const [translation, setTranslation] = useState({ x: 0, y: 0 });
        const [panning, setPanning] = useState(false);
        const [dragging, setDragging] = useState(false);
        const [_drag, set_drag] = useState({ x: 0, y: 0 });
        const [_lastDrag, set_lastDrag] = useState({ x: 0, y: 0 });
        const [loading, setLoading] = useState(false);
        const [loadingMessage, setLoadingMessage] = useState('Rendering');
        const [loadingOpaque, setLoadingOpaque] = useState(false);

        // Tap Handler
        const [dragged, setDragged] = useState(false);

        // Touch
        const [centerPoint, setCenterPoint] = useState({ x: 0, y: 0 });
        const [previousDistance, setPreviousDistance] = useState(1);
        const [tapTimer, setTapTimer] = useState(null);

        const [stateHandle, setStateHandle] = useState(false);

        const viewportRef = useRef();
        const canvasContainerRef = useRef();
        const backgroundCanvasRef = useRef();
        const paintCanvasRef = useRef();
        const previewCanvasRef = useRef();
        const selectedTool = useSelector((state) => state.tool.tool);
        const fullscreen = useSelector((state) => state.fullscreen.fullscreen);
        const dispatch = useDispatch();

        useEffect(() => {
            setTimeout(() => {
                fitToScreen();
            }, 500);
        }, [fullscreen]);

        useEffect(() => {
            if (viewportRef.current && imageAvailable) {
                switch (selectedTool) {
                    case ActionType.TRANSFORM:
                        if (panning) {
                            viewportRef.current.style.cursor = 'grabbing';
                        } else {
                            viewportRef.current.style.cursor = 'grab';
                        }
                        break;
                    case ActionType.FILLCELL:
                    case ActionType.FILLREGION:
                    case ActionType.SCRIBBLE:
                    case ActionType.ERASE:
                        viewportRef.current.style.cursor = 'crosshair';
                        break;
                    case ActionType.TEXT:
                        viewportRef.current.style.cursor = 'text';
                        break;

                    default:
                        viewportRef.current.style.cursor = 'default';
                }
            } else {
                viewportRef.current.style.cursor = 'default';
            }
        }, [viewportRef, selectedTool, panning, imageAvailable]);

        /**
         * This function loads the image into canvas.
         * This function is called when version is changed
         * @param {string} base64 - base64 data
         */

        useEffect(() => {
            const loadBase64 = (base64) => {
                let image = new Image();
                image.onload = () => {
                    let paintCanvas = paintCanvasRef.current;
                    setImageWidth(image.width);
                    setImageHeight(image.height);
                    paintCanvas.width = image.width;
                    paintCanvas.height = image.height;
                    paintCanvas.getContext('2d').fillStyle = 'rgb(255, 255, 255)';
                    paintCanvas.getContext('2d').fillRect(0, 0, 2, 2);
                    let backgroundCanvas = backgroundCanvasRef.current;
                    backgroundCanvas.width = image.width;
                    backgroundCanvas.height = image.height;
                    let previewCanvas = previewCanvasRef.current;
                    previewCanvas.width = image.width;
                    previewCanvas.height = image.height;
                    previewCanvas.getContext('2d').fillStyle = 'rgb(255, 255, 255)';
                    previewCanvas.getContext('2d').fillRect(0, 0, 2, 2);

                    try {
                        let bcContext = getReadOnlyContext();
                        bcContext.drawImage(image, 0, 0, image.width, image.height);
                        setImageAvailable(true);
                    } catch (e) {
                        console.error(e);
                    }
                    fitToScreen();
                };
                image.src = `data:image/png;base64, ${base64}`;
            };
            loadBase64(imageData);
        }, [imageData]);

        useImperativeHandle(ref, () => ({
            getPaintCanvasDataURL,
            getReadOnlyCanvasDataURL,
            getReadOnlyContext,
            reset,
            getPaintContext,
            clearPaintCanvas,
            getPreviewContext,
            clearPreviewCanvas,
            zoomIn: zoomIn,
            zoomOut: zoomOut,
            fitToScreen: fitToScreen,
            reRender,
            showLoader,
            hideLoader,
            getIsImageAvailable,
        }));

        const getReadOnlyContext = () => {
            const ctx = backgroundCanvasRef.current.getContext('2d', {
                willReadFrequently: true,
                alpha: false,
            });
            ctx.imageSmoothingEnabled = false;
            return ctx;
        };

        const getPaintContext = () => {
            return paintCanvasRef.current.getContext('2d', {
                desynchronized: true,
            });
        };

        const clearPaintCanvas = (x = 0, y = 0, width = imageWidth, height = imageHeight) => {
            const context = getPaintContext();
            context.clearRect(x, y, width, height);
        };

        const getPaintCanvasDataURL = () => {
            return paintCanvasRef.current.toDataURL();
        };

        const getReadOnlyCanvasDataURL = () => {
            return backgroundCanvasRef.current.toDataURL();
        };

        const reset = () => {
            backgroundCanvasRef.current.width = 1;
            backgroundCanvasRef.current.height = 1;
            getReadOnlyContext().clearRect(0, 0, 1, 1);
            paintCanvasRef.current.width = 1;
            paintCanvasRef.current.height = 1;
            getPaintContext().clearRect(0, 0, 1, 1);
            previewCanvasRef.current.width = 1;
            previewCanvasRef.current.height = 1;
            getPreviewContext().clearRect(0, 0, 1, 1);
            setImageAvailable(false);
        };

        const reRender = () => {
            paintCanvasRef.current.style['font-size'] = stateHandle ? '12px' : '14px';
            setStateHandle(!stateHandle);
        };

        const getPreviewContext = () => {
            return previewCanvasRef.current.getContext('2d', {
                desynchronized: true,
            });
        };

        const clearPreviewCanvas = (x = 0, y = 0, width = imageWidth, height = imageHeight) => {
            const context = getPreviewContext();
            context.clearRect(x, y, width, height);
        };

        const showLoader = (message, opaque = false) => {
            setLoadingMessage(message);
            setLoadingOpaque(opaque);
            setLoading(true);
        };

        const hideLoader = () => {
            setLoading(false);
        };

        /**
         * This method is used to zoom in functionality
         */

        const zoomIn = () => {
            let _zoomFactor = zoomFactor + 0.25;
            if (_zoomFactor > 1) {
                _zoomFactor = 1;
            }
            setZoomFactor(_zoomFactor);
            canvasContainerRef.current.classList.add('smooth-transform');
            setTimeout(() => {
                canvasContainerRef.current.classList.remove('smooth-transform');
            }, 500);
        };

        /**
         * This method is used to zoom out functionality
         */

        const zoomOut = () => {
            let _zoomFactor = zoomFactor - 0.1;
            if (_zoomFactor < 0.1) {
                _zoomFactor = 0.1;
            }
            setZoomFactor(_zoomFactor);
            canvasContainerRef.current.classList.add('smooth-transform');
            setTimeout(() => {
                canvasContainerRef.current.classList.remove('smooth-transform');
            }, 500);
        };

        /**
         * This function is for fits the image to the screen
         */

        const fitToScreen = () => {
            const viewport = viewportRef.current;
            const canvas = canvasContainerRef.current;
            if (viewport) {
                var ratioX = canvas.clientWidth / (viewport.clientWidth - 164);
                var ratioY = canvas.clientHeight / viewport.clientHeight;
                let _zoomFactor = 1 / Math.max(ratioX, ratioY);
                if (_zoomFactor > 1) _zoomFactor = 1;
                setZoomFactor(_zoomFactor);
                setTranslation({ x: 0, y: 0 });
            }
            canvasContainerRef.current.classList.add('smooth-transform');
            setTimeout(() => {
                canvasContainerRef.current.classList.remove('smooth-transform');
            }, 500);
        };
        /**
         * This useEffect Rerender if zoomfactor or translation changes.
         */

        useEffect(() => {
            const viewport = viewportRef.current;
            const canvas = canvasContainerRef.current;
            if (viewport && canvas) {
                // `>> 1` => `/ 2` but faster
                let transformOriginX = (viewport.clientWidth - canvas.clientWidth) >> 1;
                let transformOriginY = (viewport.clientHeight - canvas.clientHeight) >> 1;
                canvas.style[
                    'transform'
                ] = `translate(${transformOriginX}px, ${transformOriginY}px) scale(${zoomFactor}) translate(${translation.x}px, ${translation.y}px)`;
                viewport.style['background-position'] = `${(translation.x >> 1) * zoomFactor}px ${
                    (translation.y >> 1) * zoomFactor
                }px`;
            }
            var scaleAgnosticElements = document.getElementsByClassName('viewport-scale-agnostic');
            for (var i = 0; i < scaleAgnosticElements.length; i++) {
                scaleAgnosticElements[i].style.transform = `scale(${1 / zoomFactor})`;
            }
        }, [zoomFactor, translation]);

        useEffect(() => {
            var scaleAgnosticElements = document.getElementsByClassName('viewport-scale-agnostic');
            for (var i = 0; i < scaleAgnosticElements.length; i++) {
                scaleAgnosticElements[i].style.transform = `scale(${1 / zoomFactor})`;
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [canvasOverlayChild]);

        /**
         * This is used to implement the toggle-Zoom functionality
         * @param {*} event
         */

        const toggleZoom = (event) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(event.target.classList[0])) {
                return;
            }

            let relativeCoords = getRelativeCoords(event, zoomFactor);
            if (zoomFactor === 1) {
                fitToScreen();
            } else {
                const viewport = viewportRef.current;
                const canvas = canvasContainerRef.current;
                if (viewport) {
                    setZoomFactor(1);
                    let centerX = relativeCoords.x - canvas.clientWidth / 2;
                    let centerY = relativeCoords.y - canvas.clientHeight / 2;
                    setTranslation({ x: -centerX, y: -centerY });
                    set_lastDrag({ x: 0, y: 0 });
                    set_drag({ x: 0, y: 0 });
                    canvasContainerRef.current.classList.add('smooth-transform');
                    setTimeout(() => {
                        canvasContainerRef.current.classList.remove('smooth-transform');
                    }, 500);
                }
            }
            if (document.getSelection()) {
                document.getSelection()?.empty();
            } else if (window.getSelection()) {
                let sel = window.getSelection();
                sel?.removeAllRanges();
                event.preventDefault();
            }
        };

        /**
         * This is used to implement the double tap functionality
         * @param {*} event
         */

        const doubleTap = (event) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(event.target.classList[0])) {
                return;
            }

            if (tapTimer == null) {
                setTapTimer(
                    setTimeout(() => {
                        setTapTimer(null);
                    }, 500)
                );
            } else {
                clearTimeout(tapTimer);
                setTapTimer(null);
                toggleZoom(event);
            }
        };

        /**
         * This is used to implement for viewport handler functionality.
         * @param {*} event
         */

        const handleViewportMouseDown = (e) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(e.target.classList[0])) {
                return;
            }

            if (setPreviousDistance) {
                setCenterPoint({ x: e.clientX, y: e.clientY });
                setPanning(true);
            }
        };

        const handleViewportMouseUp = (e) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(e.target.classList[0])) {
                return;
            }

            setPanning(false);
        };

        const handleViewportMouseMove = (e) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(e.target.classList[0])) {
                return;
            }

            if (selectedTool === ActionType.TRANSFORM && panning) {
                viewportRef.current.style.cursor = 'grabbing';
                setTranslation({
                    x: translation.x + (e.clientX - centerPoint.x) / zoomFactor,
                    y: translation.y + (e.clientY - centerPoint.y) / zoomFactor,
                });
            }

            setCenterPoint({ x: e.clientX, y: e.clientY });
        };

        /**
         * This below handlerfunction is used for viewport and only for mouse supported device.
         * @param {*} e
         */
        const handleViewportOnWheel = (e) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(e.target.classList[0])) {
                return;
            }

            let _zoomFactor = zoomFactor + e.nativeEvent.wheelDelta * 0.001;
            if (_zoomFactor > 1) {
                _zoomFactor = 1;
            } else if (_zoomFactor < 0.1) {
                _zoomFactor = 0.1;
            }

            setZoomFactor(_zoomFactor);
        };

        /**
         * This below handlerfunction is used for viewport and only for touch supported screen.
         * @param {*} e
         */

        const handleViewportTouchStart = (e) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(e.target.classList[0])) {
                return;
            }

            if (e.touches.length === 2) {
                let touchA = e.touches[0];
                let touchB = e.touches[1];
                setPreviousDistance(
                    Math.abs(touchA.clientX - touchB.clientX) + Math.abs(touchA.clientY - touchB.clientY)
                );
                setCenterPoint({
                    x: (touchA.clientX + touchB.clientX) / 2,
                    y: (touchA.clientY + touchB.clientY) / 2,
                });
                e.preventDefault();
            } else if (e.touches.length === 1 && selectedTool === ActionType.TRANSFORM) {
                doubleTap(e.touches[0]);
                handleViewportMouseDown(e.touches[0]);
            }
        };

        const handleViewportTouchMove = (e) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(e.target.classList[0])) {
                return;
            }

            if (e.touches.length === 2) {
                let touchA = e.touches[0];
                let touchB = e.touches[1];
                let distance = Math.abs(touchA.clientX - touchB.clientX) + Math.abs(touchA.clientY - touchB.clientY);
                let distanceDelta = distance - previousDistance;
                let _zoomFactor = zoomFactor;
                if (distanceDelta !== 0) {
                    _zoomFactor += (distanceDelta / 1000) * _zoomFactor;
                }
                if (_zoomFactor > 1) {
                    _zoomFactor = 1;
                } else if (_zoomFactor < 0.1) {
                    _zoomFactor = 0.1;
                }
                setZoomFactor(_zoomFactor);
                setPreviousDistance(distance);
                let centerX = (touchA.clientX + touchB.clientX) / 2;
                let centerY = (touchA.clientY + touchB.clientY) / 2;
                setTranslation({
                    x: translation.x + (centerX - centerPoint.x) / _zoomFactor,
                    y: translation.y + (centerY - centerPoint.y) / _zoomFactor,
                });
                setCenterPoint({ x: centerX, y: centerY });
                e.preventDefault();
            } else if (e.touches.length === 1 && selectedTool === ActionType.TRANSFORM) {
                handleViewportMouseMove(e.touches[0]);
            }
        };

        const handleViewportTouchEnd = (e) => {
            if (!['viewport', 'canvas-preview', 'canvas-paint'].includes(e.target.classList[0])) {
                return;
            }

            if (e.touches.length === 1 && selectedTool === ActionType.TRANSFORM) {
                handleViewportMouseUp(e.touches[0]);
            }
        };

        /**
         * This function is used for double tab and click on Android mobile
         */

        const isTouchAndroid = () => {
            return navigator.userAgent.toLowerCase().match(/(android)/);
        };

        /**
         * Handles the mouse move event on canvas.
         * For touch seperate function is defined below
         * @param {*} e
         */

        const handleCanvasMouseClick = (e) => {
            if (dragged) {
                setDragged(false);
                return;
            }

            let relativeCoords = getRelativeCoords(e, zoomFactor);
            onInputOnce(relativeCoords.x, relativeCoords.y);
        };

        const handleCanvasMouseDown = (e) => {
            setDragging(true);
            setDragged(false);
            let relativeCoords = getRelativeCoords(e, zoomFactor);
            onInputStart(relativeCoords.x, relativeCoords.y);
        };

        const handleCanvasMouseUp = (e) => {
            if (dragging) {
                setDragging(false);
                let relativeCoords = getRelativeCoords(e, zoomFactor);
                onInputEnd(relativeCoords.x, relativeCoords.y);
            }
        };

        const handleCanvasMouseMove = (e) => {
            setDragged(true);

            if (dragging) {
                let relativeCoords = getRelativeCoords(e, zoomFactor);
                onInputUpdate(relativeCoords.x, relativeCoords.y);
            }
        };

        const handleCanvasTouchStart = (e) => {
            if (e.touches.length === 1 || e.type === 'touchend') {
                setDragging(true);
                setDragged(false);
                let touch = e.touches[0];
                let relativeCoords = getRelativeCoords(touch, zoomFactor);
                onInputStart(relativeCoords.x, relativeCoords.y);
            }
        };

        const handleCanvasTouchMove = (e) => {
            if ((e.touches.length === 1 || e.type === 'touchend') && dragging) {
                setDragged(true);
                let touch = e.touches[0];
                let relativeCoords = getRelativeCoords(touch, zoomFactor);
                onInputUpdate(relativeCoords.x, relativeCoords.y);
            }
        };
        const handleCanvasTouchEnd = (e) => {
            if ((e.touches.length === 1 || e.type === 'touchend') && dragging) {
                setDragging(false);
                let touch = e.touches[0];
                let relativeCoords = getRelativeCoords(touch, zoomFactor);
                onInputEnd(relativeCoords.x, relativeCoords.y);
            }
        };

        const getIsImageAvailable = () => {
            return imageAvailable;
        };

        return (
            <>
                <div
                    ref={viewportRef}
                    className={'viewport' + (fullscreen ? ' fullscreen' : '')}
                    onMouseDown={(e) => {
                        if (selectedTool === ActionType.TRANSFORM) {
                            handleViewportMouseDown(e);
                        }
                    }}
                    onMouseUp={(e) => {
                        if (selectedTool === ActionType.TRANSFORM) {
                            handleViewportMouseUp(e);
                        }
                    }}
                    onMouseMove={(e) => {
                        if (selectedTool === ActionType.TRANSFORM) {
                            handleViewportMouseMove(e);
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (selectedTool === ActionType.TRANSFORM) {
                            handleViewportMouseUp(e);
                        }
                    }}
                    onWheel={(e) => {
                        handleViewportOnWheel(e);
                    }}
                    onDoubleClick={(e) => {
                        if (!isTouchAndroid()) {
                            toggleZoom(e);
                        }
                    }}
                    onTouchStart={(e) => {
                        handleViewportTouchStart(e);
                    }}
                    onTouchMove={(e) => {
                        handleViewportTouchMove(e);
                    }}
                    onTouchEnd={(e) => {
                        handleViewportTouchEnd(e);
                    }}
                >
                    {(loading || !imageAvailable) && (
                        <div
                            className={'viewport-loading-blocker' + (loadingOpaque || !imageAvailable ? ' opaque' : '')}
                        >
                            <div>
                                <div className="viewport-loading-blocker-logo">
                                    <div className="graphic pi pi-map"></div>
                                    {/* <i className="pi pi-cog" style={{ opacity: 0, width: 0 }}></i>&nbsp; */}
                                </div>
                                <div className={'viewport-loading-blocker-message' + (loading ? ' show' : '')}>
                                    <i style={{ margin: 8 }} className="pi pi-spin spinner-16"></i>
                                    {loadingMessage}…
                                </div>
                                {!loading && (
                                    <div className={'viewport-loading-blocker-message show'}>
                                        <span style={{ opacity: 0.6 }}>{idleMessage}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        className="canvas-container"
                        ref={canvasContainerRef}
                        onClick={(e) => {
                            handleCanvasMouseClick(e);
                        }}
                        onTouchStart={(e) => {
                            handleCanvasTouchStart(e);
                        }}
                        onTouchMove={(e) => {
                            handleCanvasTouchMove(e);
                        }}
                        onTouchEnd={(e) => {
                            handleCanvasTouchEnd(e);
                        }}
                        onMouseUp={(e) => {
                            handleCanvasMouseUp(e);
                        }}
                        onMouseMove={(e) => {
                            handleCanvasMouseMove(e);
                        }}
                        onMouseDown={(e) => {
                            if (selectedTool !== ActionType.TRANSFORM) {
                                handleCanvasMouseDown(e);
                            }
                        }}
                    >
                        <canvas className="canvas-background" ref={backgroundCanvasRef}></canvas>
                        <canvas className="canvas-paint" ref={paintCanvasRef} style={{ opacity: 1 }}></canvas>
                        <canvas className="canvas-preview" ref={previewCanvasRef} style={{ opacity: 1 }}></canvas>
                        {canvasOverlayChild}
                    </div>
                    <div className="viewport-floating-panel viewport-floating-panel-left">
                        {leftChild}
                        <TransformControls
                            hidden={!imageAvailable}
                            onFitToScreen={fitToScreen}
                            onZoomIn={zoomIn}
                            onZoomOut={zoomOut}
                            onFullScreen={() => {
                                dispatch(toggleFullScreenMode());
                            }}
                        />
                    </div>
                    <div className="viewport-floating-panel viewport-floating-panel-right">{rightChild}</div>
                </div>
            </>
        );
    }
);

export default Viewport;
