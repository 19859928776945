import React, { useEffect, useRef } from 'react';
import './CustomDropdown.scss';
import '../../styles/icons.scss';
import { Dropdown } from 'primereact/dropdown';

export default function CustomDropdown({
    label,
    placeholder,
    options = [],
    disabled,
    onChange,
    selected,
    loading,
    focus,
}) {
    const dropdownRef = useRef();
    const handleChange = (v) => {
        onChange(v.value);
    };

    function formatOptions(options) {
        options = options.map((v) => ({ ...v, value: v.id || v.key, label: v.value }));
        return options;
    }
    function dropdownFocus() {
        if (focus && !disabled) {
            setTimeout(() => {
                dropdownRef.current.focus();
            }, 50);
        }
    }
    useEffect(() => {
        dropdownFocus();
    }, [focus,disabled]);

    return (
        <div className="custom-dropdown">
            <label>{label}</label>
            <div style={{ width: `calc(100% - ${label.length * 8}px)` }}>
                <Dropdown
                    ref={dropdownRef}
                    className="aq-select"
                    options={formatOptions(options)}
                    onChange={handleChange}
                    value={selected}
                    placeholder={placeholder}
                    filter={true}
                    disabled={disabled || loading}
                    dropdownIcon={loading ? 'pi pi-spin spinner-16' : 'pi pi-chevron-down'}
                />
            </div>
        </div>
    );
}
