import {setData, setVisible} from '../redux/reducer/ModalReducer';
import Store from '../redux/store/Store';

/**
 * This method is used to show the modal on the screen
 * @param {{title: string, message: string, class: string, component: React.ReactNode,
 * okCallBack: function, okText: string, cancelCallBack: function, cancelText: string,
 * disbaleOkButton: boolean, disbaleCancelButton: boolean}}
 */
export function showModal(props) {
    Store.dispatch(setVisible(true));
    Store.dispatch(setData(props));
}

/**
 * This method is used to hide the modal
 */
export function hideModal() {
    Store.dispatch(setVisible(false));
}