import React, { forwardRef, useEffect, useState } from 'react';
import './TopPanelMaintenceScheduler.scss';
import { showModal } from '../../modal/Modal';
import { getSites, getTemplateVersions, getUnits, getVessels } from '../../services/FlatoutDataAPI';
import CustomDropdown from '../dropdown/CustomDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    setOutageId,
    setSiteId,
    setTemplateVersion,
    setUnitId,
    setVesselId,
} from '../../redux/reducer/HierarchyReducer';
import { showLoading } from '../../utils/Loading';
import { Button } from 'primereact/button';
import { StatusPill } from '../statusPill/StatusPill';
import { setLoadingBlocker } from '../../redux/reducer/LoadingBlockerReducer';

const TopPanelMaintenceScheduler = forwardRef(({ children = <>

        </>, onEdit = () => {}, onSchedule = () => {}, onOptimise = () => {}, mntnScopePredictedData }, ref) => {
    const fullscreen = useSelector((state) => state.fullscreen.fullscreen);
    const isOnline = useSelector((state) => state.networkConnectionStatus.isOnline);
    const [sites, setSites] = useState([]);
    const [units, setUnits] = useState([]);
    const [vessels, setVessels] = useState([]);
    const [loadingUnits, setLoadingUnits] = useState(false);
    const [loadingVessels, setLoadingVessels] = useState(false);
    const [loadingVersions, setLoadingVersions] = useState(false);
    const [, setVersions] = useState([]);
    const [searchParams] = useSearchParams();

    let dispatch = useDispatch();
    const hierarchy = useSelector((state) => state.hierarchy);
    const loadingBlocker = useSelector((state) => state.loadingblocker.loadingblocker);

    /**
     * This useEffect function render once the page loaded and fetch site data
     * dropdown button will be disable when page is loading and offline scenario
     */
    useEffect(() => {
        async function fetchSiteData() {
            try {
                if (isOnline) {
                    let sites = await getSites(true);
                    let tempSites = [];
                    sites.map(function (site) {
                        return tempSites.push({ key: site.id, value: site.name });
                    });
                    setSites(tempSites);
                    tempSites.length <= 0 &&
                        showModal({
                            title: 'No Flatouts',
                            message: 'There are no published flatouts. Publish a flatout in Uploader to view it here.',
                            class: 'warning',
                        });
                    if (tempSites.map((v) => v.key).includes(parseInt(searchParams.get('siteId')))) {
                        dispatch(setSiteId(parseInt(searchParams.get('siteId'))));
                        if (
                            searchParams.get('unitId') == null &&
                            searchParams.get('vesselId') == null &&
                            searchParams.get('outageId') == null
                        ) {
                            window.history.replaceState({}, document.title, '/#/');
                            searchParams.forEach((value, key) => {
                                searchParams.delete(key);
                            });
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            } finally {
                showLoading(false);
            }
        }
        fetchSiteData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, searchParams]);

    /**
     * This function fetching the units when the user click over the drop-down
     * dropdown button will be disable when page is loading and offline scenario
     */

    function fetchUnits() {
        (async function () {
            setLoadingUnits(true);
            dispatch(setLoadingBlocker(true));
            try {
                if (isOnline) {
                    let units = await getUnits(hierarchy.siteId, true);
                    let tempUnits = [];
                    units.map(function (unit) {
                        return tempUnits.push({ key: unit.unitId, value: unit.unitName });
                    });
                    setUnits(tempUnits);
                    if (tempUnits.map((v) => v.key).includes(parseInt(searchParams.get('unitId')))) {
                        dispatch(setUnitId(parseInt(searchParams.get('unitId'))));
                        if (searchParams.get('vesselId') == null) {
                            window.history.replaceState({}, document.title, '/#/');
                            searchParams.forEach((value, key) => {
                                searchParams.delete(key);
                            });
                        }
                    } else if (parseInt(searchParams.get('siteId'))) {
                        showModal({
                            title: 'Unit required',
                            message: 'Please select an unit to continue!',
                            class: 'warning',
                        });
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoadingUnits(false);
                dispatch(setLoadingBlocker(false));
            }
        })();
    }

    /**
     * This function fetching the vessels when the user click over the drop-down
     * dropdown button will be disable when page is loading and offline scenario
     */

    function fetchVessels() {
        (async function () {
            setLoadingVessels(true);
            dispatch(setLoadingBlocker(true));
            try {
                if (isOnline) {
                    let vessels = await getVessels(hierarchy.siteUnitId, true);
                    let tempVessels = [];
                    vessels.map(function (vessel) {
                        return tempVessels.push({ key: vessel.id, value: vessel.name });
                    });
                    setVessels(tempVessels);
                    tempVessels.length <= 0 &&
                        showModal({
                            title: 'No Vessels',
                            message: 'The selected unit has no vessels.',
                            class: 'warning',
                        });
                    if (tempVessels?.map((v) => v.key).includes(parseInt(searchParams.get('vesselId')))) {
                        dispatch(setVesselId(parseInt(searchParams.get('vesselId'))));
                        window.history.replaceState({}, document.title, '/#/');
                        searchParams.forEach((value, key) => {
                            searchParams.delete(key);
                        });
                        searchParams.delete('unitId');
                    } else if (parseInt(searchParams.get('outageId')))
                        showModal({
                            title: 'Vessel required',
                            message: 'Please select a vessel to continue!',
                            class: 'warning',
                        });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingVessels(false);
                dispatch(setLoadingBlocker(false));
            }
        })();
    }

    /**
     * This function fetching the versions when the user click over the drop-down.
     * dropdown button will be disable when page is loading and offline scenario.
     */

    function fetchVersions() {
        (async function () {
            setLoadingVersions(true);
            dispatch(setLoadingBlocker(true));
            try {
                if (isOnline) {
                    let result = await getTemplateVersions(hierarchy.siteUnitId, hierarchy.vesselId, true);
                    let highestTemplateVersion = result[0];
                    let requiredTemplateVersion = result.filter((x) => x.id === highestTemplateVersion.id);
                    setVersions(requiredTemplateVersion);
                    if (result.length <= 0) {
                        dispatch(setTemplateVersion(-1));
                        showModal({
                            title: 'No Templates',
                            message:
                                'The selected vessel has no flatout templates! To add a flatout template click on "Upload"',
                            class: 'warning',
                        });
                    } else {
                        dispatch(setTemplateVersion(requiredTemplateVersion[0].id));
                    }
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoadingVersions(false);
                dispatch(setLoadingBlocker(false));
            }
        })();
    }

    useEffect(() => {
        if (hierarchy.siteId) {
            fetchUnits();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy.siteId]);

    useEffect(() => {
        if (hierarchy.siteUnitId) {
            fetchVessels();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy.siteUnitId]);

    useEffect(() => {
        if (hierarchy.vesselId) {
            fetchVersions();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy.vesselId]);

    //handle site change
    const handleChangeSite = (siteId) => {
        dispatch(setSiteId(siteId));
        dispatch(setUnitId(0));
        dispatch(setOutageId(0));
        dispatch(setVesselId(0));
        dispatch(setTemplateVersion(-1));
        // dispatch(setRevisionVersion(-1));
    };

    //handle unit change
    const handleChangeUnit = (unitId) => {
        dispatch(setUnitId(unitId));
        dispatch(setOutageId(0));
        dispatch(setVesselId(0));
        dispatch(setTemplateVersion(-1));
        // dispatch(setRevisionVersion(-1));
    };

    //handle vessel change
    const handleChangeVessel = (vesselId) => {
        dispatch(setVesselId(vesselId));
        dispatch(setOutageId(0));
        dispatch(setTemplateVersion(-1));
        // dispatch(setRevisionVersion(-1));
    };

    // handle version change
    const handleChangeVersion = (version) => {
        // var _version = versions[0];
        dispatch(setTemplateVersion(version));
        // dispatch(setRevisionVersion(version));
    };

    return (
        <>
            <div className="top-panel-maintence-scheduler">
                <div className="row m-12 mb-0" style={{ display: fullscreen ? 'none' : 'flex' }}>
                    <div className="col-25 pe-14">
                        <CustomDropdown
                            label={'Site:'}
                            options={sites}
                            onChange={handleChangeSite}
                            placeholder="Select a site"
                            selected={hierarchy.siteId}
                            loading={sites.length === 0}
                            disabled={
                                sites.length === 0 ||
                                loadingUnits ||
                                loadingVessels ||
                                loadingVersions ||
                                loadingBlocker
                            }
                            focus={sites.length !== 0}
                        />
                    </div>
                    <div className="col-25 pe-14">
                        <CustomDropdown
                            label={'Unit:'}
                            options={units}
                            onChange={handleChangeUnit}
                            placeholder="Select a unit"
                            selected={hierarchy.siteUnitId}
                            loading={loadingUnits}
                            disabled={hierarchy.siteId === 0 || loadingVessels || loadingVersions || loadingBlocker}
                            focus={units.length !== 0}
                        />
                    </div>
                    <div className="col-25 pe-14">
                        <CustomDropdown
                            label={'Vessel:'}
                            options={vessels}
                            onChange={handleChangeVessel}
                            placeholder="Select a vessel"
                            selected={hierarchy.vesselId}
                            loading={loadingVessels}
                            disabled={hierarchy.siteUnitId === 0 || loadingVersions || loadingBlocker}
                            focus={vessels.length !== 0}
                        />
                    </div>
                    <div className="col-25">
                        <CustomDropdown
                            label={'Version:'}
                            options={[
                                {
                                    key: hierarchy.templateVersion,
                                    label: hierarchy.templateVersion + '',
                                    value: hierarchy.templateVersion > 0 ? hierarchy.templateVersion : null,
                                },
                            ]}
                            onChange={handleChangeVersion}
                            selected={hierarchy.templateVersion}
                            loading={loadingVersions}
                            disabled={true || loadingBlocker}
                        />
                    </div>
                </div>

                <div className="row m-12">
                    <div className="col-50">
                        <div className="row">
                            {fullscreen && (
                                <div
                                    className="alcoa-logo-blue"
                                    style={{ flex: 'none', marginBottom: -4, marginRight: 8 }}
                                />
                            )}
                            <div className="col-auto">
                                <div className="row" style={{ gap: 8 }}>
                                    {' '}
                                    {mntnScopePredictedData.length > 0 ? children : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-auto" style={{ textAlign: 'right', width: -1, minWidth: 296, maxHeight: 206 }}>
                        {!isOnline && <StatusPill message="Offline" className="warning" />}
                        <Button
                            label="Edit"
                            icon="pi pi-pencil"
                            className="aq-btn"
                            disabled={hierarchy.templateVersion === -1 || mntnScopePredictedData.length === 0}
                            onClick={onEdit}
                        />
                        <Button
                            label="Optimise Overhaul"
                            className="aq-btn aq-primary m-12 mt-0 mr-0 mb-0"
                            disabled={hierarchy.templateVersion === -1 || mntnScopePredictedData.length === 0}
                            onClick={onOptimise}
                        />
                        {/* Below code is commented because the autoscheduling code has some bug which couldn't be fixed
                        before go live. The below functionality can still be achieved by manually editting a prediction 
                        by pressing the "Edit" button, creating an outage if necessary, then saving the revision and
                        clicking on "Estimate Refractory Materials"*/}
                        {/* <Button
                            label="Schedule Maintenance"
                            className="aq-btn aq-suggestive m-12 mt-0 mr-0 mb-0"
                            disabled={hierarchy.templateVersion === -1 || mntnScopePredictedData.length === 0}
                            onClick={onSchedule}
                        /> */}
                    </div>
                </div>
            </div>
        </>
    );
});

export default TopPanelMaintenceScheduler;
