import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../reducer/UserReducer';
import loadingReducer from '../reducer/LoadingReducer';
import toolReducer from '../reducer/ToolReducer';
import modalReducer from '../reducer/ModalReducer';
import hierarchyReducer from '../reducer/HierarchyReducer';
import timeReducer from '../reducer/TimeReducer';
import networkConnectionReducer from '../reducer/NetworkConnectionReducer';
import fullScreenReducer from '../reducer/FullScreenReducer';
import loadingBlockerReducer from '../reducer/LoadingBlockerReducer'
import reduxTriggersReducer from '../reducer/ReduxTriggers';

export default configureStore({
    reducer: {
        user: userReducer,
        loading:loadingReducer,
        modal:modalReducer,
        tool : toolReducer,
        hierarchy:hierarchyReducer,
        timeClock:timeReducer,
        fullscreen: fullScreenReducer,
        networkConnectionStatus:networkConnectionReducer,
        loadingblocker:loadingBlockerReducer,
        reduxTriggers:reduxTriggersReducer
      },
      middleware:(getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoreActions:['modal/setData'],
          ignoredActionPaths:['modal.data.okCallBack'],
          ignoredPaths: ['modal.data.okCallBack']
        }
      })
});