import { EventType } from "../config/Constants";

export class AnnotationEvent {

    /**
     * This method is used to draw only the bounding box 
     * @param {TextEvent} self 
     * @param {CanvasRenderingContext2D} displayContext 
     */
    static drawOnlyBoundingBox(/**@type{TextEvent} */self,/**@type {CanvasRenderingContext2D} */displayContext,/**@type{{x:int,y:int}} */ offset, color = '#FFD700', ) {
            displayContext.lineWidth=2
            displayContext.globalCompositeOperation = 'source-over'
            displayContext.strokeStyle = color
            var properties = self.flatoutEventType === EventType.SCRIBBLE ? 'scribbleProperties' : 'textProperties'
            if (offset) {
                displayContext.strokeRect(
                    self[properties].rs.x + offset.x,
                    self[properties].rs.y + offset.y,
                    self[properties].re.x - self[properties].rs.x,
                    self[properties].re.y - self[properties].rs.y 
                );
            } else {
                displayContext.strokeRect(
                    self[properties].rs.x,
                    self[properties].rs.y,
                    self[properties].re.x - self[properties].rs.x,
                    self[properties].re.y - self[properties].rs.y
                );
            }
     
    }
}