import { createSlice } from '@reduxjs/toolkit';

export const loadingBlockerSlice = createSlice({
    name: 'loadingblocker',
    initialState: {
        loadingblocker: false,
    },
    reducers: {
        setLoadingBlocker: (state, action) => {
            state.loadingblocker = action.payload;
        },
    },
});
export const { setLoadingBlocker } = loadingBlockerSlice.actions;
export default loadingBlockerSlice.reducer;
