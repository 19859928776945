import Moment from 'moment';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { showModal } from '../../modal/Modal';
import {
    setOutageId,
    setRevisionVersion,
    setSiteId,
    setTemplateVersion,
    setUnitId,
    setVesselId,
} from '../../redux/reducer/HierarchyReducer';
import { setLoadingBlocker } from '../../redux/reducer/LoadingBlockerReducer';
import { setTool } from '../../redux/reducer/ToolReducer';
import { ContextLockAPI } from '../../services/ContextLockAPI';
import { getSites, getTemplateVersions, getUnits, getVessels } from '../../services/FlatoutDataAPI';
import { ActionType } from '../../utils/Action';
import { showLoading } from '../../utils/Loading';
import { StaticStates } from '../../utils/StaticeStates';
import CustomDropdown from '../dropdown/CustomDropdown';
import { StatusPill } from '../statusPill/StatusPill';
import './TopPanelUploadTemplates.scss';

const TopPanelUploadTemplates = forwardRef(
    (
        {
            scale,
            sheet,
            flatout = null,
            analysing,
            validating,
            disabled = false,
            saveRequired = false,
            onClickImageUpload = () => {},
            onClickImageReUpload = () => {},
            onClickSave = () => {},
            onClickImageDelete = () => {},
            onClickImagePublish = () => {},
            onClickScaleSheetSize = () => {},
            locked, // Locked by me not the other user
            onSetLock = () => {},
            imageError,
        },
        ref
    ) => {
        const [sites, setSites] = useState([]);
        const [units, setUnits] = useState([]);
        const [vessels, setVessels] = useState([]);
        const [versions, setVersions] = useState([]);
        const [searchParams] = useSearchParams();
        const [showInfoModal, setShowInfoModal] = useState(false);
        const [showPublishDetails, setShowPublishDetails] = useState(false);
        const [showErrorDetails, setShowErrorDetails] = useState(false);
        const [loadingUnits, setLoadingUnits] = useState(false);
        const [loadingVessels, setLoadingVessels] = useState(false);
        const [loadingVersions, setLoadingVersions] = useState(false);
        const [loadingLock, setLoadingLock] = useState(false);
        const [lockedBy, setLockedBy] = useState(null);
        const [showLockDetails, setShowLockDetails] = useState(false);
        const hierarchy = useSelector((state) => state.hierarchy);
        let dispatch = useDispatch();
        const milliScecondsElapsed = useSelector((state) => state.timeClock.ms);
        const currentUser = useSelector((state) => state.user);
        const isOnline = useSelector((state) => state.networkConnectionStatus.isOnline);
        const fullscreen = useSelector((state) => state.fullscreen.fullscreen);
        const loadingBlocker = useSelector((state) => state.loadingblocker.loadingblocker);

        /**
         * This useEffect function render once the page loaded and fetch site data.
         * dropdown button will be disable when page is loading and offline scenario.
         */
        useEffect(() => {
            dispatch(setOutageId(0));
            dispatch(setRevisionVersion(-1));
            async function fetchSiteData() {
                try {
                    if (isOnline) {
                        let sites = await getSites();
                        let tempSites = [];
                        sites.map(function (site) {
                            return tempSites.push({ key: site.id, value: site.name });
                        });
                        setSites(tempSites);
                        tempSites.length <= 0 &&
                            showModal({
                                title: 'No Sites',
                                message:
                                    'There are no sites. Make sure sites are setup in RMS in [Data Entry > Equipment Setup > Site].',
                                class: 'warning',
                            });
                        if (tempSites.map((v) => v.key).includes(parseInt(searchParams.get('siteId')))) {
                            dispatch(setSiteId(parseInt(searchParams.get('siteId'))));
                            if (searchParams.get('unitId') == null && searchParams.get('vesselID') == null) {
                                window.history.replaceState({}, document.title, '/#/upload');
                                searchParams.forEach((value, key) => {
                                    searchParams.delete(key);
                                });
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    showLoading(false);
                }
            }

            fetchSiteData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, searchParams]);

        /**
         * This function fetching the units when the user click over the drop-down.
         * dropdown button will be disable when page is loading and offline scenario.
         */
        function fetchUnits() {
            (async function () {
                setLoadingUnits(true);
                try {
                    if (isOnline) {
                        let units = await getUnits(hierarchy.siteId);
                        let tempUnits = [];
                        units.map(function (unit) {
                            return tempUnits.push({ key: unit.unitId, value: unit.unitName });
                        });
                        setUnits(tempUnits);
                        tempUnits.length <= 0 &&
                            showModal({
                                title: 'No Units',
                                message:
                                    'The selected site has no units. Make sure Units are setup in RMS in [Data Entry > Equipment Setup > Site-Unit].',
                                class: 'warning',
                            });
                        if (tempUnits.map((v) => v.key).includes(parseInt(searchParams.get('unitId')))) {
                            dispatch(setUnitId(parseInt(searchParams.get('unitId'))));
                            if (searchParams.get('vesselId') == null) {
                                window.history.replaceState({}, document.title, '/#/upload');
                                searchParams.forEach((value, key) => {
                                    searchParams.delete(key);
                                });
                            }
                        } else if (parseInt(searchParams.get('siteId'))) {
                            showModal({
                                title: 'Unit required',
                                message: 'Please select an unit to continue!',
                                class: 'warning',
                            });
                        }
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoadingUnits(false);
                    dispatch(setLoadingBlocker(false));
                }
            })();
        }

        /**
         * This function fetching the vessels when the user click over the drop-down.
         * dropdown button will be disable when page is loading and offline scenario.
         */
        function fetchVessels() {
            (async function () {
                setLoadingVessels(true);
                dispatch(setLoadingBlocker(true));
                try {
                    if (isOnline) {
                        let vessel = await getVessels(hierarchy.siteUnitId);
                        let tempVessels = [];
                        vessel.map(function (vessel) {
                            return tempVessels.push({ key: vessel.id, value: vessel.name });
                        });
                        setVessels(tempVessels);
                        tempVessels.length <= 0 &&
                            showModal({
                                title: 'No Vessels',
                                message:
                                    'The selected unit has no vessels. Make sure Vessels are setup in RMS in [Utilities > Vessels] and [Data Entry > Equipment Setup > Site-Unit-Vessel]',
                                class: 'warning',
                            });
                        if (tempVessels?.map((v) => v.key).includes(parseInt(searchParams.get('vesselId')))) {
                            dispatch(setVesselId(parseInt(searchParams.get('vesselId'))));
                            window.history.replaceState({}, document.title, '/#/upload');
                            searchParams.forEach((value, key) => {
                                searchParams.delete(key);
                            });
                            searchParams.delete('unitId');
                        } else if (parseInt(searchParams.get('unitId'))) {
                            showModal({
                                title: 'Vessel required',
                                message: 'Please select a vessel to continue!',
                                class: 'warning',
                            });
                        }
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoadingVessels(false);
                    dispatch(setLoadingBlocker(false));
                }
            })();
        }

        /**
         * This function fetching the versions when the user click over the drop-down.
         * dropdown button will be disable when page is loading and offline scenario.
         */
        function fetchVersions() {
            (async function () {
                setLoadingVersions(true);
                dispatch(setLoadingBlocker(true));
                try {
                    if (isOnline) {
                        let result = await getTemplateVersions(hierarchy.siteUnitId, hierarchy.vesselId);
                        setVersions(result.sort((a, b) => b.id - a.id));

                        if (result.length <= 0) {
                            dispatch(setTemplateVersion(-1));
                            showModal({
                                title: 'No Templates',
                                message:
                                    'The selected vessel has no flatout templates! To add a flatout template click on "Upload"',
                                class: 'warning',
                            });
                        } else {
                            dispatch(setTemplateVersion(result[0].id));
                        }
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoadingVersions(false);
                    dispatch(setLoadingBlocker(false));
                }
            })();
        }

        useImperativeHandle(ref, () => ({
            fetchVersions,
            getLatestVersion,
        }));

        const getLatestVersion = () => {
            return versions[0];
        };

        /**
         * This function update lastest version of the page which incules the major or minor version.
         */

        const findLatestVersionId = (versions) => {
            // if (version.length === 0) return -1;
            let majors = versions.map((v) => v.majorVersion);
            let maxMajor = Math.max(...majors);
            let minors = versions.filter((v) => v.majorVersion === maxMajor).map((v) => v.minorVersion);
            let maxMinor = Math.max(...minors);
            let latestV = versions.find((v) => v.minorVersion === maxMinor && v.majorVersion === maxMajor);
            return latestV?.id;
        };

        const handleChangeSite = (siteId) => {
            dispatch(setSiteId(siteId));
            dispatch(setUnitId(0));
            dispatch(setVesselId(0));
            dispatch(setOutageId(0));
            dispatch(setTemplateVersion(-1));
        };

        const handleChangeUnit = (unitId) => {
            dispatch(setUnitId(unitId));
            dispatch(setVesselId(0));
            dispatch(setOutageId(0));
            dispatch(setTemplateVersion(-1));
        };

        const handleChangeVessel = (vesselId) => {
            dispatch(setVesselId(vesselId));
            dispatch(setOutageId(0));
            dispatch(setTemplateVersion(-1));
        };

        const handleChangeVersion = (version) => {
            dispatch(setTemplateVersion(version));
        };

        useEffect(() => {
            if (hierarchy.siteId) {
                fetchUnits();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy.siteId]);

        useEffect(() => {
            if (hierarchy.siteUnitId) {
                fetchVessels();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy.siteUnitId]);

        useEffect(() => {
            if (hierarchy.vesselId) {
                fetchVersions();
            } // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy.vesselId]);

        const onBadgeClick = () => {
            onClickScaleSheetSize();
        };
        /**
         * This function is used to lock a particular image if there is any issue then error modal comes on the screen.
         */
        async function attemptLock() {
            try {
                return await ContextLockAPI.lock(hierarchy.siteUnitId, hierarchy.vesselId);
            } catch (e) {
                if (StaticStates.UploaderLockStatusAcquired) {
                    console.warn(e);
                } else {
                    console.error(e);
                    setLockedBy(null);
                    onSetLock(false);
                    showModal({
                        title: 'Critical Error',
                        message: 'Unable to acquire lock due to some unknown error',
                        class: 'error',
                    });
                }

                return false;
            }
        }

        /**
         * This function is used for checking the locking status and set the lock for particular image.
         */
        function uploaderLock() {
            setLoadingLock(true);
            attemptLock().then((lockResponse) => {
                if (isOnline) {
                    if (lockResponse) {
                        StaticStates.UploaderLockStatusAcquired = true;
                        onSetLock(true);
                        setLoadingLock(false);
                        StaticStates.UploaderLockTimerActive = true;
                        setLockedBy(null);
                        StaticStates.BackupHierarchy.siteUnitId = hierarchy.siteUnitId;
                        StaticStates.BackupHierarchy.vesselId = hierarchy.vesselId;
                        StaticStates.BackupHierarchy.outageId = 0;
                    } else {
                        onSetLock(false);
                        StaticStates.UploaderLockTimerActive = false;
                        ContextLockAPI.getOwner(hierarchy.siteUnitId, hierarchy.vesselId).then((userId) => {
                            StaticStates.UploaderLockStatusAcquired = true;
                            setLoadingLock(false);
                            if (userId !== currentUser && getLatestVersion().key <= hierarchy.templateVersion) {
                                console.warn('Already locked by ' + userId);
                                setLockedBy(userId);
                                confirmDialog({
                                    message: `Another admin (${userId}) has this flatout image open and therefore it cannot be uploaded, marked or published.`,
                                    header: 'Flatout Locked',
                                    icon: 'pi pi-lock',
                                    className: 'aq-dialog aq-dialog-danger',
                                    maskClassName: 'aq-dialog-backdrop',
                                    style: { minWidth: 300, width: '40vw' },
                                    accept: async () => {
                                        try {
                                            uploaderLock();
                                        } catch (error) {}
                                    },
                                    acceptClassName: 'aq-btn aq-primary',
                                    acceptLabel: 'Try again',
                                    rejectLabel: 'Cancel',
                                    rejectClassName: 'aq-btn',
                                });
                            }
                        });
                    }
                }
            });
        }

        /**
         * This function is used for polling the status of image for every interval which is set by API if the user switch and refresh the page then after particular page will be unlock and avilable for all the user.
         */

        async function polling() {
            if (isOnline) {
                if (hierarchy.siteUnitId > 0 && hierarchy.vesselId > 0 && !StaticStates.FlatoutIsPublished) {
                    if (
                        hierarchy.siteUnitId !== StaticStates.BackupHierarchy.siteUnitId ||
                        hierarchy.vesselId !== StaticStates.BackupHierarchy.vesselId
                    ) {
                        await ContextLockAPI.unlock(
                            StaticStates.BackupHierarchy.siteUnitId,
                            StaticStates.BackupHierarchy.vesselId
                        ).then(() => {
                            console.info('Unlocked Successfully');
                        });
                        StaticStates.BackupHierarchy.siteUnitId = hierarchy.siteUnitId;
                        StaticStates.BackupHierarchy.vesselId = hierarchy.vesselId;
                    }
                    await ContextLockAPI.lock(
                        StaticStates.BackupHierarchy.siteUnitId,
                        StaticStates.BackupHierarchy.vesselId
                    ).then((lockSuccess) => {
                        if (!lockSuccess) {
                            onSetLock(false);
                            StaticStates.UploaderLockTimerActive = false;
                            ContextLockAPI.getOwner(hierarchy.siteUnitId, hierarchy.vesselId).then((userId) => {
                                StaticStates.UploaderLockStatusAcquired = true;
                                if (userId !== currentUser) {
                                    console.warn('Already locked by ' + userId);
                                    setLockedBy(userId);
                                    confirmDialog({
                                        message: `Another admin (${userId}) has this flatout image open and therefore it cannot be uploaded, marked or published.`,
                                        header: 'Flatout Locked',
                                        icon: 'pi pi-lock',
                                        className: 'aq-dialog aq-dialog-danger',
                                        maskClassName: 'aq-dialog-backdrop',
                                        style: { minWidth: 300, width: '40vw' },
                                        accept: async () => {
                                            try {
                                                uploaderLock();
                                            } catch (error) {}
                                        },
                                        acceptClassName: 'aq-btn aq-primary',
                                        acceptLabel: 'Try again',
                                        rejectLabel: 'Cancel',
                                        rejectClassName: 'aq-btn',
                                    });
                                }
                            });
                        } else {
                            StaticStates.UploaderLockStatusAcquired = true;
                        }
                    });
                } else {
                    await ContextLockAPI.unlock(
                        StaticStates.BackupHierarchy.siteUnitId,
                        StaticStates.BackupHierarchy.vesselId
                    ).then(() => {
                        console.info('Unlocked Successfully');
                        onSetLock(false);
                        setLockedBy(null);
                        StaticStates.UploaderLockTimerActive = false;
                    });
                }
            }
        }

        useEffect(() => {
            setLockedBy(null);
            StaticStates.UploaderLockStatusAcquired = false;
            if (
                hierarchy.siteUnitId > 0 &&
                hierarchy.vesselId > 0 &&
                StaticStates.FlatoutDataAcquired &&
                flatout &&
                !flatout.isPublished
            ) {
                if (!locked) {
                    uploaderLock();
                } else {
                    polling();
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy, flatout]);

        useEffect(() => {
            return () => {
                dispatch(setTool(ActionType.TRANSFORM));
                if (StaticStates.BackupHierarchy.siteUnitId > 0 && StaticStates.BackupHierarchy.vesselId > 0) {
                    ContextLockAPI.unlock(
                        StaticStates.BackupHierarchy.siteUnitId,
                        StaticStates.BackupHierarchy.vesselId
                    ).then(() => {
                        console.info('Uploader Unlock successful ');
                        StaticStates.UploaderLockTimerActive = false;
                    });
                }
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (StaticStates.UploaderLockTimerActive) {
                polling();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [milliScecondsElapsed]);

        return (
            <>
                <div className="top-panel-upload-templates">
                    <div className="row m-12 mb-0" style={{ display: fullscreen ? 'none' : 'flex' }}>
                        <div className="col-25 pe-14">
                            <CustomDropdown
                                label={'Site:'}
                                options={sites}
                                onChange={handleChangeSite}
                                placeholder="Select a site"
                                selected={hierarchy.siteId}
                                loading={sites.length === 0}
                                disabled={
                                    validating ||
                                    sites.length === 0 ||
                                    loadingUnits ||
                                    loadingVessels ||
                                    loadingVersions ||
                                    loadingBlocker
                                }
                                focus={sites.length !== 0}
                            />
                        </div>
                        <div className="col-25 pe-14">
                            <CustomDropdown
                                label={'Unit:'}
                                options={units}
                                onChange={handleChangeUnit}
                                placeholder="Select a unit"
                                selected={hierarchy.siteUnitId}
                                loading={loadingUnits}
                                disabled={
                                    hierarchy.siteId === 0 ||
                                    validating ||
                                    loadingVessels ||
                                    loadingVersions ||
                                    !isOnline ||
                                    loadingBlocker
                                }
                                focus={units.length !== 0}
                            />
                        </div>
                        <div className="col-25 pe-14">
                            <CustomDropdown
                                label={'Vessel:'}
                                options={vessels}
                                onChange={handleChangeVessel}
                                placeholder="Select a vessel"
                                selected={hierarchy.vesselId}
                                loading={loadingVessels}
                                disabled={
                                    hierarchy.siteUnitId === 0 ||
                                    validating ||
                                    loadingVersions ||
                                    !isOnline ||
                                    loadingBlocker
                                }
                                focus={vessels.length !== 0}
                            />
                        </div>
                        <div className="col-25">
                            <CustomDropdown
                                label={'Version:'}
                                options={versions}
                                onChange={handleChangeVersion}
                                placeholder="Select a version"
                                selected={hierarchy.templateVersion}
                                disabled={
                                    hierarchy.vesselId === 0 ||
                                    validating ||
                                    versions.length === 0 ||
                                    !isOnline ||
                                    loadingBlocker
                                }
                                loading={loadingVersions}
                                focus={vessels.length !== 0}
                            />
                        </div>
                    </div>
                    <div className="row m-12">
                        <div className="col-auto">{fullscreen && <span className="alcoa-logo-blue" />}</div>
                        {hierarchy.vesselId !== null && (
                            <div className="col-100" style={{ textAlign: 'right' }}>
                                <div
                                    className="status-pill-panel"
                                    style={{
                                        display:
                                            hierarchy.siteUnitId > 0 &&
                                            hierarchy.vesselId &&
                                            hierarchy.templateVersion >= 0
                                                ? 'inline-flex'
                                                : 'none',
                                    }}
                                >
                                    {(flatout && !flatout.isAnalysed) || analysing ? (
                                        <StatusPill message="Analysing" className="warning" />
                                    ) : (
                                        <>
                                            {flatout && flatout.isPublished && sheet ? (
                                                <>
                                                    <StatusPill
                                                        message="Published"
                                                        className="success"
                                                        action="Details"
                                                        onActionActivate={() => {
                                                            setShowPublishDetails(true);
                                                        }}
                                                    />
                                                    <StatusPill
                                                        message={'Scale (1:' + scale + '), Sheet ' + sheet.sheetSize}
                                                    />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                            {flatout && !flatout.isPublished && sheet ? (
                                                <>
                                                    <StatusPill className="secondary" message="Draft" />
                                                    <StatusPill
                                                        message={'Scale (1:' + scale + '), Sheet ' + sheet.sheetSize}
                                                        action={lockedBy !== null || loadingLock ? null : 'Edit'}
                                                        onActionActivate={onBadgeClick.bind(this)}
                                                    />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                    {!isOnline && <StatusPill message="Offline" className="warning" />}
                                    {loadingLock && <StatusPill message="Acquiring Lock…" className="warning" />}
                                    {lockedBy !== null && !loadingLock ? (
                                        <StatusPill
                                            message="Locked"
                                            className="danger"
                                            action="Details"
                                            onActionActivate={() => {
                                                setShowLockDetails(true);
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {[hierarchy.templateVersion, -1, undefined, null].includes(
                                    findLatestVersionId(versions)
                                ) && (
                                    <>
                                        <Button
                                            icon="pi pi-info"
                                            title="Flatout Image Conditions"
                                            className="aq-btn"
                                            style={{ maxWidth: 32, verticalAlign: 'middle' }}
                                            disabled={disabled || hierarchy.vesselId == null}
                                            onClick={() => setShowInfoModal(true)}
                                        />
                                        {hierarchy.siteUnitId &&
                                        isOnline &&
                                        hierarchy.vesselId &&
                                        ((flatout && flatout.isPublished) || versions?.length < 1) ? (
                                            <Button
                                                icon="pi pi-cloud-upload"
                                                label={'Upload' + (flatout && flatout.isPublished ? ' New' : '')}
                                                className="aq-btn aq-suggestive m-12 mt-0 mr-0 mb-0"
                                                style={{ maxWidth: 150, maxHeight: 32, verticalAlign: 'middle' }}
                                                onClick={onClickImageUpload}
                                                disabled={validating || lockedBy !== null || loadingLock}
                                            />
                                        ) : (
                                            ''
                                        )}
                                        {hierarchy.siteUnitId &&
                                        hierarchy.vesselId &&
                                        hierarchy.templateVersion >= 0 &&
                                        flatout &&
                                        isOnline &&
                                        !flatout.isPublished &&
                                        !analysing ? (
                                            <>
                                                <Button
                                                    icon="pi pi-save"
                                                    title="Save the zone maps. This is necessary for area calculation to work."
                                                    className="aq-btn aq-primary m-12 mt-0 mr-0 mb-0"
                                                    style={{
                                                        maxWidth: 32,
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={onClickSave}
                                                    disabled={loadingLock || imageError !== null || lockedBy !== null || !saveRequired}
                                                />
                                                <Button
                                                    title="Delete this vessel flatout image."
                                                    icon="pi pi-trash"
                                                    className="aq-btn aq-destructive m-12 mt-0 mr-0 mb-0"
                                                    style={{
                                                        maxWidth: 32,
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={onClickImageDelete}
                                                    disabled={loadingLock || lockedBy !== null}
                                                />
                                                <Button
                                                    icon="pi pi-angle-double-up"
                                                    title="Publish this image. It will make it available to users for marking"
                                                    label="Publish"
                                                    className="aq-btn aq-suggestive m-12 mt-0 mr-0 mb-0"
                                                    style={{
                                                        maxWidth: 120,
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={onClickImagePublish}
                                                    disabled={loadingLock || imageError !== null || lockedBy !== null}
                                                />
                                                <Button
                                                    title="Upload a new template."
                                                    icon="pi pi-cloud-upload"
                                                    className="aq-btn aq-primary m-12 mt-0 mr-0 mb-0"
                                                    style={{
                                                        maxWidth: 32,
                                                        verticalAlign: 'middle',
                                                    }}
                                                    onClick={onClickImageReUpload}
                                                    disabled={loadingLock || lockedBy !== null}
                                                />
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <Dialog
                        header="Flatout Image Validation Conditions"
                        visible={showInfoModal}
                        style={{ width: '50vw', minWidth: 600 }}
                        onHide={() => setShowInfoModal(false)}
                        className="aq-dialog"
                        maskClassName="aq-dialog-backdrop"
                        closeIcon="pi pi-times"
                    >
                        <>
                            <p className="m-0">
                                For the image to be usable in the application the following conditions need to be met:
                            </p>
                            <ul>
                                <li>
                                    <span className="highlight">Scanned</span> images are not supported. Please make
                                    sure the uploaded image is&nbsp;
                                    <span className="highlight">directly exported from the CAD software</span>.
                                </li>
                                <li>
                                    The image needs to be minimum <span className="highlight">5000x3500px</span>{' '}
                                    resolution. Export image with the highest possible resolution from the CAD software
                                    (max 9000x6000).{' '}
                                    <span className="highlight">
                                        Do not resize the drawing in a 3rd party tool after it has been exported.
                                    </span>
                                </li>
                                <li>
                                    The image must be a <span className="highlight">tif/png/bmp/jpeg</span> image.
                                </li>
                                <li>{'The image should be of the highest quality (sharpness and clarity)'}</li>
                                <li>
                                    The image should be <span className="highlight">monochromatic</span> / black and
                                    white.
                                </li>
                                <li>
                                    The image should have proper scale and sheet size labels, with&nbsp;
                                    <span className="highlight">consistent font, size and position</span>.
                                </li>
                                <li>The cell and zone boundaries should not have any breakage.</li>
                            </ul>
                        </>
                    </Dialog>
                    <Dialog
                        header="Flatout Published By"
                        className="aq-dialog"
                        maskClassName="aq-dialog-backdrop"
                        visible={showPublishDetails}
                        style={{ width: '50vw', minWidth: 600 }}
                        onHide={() => setShowPublishDetails(false)}
                        closeIcon="pi pi-times"
                    >
                        <>
                            <h1>{flatout ? flatout.modifiedBy : ''}</h1>
                            <h2>
                                on&nbsp;<span>{Moment(flatout ? flatout.modifiedDate : new Date()).format('LL')}</span>
                            </h2>
                        </>
                    </Dialog>
                    <Dialog
                        header="Image couldn't be processed"
                        className="aq-dialog aq-dialog-danger"
                        maskClassName="aq-dialog-backdrop"
                        visible={showErrorDetails}
                        style={{ width: '50vw' }}
                        onHide={() => setShowErrorDetails(false)}
                        closeIcon="pi pi-times"
                    >
                        <>
                            <h1>Reason:</h1>
                            <h2>{imageError}</h2>
                        </>
                    </Dialog>
                    <Dialog
                        header="Flatout Locked"
                        className="aq-dialog aq-dialog-danger"
                        maskClassName="aq-dialog-backdrop"
                        visible={showLockDetails}
                        style={{ width: '50vw' }}
                        onHide={() => setShowLockDetails(false)}
                        closeIcon="pi pi-times"
                        footer={
                            <>
                                <Button
                                    label="Cancel"
                                    className="aq-btn"
                                    onClick={() => {
                                        setShowLockDetails(false);
                                    }}
                                />
                                <Button
                                    label="Try Again"
                                    className="aq-btn aq-primary"
                                    onClick={() => {
                                        try {
                                            uploaderLock();
                                            setShowLockDetails(false);
                                        } catch (e) {}
                                    }}
                                />
                            </>
                        }
                    >
                        <>
                            <p>
                                Another admin <i>{`(${lockedBy})`}</i> has this flatout image open and therefore it
                                cannot be uploaded, marked or published.
                            </p>
                        </>
                    </Dialog>
                </div>
            </>
        );
    }
);

export default TopPanelUploadTemplates;
