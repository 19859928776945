import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
    name:'loading',
    initialState:{
        loading:0,
        minimized:false,
    },
    reducers:{
        setLoading:(state,action)=>{
            state.loading = action.payload ? state.loading+1 : state.loading-1;
            state.loading = Math.max(state.loading, 0);
        },
        setMinimized :(state,action)=>{
            state.minimized = action.payload;
        },
       
    }
})
export const {setLoading, setMinimized} = loadingSlice.actions;
export default loadingSlice.reducer;