import { showModal } from '../modal/Modal';

export function handleError(errorResponse) {
  /**
 * This method is used to handle all the errors in a centralised manner
 * @param {*} errorResponse 
 * @returns 
 */
    if (!errorResponse) {
        showModal({ title: 'Network Error', message: 'Aw snap ! Connection to API server failed', class: 'error' });
    }
    else if (errorResponse.error && errorResponse.error instanceof ProgressEvent) {
        if (errorResponse.status === 0) {
            showModal({ title: 'Network Error', message: 'Aw snap ! Connection to API server failed', class: 'error' });
        } else {
            showModal({ title: 'Network Error', message: errorResponse.message, class: 'error' });
        }
    } else if (errorResponse.error && errorResponse.error instanceof ErrorEvent) {
        showModal({
            title: 'Request failed',
            message: 'Something went wrong! RMS Flatout app ran into an unexpected problem! A browser refresh is recommended.',
            class: 'error',
        });
    } else {
        var titleText = '';
        var _response = errorResponse.response ? errorResponse.response : errorResponse;
        var status = _response.status;
        if (status !== undefined && status !== null) {
            switch (status) {
                case 0:
                    titleText = 'Network Not Available';
                    showModal({ title: titleText, message: _response.data ? _response.data : "Aw Snap ! Conection to the internet server lost", class: 'error' })
                    return;
                case 204:
                    titleText = 'No Content';
                    return [];
                case 400:
                    titleText = 'Something is wrong!';
                    break;
                case 401:
                    titleText = 'Access Denied!';
                    break;
                case 403:
                    titleText = 'Forbidden Operation!';
                    break;
                case 405:
                    titleText = 'Operation Not Allowed!';
                    break;
                case 408:
                    titleText = 'Request Timed Out!';
                    break;
                case 429:
                    titleText = 'API Server is Busy';
                    break;
                case 500:
                    titleText = 'API Server Error!';
                    break;
                case 501:
                    titleText = 'Feature Not Available';
                    break;
                case 502:
                    titleText = 'Gateway Error!';
                    break;
                case 503:
                    titleText = 'API Server Unavailable';
                    break;
                case 504:
                    titleText = 'Taking Too Long';
                    break;
                default:
                    titleText = 'Error ' + status;
            }
            showModal({ title: titleText, message: _response.data, class: 'error' })
        }
    }
    return errorResponse;
}
