import { useEffect } from "react";
import { showLoading } from "../../utils/Loading";
import "./AccessDeniedPage.scss";

export default function AccessDeniedPage() {
  const errorMessage = "You do not have permission to view this page";

  useEffect(() => {
    setTimeout(() => {
      showLoading(false);
    }, 1000);
  }, [])

  return (
    <div className="container">
      <div className="access-error-image"></div>
      <h1 className="h1 text-primary" style={{fontSize:'large'}}>Access Denied!</h1>
      <div>{errorMessage}</div>
    </div>
  );
}
