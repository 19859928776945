import { AnnotationProperties } from './AnnotationProperties';
// This class stores all the Scribble properties as a single object
export class ScribbleProperties extends AnnotationProperties {
    /** @type {Array}ScribblePath*/
    sp = [];
    constructor(scribblepath, colorValue, lineThickness, rectStart, rectEnd) {
        super(colorValue, lineThickness, rectStart, rectEnd);
        this.sp = scribblepath;
    }
}
