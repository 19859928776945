import { useDispatch, useSelector } from 'react-redux';
import { setTool } from '../../redux/reducer/ToolReducer';
import { ActionType } from '../../utils/Action';
import { Button } from 'primereact/button';
import '../../styles/icons.scss';
import './ToolsBarUploadTemplates.scss';
import { Tooltip } from 'primereact/tooltip';

export default function ToolsBarUploadTemplates({ disabled = false }) {
    const dispatch = useDispatch();
    const selectedTool = useSelector((state) => state.tool.tool);

    function setSelected(val) {
        if (disabled) return;
        dispatch(setTool(val));
    }

    return (
        <div className="aq-floating-panel">
            <div className={'toolbox' + (disabled ? ' disabled' : '')}>
                <Button
                    icon="tool-icon tool-icon-transform"
                    id='transform-tool'
                    className={selectedTool === ActionType.TRANSFORM ? 'active' : ''}
                    onClick={() => setSelected(ActionType.TRANSFORM)}
                />
                <Button
                    icon="tool-icon tool-icon-id-cell"
                    id='id-cell-tool'
                    className={selectedTool === ActionType.FILLCELL ? 'active' : ''}
                    onClick={() => setSelected(ActionType.FILLCELL)}
                />
                <Button
                    icon="tool-icon tool-icon-id-region"
                    id='id-region-tool'
                    className={selectedTool === ActionType.FILLREGION ? 'active' : ''}
                    onClick={() => setSelected(ActionType.FILLREGION)}
                />
                <Button
                    icon="tool-icon tool-icon-delete"
                    id='delete-tool'
                    className={selectedTool === ActionType.ERASE ? 'delete active' : ''}
                    onClick={() => setSelected(ActionType.ERASE)}
                />
            </div>
            <Tooltip target='#transform-tool' position='right'>
                {'Pan & Zoom '}<span className="accel-label">W</span>
            </Tooltip>
            <Tooltip target='#id-cell-tool' position='right'>
              Identify Cell <span className="accel-label">C</span>
            </Tooltip>
            <Tooltip target='#id-region-tool' position='right'>
              Identify Cells in Region <span className="accel-label">R</span>
            </Tooltip>
            <Tooltip target='#delete-tool' position='right'>
              Delete Cell or Create Hole <span className="accel-label">X</span>
            </Tooltip>
        </div>
    );
}
