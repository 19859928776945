import Image from 'image-js';
import { showModal } from '../modal/Modal';

/**
 * @param {File} file
 * @return {Promise.<Image, error>} - Returns image object or error
 */

export function loadImageFromFile(file) {
    return new Promise(async (resolve, reject) => {
        try {
            const fileBuffer = await file.arrayBuffer();
            let image = await Image.load(fileBuffer);
            resolve(image);
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * @param {Image} image
 * @returns {boolean} - true if image is valid
 */
export function validateImageResolution(image) {
    return image.width > 4900 && image.height > 3400;
}

/**
 * @param {file} image file
 * @returns {boolean} - true if image is valid
 */

export function validateImageFormat(file) {
    return new Promise((resolve, reject) => {
        try {
            var blob = file;
            var fileReader = new FileReader();
            fileReader.readAsArrayBuffer(blob);
            fileReader.onloadend = function (e) {
                var arr = new Uint8Array(e.target.result).subarray(0, 4);
                var header = '';
                var type = '';
                for (var i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                    switch (header) {
                        case '89504e47':
                            type = 'image/png';
                            break;
                        case 'ffd8ffe0':
                            type = 'image/jpeg';
                            break;
                        case '424d':
                            type = 'image/bmp';
                            break;
                        case '49492a00':
                        case '4d4d002a':
                            type = 'image/tiff';
                            break;
                        default:
                            type = 'unknown';
                            break;
                    }
                    resolve(type === 'image/jpeg' || 'image/png' || 'image/bmp' || 'image/tiff');
                }
            };
        } catch (err) {
            resolve(false);
        }
    });
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * @param {File} file
 * @returns a promise which resolves into {status: 200, ...} for full success or {status: 201, ...} for partial success and {status: 400, ..} for complete failure or Error for exceptions
 */

export function verifyImage(file) {
    return new Promise(async (resolve, reject) => {
        try {
            let image;
            await delay(200);
            try {
                image = await loadImageFromFile(file);
            } catch (err) {
                showModal({
                    title: 'Invalid image format',
                    message: 'The format of selected image is invalid. Please select a valid image.',
                    class: 'error',
                });
                reject(err);
                return;
            }
            let isValidResolution = validateImageResolution(image);
            let isValidFormat = validateImageFormat(file);
            if (isValidFormat && isValidResolution) {
                resolve({
                    status: 200,
                    message: 'Image verification completed successfully',
                    isValidResolution,
                    isValidFormat,
                });
            } else {
                showModal({
                    title: 'Validation Failed!',
                    message: 'Please correct the errors.',
                    class: !isValidResolution || !isValidFormat ? 'error' : 'warning',
                    component: () => (
                        <div style={{ width: '100%' }}>
                            <ul style={{textAlign: 'left', fontSize: 'small'}}>
                                {!isValidResolution && (
                                    <li>
                                       The resolution of the selected image is low. Image resolution should be 5000x3500px or more.
                                    </li>
                                )}
                                {!isValidFormat && (
                                    <li>
                                        The format of selected image is invalid. Images should be in jpg or jfif format.
                                    </li>
                                )}
                            </ul>
                        </div>
                    ),
                });
            }
            reject("Preliminary validation failed");
        } catch (err) {
            reject(err);
        }
    });
}
