/**
 * This is the enum of the presentation modes available
 */
 export const PresentationMode = {
    DMG_LEVEL: 0,
    DMG_TYPE: 1,
    DMG_RATE: 2,
    MNTN_SCOPE: 3,
};

/**
 * This is the enum of Fill types
 */
export const PatternStyle = {
    SOLID: 0,
    LEFT_DIAGONAL: 1,
    RIGHT_DIAGONAL: 2,
    VERTICAL: 3,
    HORIZONTAL: 4,
    DOTTED: 5,
    CHECKER_BOARD: 6
};

/**
 * This is the enum of the supported actions in the Inspection Flatout
 */
export const EventType = {
    // INSPECTOR - ACTIONS
    CELL: 0,
    TEXT: 1,
    SCRIBBLE: 2,
    INHIBIT: 3,
};

/**
 * This is the base class of Event
 */
export class Event {
    id = 1;
    flatoutEventType = EventType.CELL;
    parentEventIndex = null;
}


export const SelectToolColors={
    GOLD:`#FFD700`,
    YELLOW:`rgba(255,255,0,0.25)`
}