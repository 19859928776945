import { Cell } from '../models/Cell';
import { MetaData } from '../models/MetaData';
import { Coord } from './Coord';
import { GeometryAnalyser } from './GeometryAnalyser';
import {
    findAdditiveShape,
    findCentroid,
    find_polygon_vertices,
    insidePolygon,
    sleep,
    withinManhattanRadius,
} from './Helper';

/**
 * Detect cells in an image by providing starting coordinates
 */
export class CellDetection {
    static detectedCells = [];
    static width = 4000;
    static height = 2666;
    static imageBuffer = null;
    static paintCtx = null;
    static debug = false;
    static associatedZoneId = 0;
    static associatedZoneIsPatternFilled = false;
    static maxAllowedCellArea = 0;
    static get minAllowedCellArea() {
        return 5;
    }
    static maxManhattanDistance = 0;
    static maxIterationCount = 1000;

    // Setting this will configure the upper values
    /**
     * @param {number} _scale
     */
    static set scale(_scale) {
        this.maxAllowedCellArea = Math.trunc((3000 * 2500) / (_scale * _scale));
        this.maxManhattanDistance = Math.trunc((40 * 50) / _scale);
        this.maxIterationCount = 1000;
    }

    static async detect(coord, zoneColorsMap, cellCheckIndices = [], debug = false) {
        // Check if the given starting coordinate is already within a known cell
        if (cellCheckIndices.length === 0) {
            for (let i = 0; i < this.detectedCells.length; i++) {
                if (withinManhattanRadius(this.detectedCells[i].g.c, coord, this.maxManhattanDistance)) {
                    if (insidePolygon(this.detectedCells[i].g.ap, coord)) {
                        if (this.detectedCells[i].zId !== this.associatedZoneId) {
                            this.detectedCells[i].zId = this.associatedZoneId;
                            this.detectedCells[i].metadata.isPatternFilled = this.associatedZoneIsPatternFilled;
                            if (this.paintCtx && zoneColorsMap) {
                                await Cell.drawZone(this.detectedCells[i], zoneColorsMap, this.paintCtx);
                            }
                        }

                        return;
                    }
                }
            }
        } else {
            // If a specific set of cell indices are provided
            // then check among them only
            for (let i = 0; i < cellCheckIndices.length; i++) {
                if (
                    withinManhattanRadius(this.detectedCells[cellCheckIndices[i]].g.c, coord, this.maxManhattanDistance)
                ) {
                    if (insidePolygon(this.detectedCells[cellCheckIndices[i]].g.ap, coord)) {
                        if (this.detectedCells[cellCheckIndices[i]].zId !== this.associatedZoneId) {
                            this.detectedCells[cellCheckIndices[i]].zId = this.associatedZoneId;
                            this.detectedCells[cellCheckIndices[i]].metadata.isPatternFilled =
                                this.associatedZoneIsPatternFilled;
                            if (this.paintCtx && zoneColorsMap) {
                                await Cell.drawZone(
                                    this.detectedCells[cellCheckIndices[i]],
                                    zoneColorsMap,
                                    this.paintCtx
                                );
                            }
                        }

                        return;
                    }
                }
            }
        }

        // Scan in all four directions to find the path with the highest perimeter
        let boundary_up = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.UP,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );
        let boundary_left = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.LEFT,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );
        let boundary_right = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.RIGHT,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );
        let boundary_down = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.DOWN,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );

        // Find max boundary
        let boundary_max = [];
        var consider_up = boundary_up?.length > boundary_down?.length;
        var consider_left = boundary_left?.length > boundary_right?.length;

        if (consider_up) {
            if (consider_left) {
                boundary_max = boundary_up?.length > boundary_left?.length ? boundary_up : boundary_left;
            } else {
                boundary_max = boundary_up?.length > boundary_right?.length ? boundary_up : boundary_right;
            }
        } else {
            if (consider_left) {
                boundary_max = boundary_down?.length > boundary_left?.length ? boundary_down : boundary_left;
            } else {
                boundary_max = boundary_down?.length > boundary_right?.length ? boundary_down : boundary_right;
            }
        }

        // If there is no boundary then, presumably no cell was detected
        if (!boundary_max) {
            return;
        }

        // Clean boundary
        let vertices = GeometryAnalyser.findPolygonVertices(boundary_max);
        var additive_shape = GeometryAnalyser.interleaveCoords(vertices); //additive_shape == modified additive path==perimeter

        // If the current cell is not surrounding the given starting coordinate
        // then it's not a valid cell
        if (!insidePolygon(additive_shape, coord)) {
            return;
        }

        if (vertices[0]) {
            let centroid_value = findCentroid(vertices);
            let centroid_coord = { x: centroid_value.x, y: centroid_value.y };
            // If the resulting cell is one that is already known
            if (centroid_value.area < this.minAllowedCellArea || centroid_value.area > this.maxAllowedCellArea || centroid_value.shoelaceArea>0) {
                // To be a cell it must have a minimum area
                return;
            }
            for (let i = 0; i < this.detectedCells.length; i++) {
                if (withinManhattanRadius(this.detectedCells[i].g.c, centroid_coord, this.maxManhattanDistance)) {
                    if (
                        insidePolygon(this.detectedCells[i].g.ap, centroid_coord) ||
                        insidePolygon(additive_shape, this.detectedCells[i].g.c) ||
                        withinManhattanRadius(this.detectedCells[i].g.c, centroid_coord, 2)
                    ) {
                        if (this.detectedCells[i].zId !== this.associatedZoneId) {
                            this.detectedCells[i].zId = this.associatedZoneId;
                            this.detectedCells[i].metadata.isPatternFilled = this.associatedZoneIsPatternFilled;
                            if (this.paintCtx && zoneColorsMap) {
                                await Cell.drawZone(this.detectedCells[i], zoneColorsMap, this.paintCtx);
                            }
                        }

                        return;
                    }
                }
            }
            if (cellCheckIndices.length === 0) {
                for (let i = 0; i < this.detectedCells.length; i++) {
                    if (withinManhattanRadius(this.detectedCells[i].g.c, centroid_coord, this.maxManhattanDistance)) {
                        if (
                            insidePolygon(this.detectedCells[i].g.ap, centroid_coord) ||
                            insidePolygon(additive_shape, this.detectedCells[i].g.c) ||
                            withinManhattanRadius(this.detectedCells[i].g.c, centroid_coord, 2)
                        ) {
                            if (this.detectedCells[i].zId !== this.associatedZoneId) {
                                this.detectedCells[i].zId = this.associatedZoneId;
                                this.detectedCells[i].metadata.isPatternFilled = this.associatedZoneIsPatternFilled;
                                if (this.paintCtx && zoneColorsMap) {
                                    await Cell.drawZone(this.detectedCells[i], zoneColorsMap, this.paintCtx);
                                }
                            }

                            return;
                        }
                    }
                }
            } else {
                for (let i = 0; i < cellCheckIndices.length; i++) {
                    if (
                        withinManhattanRadius(
                            this.detectedCells[cellCheckIndices[i]].g.c,
                            centroid_coord,
                            this.maxManhattanDistance
                        )
                    ) {
                        if (
                            insidePolygon(this.detectedCells[cellCheckIndices[i]].g.ap, centroid_coord) ||
                            insidePolygon(additive_shape, this.detectedCells[cellCheckIndices[i]].g.c) ||
                            withinManhattanRadius(this.detectedCells[cellCheckIndices[i]].g.c, centroid_coord, 2)
                        ) {
                            if (this.detectedCells[cellCheckIndices[i]].zId !== this.associatedZoneId) {
                                this.detectedCells[cellCheckIndices[i]].zId = this.associatedZoneId;
                                this.detectedCells[cellCheckIndices[i]].metadata.isPatternFilled =
                                    this.associatedZoneIsPatternFilled;
                                if (this.paintCtx && zoneColorsMap) {
                                    await Cell.drawZone(
                                        this.detectedCells[cellCheckIndices[i]],
                                        zoneColorsMap,
                                        this.paintCtx
                                    );
                                }
                            }

                            return;
                        }
                    }
                }
            }

            let perimeter = boundary_max.length;
            let subtractiveDataSet = new Set();

            var cell = new Cell(
                this.associatedZoneId,
                additive_shape.slice(),
                perimeter,
                subtractiveDataSet,
                new Coord(centroid_value.x, centroid_value.y),
                new Coord(1, 1),
                centroid_value.area,
                null,
                new MetaData(this.associatedZoneIsPatternFilled)
            );

            this.detectedCells.push(cell);
            Cell.drawZone(cell, zoneColorsMap, this.paintCtx);
        }
    }

    static async detectHole(zoneColorsMap, coord, debug = false) {
        // Scan in all four directions to find the path with the highest perimeter
        let boundary_up = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.UP,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );
        let boundary_left = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.LEFT,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );
        let boundary_right = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.RIGHT,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );
        let boundary_down = GeometryAnalyser.findPath(
            this.imageBuffer,
            coord,
            GeometryAnalyser.ScanDirection.DOWN,
            this.width,
            this.height,
            this.maxIterationCount,
            debug ? this.paintCtx : null
        );

        // Find max boundary
        let boundary_max = [];
        var consider_up = boundary_up?.length > boundary_down?.length;
        var consider_left = boundary_left?.length > boundary_right?.length;

        if (consider_up) {
            if (consider_left) {
                boundary_max = boundary_up?.length > boundary_left?.length ? boundary_up : boundary_left;
            } else {
                boundary_max = boundary_up?.length > boundary_right?.length ? boundary_up : boundary_right;
            }
        } else {
            if (consider_left) {
                boundary_max = boundary_down?.length > boundary_left?.length ? boundary_down : boundary_left;
            } else {
                boundary_max = boundary_down?.length > boundary_right?.length ? boundary_down : boundary_right;
            }
        }
        if (!boundary_max) {
            return;
        }
        var perimeter = boundary_max.length;
        let vertices = find_polygon_vertices(boundary_max);
        await sleep(1);
        if (vertices[0]) {
            let centroid_value = findCentroid(vertices);
            let subtractive_shape = findAdditiveShape(vertices);
            let isAbsent = true;
            for (let i = 0; i < this.detectedCells.length; i++) {
                if (withinManhattanRadius(this.detectedCells[i].g.c, centroid_value, this.maxManhattanDistance)) {
                    if (insidePolygon(this.detectedCells[i].g.ap, coord)) {
                        if (perimeter / this.detectedCells[i].g.al >= 0.75) {
                            await Cell.drawZone(this.detectedCells[i], null, this.paintCtx,'destination-out');
                            this.detectedCells.splice(i, 1);
                            return;
                        }

                        for (let j = 0; j < this.detectedCells[i].g.sPs.size; j++) {
                            if (insidePolygon(this.detectedCells[i].g.sPs[j], centroid_value)) {
                                isAbsent = false;
                                break;
                            }
                        }

                        if (isAbsent) {
                            this.detectedCells[i].g.sPs.add(subtractive_shape.slice());
                            await Cell.drawHoles(this.detectedCells[i], this.paintCtx);
                            break;
                        }
                    }
                }
            }
            
        }
    }

    static async drawAll (zoneColorMap, drawCallback = () => {}) {
        for (let i = 0; i < this.detectedCells.length; i++) {
            if (i % 128 === 0) {
                await sleep(1);
            }

            await Cell.drawZone(this.detectedCells[i], zoneColorMap, this.paintCtx);
            if (this.detectedCells[i].g.sPs.size > 0) {
                await Cell.drawHoles(this.detectedCells[i], this.paintCtx);
            }

            drawCallback();
        }
    }
}
