import { pixel } from './Pixel';
import { Coord } from './Coord';
import {
    createCheckerboardPattern,
    createHorizontalPattern,
    createLeftDiagonalPattern,
    createRightDiagonalPattern,
    createSolidPattern,
    createVerticalPattern,
} from './Pattern';
import { PatternStyle } from '../config/Constants';

/**
 * For Getting the relative click coordinates
 * @param {MouseEvent | TouchEvent} e
 * @param {HTMLCanvasElement} canvas
 * @returns {{x: number, y: number}}
 */
export function getCoordinates(e, canvas, zoomScale = 1) {
    return {
        x: Math.round(((e.clientX || e.changedTouches[0].clientX) - canvas.getBoundingClientRect().x) / zoomScale),
        y: Math.round(((e.clientY || e.changedTouches[0].clientY) - canvas.getBoundingClientRect().y) / zoomScale),
    };
}

/**
 *
 * @param {ImageData} imageData
 */
export function getImageDataIndexFromXY(imageData, x, y) {
    return {
        r: (y * imageData.width + x) * 4,
        g: (y * imageData.width + x) * 4 + 1,
        b: (y * imageData.width + x) * 4 + 2,
        a: (y * imageData.width + x) * 4 + 3,
    };
}

/**
 * It is used to get the relative coordinates
 * @param {*} event
 * @returns
 */

export function getRelativeCoords(event, zoomFactor) {
    if (event !== undefined) {
        let rect = event.target.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
        return { x: Math.round(x / zoomFactor), y: Math.round(y / zoomFactor) };
    }
    return { x: 0, y: 0 };
}

export function getAbsoluteCoords(canvasContainer, zoomFactor, coords) {
    let rect = canvasContainer.getBoundingClientRect();
    let x = coords.x * zoomFactor;
    let y = coords.y * zoomFactor;
    return { x: x + rect.left, y: y + rect.top };
}

/**
 * Just a function to check if canvas and coordinates are working fine
 * @param {HTMLCanvasElement} canvas
 * @param {{x: number, y: number}} coordinates
 * @param {string} color
 */
export function drawTestDot(canvas, coordinates, color) {
    coordinates = { x: Math.round(coordinates.x), y: Math.round(coordinates.y) };
    let start = new Date();
    let context = canvas.getContext('2d');
    context.fillStyle = color;
    context.fillRect(coordinates.x, coordinates.y, 2, 2);
}

/**
 * Just a function to check color of clicked position
 * @param {HTMLCanvasElement} canvas
 * @param {{x: number, y: number}} coordinates
 */
export function colorPicker(canvas, coordinates) {
    //console.log('Color', colorFromPixel(canvas, coordinates), rgb2cmyk(colorFromPixel(canvas, coordinates)));
}

export function getPixelFromBuffer(coord, width, samplerBuffer) {
    return new pixel(
        samplerBuffer.data[coord.y * width * 4 + coord.x * 4],
        samplerBuffer.data[coord.y * width * 4 + coord.x * 4 + 1],
        samplerBuffer.data[coord.y * width * 4 + coord.x * 4 + 2],
        samplerBuffer.data[coord.y * width * 4 + coord.x * 4 + 3]
    );
}

export function getTemplateFromBuffer(coord, width, samplerBuffer) {
    let r = samplerBuffer.data[coord.y * width * 4 + coord.x * 4];
    let g = samplerBuffer.data[coord.y * width * 4 + coord.x * 4 + 1];
    let b = samplerBuffer.data[coord.y * width * 4 + coord.x * 4 + 2];

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////       PHASE -- 2      ////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * This function finds the Manhattan Distance
 * @param {{x:a,y:b}} centroid
 * @param {{x:a,y:b}} currentCoord
 * @returns {float} ManhatttanDistance
 */
export const findManhattanDistance = (centroid, currentCoord) => {
    return Math.abs(centroid.x - currentCoord.x) + Math.abs(centroid.y - currentCoord.y);
};

/**
 * This method returns true if the manhattan distance of the centroid a particular cell and the given coordinate is less than CHECK_DISTANCE value
 * @param {{x:x,y:y}} centroid
 * @param {{x:x,y:y}} currentCoord
 * @param {int} CHECK_DISTANCE
 * @returns {bool}
 */
export const withinManhattanRadius = (centroid, currentCoord, CHECK_DISTANCE) => {
    return findManhattanDistance(centroid, currentCoord) <= CHECK_DISTANCE;
};

/**
 * It checks whether the rgb value of a pixel is greatr than a specified value
 * @param {*} samplerBuffer
 * @param {*} x
 * @param {*} y
 * @param {*} value
 * @returns {bool} value
 */
export const getValue = (samplerBuffer, x, y, value = 30) => {
    let width = 4000;
    return (
        samplerBuffer[y * width * 4 + x * 4] > value || // red
        samplerBuffer[y * width * 4 + x * 4 + 1] > value || //green
        samplerBuffer[y * width * 4 + x * 4 + 2] > value //blue
    );
};

/**
 * It checks whether the rgb value of a pixel is greatr than a specified value
 * @param {*} samplerBuffer
 * @param {*} x
 * @param {*} y
 * @param {*} value
 * @returns {bool} value
 */

export const getAbsoluteValue = (samplerBuffer, x, y, value = 30) => {
    let width = 4000;
    return (
        samplerBuffer[y * width * 4 + x * 4] > value && // red
        samplerBuffer[y * width * 4 + x * 4 + 1] > value && //green
        samplerBuffer[y * width * 4 + x * 4 + 2] > value //blue
    );
};

/**
 * This finds the additive shape
 * @param {*} path
 * @returns
 */
export const findAdditiveShape = (path) => {
    const polygon = [];
    polygon.push(...path);
    const additiveShape = new Uint16Array(2 * path.length);
    for (let i = 0; i < polygon.length; i++) {
        additiveShape[2 * i] = polygon[i].x;
        additiveShape[2 * i + 1] = polygon[i].y;
    }
    return additiveShape.slice();
};

export const findAreaUsingShoelace = (poly) => {
    if (!poly || poly.length < 3) return null;
    let end = poly.length - 1;
    let sum = poly[end]['x'] * poly[0]['y'] - poly[0]['x'] * poly[end]['y'];
    for (let i = 0; i < end; ++i) {
        const n = i + 1;
        sum += poly[i]['x'] * poly[n]['y'] - poly[n]['x'] * poly[i]['y'];
    }
    return sum >> 1;
};

/**
 * This methods finds the centroid given a set of polygon vertices
 * @param {*} path
 * @returns
 */
export const findCentroid = (path) => {
    const polygon = [];
    polygon.push(...path);
    const triangles = [];

    for (let i = 0; i < polygon.length - 1; i++) {
        triangles.push([polygon[0], polygon[i], polygon[i + 1]]);
    }
    const triangleCentroids = [];
    const shoelaceArea = findAreaUsingShoelace(polygon);
    let totalArea = 0;
    let centroid_x = 0;
    let centroid_y = 0;
    if (shoelaceArea < 0) {
        for (let i = 0; i < triangles.length; i++) {
            const [p1, p2, p3] = triangles[i];
            const area = 0.5 * Math.abs(p1.x * (p2.y - p3.y) + p2.x * (p3.y - p1.y) + p3.x * (p1.y - p2.y));
            const x = (p1.x + p2.x + p3.x) / 3;
            const y = (p1.y + p2.y + p3.y) / 3;
            triangleCentroids.push({ x, y, area });
            totalArea += area;
        }

        for (let i = 0; i < triangleCentroids.length; i++) {
            const { x, y, area } = triangleCentroids[i];
            centroid_x += area * x;
            centroid_y += area * y;
        }
        centroid_x = Math.round(centroid_x / totalArea);
        centroid_y = Math.round(centroid_y / totalArea);
    }
    return { x: centroid_x, y: centroid_y, area: totalArea };
};

/**
 * It finds the polygon vertices
 * @param {*} path
 * @returns polygon vertices in [{x:a,y:b}...] format
 */

export const find_polygon_vertices = (path) => {
    var cornerVertices = [];
    var removal_queue = [];
    let prev_index = 1;
    for (let i = 2; i < path.length - 1; i++) {
        if (collinear(path[prev_index], path[i], path[i + 1])) {
            removal_queue.push(i);
        } else {
            prev_index = i;
        }
    }

    for (let i = 1; i < path.length; i++) {
        if (!removal_queue.includes(i)) {
            cornerVertices.push(path[i]);
        }
    }

    return cornerVertices;
};

const collinear = (point1, point2, point3) => {
    return point1.x * (point2.y - point3.y) + point2.x * (point3.y - point1.y) + point3.x * (point1.y - point2.y) === 0;
};

/**
 * This method is used to convert hexadecimal color value to rgba color value
 * @param {*} hex
 * @returns
 */
export const hexToRgb = (hex) => {
    if (hex != null) {
        hex = hex.replace('#', '');
        var hexInt = parseInt(hex, 16);
        var red = (hexInt >> 16) & 255;
        var green = (hexInt >> 8) & 255;
        var blue = hexInt & 255;
        var alpha = 255;
        return `rgba(` + red + `,` + green + `,` + blue + `,` + alpha + `)`;
    }
};

/**
 * This method is used to check whether the polygon using the old logic
 * @param {*} path
 * @param {*} pointCoord
 * @returns
 */

export const oldInsidePolygon = (path, pointCoord) => {
    let ans = false;
    for (let i = 0; i < path.length / 2 - 1; i++) {
        let pointA = { x: path[2 * i], y: path[2 * i + 1] };
        let pointB = { x: path[2 * (i + 1)], y: path[2 * (i + 1) + 1] };
        if (!(Math.min(pointA.y, pointB.y) <= pointCoord.y && pointCoord.y <= Math.max(pointA.y, pointB.y))) continue;
        if (pointCoord.x <= Math.min(pointA.x, pointB.x)) continue;
        let cur_x =
            pointA.x === pointB.x
                ? pointA.x
                : pointA.x + ((pointCoord.y - pointA.y) * (pointB.x - pointA.x)) / (pointB.y - pointA.y);
        ans ^= pointCoord.x > cur_x;
    }
    return ans;
};

/**
 * This is used to check whether the points lies inside the given set of polygon coordinates
 * @param {*} polygon
 * @param {*} point
 * @returns
 */
export const checkWindingNumber = (polygon, point) => {
    var wn = 0;
    var n = polygon.length / 2 - 1;
    for (var i = 0; i < n; i++) {
        var edgeStart = { x: polygon[2 * i], y: polygon[2 * i + 1] };
        var edgeEnd = { x: polygon[(2 * (i + 1)) % n], y: polygon[((2 * (i + 1)) % n) + 1] };
        if (edgeStart.y <= point.y) {
            if (edgeEnd.y > point.y && isLeft(point, edgeStart, edgeEnd) > 0) {
                wn++;
            }
        } else {
            if (edgeEnd.y <= point.y && isLeft(point, edgeStart, edgeEnd) < 0) {
                wn--;
            }
        }
    }
    // If winding nunber is 0 then point lies outside the polygon
    return wn !== 0;
};
const isLeft = (a, b, c) => {
    return (b.x - a.x) * (c.y - a.y) - (c.x - a.x) * (b.y - a.y);
};

/**
 * This method is used to check whether the given point lies inside the given path polygon
 * @param {*} path
 * @param {*} pointCoord
 * @returns {bool} true if inside polygon otherwise false
 */
export const insidePolygon = (polygon, point) => {
    var x = point.x;
    var y = point.y;
    var n = polygon.length / 2;
    var inside = false;
    var p1x = polygon[0];
    var p1y = polygon[1];
    for (var i = 0; i <= n; i++) {
        var p2x = polygon[(i % n) * 2];
        var p2y = polygon[(i % n) * 2 + 1];
        if (y > Math.min(p1y, p2y)) {
            if (y <= Math.max(p1y, p2y)) {
                if (x <= Math.max(p1x, p2x)) {
                    if (p1y !== p2y) {
                        var xints = ((y - p1y) * (p2x - p1x)) / (p2y - p1y) + p1x;
                    }
                    if (p1x === p2x || x <= xints) {
                        inside = !inside;
                    }
                }
            }
        }
        p1x = p2x;
        p1y = p2y;
    }
    return inside;
};

/**
 * This is the array of Pattern Types
 */
export const PatternType = [
    PatternStyle.LEFT_DIAGONAL,
    PatternStyle.RIGHT_DIAGONAL,
    PatternStyle.VERTICAL,
    PatternStyle.HORIZONTAL,
];

/**
 * This method is used to used to configure the pattern for canvas
 * @param {*} fgColor
 * @param {*} size
 * @param {*} bgColor
 * @param {*} type
 * @returns canvas  - pattern
 */
export const configurePattern = (fgColor, bgColor, type) => {
    var canvas = null;
    switch (type) {
        case PatternStyle.LEFT_DIAGONAL:
            canvas = createLeftDiagonalPattern(fgColor, bgColor);
            break;
        case PatternStyle.RIGHT_DIAGONAL:
            canvas = createRightDiagonalPattern(fgColor, bgColor);
            break;
        case PatternStyle.VERTICAL:
            canvas = createVerticalPattern(fgColor, bgColor);
            break;
        case PatternStyle.HORIZONTAL:
            canvas = createHorizontalPattern(fgColor, bgColor);
            break;
        case PatternStyle.SOLID:
            canvas = createSolidPattern(fgColor);
            break;
        case PatternStyle.CHECKER_BOARD:
            canvas = createCheckerboardPattern();
            break;
        default:
            console.error('Required Pattern Type not found');
    }
    return canvas;
};

/**
 * it gets type from the colorsZoneMapList based on the type
 * @param {*} colorsZoneMapList
 * @param {int} zoneId
 * @returns
 */
export const getPatternType = (colorsZoneMapList, zoneId) => {
    let type = -1;
    for (let i = 0; i < colorsZoneMapList.length; i++) {
        if (colorsZoneMapList[i].zoneId === zoneId) {
            type = colorsZoneMapList[i].type;
            break;
        }
    }
    return type;
};

/**
 * it gets canvas from the colorsZoneMapList based on the type
 * @param {*} colorsZoneMapList
 * @param {int} zoneId
 * @returns
 */
export const getPatternCanvas = (colorsZoneMapList, zoneId) => {
    let canvas = null;
    for (let i = 0; i < colorsZoneMapList.length; i++) {
        // eslint-disable-next-line eqeqeq
        if (colorsZoneMapList[i].zoneId === zoneId) {
            canvas = colorsZoneMapList[i].canvas;
            break;
        }
    }
    return canvas;
};

/**
 * it gets color from the colorsZoneMapList based on the type
 * @param {*} colorsZoneMapList
 * @param {int} zoneId
 * @returns
 */
export const getZoneColor = (colorsZoneMapList, zoneId) => {
    let color = null;
    for (let i = 0; i < colorsZoneMapList.length; i++) {
        if (colorsZoneMapList[i].zoneId === zoneId) {
            color = colorsZoneMapList[i].color;
            break;
        }
    }
    return color;
};

/**
 * Provides a set of coordinates representing an enclosed space and the possible center of area of the space
 * @param samplerBuffer This is read only canvas context which is used for control
 * @param coord  the coordinate of the current mouse position
 * @param threshold For determining what counts as "black" pixel
 * @returns
 */
export function getCenterOfArea(samplerBuffer, coord, threshold) {
    let lx, ly, tx, ty, rx, ry, bx, by;

    // Find upper limit
    tx = coord.x;
    ty = coord.y;
    for (; ty > 0; ty--) {
        if (!getValue(samplerBuffer, tx, ty - 1, 128)) {
            break;
        }
    }

    // Find lower limit
    bx = coord.x;
    by = coord.y;
    for (; by < 2666; by++) {
        if (!getValue(samplerBuffer, bx, by + 1, 128)) {
            break;
        }
    }

    // Find left limit
    lx = coord.x;
    ly = coord.y;
    for (; lx > 0; lx--) {
        if (!getValue(samplerBuffer, lx - 1, ly, 128)) {
            break;
        }
    }

    // Find right limit
    rx = coord.x;
    ry = coord.y;
    for (; rx < 4000; rx++) {
        if (!getValue(samplerBuffer, rx + 1, ry, 128)) {
            break;
        }
    }

    // Find center of area of unit cell
    var ay = Math.round((ty + by) / 2);
    var ax = Math.round((lx + rx) / 2);

    ly = ay;
    ry = ay;
    tx = ax;
    bx = ax;

    return {
        lx: lx,
        rx: rx,
        tx: tx,
        bx: bx,
        ly: ly,
        ry: ry,
        ty: ty,
        by: by,
        ax: ax,
        ay: ay,
    };
}

export const mapRange = (input_start, input_end, output_start, output_end, input) => {
    let slope = (output_end - output_start) / (input_end - input_start);
    let output = output_start + slope * (input - input_start);
    return output;
};

/**
 * This method is used to convert an array to base64  string
 * @param {*} array
 * @returns base64 encodedString
 */
export const convertArrayToBase64 = (array) => {
    if (array.length === 0) return '';
    let binaryString = '';
    for (let i = 0; i < array.length; i++) {
        binaryString += String.fromCharCode(array[i] & 0xff, (array[i] >> 8) & 0xff);
    }
    return btoa(binaryString);
};

/**
 * This method is used to convert a base64 string back to the array
 * @param {*} base64String
 * @returns
 */
export const convertBase64ToArray = (base64String) => {
    if (base64String.length === 0) return [];
    let binaryString = atob(base64String);
    const uint16Array = new Uint16Array(binaryString.length / 2);
    for (let i = 0; i < binaryString.length; i += 2) {
        uint16Array[i / 2] = binaryString.charCodeAt(i) | (binaryString.charCodeAt(i + 1) << 8);
    }
    return Array.from(uint16Array);
};

/**
 * This method is used to convert a set of arrays to base 64 strings
 * @param {Set which contains array} setOfArrays
 * @returns
 */
export const convertSetofArraysToBase64 = (setOfArrays) => {
    let allArrays = Array.from(setOfArrays);
    let encodedString = [];
    for (let i = 0; i < allArrays.length; i++) {
        encodedString.push(convertArrayToBase64(allArrays[i]));
    }
    return encodedString;
};

/**
 * This method is used to convert base64 strings to a set of arrays
 * @param {*} encodedString
 * @returns
 */

export const convertBase64ToArrayOfArrays = (encodedString) => {
    if (Object.keys(encodedString).length === 0) return [];
    let allBase64Strings = encodedString.slice();
    let decodedSetOfArrays = [];
    for (let i = 0; i < allBase64Strings.length; i++) {
        decodedSetOfArrays.push(convertBase64ToArray(allBase64Strings[i]));
    }
    return decodedSetOfArrays;
};

/**
 * This method is used to delay the thread execution by 'ms' milliseconds
 * @param {int} ms Time in milliseconds to sleep thread
 * @returns
 */
export const sleep = (ms) => {
    /* Using `requestAnimationFrame` because setTimeout or setInterval
     * do not work properly in Microsoft Edge when "efficiency mode" is on
     */
    return new Promise((resolve) => {
        let start = performance.now();

        function checkElapsed(currentTime) {
            const elapsed = currentTime - start;

            if (elapsed >= ms) {
                resolve();
            } else {
                requestAnimationFrame(checkElapsed);
            }
        }

        requestAnimationFrame(checkElapsed);
    })
}

// export const sleep = (ms) => {
//     return new Promise((resolve) => {
//         setTimeout(() => {
//             resolve('');
//         }, ms);
//     });
// };

/**
 * 
 * @param {() => {}} callback Function to run after delay
 * @param {number} ms Millisecond delay after which function is ran
 * @returns 
 */
export const setFixedTimeout = (callback, ms) => {
    /* Using `requestAnimationFrame` because setTimeout or setInterval
     * do not work properly in Microsoft Edge when "efficiency mode" is on
     */
    return new Promise((resolve) => {
        let start = performance.now();

        function checkElapsed(currentTime) {
            const elapsed = currentTime - start;

            if (elapsed >= ms) {
                callback();
                resolve();
            } else {
                requestAnimationFrame(checkElapsed);
            }
        }

        requestAnimationFrame(checkElapsed);
    })
}

/**
 * This method is used to find out how much does the additive data and subtractice data matches by a certain Percentage
 * @param {*} additiveData
 * @param {*} subtractiveData
 * @param {*} THRESHOLD_PERCENTAGE
 * @returns
 */
export const checkHowMuchAdditiveSubtractiveDataMatch = (additiveData, subtractiveData, THRESHOLD_PERCENTAGE = 30) => {
    let matches = 0;
    const minLength = additiveData.length <= subtractiveData.length ? additiveData.length : subtractiveData.length;
    for (let i = 0; i < minLength; i += 2) {
        if (additiveData[i] === subtractiveData[i] && additiveData[i + 1] === subtractiveData[i + 1]) matches++;
    }
    const totalCoords = additiveData.length >= subtractiveData.length ? additiveData.length : subtractiveData.length;
    const matchPercentage = Math.round(matches / totalCoords) * 100;
    return matchPercentage >= THRESHOLD_PERCENTAGE;
};

/**
 * This method checks whether the point is inside the bounding box or not
 * @param {*} rectStart
 * @param {*} rectEnd
 * @param {*} point
 * @returns
 */
export const checkWhetherInsideBoundBox = (
    /**@type{{x:x,y:y}} */ rectStart,
    /**@type{{x:x,y:y}} */ rectEnd,
    /**@type{{x:x,y:y}} */ point
) => {
    if (point.x >= rectStart.x && point.x <= rectEnd.x && point.y >= rectStart.y && point.y <= rectEnd.y) return true;
    return false;
};

export function distanceFromLineSegment(pointA, pointB, pointE) {
    var AB = new Coord(pointB.x - pointA.x, pointB.y - pointA.y);
    var BE = new Coord(pointE.x - pointB.x, pointE.y - pointB.y);
    var AE = new Coord(pointE.x - pointA.x, pointE.y - pointA.y);

    var AB_dot_BE = AB.x * BE.x + AB.y * BE.y;
    var AB_dot_AE = AB.x * AE.x + AB.y * AE.y;

    var minDistance = 0;

    if (AB_dot_BE > 0) {
        var y = pointE.y - pointB.y;
        var x = pointE.x - pointB.x;
        minDistance = Math.sqrt(x * x + y * y);
    } else if (AB_dot_AE < 0) {
        y = pointE.y - pointA.y;
        x = pointE.x - pointA.x;
        minDistance = Math.sqrt(x * x + y * y);
    } else {
        var mod = Math.sqrt(AB.x * AB.x + AB.y * AB.y);
        minDistance = Math.abs(AB.x * AE.y - AB.y * AE.x) / mod;
    }

    return minDistance;
}

/**
 * This method is used to update the cellIndexMap
 * @param {list} cellsIndexMap
 * @param {number} cellIndex
 * @returns
 */
export const updateCellsIndexMap = (cellsIndexMap, cellIndex, eventIndex) => {
    if (cellsIndexMap.length > 0) cellsIndexMap[cellIndex] = eventIndex;
    return cellsIndexMap;
};

export const checkIfPointPathMatch = (path, point, CHECK_DISTANCE = 10) => {
    for (let i = 0; i < path.length - 1; i += 2) {
        if (distanceFromLineSegment(path[i], path[i + 1], point) <= CHECK_DISTANCE) return true;
    }
    return false;
};

/**
 * This method is used to get the base64 pattern of the image
 * @param {*} patternFgColor
 * @param {*} patternBgColor
 * @param {*} patternStyle
 * @returns
 */
export const getBase64 = (patternFgColor, patternBgColor, patternStyle) => {
    if (patternFgColor) {
        let canvas = configurePattern(patternFgColor, patternBgColor, patternStyle);
        return canvas.toDataURL();
    }
};

export const getPoint = (c, r, a) => {
    return { x: c + Math.cos(a) * r, y: c + Math.sin(a) * r };
};

/**
 * This method is used to load the image when the base64 string is given
 * @param {string} src
 * @returns
 */

export const loadImageProcess = (src) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
};

export const deepCopy = (data) => {
    return JSON.parse(JSON.stringify(data));
};

export const checkWhetherAnnotationsIntersect = (rect1_start, rect1_end, rect2_start, rect2_end) => {
    if (
        rect1_start.x >= rect2_end.x ||
        rect1_end.x <= rect2_start.x ||
        rect1_start.y >= rect2_end.y ||
        rect1_end.y <= rect2_start.y
    )
        return false;
    return true;
};

/**
 * This method accepts color in rgb format and gives the result in  {r: {int},g:{int},b:{int}}
 * @param {rgb({int},{int},{int})}  inputColor
 * @returns {{ r:{int},g:{int},b:{int}}}
 */

export const parseColor = (/**@type {`rgb({int},{int},{int})`} */ inputColor) => {
    const numbers = inputColor.match(/\d+/g);
    const outputColor = { r: parseInt(numbers[0]), g: parseInt(numbers[1]), b: parseInt(numbers[2]) };
    return outputColor;
};


export const parseEventStream = (/**@type{string} */unprocessedData) => {
    const okPattern = 'Ok>>>>';
    const badRequestPattern = '400>>>>';
    const okIndex = unprocessedData.indexOf(okPattern);
    if (okIndex >= 0) {
        return { status: 200, body: JSON.parse(unprocessedData.substring(okIndex + okPattern.length))}
    }
    const index400 = unprocessedData.indexOf(badRequestPattern)
    if(index400 >= 0) {
        return { status: 400, body: unprocessedData.substring(index400 + badRequestPattern.length)}
    }


    return { status: 500, body: 'An unexpected error occured!'}
}