import { Button } from 'primereact/button';
import React from 'react';
import { PatternStyle } from '../../config/Constants';
import './ZoneBoxUploadTemplates.scss';

export default function ZoneBoxUploadTemplates({
    zones,
    zoneColorsMap,
    hidden = false,
    disabled = false,
    selectedZone,
    onZoneClick = (zone) => {},
    onClearZone = () => {},
    onZoneConfigureClick = () => {}
}) {
    const getFill = (zoneId) => {
        let _colormap = zoneColorsMap.find((_map) => _map.zoneId === zoneId);
        return _colormap.type === PatternStyle.SOLID ? _colormap.color : `url(${_colormap.canvas.toDataURL()})`;
    };

    const onActivate = (zone) => {
        onZoneClick(zone);
    };

    return (
        <div className="zonebox" style={{ right: hidden ? -71 : 8, opacity: hidden ? 0 : 1 }}>
            <div style={{ fontSize: '0.8rem', padding: 8, fontWeight: 'bolder', marginBottom: -20 }}>
                <div style={{ display: 'inline-flex', alignItems: 'center', height: 32, paddingRight: 8 }}>
                    {disabled ? 'ZONE LEGEND:' : 'SELECT ZONE:'}
                </div>
                <Button
                    onClick={() => onZoneConfigureClick()}
                    icon="pi pi-cog"
                    className="aq-btn aq-rounded"
                    style={{ float: 'right', maxWidth: 31, maxHeight: 31, alignItems: 'center' }}
                />
            </div>
            <div className="scrollable" style={{ padding: 8 }}>
                {!hidden && zoneColorsMap.length > 0 && (
                    <>
                        {zones.map((z) => {
                            return (
                                <div
                                    key={z.id}
                                    id={z.id}
                                    style={{
                                        pointerEvents: disabled ? 'none' : 'auto',
                                        display: z.isParent ? 'none' : 'initial',
                                    }}
                                    onClick={() => {
                                        onActivate(z);
                                    }}
                                >
                                    <div
                                        className={
                                            'zonebox-item' + (selectedZone === z.id && !disabled ? ' selected' : '')
                                        }
                                    >
                                        <div className="zonebox-item-name">
                                            <div
                                                style={{ background: getFill(z.id) }}
                                                className="zonebox-item-name-dot"
                                            ></div>
                                            <div>{z.name}</div>
                                        </div>
                                        {z?.vertices?.length > 2 && !disabled && (
                                            <div
                                                className="zonebox-item-btn"
                                                onClick={() => {
                                                    onClearZone(z.id);
                                                }}
                                            >
                                                <i className="pi pi-times" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}
