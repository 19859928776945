import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AppRole } from '../config/AuthConfig';
import AccessDeniedPage from '../pages/accessDeniedPage/AccessDeniedPage';
import InspectionFlatouts from '../pages/inspectionFlatouts/InspectionFlatouts';
import MaintenanceScheduler from '../pages/maintenanceScheduler/MaintenanceScheduler';
import PageNotFound from '../pages/pageNotFound/PageNotFound';
import ThemeDemo from '../pages/themeDemo/ThemeDemo';
import UploadTemplates from '../pages/uploadTemplates/UploadTemplates';
import '../styles/aqua/primitives/animations.scss';


export default function AppRoutes({ onRouteChange = () => {} }) {
    const user = useSelector((state) => state.user);
    const location = useLocation();

    useEffect(() => {
        onRouteChange(location.pathname);
    }, [onRouteChange, location]);

    return (
        <>
            {user.role && (
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            [
                                AppRole.ADMINISTRATOR,
                                AppRole.STANDARD_USER,
                                AppRole.CONTRACTOR,
                                AppRole.READONLY,
                            ].includes(user.role) ? (
                                <InspectionFlatouts user={user} />
                            ) : (
                                <AccessDeniedPage />
                            )
                        }
                    />
                    <Route
                        exact
                        path="/upload"
                        element={
                            user.role === AppRole.ADMINISTRATOR ? <UploadTemplates user={user} /> : <AccessDeniedPage />
                        }
                    />
                    <Route
                        exact
                        path="/mntnsched"
                        element={
                            [
                                AppRole.ADMINISTRATOR,
                                AppRole.STANDARD_USER,
                                AppRole.CONTRACTOR,
                                AppRole.READONLY,
                            ].includes(user.role) ? (
                                <MaintenanceScheduler user={user} />
                            ) : (
                                <AccessDeniedPage />
                            )
                        }
                    />
                    <Route exact path="/theme" element={<ThemeDemo />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            )}
        </>
    );
}
