import { createSlice } from '@reduxjs/toolkit';
export const reduxTriggersSlice = createSlice({
    name: 'reduxTriggers',
    initialState: {
        undo: 1,
        redo: 1,
    },
    reducers: {
        triggerUndo: (state) => {
            state.undo = 2;
        },
        clearTriggerUndo: (state) => {
            state.undo = 1;
        },
        triggerRedo: (state) => {
            state.redo = 2;
        },
        clearTriggerRedo: (state) => {
            state.redo = 1;
        },
    },
});

export const { triggerUndo, triggerRedo, clearTriggerUndo, clearTriggerRedo } = reduxTriggersSlice.actions;
export default reduxTriggersSlice.reducer;
