export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/" +
      process.env.REACT_APP_TENANT_ID +
      "/",
    redirectUri: "/",
  },
  cach: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [process.env.REACT_APP_API_URL + "/User_Impersonation", "User.Read"],
};

export const AppRole = {
  ADMINISTRATOR: "Administrator",
  STANDARD_USER: "StandardUser",
  CONTRACTOR: "Contractor",
  READONLY: "ReadOnly",
};
