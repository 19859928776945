import Axios from './Axios';

export class ContextLockAPI {


     /**
     * This method is used to lock a particular image 
     * @param {int} unitId 
     * @param {int} vesselId 
     * @param {int} outageId 
     * @param {string} page 
     * @returns 
     */

    static lock = (unitId, vesselId, outageId = null, page = 'UPLOADER') => {
        var body = ['RMS', 'FLATOUT', page, unitId.toString(), vesselId.toString()];
        if (outageId !== null) {
            body.push(outageId.toString());
        }
        return new Promise(async (resolve, reject) => {
            let response = await Axios.put(`/ContextLock/Lock?lockTimeSpanMinutes=60`, body);
            if (response && response.status) {
                resolve(response.data);
            }
        });
    };

    /**
     * This method is used to unlock a particular image 
     * @param {int} unitId 
     * @param {int} vesselId 
     * @param {int} outageId 
     * @param {string} page 
     * @returns 
   */

    static unlock = (unitId, vesselId, outageId = null, page = 'UPLOADER') => {
        var body = ['RMS', 'FLATOUT', page, unitId.toString(), vesselId.toString()];
        if (outageId !== null) {
            body.push(outageId.toString());
        }
        return new Promise(async (resolve, reject) => {
            let response = await Axios.put(`/ContextLock/Unlock`, body);
            if (response && response.status ) {
                resolve(response.data);
            }
        });
    };

     /**
     * This method is used to get the owner of a particular image
     * @param {int} unitId 
     * @param {int} vesselId 
     * @param {int} outageId 
     * @param {string} page 
     * @returns 
   */

    static getOwner = (unitId, vesselId, outageId = null, page = 'UPLOADER') => {
        var body = ['RMS', 'FLATOUT', page, unitId.toString(), vesselId.toString()];
        if (outageId !== null) {
            body.push(outageId.toString());
        }
        return new Promise(async (resolve, reject) => {
            let response = await Axios.put(`/ContextLock/GetOwner`, body);
            if (response && response.status) {
                resolve(response.data);
            }
        });
    };
}
