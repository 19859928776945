import './AlertModal.scss';
import { useSelector } from 'react-redux';
import { hideModal } from '../../modal/Modal';
import { Button } from 'primereact/button';



const Backdrop = () => {
    return <div className="backdrop"></div>;
};

const AlertModal = () => {
    let visible = useSelector((state) => state.modal.visible);
    let cls = useSelector((state) => state.modal.data?.class);
    let data = useSelector((state) => state.modal.data);
    let CustomComponent = useSelector((state) => state.modal.data.component);
    const copyToClipboard=()=>{
        navigator.clipboard.writeText(data.message.title ? data.message.title : data.message)
    }

    return (
        <>
            {visible && (
                <>
                    <Backdrop />
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <div className="alert-container">
                            {cls === 'success' && (
                                <span
                                    className="alert-icon alert-icon-success pi pi-check-circle"
                                    style={{ fontSize: '32px' }}
                                />
                            )}
                            {cls === 'error' && (
                                <span
                                    className="alert-icon alert-icon-error pi pi-times-circle"
                                    style={{ fontSize: '32px' }}
                                />
                            )}
                            {cls === 'warning' && (
                                <span
                                    className="alert-icon alert-icon-warning pi pi-exclamation-triangle"
                                    style={{ fontSize: '32px' }}
                                />
                            )}
                            <div className="alert-content">
                                <h3>{data.title ? data.title:'Network Error'}</h3>
                                <p>{data.message.title ? data.message.title : data.message}</p>
                                {CustomComponent && <CustomComponent />}
                            </div>
                            <div className={'lower-box ' + cls}>
                                <div className={'triangle ' + cls}></div>
                                {(data.cancelText || data.cancelCallBack) && (
                                    <input
                                        disabled={data.disbaleCancelButton}
                                        style={{
                                            opacity: data.disbaleCancelButton ? 0.3 : 1,
                                            borderRadius: '4px 0 0 4px',
                                        }}
                                        type="button"
                                        className="main-button"
                                        onClick={() => {
                                            hideModal();
                                            data.cancelCallBack && data.cancelCallBack();
                                        }}
                                        value={data.cancelText ? data.cancelText.toUpperCase() : 'BACK'}
                                    />
                                )}
                                <input
                                    disabled={data.disbaleOkButton}
                                    style={{
                                        opacity: data.disbaleOkButton ? 0.3 : 1,
                                        width: '9em',
                                        borderRadius: '4px 0 0 4px',
                                    }}
                                    type="button"
                                    className="main-button"
                                    onClick={() => {
                                        hideModal();
                                        data.okCallBack && data.okCallBack();
                                    }}
                                    value={data.okText ? data.okText.toUpperCase() : 'OK'}
                                />
                                <Button
                                    className="main-button"
                                    icon="pi pi-copy"
                                    style={{ color: 'white !important', marginLeft: 1, borderRadius: '0 4px 4px 0' }}
                                    onClick={copyToClipboard}
                                    title="Copy to Clipboard"
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default AlertModal;