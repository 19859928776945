import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './MonthYearPicker.scss';

const MonthYearPicker = forwardRef(
    (
        {
            month = 0,
            year = 2023,
            minYear = 2023,
            dangerMonth = 5,
            dangerYear = 2029,
            optiMonthStart = 7,
            optiMonthEnd = 4,
            optiYearStart = 2024,
            optiYearEnd = 2026,
            outages = [],
            disabled = false,
            onChangeMonth = () => {},
            onChangeYear = () => {},
            onConfirm = () => {},
        },
        ref
    ) => {
        const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const [years, setYears] = useState([]);
        const [backUpYears, setBackUpYears] = useState(0);
        const [backUpMonth, setBackUpMonth] = useState(0);
        const [saving, setSaving] = useState(false);
        const [isOpen, setIsOpen] = useState(false);

        useEffect(() => {
            const _years = [];
            for (let i = 0; i < 12; i++) {
                _years.push(minYear + i);
            }
            setYears(_years);
        }, [minYear]);

        const myPickerRef = useRef();
        const buttonRef = useRef();

        const dismiss = () => {
            setIsOpen(false);
            if (!saving) {
                onChangeMonth(backUpMonth);
                onChangeYear(backUpYears);
            } else {
                onConfirm(month, year);
            }
        };

        const openBox = () => {
            if (buttonRef.current && !isOpen) buttonRef.current.click();
        };

        useImperativeHandle(ref, () => ({
            openBox,
        }));

        const getOptiMonthClass = (_month) => {
            if (year > optiYearStart && year < optiYearEnd) {
                return ' opti';
            }

            if (optiYearStart === optiYearEnd && year === optiYearStart) {
                if (_month > optiMonthStart && _month < optiMonthEnd) {
                    return ' opti';
                } else if (_month === optiMonthStart) {
                    return ' opti-start';
                } else if (_month === optiMonthEnd) {
                    return ' opti-end';
                }

                return '';
            }

            if (year === optiYearStart) {
                if (_month > optiMonthStart) {
                    return ' opti';
                } else if (_month === optiMonthStart) {
                    return ' opti-start';
                }
            }

            if (year === optiYearEnd) {
                if (_month < optiMonthEnd) {
                    return ' opti';
                } else if (_month === optiMonthEnd) {
                    return ' opti-end';
                }
            }

            return '';
        };

        const checkOutageYear = (_year) => {
            for (let i = outages.length - 1; i >= 0; i--) {
                let _date = new Date(outages[i].outageStartDate);
                let _outageYear = _date.getFullYear();
                if (_outageYear === _year) {
                    return ' outage'
                }
            }
            return ''
        };
     
        const checkOutageMonth = (_month) => {
            for (let i = outages.length - 1; i >= 0; i--) {
                let _date = new Date(outages[i].outageStartDate);
                let _outageMonth = _date.getMonth();
                let _outageYear = _date.getFullYear();

                if(_outageMonth ===_month && _outageYear === year){
                    return ' outage'
                }
            }
            return ''
        };

        return (
            <>
                <Button
                    ref={buttonRef}
                    label={
                        <>
                            <span>{year}</span>
                            &nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;<span>{monthLabels[month]}</span>
                        </>
                    }
                    icon="pi pi-angle-down"
                    iconPos="right"
                    className="aq-btn aq-primary"
                    onClick={(e) => {
                        myPickerRef.current.toggle(e);
                    }}
                    style={{ width: 198, minWidth: 198, maxWidth: 198, textAlign: 'left' }}
                />
                <OverlayPanel
                    ref={myPickerRef}
                    className="aq-flyout my-selector"
                    onShow={() => {
                        setSaving(false);
                        setBackUpMonth(month);
                        setBackUpYears(year);
                        setIsOpen(true);
                    }}
                    onHide={dismiss}
                >
                    <div className="row">
                        <div className="col-50" style={{ borderRight: '1px solid #d9d9d9' }}>
                            {years.map((_year, index) => (
                                <div
                                    onClick={() => onChangeYear(_year)}
                                    title={checkOutageYear(_year).length > 0 ? 'Outages are planned in this year' : null}
                                    style={{ marginLeft: 0 }}
                                    className={
                                        'my-selector-item' +
                                        (_year === year ? ' active' : '') +
                                        (_year >= dangerYear ? ' danger' : '') +
                                        (_year === optiYearStart && optiYearStart === optiYearEnd
                                            ? ' opti-single'
                                            : '') +
                                        (_year === optiYearStart && optiYearStart !== optiYearEnd
                                            ? ' opti-start'
                                            : '') +
                                        (_year === optiYearEnd && optiYearStart !== optiYearEnd ? ' opti-end' : '') +
                                        (_year > optiYearStart && _year < optiYearEnd ? ' opti' : '') +
                                        (_year === optiYearStart && _year === optiYearEnd ? ' opti' : '') +
                                        checkOutageYear(_year)
                                    }
                                    key={index}
                                >
                                    {_year}
                                </div>
                            ))}
                        </div>
                        <div className="col-50">
                            {monthLabels.map((_month, index) => (
                                <div
                                    onClick={() => onChangeMonth(index)}
                                    style={{ marginRight: 0 }}
                                    title={checkOutageMonth(index).length > 0 ? 'An outage is planned in this month' : null}
                                    className={
                                        'my-selector-item' +
                                        (index === month ? ' active' : '') +
                                        (year === dangerYear
                                            ? index >= dangerMonth
                                                ? ' danger'
                                                : ''
                                            : year > dangerYear
                                            ? ' danger'
                                            : '') +
                                        getOptiMonthClass(index) +
                                        checkOutageMonth(index)
                                    }
                                    key={index}
                                >
                                    {_month}
                                </div>
                            ))}
                        </div>
                    </div>
                    <Button
                        label="Predict"
                        className="aq-btn aq-suggestive predict-confirm"
                        icon="tool-icon tool-icon-predict predict-confirm-icon"
                        style={{ width: '100%', marginTop: '1rem' }}
                        onClick={() => {
                            setSaving(true);
                            myPickerRef.current.hide();
                        }}
                    />
                </OverlayPanel>
            </>
        );
    }
);

export default MonthYearPicker;
