import { createSlice } from "@reduxjs/toolkit";

export const fullscreenSlice = createSlice({
    name:'fullscreen',
    initialState: {
        fullscreen: false
    },
    reducers:{
        enterFullscreenMode:(state)=>{
            state.fullscreen = true;
        },
        exitFullscreenMode:(state)=>{
            state.fullscreen = false;
        },
        toggleFullScreenMode:(state)=> {
            state.fullscreen = !state.fullscreen;
        }
    }
})
export const {enterFullscreenMode, exitFullscreenMode, toggleFullScreenMode} = fullscreenSlice.actions;
export default fullscreenSlice.reducer;