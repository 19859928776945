import DamageLevelLUT from '../../assets/luts/DamageLevelLUT.png';
import DamageRateLUT from '../../assets/luts/DamageRateLUT.png';
import { useEffect, useRef, useState } from 'react';
import { EventType, PresentationMode } from '../../config/Constants';
import { loadPatternCanvas } from '../../utils/Pattern';
import Viewport from '../../components/viewport/Viewport';
import TopPanelInspectionFlatout from '../../components/topPanel/TopPanelInspectionFlatout';
import ToolsBarInspectionFlatout from '../../components/tools/ToolsBarInspectionFlatout';
import './InspectionFlatouts.scss';
import { FlatoutDataAPI } from '../../services/FlatoutDataAPI';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateImage } from '../../services/ImageAPI';
import { PerfMon } from '../../utils/PerfMon';
import { hideModal, showModal } from '../../modal/Modal';
import {
    checkIfPointPathMatch,
    checkWhetherAnnotationsIntersect,
    checkWhetherInsideBoundBox,
    convertBase64ToArray,
    convertBase64ToArrayOfArrays,
    deepCopy,
    distanceFromLineSegment,
    insidePolygon,
    parseEventStream,
    sleep,
    updateCellsIndexMap,
    withinManhattanRadius,
} from '../../utils/Helper';
import { ActionType } from '../../utils/Action';
import { PaintCell } from '../../components/tools/PaintCell';
import { Coord } from '../../utils/Coord';
import { RevisionAPI } from '../../services/RevisionAPI';
import { CellEvent } from '../../event/CellEvent';
import { TemplateAPI } from '../../services/TemplateAPI';
import { PaintRegion } from '../../components/tools/PaintRegion';
import { Cell } from '../../models/Cell';
import { CellDetection } from '../../utils/CellDetection';
import { loadLUTData, lookUpColor } from '../../utils/Lut';
import TextContextBar from '../../components/textContextBar/TextContextBar';
import ScribbleContextBar from '../../components/scribbleContextBar/ScribbleContextBar';
import { TextEvent } from '../../event/TextEvent';
import { Button } from 'primereact/button';
import { ScribbleEvent } from '../../event/ScribbleEvent';
import { EditPropertiesDialog } from '../../components/editPropertiesDialog/EditPropertiesDialog';
import { SelectTool } from '../../components/tools/SelectTool';
import { AnnotationEvent } from '../../event/AnnotationEvent';
import { confirmDialog } from 'primereact/confirmdialog';
import PresentationModeSwitcher2 from '../../components/presentationModeSwitcher/PresentationModeSwitcher2';
import CellContextBar from '../../components/cellContextBar/CellContextBar';
import { showLoading } from '../../utils/Loading';
import { setTool } from '../../redux/reducer/ToolReducer';
import { Tessellation } from '../../utils/Tessellation';
import { StaticStates } from '../../utils/StaticeStates';
import { PredictionAPI } from '../../services/PredictionAPI';
import { setLoadingBlocker } from '../../redux/reducer/LoadingBlockerReducer';
import { clearTriggerRedo, clearTriggerUndo, triggerRedo, triggerUndo } from '../../redux/reducer/ReduxTriggers';

export default function InspectionFlatouts() {
    const selectedTool = useSelector((state) => state.tool.tool);
    const hierarchy = useSelector((state) => state.hierarchy);
    const undoTriggered = useSelector((state) => state.reduxTriggers.undo);
    const redoTriggered = useSelector((state) => state.reduxTriggers.redo);
    const viewportRef = useRef();
    const topPanelInspectionFlatoutRef = useRef();
    const imageUploadInterval = useRef(0);
    const textBoxRef = useRef();
    const textInputRef = useRef();
    const editPropModalRef = useRef();

    const [imageData, setImageData] = useState(null);
    const [flatout, setFlatout] = useState(null);
    const [paintCell, setPaintCell] = useState(new PaintCell());
    const [paintRegion, setPaintRegion] = useState(new PaintRegion());
    const [selectTool, setSelectTool] = useState(new SelectTool());
    const [currentEvent, setCurrentEvent] = useState();
    const [cellIndexMap, setCellIndexMap] = useState(new Uint16Array());
    const [eventSequence, setEventSequence] = useState([]);
    const [newEventIndices, setNewEventIndices] = useState([]);
    const [annotationHashmap, setAnnotationHashmap] = useState([]);
    const [dmgLevelLUT, setDmgLevelLUT] = useState(null);
    const [dmgRateLUT, setDmgRateLUT] = useState(null);
    const [dmgTypeData, setDmgTypeData] = useState([]);
    const [mntnScopeData, setMntnScopeData] = useState([]);

    const [chosenDmgLevel, setChosenDmgLevel] = useState(0);
    const [chosenDmgType, setChosenDmgType] = useState(0);
    const [chosenDmgRate, setChosenDmgRate] = useState(0);
    const [chosenMntnScope, setChosenMntnScope] = useState(0);

    const [chosenScribbleThickness, setChosenScribbleThickness] = useState(3);
    const [chosenScribbleColor, setChosenScribbleColor] = useState('rgb(0,0,0)');

    const [chosenTextValue, setChosenTextValue] = useState('');
    const [chosenTextColor, setChosenTextColor] = useState('rgb(0,0,80)');
    const [chosenTextSize, setChosenTextSize] = useState(36);
    const [chosenTextStyle, setChosenTextStyle] = useState({ bold: false, italic: false });

    // Selection System
    const [selectedEventIndices, setSelectedEventIndices] = useState([]);
    const [annotationTranslation, setAnnotationTranslation] = useState(null);

    const [maxDmgRate, setMaxDmgRate] = useState(0.5);

    const [damageTypePatterns, setDamageTypePatterns] = useState([]);
    const [mntnScopePatterns, setMntnScopePatterns] = useState([]);
    const [viewMode, setViewMode] = useState(PresentationMode.DMG_LEVEL);

    const [redoStack, setRedoStack] = useState([]);
    const [undoEvents, setUndoEvents] = useState([]);
    const [undoLimit, setUndoLimit] = useState(0);
    const [loadingPrediction, setLoadingPrediction] = useState(false);

    const [locked, setLocked] = useState(false);

    const dispatch = useDispatch();

    // This method is used to load all the patterns that are required for damge type and maintenance scope
    const loadAllPatterns = async () => {
        loadLUTData(DamageLevelLUT, setDmgLevelLUT);
        loadLUTData(DamageRateLUT, setDmgRateLUT);
        var damageTypes = await FlatoutDataAPI.getDamageTypes();
        var maintenanceScopes = await FlatoutDataAPI.getMaintenanceScope();

        setDmgTypeData(damageTypes);
        setMntnScopeData(maintenanceScopes);
        let patternCanvases = [];
        for (let i = 0; i < damageTypes.length; i++) {
            patternCanvases.push(
                loadPatternCanvas(
                    `rgb(${damageTypes[i].patternFgColorR},${damageTypes[i].patternFgColorG},${damageTypes[i].patternFgColorB})`,
                    `rgb(${damageTypes[i].patternBgColorR},${damageTypes[i].patternBgColorG},${damageTypes[i].patternBgColorB})`,
                    damageTypes[i].patternStyle,
                    damageTypes[i].patternWeight
                )
            );
        }

        setDamageTypePatterns([...patternCanvases]);

        patternCanvases = [];
        for (let i = 0; i < maintenanceScopes.length; i++) {
            patternCanvases.push(
                loadPatternCanvas(
                    `rgb(${maintenanceScopes[i].patternFgColorR},${maintenanceScopes[i].patternFgColorG},${maintenanceScopes[i].patternFgColorB})`,
                    `rgb(${maintenanceScopes[i].patternBgColorR},${maintenanceScopes[i].patternBgColorG},${maintenanceScopes[i].patternBgColorB})`,
                    maintenanceScopes[i].patternStyle,
                    maintenanceScopes[i].patternWeight
                )
            );
        }

        setMntnScopePatterns([...patternCanvases]);
        showLoading(false);
    };
    /**
     * This method is used to handle the keyboad shortcuts
     * @param {Event} event 
     */
    const handleKeyBoardEvents = (event) => {
        if (event) {
            let cls1 = document.body.getElementsByClassName('p-dropdown-panel');
            let cls2 = document.body.getElementsByClassName('p-dialog-header');
            let key = event.key.toLowerCase();
            hideModal();
            if (
                cls1.length === 0 &&
                cls2.length === 0 &&
                viewportRef.current &&
                viewportRef.current.getIsImageAvailable() &&
                (textBoxRef.current.style.display === '' || textBoxRef.current.style.display === 'none')
            ) {
                switch (key) {
                    case 'w':
                        dispatch(setTool(ActionType.TRANSFORM));
                        break;
                    case 's':
                        dispatch(setTool(ActionType.SELECT));
                        break;
                    case 'c':
                        dispatch(setTool(ActionType.FILLCELL));
                        break;
                    case 'r':
                        dispatch(setTool(ActionType.FILLREGION));
                        break;
                    case 'x':
                        dispatch(setTool(ActionType.ERASE));
                        break;
                    case 't':
                        dispatch(setTool(ActionType.TEXT));
                        break;
                    case 'l':
                        dispatch(setTool(ActionType.SCRIBBLE));
                        break;
                    case 'z':
                        dispatch(triggerUndo());
                        break;
                    case 'y':
                        dispatch(triggerRedo());
                        break;
                    case '+':
                    case '=':
                        viewportRef.current.zoomIn();
                        break;
                    case '-':
                    case '_':
                        viewportRef.current.zoomOut();
                        break;
                    case 'f':
                        viewportRef.current.fitToScreen();
                        break;
                    default:
                }
            }
        }
    };
    // This use effect is used as helper to trigger undo using keyboard shortcut
    useEffect(() => {
        if (eventSequence && eventSequence.length > undoLimit && undoTriggered === 2) undo();
        dispatch(clearTriggerUndo());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [undoTriggered]);

    // This use effect is used as helper to trigger redo using keyboard shortcut
    useEffect(() => {
        if (eventSequence && redoStack.length > 0 && redoTriggered === 2) redo();
        dispatch(clearTriggerRedo());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redoTriggered]);
    // This use effect is used as initialiser when loading the page  and reset the page when leaving the page
    useEffect(() => {
        loadAllPatterns();
        window.addEventListener('keypress', handleKeyBoardEvents);

        return () => {
            StaticStates.FlatoutDataAcquired = false;
            StaticStates.FlatoutIsPublished = false;
            StaticStates.UploaderLockStatusAcquired = false;
            StaticStates.UploaderLockTimerActive = false;
            StaticStates.InspectorLockTimerActive = false;
            StaticStates.InspectorLockStatusAcquired = false;
            window.removeEventListener('keypress', handleKeyBoardEvents);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // This use effect is set the scale for paint cell 
    useEffect(() => {
        if (flatout) {
            PaintCell.scale = flatout.mmScale;
        }
    }, [flatout]);

    useEffect(() => {
        setFlatout(null);
        setImageData(null);
        CellDetection.detectedCells = [];
        setEventSequence([]);
        setUndoLimit(0);
        setAnnotationHashmap([]);
        setCellIndexMap([]);
        dispatch(setTool(ActionType.TRANSFORM));
        if (viewportRef.current) viewportRef.current.reset();
        if (
            hierarchy.siteId > 0 &&
            hierarchy.siteUnitId > 0 &&
            hierarchy.vesselId > 0 &&
            hierarchy.templateVersion >= 0 &&
            hierarchy.revisionVersion >= 0 &&
            hierarchy.outageId > 0 &&
            damageTypePatterns.length > 0
        ) {
            viewportRef.current.showLoader('Loading Drawing', true);
            fetchImage().then(() => {
                fetchTemplateData()
                    .then(async () => {
                        await fetchFlatoutData();
                        await revisionData();
                        viewportRef.current.hideLoader();
                    })
                    .catch((error) => {
                        viewportRef.current.hideLoader();
                    });
                if (textInputRef.current) textInputRef.current.style.fontSize = '36px';
                setChosenTextSize(36);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy, damageTypePatterns]);
    /**
     * This method is used to fetch the image
     */
    const fetchImage = async () => {
        if (imageUploadInterval.current) {
            clearInterval(imageUploadInterval.current);
        }
        console.info('Attempting to fetch image…');
        await tryFetchImage();
    };
    /**
     * This method is used to load the image
     * @param {int} pollCount 
     * @returns 
     */
    const tryFetchImage = async (pollCount = 0) => {
        if (pollCount > 30) {
            return;
        }
        dispatch(setLoadingBlocker(true));
        try {
            let imageResponse = await getTemplateImage(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.templateVersion
            );
            if (imageResponse.status === 200) {
                PerfMon.end();
                if (typeof imageResponse.data === 'string' || imageResponse.data instanceof String) {
                    showModal({
                        title: 'Invalid Image',
                        message: imageResponse.data,
                        class: 'error',
                    });
                } else {
                    console.info('Got image');
                    setImageData(imageResponse.data.data);
                }
            } else if (imageResponse.status === 204) {
                console.info('Still analysing. Retrying after 5 seconds…');
                PerfMon.start('Image Analysis');
                await sleep(5000);
                await tryFetchImage(pollCount + 1);
            }
            dispatch(setLoadingBlocker(false));
        } catch (err) {
            console.error(err);
        } finally {
            viewportRef.current.hideLoader();
        }
    };
    /**
     * This method is used to fetch the Flatout data 
     */
    const fetchFlatoutData = async () => {
        var flatout = await FlatoutDataAPI.get(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion);
        setFlatout(flatout);
    };
    /**
     * This method is used to get the revision data 
     */
    const revisionData = async () => {
        viewportRef.current.showLoader('Rendering Revision', true);
        dispatch(setLoadingBlocker(true));
        var response = await RevisionAPI.getFlatoutRevision(
            hierarchy.siteUnitId,
            hierarchy.vesselId,
            hierarchy.outageId,
            hierarchy.templateVersion,
            hierarchy.revisionVersion
        );
        if (response.eventSequence) {
            loadRevisionData(response.eventSequence);
        } else {
            viewportRef.current.hideLoader();
        }
        dispatch(setLoadingBlocker(false));
    };
    /**
     * This method is to to fetch the template data 
     */
    const fetchTemplateData = async () => {
        viewportRef.current.showLoader('Loading Cells', true);
        dispatch(setLoadingBlocker(true));
        var template = await TemplateAPI.get(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion);
        if (template.cells) {
            loadTemplateData(template.cells, template.tessellated);
        }
        dispatch(setLoadingBlocker(false));
    };

    /**
     * This method is used to load the template data 
     * @param {[]} cells 
     * @param {boolean} isTessellated 
     */

    const loadTemplateData = (cells, isTessellated) => {
        CellDetection.detectedCells = [];
        for (let i = 0; i < cells.length; i++) {
            var _cell = deepCopy(cells[i]);
            _cell.g.ap = convertBase64ToArray(_cell.g.ap);
            let encodedSubtractiveData = convertBase64ToArrayOfArrays(_cell.g.sPs);
            _cell.g.sPs = new Set(encodedSubtractiveData.map((data) => [...data]));

            let _centroid = [..._cell.g.c];
            _cell.g.c = new Coord(_centroid[0], _centroid[1]);

            let _scale = [..._cell.g.s];
            _cell.g.s = new Coord(_scale[0], _scale[1]);


            CellDetection.detectedCells.push(_cell);
        }

        for (let i = 0; i < cells.length; i++) {
            if (isTessellated) {
                CellDetection.detectedCells[i].g = Tessellation.decode(CellDetection.detectedCells[i].g, CellDetection.detectedCells);
            }
        }
    };

    /**
     * This method is used to load the previous revision data to the event sequences
     * @param {[]} eventList 
     */
    const loadRevisionData = async (eventList) => {
        let _cellIndexMap = [];
        let es = [];
        let _annotationHashMap = [];
        let indexes = [];
        let maxDamageRate = 0.5;
        for (let i = 0; i < eventList.length; i++) {
            var _event = eventList[i];

            switch (_event.flatoutEventType) {
                case EventType.CELL:
                    _cellIndexMap.push(i);
                    indexes.push(i);

                    if (_event.cellProperties.dr > maxDamageRate) {
                        maxDamageRate = _event.cellProperties.dr;
                    }

                    _event.cellIndex = i;
                    break;
                case EventType.TEXT:
                    _event.textProperties.rs = new Coord(_event.textProperties.rs[0], _event.textProperties.rs[1]);
                    _event.textProperties.re = new Coord(_event.textProperties.re[0], _event.textProperties.re[1]);
                    _event.textProperties.p = new Coord(_event.textProperties.p[0], _event.textProperties.p[1]);
                    _annotationHashMap.push(i);
                    indexes.push(i);
                    break;
                case EventType.SCRIBBLE:
                    _event.scribbleProperties.rs = new Coord(
                        _event.scribbleProperties.rs[0],
                        _event.scribbleProperties.rs[1]
                    );
                    _event.scribbleProperties.re = new Coord(
                        _event.scribbleProperties.re[0],
                        _event.scribbleProperties.re[1]
                    );
                    let sp = [];
                    for (let k = 0; k < _event.scribbleProperties.sp.length; k++) {
                        sp.push(new Coord(_event.scribbleProperties.sp[k][0], _event.scribbleProperties.sp[k][1]));
                    }
                    _event.scribbleProperties.sp = sp;
                    _annotationHashMap.push(i);
                    indexes.push(i);
                    break;
                default:
                    break;
            }
            _event.id = i;
            es.push(_event);
        }

        if (0.5 < maxDamageRate) {
            setMaxDmgRate(maxDamageRate);
        }

        let _undoLimit = eventList.length;

        if (
            StaticStates.PredictedData.damageLevelsData.length > 0 ||
            StaticStates.PredictedData.mntnScopeData.length > 0
        ) {
            viewportRef.current.showLoader('Loading Previously Predicted Data');
            await sleep(10);
            let eventIndexForRegion = [];
            for (let i = 0; i < _cellIndexMap.length; i++) {
                let requireEvent = es[_cellIndexMap[i]];
                let localEvent = JSON.parse(JSON.stringify(requireEvent));
                localEvent['parentEventIndex'] = requireEvent['id'];
                let _newIndex = es.length;
                if (StaticStates.PredictedData.damageLevelsData.length > 0) {
                    localEvent['cellProperties']['dl'] = StaticStates.PredictedData.damageLevelsData[i];
                }
                if (StaticStates.PredictedData.mntnScopeData.length > 0) {
                    localEvent['cellProperties']['ms'] = StaticStates.PredictedData.mntnScopeData[i];
                }
                localEvent['id'] = _newIndex;
                let newCellEvent = CellEvent.setAllValuesFromObject(localEvent);
                if (i === 0) newCellEvent = { ...newCellEvent, nEventsAfter: _cellIndexMap.length - 1 };
                else if (i === _cellIndexMap.length - 1)
                    newCellEvent = { ...newCellEvent, nEventsBefore: _cellIndexMap.length - 1 };
                es.push(newCellEvent);
                _cellIndexMap = updateCellsIndexMap(_cellIndexMap, newCellEvent['cellIndex'], _newIndex);
                eventIndexForRegion.push(_newIndex);
            }

            StaticStates.PredictedData.damageLevelsData = [];
            StaticStates.PredictedData.mntnScopeData = [];

            indexes = eventIndexForRegion;
        }

        setCellIndexMap([..._cellIndexMap]);
        setAnnotationHashmap([..._annotationHashMap]);
        setEventSequence([...es]);
        setNewEventIndices([...indexes]);
        setUndoLimit(_undoLimit);
        setRedoStack([]);
    };
    /**
     * This method is used to handle the mouse click events
     * @param {int} x 
     * @param {int} y 
     * @returns 
     */
    const handleInputOnce = async (x, y) => {
        switch (selectedTool) {
            case ActionType.TEXT:
                var textStyle = [];
                if (chosenTextStyle.bold) {
                    textStyle.push('bold');
                }

                if (chosenTextStyle.italic) {
                    textStyle.push('italic');
                }

                let textEvent = new TextEvent(chosenTextSize, chosenTextColor, textStyle.join(' '), new Coord(x, y));
                setCurrentEvent(textEvent);
                TextEvent.start(textEvent, new Coord(x, y));
                textBoxRef.current.style['opacity'] = 1;
                textBoxRef.current.style['display'] = 'block';

                textBoxRef.current.style['top'] = y + 'px';
                textBoxRef.current.style['left'] = x + 'px';

                setTimeout(() => {
                    textInputRef.current.focus({ preventScroll: true });
                }, 255);

                break;
            case ActionType.SELECT:
                let actionPresent = findAnnotation(x, y, ActionType.TEXT);
                if (actionPresent == null) {
                    actionPresent = findAnnotation(x, y, ActionType.SCRIBBLE);
                }

                if (actionPresent != null) {
                    if (selectedEventIndices === actionPresent.parentEvent) {
                        setSelectedEventIndices([]);
                        return;
                    }

                    let /**@type{Event} */ se = actionPresent.parentEvent;
                    let seIndex = actionPresent.parentEventId;
                    if (selectedEventIndices.length === 0) setSelectedEventIndices([seIndex]);

                    if (
                        se &&
                        (se.flatoutEventType === ActionType.TEXT || se.flatoutEventType === ActionType.SCRIBBLE)
                    ) {
                        SelectTool.end(undefined, selectedEventIndices.length >= 1);
                        se = null;
                    }
                } else {
                    // Look for cells
                    let cellIndex = findCell(x, y);
                    if (cellIndex >= 0) {
                        let cell = CellDetection.detectedCells[cellIndex];
                        SelectTool.drawOnOverlayCanvas(cell, viewportRef.current.getPreviewContext());
                        if (selectedEventIndices.length === 0) setSelectedEventIndices([cellIndexMap[cellIndex]]);
                        SelectTool.end(undefined, selectedEventIndices.length >= 1);
                    }
                }

                break;
            default:
        }
    };

    /**
     * This method is used to handle the mouse down events
     * @param {int} x 
     * @param {int} y 
     */
    const handleInputStart = async (x, y) => {
        let paintCtx = viewportRef.current.getPaintContext();
        let previewContext = viewportRef.current.getPreviewContext();
        switch (selectedTool) {
            case ActionType.FILLCELL:
                let updatedPaintCell = new PaintCell();
                PaintCell.start(updatedPaintCell, paintCtx, new Coord(x, y));
                setPaintCell(updatedPaintCell);
                break;
            case ActionType.FILLREGION:
                let updatedPaintRegion = new PaintRegion();
                PaintRegion.start(updatedPaintRegion, new Coord(x, y), previewContext);
                setPaintRegion(updatedPaintRegion);
                break;
            case ActionType.SCRIBBLE:
                let localEvent = new ScribbleEvent(chosenScribbleColor, chosenScribbleThickness);
                ScribbleEvent.start(localEvent, paintCtx);
                setCurrentEvent(localEvent);
                break;
            case ActionType.SELECT:
                let localSelectTool = new SelectTool();
                viewportRef.current.clearPreviewCanvas();
                SelectTool.start(
                    localSelectTool,
                    new Coord(x, y),
                    viewportRef.current.getPreviewContext(),
                    selectedEventIndices.length >= 1
                );
                setSelectTool(localSelectTool);
                setAnnotationTranslation(null);
                break;
            default:
        }
    };


    /**
     * This method is used to handle mouse move events
     * @param {int} x 
     * @param {int} y 
     */
    const handleInputUpdate = async (x, y) => {
        let paintCtx = viewportRef.current.getPaintContext();
        let previewContext = viewportRef.current.getPreviewContext();
        switch (selectedTool) {
            case ActionType.FILLCELL:
                if (viewMode === PresentationMode.DMG_LEVEL) {
                    paintCtx.fillStyle = getCellFill(chosenDmgLevel);
                } else if (viewMode === PresentationMode.DMG_RATE) {
                    paintCtx.fillStyle = getCellFill(chosenDmgRate);
                } else if (viewMode === PresentationMode.DMG_TYPE) {
                    paintCtx.fillStyle = getCellFill(chosenDmgType, paintCtx);
                } else if (viewMode === PresentationMode.MNTN_SCOPE) {
                    paintCtx.fillStyle = getCellFill(chosenMntnScope, paintCtx);
                }

                let updatedPaintCell = { ...paintCell };
                PaintCell.move(updatedPaintCell, paintCtx, new Coord(x, y), CellDetection.detectedCells, cellIndexMap);
                setPaintCell(updatedPaintCell);
                break;
            case ActionType.FILLREGION:
                let updatedPaintRegion = { ...paintRegion };
                if (viewMode === PresentationMode.DMG_LEVEL) {
                    previewContext.fillStyle = getCellFill(chosenDmgLevel);
                } else if (viewMode === PresentationMode.DMG_RATE) {
                    previewContext.fillStyle = getCellFill(chosenDmgRate);
                } else if (viewMode === PresentationMode.DMG_TYPE) {
                    previewContext.fillStyle = getCellFill(chosenDmgType, paintCtx);
                } else if (viewMode === PresentationMode.MNTN_SCOPE) {
                    previewContext.fillStyle = getCellFill(chosenMntnScope, paintCtx);
                }
                PaintRegion.move(
                    updatedPaintRegion,
                    new Coord(x, y),
                    previewContext,
                    CellDetection.detectedCells,
                    cellIndexMap
                );
                break;
            case ActionType.SCRIBBLE:
                let localEvent = { ...currentEvent };
                ScribbleEvent.move(localEvent, new Coord(x, y), paintCtx);
                setCurrentEvent(localEvent);
                break;
            case ActionType.SELECT:
                let /**@type {SelectTool} */ localSelectTool = { ...selectTool };
                SelectTool.move(
                    localSelectTool,
                    new Coord(x, y),
                    viewportRef.current.getPreviewContext(),
                    CellDetection.detectedCells,
                    cellIndexMap,
                    selectedEventIndices.length > 0
                );
                setSelectTool(localSelectTool);
                if (SelectTool.dragging && selectedEventIndices.length > 0) {
                    viewportRef.current.clearPreviewCanvas();
                    AnnotationEvent.drawOnlyBoundingBox(
                        eventSequence[selectedEventIndices[0]],
                        viewportRef.current.getPreviewContext(),
                        SelectTool.relativeTranslation
                    );
                }
                break;
            default:
        }
    };
    /**
     * This method is used to handle the mouse up events
     * @param {int} x
     * @param {int} y
     */
    const handleInputEnd = async (x, y) => {
        let paintCtx = viewportRef.current.getPaintContext();
        switch (selectedTool) {
            case ActionType.FILLCELL:
                let updatedPaintCell = { ...paintCell };
                var cellEvIndices = PaintCell.end(
                    updatedPaintCell,
                    CellDetection.detectedCells,
                    paintCtx,
                    cellIndexMap
                );
                let esForCell = eventSequence.slice();
                let cimForCell = cellIndexMap.slice();
                let eventIndexForCell = [];
                for (let i = 0; i < cellEvIndices.length; i++) {
                    let requireEvent = esForCell[cellEvIndices[i]];
                    let localEvent = JSON.parse(JSON.stringify(requireEvent));
                    localEvent['parentEventIndex'] = requireEvent['id'];
                    let _newIndex = esForCell.length;
                    if (viewMode === PresentationMode.DMG_LEVEL) {
                        localEvent['cellProperties']['dl'] = chosenDmgLevel;
                    } else if (viewMode === PresentationMode.DMG_TYPE) {
                        localEvent['cellProperties']['dt'] = chosenDmgType;
                    } else if (viewMode === PresentationMode.DMG_RATE) {
                        localEvent['cellProperties']['dr'] = chosenDmgRate;

                        if (chosenDmgRate > maxDmgRate) {
                            setMaxDmgRate(chosenDmgRate);
                        }
                    } else {
                        localEvent['cellProperties']['ms'] = chosenMntnScope;
                    }
                    localEvent['id'] = _newIndex;
                    let newCellEvent = CellEvent.setAllValuesFromObject(localEvent);
                    if (i === 0) newCellEvent = { ...newCellEvent, nEventsAfter: cellEvIndices.length - 1 };
                    else if (i === cellEvIndices.length - 1)
                        newCellEvent = { ...newCellEvent, nEventsBefore: cellEvIndices.length - 1 };
                    esForCell.push(newCellEvent);
                    cimForCell = updateCellsIndexMap(cimForCell, newCellEvent['cellIndex'], _newIndex);
                    eventIndexForCell.push(_newIndex);
                }
                setEventSequence([...esForCell]);
                setRedoStack([]);
                setCellIndexMap([...cimForCell]);
                setNewEventIndices([...eventIndexForCell]);
                break;
            case ActionType.FILLREGION:
                let updatedPaintRegion = { ...paintRegion };
                // eslint-disable-next-line no-redeclare
                var cellEvIndices = PaintRegion.end(updatedPaintRegion);
                viewportRef.current.clearPreviewCanvas();
                let esForRegion = eventSequence.slice();
                let cimForRegion = cellIndexMap.slice();
                let eventIndexForRegion = [];
                for (let i = 0; i < cellEvIndices.length; i++) {
                    let requireEvent = esForRegion[cellEvIndices[i]];
                    let localEvent = JSON.parse(JSON.stringify(requireEvent));
                    localEvent['parentEventIndex'] = requireEvent['id'];
                    let _newIndex = esForRegion.length;
                    if (viewMode === PresentationMode.DMG_LEVEL) {
                        localEvent['cellProperties']['dl'] = chosenDmgLevel;
                    } else if (viewMode === PresentationMode.DMG_TYPE) {
                        localEvent['cellProperties']['dt'] = chosenDmgType;
                    } else if (viewMode === PresentationMode.DMG_RATE) {
                        localEvent['cellProperties']['dr'] = chosenDmgRate;

                        if (chosenDmgRate > maxDmgRate) {
                            setMaxDmgRate(chosenDmgRate);
                        }
                    } else {
                        localEvent['cellProperties']['ms'] = chosenMntnScope;
                    }
                    localEvent['id'] = _newIndex;
                    let newCellEvent = CellEvent.setAllValuesFromObject(localEvent);
                    if (i === 0) newCellEvent = { ...newCellEvent, nEventsAfter: cellEvIndices.length - 1 };
                    else if (i === cellEvIndices.length - 1)
                        newCellEvent = { ...newCellEvent, nEventsBefore: cellEvIndices.length - 1 };
                    esForRegion.push(newCellEvent);
                    cimForRegion = updateCellsIndexMap(cimForRegion, newCellEvent['cellIndex'], _newIndex);
                    eventIndexForRegion.push(_newIndex);
                }
                setEventSequence([...esForRegion]);
                setRedoStack([]);
                setCellIndexMap([...cimForRegion]);
                setNewEventIndices([...eventIndexForRegion]);
                break;
            case ActionType.SCRIBBLE:
                let _eventSequence = eventSequence.slice();
                let eventId = eventSequence.length;
                let localEvent = { ...currentEvent };
                localEvent['id'] = eventId;
                setCurrentEvent(localEvent);
                let value = ScribbleEvent.end(localEvent, paintCtx);
                if (value) {
                    _eventSequence.push(localEvent);
                    let _annotationHashMap = annotationHashmap.slice();
                    _annotationHashMap.push(_eventSequence.length - 1);

                    setAnnotationHashmap([..._annotationHashMap]);
                    setEventSequence([..._eventSequence]);
                    setRedoStack([]);
                    setNewEventIndices([_eventSequence.length - 1]);
                }
                break;
            case ActionType.ERASE:
                let esResponse = eraseAction(x, y);
                if (esResponse && esResponse.isDeleted) {
                    let _eventSequence = eventSequence.slice();

                    if (
                        esResponse.deletedEvent.flatoutEventType === EventType.SCRIBBLE ||
                        esResponse.deletedEvent.flatoutEventType === EventType.TEXT
                    ) {
                        let _annotationHashMap = annotationHashmap.slice();
                        let lastIndex = _eventSequence.length;
                        _eventSequence.push(esResponse.deletedEvent);
                        _annotationHashMap[esResponse.index] = lastIndex;
                        setEventSequence(_eventSequence);
                        setAnnotationHashmap(_annotationHashMap);
                        setNewEventIndices([lastIndex]);
                        setRedoStack([]);
                    } else if (esResponse.deletedEvent.flatoutEventType === EventType.CELL) {
                        let _cellIndexMap = cellIndexMap.slice();
                        let lastIndex = _eventSequence.length;
                        _eventSequence.push(esResponse.deletedEvent);
                        _cellIndexMap[esResponse.cellIndex] = lastIndex;
                        setEventSequence(_eventSequence);
                        setCellIndexMap(_cellIndexMap);
                        setNewEventIndices([lastIndex]);
                        setRedoStack([]);
                    }
                }
                break;
            case ActionType.SELECT:
                let localSelectTool = { ...selectTool };
                let response = SelectTool.end(localSelectTool, selectedEventIndices.length >= 1);
                if (response.x != null) {
                    setAnnotationTranslation(new Coord(response.x, response.y));
                } else if (response.length > 0) {
                    setSelectTool(localSelectTool);
                    setSelectedEventIndices([...response]);
                } else {
                    SelectTool.end(undefined, selectedEventIndices.length >= 1);
                    setSelectedEventIndices([]);
                }
                break;
            default:
        }
    };

    /**
     * This method is used to confirm the text in the textbox
     * @type {Event} ev
     */
    const textConfirm = (ev) => {
        ev.stopPropagation();
        if (chosenTextValue.trim().length === 0) {
            return;
        }

        let paintCtx = viewportRef.current.getPaintContext();
        let eventId = eventSequence.length;
        let localEvent = { ...currentEvent };
        localEvent['id'] = eventId;
        var textStyle = [];
        if (chosenTextStyle.bold) {
            textStyle.push('bold');
        }

        if (chosenTextStyle.italic) {
            textStyle.push('italic');
        }

        let value = TextEvent.end(
            localEvent,
            paintCtx,
            chosenTextValue.trim(),
            chosenTextColor,
            chosenTextSize,
            textStyle.join(' '),
            localEvent['id']
        );
        if (value) {
            let es = eventSequence.slice();
            es.push(localEvent);
            setEventSequence((prevEventsList) => {
                var updatedList = [...prevEventsList, localEvent];
                return updatedList;
            });
            setRedoStack([]);
            setAnnotationHashmap((prevDataList) => [...prevDataList, es.length - 1]);
            setNewEventIndices([es.length - 1]);
        }
        setChosenTextValue('');
        textBoxRef.current.style.display = 'none';
    };

    /**
     * This method is used to reset the textbox
     * @param {Event} ev 
     */
    const textBoxCancel = (ev) => {
        if (ev) ev.stopPropagation();
        setChosenTextValue('');
        textBoxRef.current.style.display = 'none';
        setTimeout(() => {
            textBoxRef.current.focus();
        }, 200);
    };

    // This is used to redraw the cells based on the current presentation mode
    useEffect(() => {
        if (CellDetection.detectedCells.length > 0) {
            draw();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMode]);

    /**
     * This method is used to draw the events 
     * @param {[]} targetEventIndices 
     * @param {boolean} drawTarget 
     */
    const draw = async (targetEventIndices = [], drawTarget = true) => {
        if (viewportRef.current) {
            let ctx = viewportRef.current.getPaintContext();

            let drawForUndo = targetEventIndices.length === 0;
            // Number of items in target will only be same
            if (
                (targetEventIndices.length > 0 || undoEvents.length > 0) &&
                targetEventIndices.length !== eventSequence.length
            ) {
                var targetEvent = drawForUndo ? undoEvents[0] : eventSequence[targetEventIndices[0]];
                if (targetEvent.flatoutEventType === EventType.CELL) {
                    let cellRectStartX = 4000;
                    let cellRectStartY = 2666;
                    let cellRectEndX = 0;
                    let cellRectEndY = 0;

                    let nCells = drawForUndo ? undoEvents.length : targetEventIndices.length;
                    for (let k = 0; k < nCells; k++) {
                        var targetCellEvent = drawForUndo
                            ? eventSequence[undoEvents[k].parentEventIndex]
                            : eventSequence[targetEventIndices[k]];
                        let cell = CellDetection.detectedCells[targetCellEvent.cellIndex];
                        if (cell) {
                            if (cell.g.c.x < cellRectStartX) {
                                cellRectStartX = cell.g.c.x;
                            }
                            if (cell.g.c.y < cellRectStartY) {
                                cellRectStartY = cell.g.c.y;
                            }
                            if (cell.g.c.x > cellRectEndX) {
                                cellRectEndX = cell.g.c.x;
                            }

                            if (cell.g.c.y > cellRectEndY) {
                                cellRectEndY = cell.g.c.y;
                            }

                            // Draw Cell
                            Cell.draw(cell, getCellFill(targetCellEvent, ctx), ctx);
                        }
                    }

                    for (let i = 0; i < annotationHashmap.length; i++) {
                        var annotation = eventSequence[annotationHashmap[i]];
                        var _properties =
                            annotation.flatoutEventType === EventType.SCRIBBLE
                                ? 'scribbleProperties'
                                : 'textProperties';
                        if (
                            annotation[_properties].rs.x < cellRectStartX &&
                            annotation[_properties].rs.y < cellRectStartY &&
                            annotation[_properties].re.x > cellRectEndX &&
                            annotation[_properties].re.y > cellRectEndY
                        ) {
                            if (annotation.flatoutEventType === EventType.SCRIBBLE) {
                                ScribbleEvent.drawFromObject(annotation, ctx);
                            } else if (annotation.flatoutEventType === EventType.TEXT) {
                                TextEvent.drawTextFromObject(annotation, ctx);
                            }
                        }
                    }
                } else if (
                    targetEvent.flatoutEventType === EventType.SCRIBBLE ||
                    targetEvent.flatoutEventType === EventType.TEXT
                ) {
                    let targetEventProperties =
                        targetEvent.flatoutEventType === EventType.SCRIBBLE ? 'scribbleProperties' : 'textProperties';
                    // Clear bounding box
                    ctx.clearRect(
                        targetEvent[targetEventProperties].rs.x,
                        targetEvent[targetEventProperties].rs.y,
                        targetEvent[targetEventProperties].re.x - targetEvent[targetEventProperties].rs.x,
                        targetEvent[targetEventProperties].re.y - targetEvent[targetEventProperties].rs.y
                    );
                    // Draw cells which are within the bounding box
                    for (let i = 0; i < CellDetection.detectedCells.length; i++) {
                        var cell = CellDetection.detectedCells[i];
                        if (
                            targetEvent[targetEventProperties].rs.x - 25 < cell.g.c.x &&
                            targetEvent[targetEventProperties].rs.y - 25 < cell.g.c.y &&
                            targetEvent[targetEventProperties].re.x + 25 > cell.g.c.x &&
                            targetEvent[targetEventProperties].re.y + 25 > cell.g.c.y
                        ) {
                            var cellEvent = eventSequence[cellIndexMap[i]];
                            Cell.draw(cell, getCellFill(cellEvent, ctx), ctx);
                        }
                    }

                    // Draw annotations which overlap with the target annotation
                    for (let i = 0; i < annotationHashmap.length; i++) {
                        /*
                         * Find out which annotations have common
                         * boundaries with the target annotation and
                         * draw them.
                         */
                        let /**@type {ScribbleEvent} */ annotation = eventSequence[annotationHashmap[i]];
                        let annotationProperties =
                            annotation.flatoutEventType === EventType.SCRIBBLE
                                ? 'scribbleProperties'
                                : 'textProperties';
                        if (annotation.id !== targetEvent.id) {
                            if (
                                checkWhetherAnnotationsIntersect(
                                    annotation[annotationProperties].rs,
                                    annotation[annotationProperties].re,
                                    targetEvent[targetEventProperties].rs,
                                    targetEvent[targetEventProperties].re
                                )
                            ) {
                                if (annotation.flatoutEventType === EventType.SCRIBBLE) {
                                    ScribbleEvent.drawFromObject(annotation, ctx);
                                } else {
                                    TextEvent.drawTextFromObject(annotation, ctx);
                                }
                            }
                        }
                    }
                    if (drawTarget && undoEvents.length === 0) {
                        // Finally draw the target annotation
                        if (targetEvent.flatoutEventType === EventType.SCRIBBLE) {
                            ScribbleEvent.drawFromObject(targetEvent, ctx);
                        } else {
                            TextEvent.drawTextFromObject(targetEvent, ctx);
                        }
                    } else if (
                        undoEvents.length > 0 &&
                        undoEvents[0].flatoutEventType !== EventType.CELL &&
                        undoEvents[0].parentEventIndex != null
                    ) {
                        let parentAnnotation = eventSequence[undoEvents[0].parentEventIndex];
                        if (parentAnnotation.flatoutEventType === EventType.SCRIBBLE)
                            ScribbleEvent.drawFromObject(parentAnnotation, ctx);
                        else TextEvent.drawTextFromObject(parentAnnotation, ctx);
                    }
                }
            } else {
                ctx.clearRect(0, 0, 4000, 2666);

                // Draw all cells
                for (let i = 0; i < CellDetection.detectedCells.length; i++) {
                    let cell = CellDetection.detectedCells[i];
                    let /**@type {CellEvent} */ cellEvent = eventSequence[cellIndexMap[i]];
                    Cell.draw(cell, getCellFill(cellEvent, ctx), ctx);
                }

                // Draw all annotations
                for (let i = 0; i < annotationHashmap.length; i++) {
                    let annotation = eventSequence[annotationHashmap[i]];
                    if (annotation.flatoutEventType === EventType.SCRIBBLE) {
                        ScribbleEvent.drawFromObject(annotation, ctx);
                    } else if (annotation.flatoutEventType === EventType.TEXT) {
                        TextEvent.drawTextFromObject(annotation, ctx);
                    }
                }
            }
        }
    };

    /**
     * The below is typically needed to undoing annotations
     */
    useEffect(() => {
        if (eventSequence.length > 0 && undoEvents.length > 0) {
            draw().then(() => {
                setUndoEvents([]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSequence, undoEvents]);
    /**
     * This method is used to undo the action on the top of the event sequence
     * @returns 
     */
    const undo = async () => {
        if (eventSequence.length <= undoLimit) return;
        let _eventSequence = [...eventSequence];
        let _redoStack = [...redoStack];
        let /**@type {CellEvent} */ _removedElement = _eventSequence.splice(_eventSequence.length - 1, 1)[0];
        if (
            _removedElement.flatoutEventType === EventType.SCRIBBLE ||
            _removedElement.flatoutEventType === EventType.TEXT
        ) {
            let _annotationHashMap = [...annotationHashmap];
            let _annotation_index = -1;
            _redoStack.push(_removedElement);
            for (let j = _annotationHashMap.length - 1; j >= 0; j--) {
                if (eventSequence[_annotationHashMap[j]].id === _removedElement.id) {
                    _annotation_index = j;
                    break;
                }
            }

            if (_annotation_index > -1) {
                if (_removedElement.parentEventIndex) {
                    _annotationHashMap[_annotation_index] = _removedElement.parentEventIndex;
                } else {
                    _annotationHashMap.splice(_annotation_index, 1);
                }
                setAnnotationHashmap(_annotationHashMap.slice());
                setEventSequence(_eventSequence.slice());
                setUndoEvents([_removedElement]);
            }
        }
        if (_removedElement.flatoutEventType === EventType.CELL) {
            let _cellIndexMap = [...cellIndexMap];
            let _undoEvents = [_removedElement];
            if (_removedElement['nEventsBefore'] || _removedElement['nEventsAfter']) {
                let eventsBefore = _removedElement.nEventsBefore;
                let _newEventIndexes = [];

                let _requiredEvent = eventSequence[_cellIndexMap[_removedElement.cellIndex]];
                _cellIndexMap[_removedElement.cellIndex] = _requiredEvent.parentEventIndex;
                _redoStack.push(_removedElement);
                for (let count = 0; count < eventsBefore; count++) {
                    let _cellRemoveEvent = _eventSequence.pop();
                    if (_cellRemoveEvent) {
                        _redoStack.push(_cellRemoveEvent);
                        let _requiredEvent = eventSequence[_cellIndexMap[_cellRemoveEvent.cellIndex]];
                        _cellIndexMap[_cellRemoveEvent.cellIndex] = _requiredEvent.parentEventIndex;
                        _newEventIndexes.push(_requiredEvent.parentEventIndex);
                    }
                    _undoEvents.push(_cellRemoveEvent);
                }
                setCellIndexMap(_cellIndexMap.slice());
                setEventSequence(_eventSequence.slice());
                setUndoEvents([..._undoEvents]);
            } else {
                let _requiredEvent = eventSequence[_cellIndexMap[_removedElement.cellIndex]];
                _cellIndexMap[_removedElement.cellIndex] = _requiredEvent.parentEventIndex;
                _redoStack.push(_requiredEvent);
                setCellIndexMap(_cellIndexMap.slice());
                setEventSequence(_eventSequence.slice());
                setUndoEvents([..._undoEvents]);
            }
        }
        setRedoStack(_redoStack);
    };

    /**
     * This method is used redo the action on the top of the redo stack
     * @returns 
     */
    const redo = () => {
        if (redoStack.length === 0) return;
        let _redoStack = [...redoStack];
        let /**@type {ScribbleEvent} */ eventToBeAdded = _redoStack.splice(_redoStack.length - 1, 1)[0];
        if (
            eventToBeAdded.flatoutEventType === EventType.SCRIBBLE ||
            eventToBeAdded.flatoutEventType === EventType.TEXT
        ) {
            let _annotationHashMap = [...annotationHashmap];
            let _newIndex = eventSequence.length;
            eventToBeAdded.id = _newIndex;
            let _eventSequence = [...eventSequence, eventToBeAdded];

            let _annotation_index = -1;
            for (let i = _annotationHashMap.length - 1; i >= 0; i--) {
                if (_annotationHashMap[i] === eventToBeAdded.parentEventIndex) {
                    _annotation_index = i;
                    break;
                }
            }
            if (_annotation_index > -1) _annotationHashMap[_annotation_index] = eventToBeAdded.id;
            else _annotationHashMap.push(eventToBeAdded.id);
            setAnnotationHashmap(_annotationHashMap);
            setEventSequence(_eventSequence);
            setNewEventIndices([_eventSequence.length - 1]);
        } else if (eventToBeAdded.flatoutEventType === EventType.CELL) {
            if (eventToBeAdded['nEventsBefore'] || eventToBeAdded['nEventsAfter']) {
                let eventsAfter = eventToBeAdded.nEventsAfter;
                let _newEventIndexes = [];

                let _cellIndexMap = [...cellIndexMap];
                let _newIndex = eventSequence.length;
                _newEventIndexes.push(_newIndex);
                eventToBeAdded.id = _newIndex;
                _cellIndexMap[eventToBeAdded.cellIndex] = _newIndex;
                let _eventSequence = [...eventSequence, eventToBeAdded];

                for (let count = 0; count < eventsAfter; count++) {
                    let _eventToBeAdded = _redoStack.pop();
                    _newIndex = _eventSequence.length;
                    _eventToBeAdded.id = _newIndex;
                    _eventSequence.push(_eventToBeAdded);
                    _cellIndexMap[_eventToBeAdded.cellIndex] = _newIndex;
                    _newEventIndexes.push(_newIndex);
                }
                setCellIndexMap(_cellIndexMap);
                setEventSequence(_eventSequence);
                setNewEventIndices(_newEventIndexes);
            } else {
                let _newEventIndexes = [];

                let _cellIndexMap = [...cellIndexMap];
                let _newIndex = eventSequence.length;
                _newEventIndexes.push(_newIndex);
                eventToBeAdded.id = _newIndex;
                _cellIndexMap[eventToBeAdded.cellIndex] = _newIndex;
                let _eventSequence = [...eventSequence, eventToBeAdded];
                setCellIndexMap(_cellIndexMap);
                setEventSequence(_eventSequence);
                setNewEventIndices(_newEventIndexes);
            }
        }

        setRedoStack(_redoStack);
    };

    /**
     * This method is used to get the cell fill based on the presentation mode 
     * @param {number} value 
     * @param {CanvasRenderingContext2D} ctx 
     * @returns 
     */
    const getCellFill = (value, ctx = null) => {
        let color = '';
        debugger
        switch (viewMode) {
            case PresentationMode.DMG_LEVEL:
                if (value.cellProperties) {
                    value = value.cellProperties.dl;
                }

                color = lookUpColor(dmgLevelLUT, value, 0, 1);
                break;
            case PresentationMode.DMG_RATE:
                if (value.cellProperties) {
                    value = value.cellProperties.dr;
                }

                // eslint-disable-next-line eqeqeq
                if (value == 0) color = `rgb(72,72,72)`;
                else color = lookUpColor(dmgRateLUT, value, 0, 0.1, PresentationMode.DMG_RATE);

                break;
            case PresentationMode.DMG_TYPE:
                if (value.cellProperties) {
                    value = value.cellProperties.dt;
                }

                if (ctx != null) {
                    color = ctx.createPattern(damageTypePatterns[value], 'repeat');
                }
                break;
            default:
                if (value.cellProperties) {
                    value = value.cellProperties.ms;
                }

                if (ctx != null) {
                    color = ctx.createPattern(mntnScopePatterns[value], 'repeat');
                }
                break;
        }

        return color;
    };
    /**
     * This method is used to find the annotation based on the type specified
     * @param {number} x
     * @param {number} y
     * @param {ActionType} type
     * @returns
     */
    const findAnnotation = (x, y, type) => {
        for (let i = annotationHashmap.length - 1; i >= 0; i--) {
            let event = eventSequence[annotationHashmap[i]];
            let properties = event.flatoutEventType === EventType.SCRIBBLE ? 'scribbleProperties' : 'textProperties';
            if (checkWhetherInsideBoundBox(event[properties]['rs'], event[properties]['re'], { x: x, y: y })) {
                if (event.flatoutEventType === type && event.flatoutEventType === EventType.TEXT)
                    return { /**@type{TextEvent} */ parentEvent: event, parentEventId: annotationHashmap[i] };
                if (event.flatoutEventType === type && event.flatoutEventType === EventType.SCRIBBLE) {
                    if (
                        checkIfPointPathMatch(
                            event.scribbleProperties.sp,
                            new Coord(x, y),
                            event.scribbleProperties.s << 3
                        )
                    )
                        return { /**@type{ScribbleEvent} */ parentEvent: event, parentEventId: annotationHashmap[i] };
                }
            }
        }
        return null;
    };

    /**
     * This method is used to find the cell
     * @param {number} x
     * @param {number} y
     * @returns
     */
    const findCell = (x, y) => {
        let index = -1;
        for (let i = 0; i < CellDetection.detectedCells.length; i++) {
            if (
                withinManhattanRadius(
                    CellDetection.detectedCells[i].g.c,
                    { x: x, y: y },
                    Math.trunc((40 * 50) / flatout.mmScale)
                )
            ) {
                if (insidePolygon(CellDetection.detectedCells[i].g.ap, { x: x, y: y })) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    };
    /**
     * This method is used to erase the annotation event and reset the values of the cell
     * @param {int} x 
     * @param {int} y 
     * @returns 
     */
    const eraseAction = (x, y) => {
        let isDeleted = false;
        let _annotationHashmap = [...annotationHashmap];
        let newDeletedEvent = undefined;

        for (let i = _annotationHashmap.length - 1; i >= 0; i--) {
            let localEvent = eventSequence[_annotationHashmap[i]];
            let properties =
                localEvent.flatoutEventType === EventType.SCRIBBLE ? 'scribbleProperties' : 'textProperties';
            if (
                checkWhetherInsideBoundBox(localEvent[properties]['rs'], localEvent[properties]['re'], { x: x, y: y })
            ) {
                if (localEvent.flatoutEventType === EventType.SCRIBBLE) {
                    for (let j = 0; j < localEvent.scribbleProperties.sp.length - 1; j++) {
                        let distance = distanceFromLineSegment(
                            localEvent.scribbleProperties.sp[j],
                            localEvent.scribbleProperties.sp[j + 1],
                            { x: x, y: y }
                        );
                        if (distance < localEvent.scribbleProperties.s << 3) {
                            isDeleted = true;
                            newDeletedEvent = new ScribbleEvent();
                            newDeletedEvent.id = eventSequence.length;
                            newDeletedEvent.parentEventIndex = localEvent.id;
                            return { isDeleted: isDeleted, deletedEvent: newDeletedEvent, index: i };
                        }
                    }
                } else if (localEvent.flatoutEventType === EventType.TEXT) {
                    isDeleted = true;
                    newDeletedEvent = new TextEvent();
                    newDeletedEvent.id = eventSequence.length;
                    newDeletedEvent.parentEventIndex = localEvent.id;
                    return { isDeleted: isDeleted, deletedEvent: newDeletedEvent, index: i };
                }
            }
        }

        if (!isDeleted) {
            let _cellIndexMap = cellIndexMap;
            let cellIndex = findCell(x, y);
            if (cellIndex > -1) {
                isDeleted = true;
                newDeletedEvent = new CellEvent();
                CellEvent.setAllValues(
                    newDeletedEvent,
                    eventSequence.length,
                    cellIndex,
                    0,
                    0,
                    0,
                    0,
                    eventSequence[_cellIndexMap[cellIndex]].id
                );
                return { isDeleted: true, deletedEvent: newDeletedEvent, cellIndex: cellIndex };
            }
            return { isDeleted: false };
        }
    };
    // This block of the code is used  redraw a single event
    useEffect(() => {
        if (eventSequence.length > 0 && newEventIndices.length > 0) {
            draw(newEventIndices).then(() => {
                if (newEventIndices.length === 1) {
                    let /** @type {ScribbleEvent} */ targetEvent = eventSequence[newEventIndices[0]];
                    if (
                        targetEvent.parentEventIndex != null &&
                        (targetEvent.flatoutEventType === EventType.SCRIBBLE ||
                            targetEvent.flatoutEventType === EventType.TEXT)
                    ) {
                        let /** @type {ScribbleEvent} */ parentEvent = eventSequence[targetEvent.parentEventIndex];
                        let parentEventProperties =
                            parentEvent.flatoutEventType === EventType.SCRIBBLE
                                ? 'scribbleProperties'
                                : 'textProperties';
                        let targetEventProperties =
                            targetEvent.flatoutEventType === EventType.SCRIBBLE
                                ? 'scribbleProperties'
                                : 'textProperties';

                        if (
                            parentEvent.flatoutEventType !== EventType.CELL &&
                            !(
                                parentEvent[parentEventProperties]['rs'].x ===
                                targetEvent[targetEventProperties]['rs'].x &&
                                parentEvent[parentEventProperties]['rs'].y ===
                                targetEvent[targetEventProperties]['rs'].y &&
                                parentEvent[parentEventProperties]['re'].x ===
                                targetEvent[targetEventProperties]['rs'].x &&
                                parentEvent[parentEventProperties]['re'].y ===
                                targetEvent[targetEventProperties]['re'].y
                            )
                        ) {
                            draw([parentEvent.id], false);
                        }
                    }
                }
                setNewEventIndices([]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSequence, newEventIndices]);

    /**
     * This method is used to get the selected value based on the  presentation mode
     * @returns 
     */
    const getSelectedValue = () => {
        switch (viewMode) {
            case PresentationMode.DMG_LEVEL:
                return chosenDmgLevel;
            case PresentationMode.DMG_RATE:
                return chosenDmgRate;
            case PresentationMode.DMG_TYPE:
                return chosenDmgType;
            case PresentationMode.MNTN_SCOPE:
                return chosenMntnScope;
            default:
                return 0;
        }
    };

    // Watch the selected event list
    useEffect(() => {
        if (viewportRef.current) {
            if (selectedEventIndices.length === 0) {
                viewportRef.current.clearPreviewCanvas();
            } else {
                if (eventSequence[selectedEventIndices[0]].flatoutEventType !== EventType.CELL) {
                    AnnotationEvent.drawOnlyBoundingBox(
                        eventSequence[selectedEventIndices[0]],
                        viewportRef.current.getPreviewContext()
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSequence, selectedEventIndices]);

    /**
     * This method is used add the event that has been modified using the select tool
     * @param {[*]} savedProperties 
     */
    const handleSelectSave = async (savedProperties) => {
        setAnnotationTranslation(null);
        let /** @type {Event} */ requiredEvent = eventSequence[savedProperties['selectedEventIndices'][0]];
        let type = savedProperties['type'];
        let localEvent = null;
        let es = [];
        let _newIndex = -1;
        switch (type) {
            case EventType.CELL:
                let newEventIndices = [];
                es = eventSequence.slice();
                let cim = cellIndexMap.slice();
                //
                for (let i = 0; i < savedProperties['selectedEventIndices'].length; i++) {
                    requiredEvent = eventSequence[savedProperties['selectedEventIndices'][i]];
                    localEvent = deepCopy(requiredEvent);
                    localEvent['parentEventIndex'] = requiredEvent['id'];
                    _newIndex = es.length;
                    for (const [key, value] of Object.entries(savedProperties['cellProperties'])) {
                        localEvent['cellProperties'][key] = value;
                    }

                    localEvent['id'] = _newIndex;
                    let newCellEvent = CellEvent.setAllValuesFromObject(localEvent);
                    if (i === 0)
                        newCellEvent = {
                            ...newCellEvent,
                            nEventsAfter: savedProperties['selectedEventIndices'].length - 1,
                        };
                    else if (i === savedProperties['selectedEventIndices'].length - 1)
                        newCellEvent = {
                            ...newCellEvent,
                            nEventsBefore: savedProperties['selectedEventIndices'].length - 1,
                        };
                    es.push(newCellEvent);
                    cim = updateCellsIndexMap(cim, newCellEvent['cellIndex'], _newIndex);
                    newEventIndices.push(_newIndex);
                }
                setEventSequence([...es]);
                setCellIndexMap([...cim]);

                // Draw all cells that have changed
                setNewEventIndices(newEventIndices);
                setRedoStack([]);
                break;
            case EventType.SCRIBBLE:
                requiredEvent = eventSequence[savedProperties['selectedEventIndices'][0]];
                localEvent = deepCopy(requiredEvent);
                localEvent['parentEventIndex'] = requiredEvent['id'];
                es = eventSequence.slice();
                _newIndex = es.length;
                for (const [key, value] of Object.entries(savedProperties['scribbleProperties']))
                    localEvent['scribbleProperties'][key] = value;
                localEvent['id'] = _newIndex;
                let newScribbleEvent = ScribbleEvent.setAllValuesFromObject(localEvent);
                es.push(newScribbleEvent);

                let _annotationHashmap = annotationHashmap.slice();
                let annotation_index = -1;
                for (let j = 0; j < _annotationHashmap.length; j++) {
                    if (_annotationHashmap[j] === requiredEvent.id) {
                        annotation_index = j;
                        break;
                    }
                }

                if (annotation_index >= 0) {
                    _annotationHashmap[annotation_index] = _newIndex;
                    setAnnotationHashmap(_annotationHashmap);
                    setEventSequence([...es]);
                    setNewEventIndices([_newIndex]);
                    setRedoStack([]);
                }

                break;
            case EventType.TEXT:
                requiredEvent = eventSequence[savedProperties['selectedEventIndices'][0]];
                localEvent = deepCopy(requiredEvent);
                localEvent['parentEventIndex'] = requiredEvent['id'];
                es = eventSequence.slice();
                _newIndex = es.length;
                for (const [key, value] of Object.entries(savedProperties['textProperties']))
                    localEvent['textProperties'][key] = value;
                localEvent['id'] = _newIndex;
                let newTextEvent = TextEvent.setAllValuesFromObject(localEvent);
                newTextEvent.id = es.length;
                es.push(newTextEvent);
                let _annotationHashmap2 = annotationHashmap.slice();
                let _annotation_index = -1;
                for (let j = 0; j < _annotationHashmap2.length; j++) {
                    if (_annotationHashmap2[j] === requiredEvent.id) {
                        _annotation_index = j;
                        break;
                    }
                }
                if (_annotation_index >= 0) {
                    _annotationHashmap2[_annotation_index] = _newIndex;
                    setAnnotationHashmap(_annotationHashmap2);
                    setEventSequence([...es]);
                    setNewEventIndices([_newIndex]);
                    setRedoStack([]);
                }
                break;
            default:
        }

        setSelectedEventIndices([]);
    };

    /**
     * This method used to handle the select tool cancel
     */
    const handleSelectCancel = () => {
        setAnnotationTranslation(null);
        setSelectedEventIndices([]);
    };

    /**
     * The method stores the script to print the image
     * @param {string} displayCanvasSource 
     * @param {string} templateCanvasSource 
     * @param {string} title 
     * @returns 
     */
    const ImagetoPrint = (displayCanvasSource, templateCanvasSource, title) => {
        return (
            '<html>' +
            '<head>' +
            `<title>${title}</title>` +
            '<script>' +
            'function autoprint(){setTimeout(() => {window.print();;window.close()}, 10);}' +
            '</script>' +
            '<style>@media {' +
            " @page {size:'A2'" +
            // " @bottom-left-corner {content: 'bar';}"+
            '}}</style>' +
            '</head>' +
            '<body onload="autoprint()" style="padding: 0 !important; margin: 0 !important;">' +
            '<div style="position: relative">' +
            `<img title="img" style="width: 100%; height: auto;" src="${templateCanvasSource}" \\>` +
            `<img title="img2" src="${displayCanvasSource}" style="position:absolute;top:0;left:0;width:100%;mix-blend-mode: multiply;" \\>` +
            '</div>' +
            '</body>' +
            '</html>'
        );
    };
    /**
     * This method is used to print the image based on the present view 
     */
    const print = () => {
        var pwa = window.open('about:revision_image', '_new');
        pwa.document.open();
        pwa.document.write(
            ImagetoPrint(
                viewportRef.current.getPaintCanvasDataURL(),
                viewportRef.current.getReadOnlyCanvasDataURL(),
                'Revision Image'
            )
        );
        pwa.document.close();
    };
    /**
     * This method is used to get the event sequence payload
     */
    const getEventSequencePayload = () => {
        let eventSequencePayload = [];
        let _index = 0;
        for (let i = 0; i < cellIndexMap.length; i++) {
            let localCellEvent = {};
            for (const key of Object.keys(eventSequence[cellIndexMap[i]])) {
                if (key !== 'parentEventIndex') {
                    localCellEvent[key] = eventSequence[cellIndexMap[i]][key];
                }
            }
            localCellEvent.id = _index++;
            eventSequencePayload.push(localCellEvent);
        }

        for (let i = 0; i < annotationHashmap.length; i++) {
            let localAnnotationEvent = {};
            let _annotationEvent = eventSequence[annotationHashmap[i]];
            if (
                (_annotationEvent.flatoutEventType === EventType.TEXT && _annotationEvent.textProperties.t === '') ||
                (_annotationEvent.flatoutEventType === EventType.SCRIBBLE &&
                    _annotationEvent.scribbleProperties.sp.length === 0)
            )
                continue;
            else {
                for (const key of Object.keys(_annotationEvent)) {
                    if (key !== 'parentEventIndex') {
                        localAnnotationEvent[key] = eventSequence[annotationHashmap[i]][key];
                    }
                }
                if (localAnnotationEvent.flatoutEventType === EventType.SCRIBBLE) {
                    localAnnotationEvent.scribbleProperties.rs = [
                        localAnnotationEvent.scribbleProperties.rs.x,
                        localAnnotationEvent.scribbleProperties.rs.y,
                    ];
                    localAnnotationEvent.scribbleProperties.re = [
                        localAnnotationEvent.scribbleProperties.re.x,
                        localAnnotationEvent.scribbleProperties.re.y,
                    ];
                    let pathArray = [];
                    for (let k = 0; k < localAnnotationEvent.scribbleProperties.sp.length; k++) {
                        pathArray.push([
                            localAnnotationEvent.scribbleProperties.sp[k].x,
                            localAnnotationEvent.scribbleProperties.sp[k].y,
                        ]);
                    }
                    localAnnotationEvent.scribbleProperties.sp = [...pathArray];
                } else if (localAnnotationEvent.flatoutEventType === EventType.TEXT) {
                    localAnnotationEvent.textProperties.rs = [
                        localAnnotationEvent.textProperties.rs.x,
                        localAnnotationEvent.textProperties.rs.y,
                    ];
                    localAnnotationEvent.textProperties.re = [
                        localAnnotationEvent.textProperties.re.x,
                        localAnnotationEvent.textProperties.re.y,
                    ];
                    localAnnotationEvent.textProperties.p = [
                        localAnnotationEvent.textProperties.p.x,
                        localAnnotationEvent.textProperties.p.y,
                    ];
                }

                localAnnotationEvent.id = _index++;
                eventSequencePayload.push(localAnnotationEvent);
            }
        }

        return eventSequencePayload;
    };
    /**
     * This method is used to show the save event sequence dialog when the user clicks
     */
    const handleSaveEventSequence = () => {
        confirmDialog({
            message: 'Are you sure you want to save this revision?',
            header: 'Save Revision?',
            icon: 'pi pi-save',
            className: 'aq-dialog',
            maskClassName: 'aq-dialog-backdrop',
            resizable: false,
            draggable: false,
            acceptClassName: 'aq-btn aq-btn-md aq-suggestive',
            acceptIcon: 'pi pi-save',
            acceptLabel: 'Save',
            rejectClassName: 'aq-btn aq-btn-md',
            rejectIcon: 'pi pi-times',
            rejectLabel: 'Cancel',
            accept: saveRevision.bind(this),
        });
    };

    /**
     * This method is used to save the revision in the database
     * @returns 
     */
    const saveRevision = async () => {
        if (eventSequence.length <= undoLimit) {
            showModal({
                title: 'Critical Error!',
                message: 'Saving was aborted due to a critical error. Please try again.',
                class: 'error',
            });

            return;
        }
        viewportRef.current.showLoader('Saving Revision', false);
        try {
            var response = await RevisionAPI.add(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.outageId,
                getEventSequencePayload()
            );
            if (response) {
                showModal({
                    title: 'Saved successfully!',
                    message: 'New revision created for the given outage',
                    class: 'success',
                });
            }

            // Fetch versions again
            topPanelInspectionFlatoutRef.current.fetchHistory();
        } catch (e) {
        } finally {
            viewportRef.current.hideLoader();
        }
    };
    /**
     * This method is used to estimate materials based on the damage level and maintenance scopes
     * @param {boolean} incrementVersion 
     */
    const estimateMaterials = async (incrementVersion = false) => {
        viewportRef.current.showLoader('Estimating Materials', false);
        dispatch(setLoadingBlocker(true));
        try {
            var response = await RevisionAPI.estimateRefractoryMaterials(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.outageId,
                hierarchy.revisionVersion + (incrementVersion ? 1 : 0)
            );
            if (response) {
                showModal({
                    title: 'Done',
                    message:
                        'Materials estimated for the given outage. Visit RMS > Outage Setup > Estimate Refractory Materials to review.',
                    class: 'success',
                });
            }
            dispatch(setLoadingBlocker(false));
        } catch (e) {
            console.error(e);
        } finally {
            viewportRef.current.hideLoader();
        }
    };

    /**
     * This method is used to call the functionalities when the user clicks save and estimate materials option
     */
    const saveAndEstimateMaterials = () => {
        confirmDialog({
            message: 'Are you sure you want to save this revision and estimate materials?',
            header: 'Save Revision and Estimate?',
            icon: 'pi pi-save',
            className: 'aq-dialog',
            maskClassName: 'aq-dialog-backdrop',
            resizable: false,
            draggable: false,
            acceptClassName: 'aq-btn aq-btn-md aq-suggestive',
            acceptIcon: 'pi pi-save',
            acceptLabel: 'Save',
            rejectClassName: 'aq-btn aq-btn-md',
            rejectIcon: 'pi pi-times',
            rejectLabel: 'Cancel',
            accept: () => {
                saveRevision().then(() => {
                    estimateMaterials(true);
                });
            },
        });
    };

    /*
     * This useeffect is used to call the text box cancel
     */
    useEffect(() => {
        if (textBoxRef.current)
            if (selectedTool !== ActionType.TEXT) {
                textBoxCancel();
            }
    }, [selectedTool, textBoxRef]);
    /**
     * This method is used to reset the translation of the annotation
     */
    const resetAnnotationTranslation = () => {
        setAnnotationTranslation(null);
        viewportRef.current.clearPreviewCanvas();
        SelectTool.relativeTranslation = new Coord();
        AnnotationEvent.drawOnlyBoundingBox(
            eventSequence[selectedEventIndices[0]],
            viewportRef.current.getPreviewContext(),
            SelectTool.relativeTranslation
        );
    };
    /**
     * This method is used to add the predicted data (damage level / maintenance scopes) to the event sequence
     * @param {[]} dataList 
     */
    const addPredictedDataToEventSequence = (dataList) => {
        let esForPredict = [...eventSequence];
        let cimForRegion = cellIndexMap.slice();
        let eventIndexForRegion = [];
        for (let i = 0; i < cellIndexMap.length; i++) {
            let requireEvent = esForPredict[cellIndexMap[i]];
            let localEvent = JSON.parse(JSON.stringify(requireEvent));
            localEvent['parentEventIndex'] = requireEvent['id'];
            let _newIndex = esForPredict.length;
            if (viewMode === PresentationMode.DMG_LEVEL) {
                localEvent['cellProperties']['dl'] = dataList[i] / 100;
            } else if (viewMode === PresentationMode.MNTN_SCOPE) {
                localEvent['cellProperties']['ms'] = dataList[i];
            }
            localEvent['id'] = _newIndex;
            let newCellEvent = CellEvent.setAllValuesFromObject(localEvent);
            if (i === 0) newCellEvent = { ...newCellEvent, nEventsAfter: dataList.length - 1 };
            else if (i === dataList.length - 1) newCellEvent = { ...newCellEvent, nEventsBefore: dataList.length - 1 };
            esForPredict.push(newCellEvent);
            cimForRegion = updateCellsIndexMap(cimForRegion, newCellEvent['cellIndex'], _newIndex);
            eventIndexForRegion.push(_newIndex);
        }
        setEventSequence([...esForPredict]);
        setRedoStack([]);
        setCellIndexMap([...cimForRegion]);
        setNewEventIndices([...eventIndexForRegion]);
    };

    /**
     * This method to execute the predict functionalities depending on the view mode when the method is called
     */
    const predict = async () => {
        if (viewMode === PresentationMode.DMG_LEVEL || viewMode === PresentationMode.MNTN_SCOPE) {
            dispatch(setLoadingBlocker(true));
            setLoadingPrediction(true);
            let _mode = viewMode === 0 ? 'DamageLevels' : 'MaintenanceScopes';
            viewportRef.current.showLoader('Predicting', false);
            // This method is used to call the predict function 
            await PredictionAPI.predict(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.templateVersion,
                hierarchy.outageStartYearMonth.year,
                hierarchy.outageStartYearMonth.month,
                _mode
            ).then((response) => {
                try {
                    if (response.data) {
                        var predictionResponse = parseEventStream(response.data);

                        if (predictionResponse.status === 200) {
                            if (viewMode === 0) {
                                const damageLevels = convertBase64ToArray(predictionResponse.body['DamageLevels']);
                                addPredictedDataToEventSequence(damageLevels);
                            } else {
                                const mtnScopes = convertBase64ToArray(predictionResponse.body['MntnScopes']);
                                addPredictedDataToEventSequence(mtnScopes);
                            }
                        } else if (predictionResponse.status === 204) {
                            showModal({
                                title: 'Prediction Unavilable',
                                message: 'Prediction not yet ready',
                                class: 'error',
                            });
                        } else if (predictionResponse.status === 400) {
                            showModal({
                                title: 'Prediction Unavilable',
                                message: predictionResponse.body,
                                class: 'error',
                            });
                        } else {
                            showModal({ title: 'Server Error', message: predictionResponse.body, class: 'error' });
                        }
                    }
                    dispatch(setLoadingBlocker(false));
                } catch (err) {
                    console.error('Prediction Unavailable');
                } finally {
                    viewportRef.current.hideLoader();
                    setLoadingPrediction(false);
                }
            });
        }
    };

    return (
        <div className="inspection-container">
            <TopPanelInspectionFlatout
                ref={topPanelInspectionFlatoutRef}
                draw={() => draw()}
                print={() => print()}
                onUndo={undo.bind(this)}
                onRedo={redo.bind(this)}
                undoEnabled={eventSequence.length > undoLimit}
                redoEnabled={redoStack.length > 0}
                onClickSave={handleSaveEventSequence.bind(this)}
                onClickEstimate={estimateMaterials.bind(this)}
                onClickSaveAndEstimate={saveAndEstimateMaterials.bind(this)}
                locked={locked}
                onSetLock={(_lock) => {
                    setLocked(_lock);
                }}
            >
                {imageData && eventSequence.length > 0 ? (
                    <PresentationModeSwitcher2
                        presentationModes={[
                            PresentationMode.DMG_LEVEL,
                            PresentationMode.DMG_TYPE,
                            PresentationMode.MNTN_SCOPE,
                            PresentationMode.DMG_RATE,
                        ]}
                        selectedMode={viewMode}
                        onSelectMode={setViewMode}
                    />
                ) : (
                    ''
                )}
            </TopPanelInspectionFlatout>

            {damageTypePatterns.length > 0 && (
                <>
                    <Viewport
                        ref={viewportRef}
                        onInputOnce={handleInputOnce.bind(this)}
                        onInputStart={handleInputStart.bind(this)}
                        onInputEnd={handleInputEnd.bind(this)}
                        onInputUpdate={handleInputUpdate.bind(this)}
                        imageData={imageData}
                        idleMessage="Select an Outage to View Flatout Drawing"
                        leftChild={
                            <ToolsBarInspectionFlatout
                                disabled={flatout === null || !locked || SelectTool.dragging}
                                transforming={selectedEventIndices && selectedEventIndices.length > 0}
                            />
                        }
                        rightChild={
                            <>
                                {damageTypePatterns.length > 0 && mntnScopePatterns.length > 0 ? (
                                    <CellContextBar
                                        presentationMode={viewMode}
                                        dmgTypePatternDefs={dmgTypeData}
                                        dmgTypePatterns={damageTypePatterns}
                                        mntnScopePatternDefs={mntnScopeData}
                                        mntnScopePatterns={mntnScopePatterns}
                                        value={getSelectedValue()}
                                        onChange={(value) => {
                                            if (viewMode === PresentationMode.DMG_LEVEL) {
                                                setChosenDmgLevel(value);
                                            } else if (viewMode === PresentationMode.DMG_TYPE) {
                                                setChosenDmgType(value);
                                            } else if (viewMode === PresentationMode.DMG_RATE) {
                                                setChosenDmgRate(value);
                                            } else {
                                                setChosenMntnScope(value);
                                            }
                                        }}
                                        hidden={
                                            selectedTool !== ActionType.FILLCELL &&
                                            selectedTool !== ActionType.FILLREGION
                                        }
                                        predict={predict}
                                        predicting={loadingPrediction}
                                    />
                                ) : (
                                    ''
                                )}
                                <TextContextBar
                                    hidden={selectedTool !== ActionType.TEXT}
                                    color={chosenTextColor}
                                    size={chosenTextSize}
                                    onChangeColor={(color) => {
                                        setChosenTextColor(color);
                                        textInputRef.current.style.color = color;
                                    }}
                                    onChangeSize={(size) => {
                                        setChosenTextSize(size);
                                        textInputRef.current.style.fontSize = `${size}px`;
                                    }}
                                    style={chosenTextStyle}
                                    onChangeStyle={(style) => {
                                        setChosenTextStyle(style);
                                        textInputRef.current.style.fontStyle = style.italic ? 'italic' : 'normal';
                                        textInputRef.current.style.fontWeight = style.bold ? 'bold' : 'normal';
                                    }}
                                />
                                <ScribbleContextBar
                                    hidden={selectedTool !== ActionType.SCRIBBLE}
                                    color={chosenScribbleColor}
                                    thickness={chosenScribbleThickness}
                                    onChangeColor={setChosenScribbleColor}
                                    onChangeThickness={setChosenScribbleThickness}
                                />
                            </>
                        }
                        canvasOverlayChild={
                            <div id="textbox" ref={textBoxRef} className="textbox-editor">
                                <input
                                    type="text"
                                    ref={textInputRef}
                                    className="textbox-editor-input"
                                    autoFocus
                                    value={chosenTextValue}
                                    onFocus={(e) => {
                                        e.preventDefault();
                                        e.target.focus({ preventScroll: true });
                                    }}
                                    onChange={(e) => {
                                        setChosenTextValue(e.target.value);
                                    }}
                                    onKeyDown={(event) => {
                                        let key = event.key.toLowerCase();
                                        if (key === 'enter' || key === 'return') {
                                            textConfirm(event);
                                        } else if (key === 'escape') {
                                            textBoxCancel(event);
                                        }
                                    }}
                                />
                                <div
                                    className="aq-group viewport-scale-agnostic"
                                    style={{ transformOrigin: 'top left' }}
                                >
                                    <Button
                                        onClick={textConfirm.bind(this)}
                                        icon="pi pi-check"
                                        className="aq-btn aq-primary"
                                    />
                                    <Button onClick={textBoxCancel.bind(this)} icon="pi pi-times" className="aq-btn" />
                                </div>
                            </div>
                        }
                    />
                    {flatout && (
                        <>
                            {damageTypePatterns.length > 0 && mntnScopePatterns.length > 0 ? (
                                <EditPropertiesDialog
                                    dmgTypePatternDefs={dmgTypeData}
                                    dmgTypePatterns={damageTypePatterns}
                                    mntnScopePatternDefs={mntnScopeData}
                                    mntnScopePatterns={mntnScopePatterns}
                                    ref={editPropModalRef}
                                    selectedEventIndices={selectedEventIndices}
                                    eventSequence={eventSequence}
                                    annotationTranslation={annotationTranslation}
                                    onSave={handleSelectSave}
                                    onCancel={handleSelectCancel}
                                    passThrough={SelectTool.dragging || selectedTool === ActionType.TRANSFORM}
                                    resetAnnotationTranslation={resetAnnotationTranslation}
                                />
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
