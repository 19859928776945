/** Performance monitoring and profiling. */
export class PerfMon {
    static debug = false;
    static __context = ''
    static __startTime = null

    /**
     * Start performance profiling.
     * @param {string} context Context for which the result will be logged
     */
    static start (context) {
        this.__context = context;
        if (this.debug) {
            this.__startTime = performance.now();
        }
    }

    /** Stop performance profiling. */
    static end () {
        if (this.debug && this.__startTime) {
            console.debug(`${this.__context} took ${performance.now() - this.__startTime}ms.`);
            this.__startTime = 0;
        }
    }
}