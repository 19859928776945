import { Coord } from '../../utils/Coord';
import { insidePolygon, sleep, withinManhattanRadius } from '../../utils/Helper';

export class PaintCell {
    static dragging = false;
    static previewOn = false;
    static selectedCellEventIndices = [];
    static selectedCells = [];
    static visitedCoordIndex = -1;
    static maxManhattanDistance = 0;
    width = 4000;
    height = 2666;
    intervalId = null;
    fillStartCoords = [];
    canvasContext = undefined;
    

    /**
     * This method is used to set the scale
     * @param {number} _scale
     */
    static set scale(_scale) {
        this.maxManhattanDistance = Math.trunc((25 * 50) / _scale);
    }

    /**
     * This method is used to initialse the object of fillCelltool on mouse start
     * @param {PaintCell} paintCell
     * @param {CanvasRenderingContext2D} canvasContext
     * @param {Coord} startingCoord
     */

    static start(
        /** @type {PaintCell} */ paintCell,
        /**@type {CanvasRenderingContext2D} */ canvasContext,
        /**@type {Coord} */ startingCoord
    ) {
        PaintCell.dragging = true;
        paintCell.fillStartCoords.push(startingCoord);
        paintCell.canvasContext = canvasContext;
        paintCell.intervalId = setInterval(() => {
            PaintCell.previewOn = true;
        }, 20);
    }

    /**
     *
     * @param {PaintCell} paintCell
     * @param {CanvasRenderingContext2D} canvasContext
     * @param {Coord} coord
     * @param {CellGeometryFull} cells
     * @param {num[]} cells_index_map
     */

    static move(/** @type {PaintCell} */ paintCell, canvasContext, coord, cells, cells_index_map) {
        if (PaintCell.dragging) {
            let interpolatedCoordinates = PaintCell.interpolate(
                paintCell.fillStartCoords[paintCell.fillStartCoords.length - 1],
                coord
            );
            // interpolatedCoordinates.forEach((_coord) => {
            //     canvasContext.fillStyle = 'rgb(0,255,0)';
            //     canvasContext.fillRect(_coord.x, _coord.y, 4, 4);
            // });
            paintCell.fillStartCoords.push(...interpolatedCoordinates.slice(1));
            if (PaintCell.previewOn) {
                PaintCell.previewOn = false;
                for (
                    let i = PaintCell.visitedCoordIndex === -1 ? 0 : PaintCell.visitedCoordIndex;
                    i < paintCell.fillStartCoords.length;
                    i++
                ) {
                    PaintCell.findCellsAsyncProcess(
                        paintCell,
                        cells,
                        canvasContext,
                        cells_index_map,
                        paintCell.fillStartCoords[i]
                    );
                    PaintCell.visitedCoordIndex = i;
                }
            }
        }
    }

    /**
     *
     * @param {FillCellTool} paintCell
     * @param {*} cells
     * @param {CanvasRenderingContext2D} canvasContext
     * @param {*} cells_index_map
     * @returns
     */
    static end(
        /** @type {FillCellTool} */ paintCell,
        cells,
        /**@type {CanvasRenderingContext2D} */ canvasContext,
        cells_index_map
    ) {
        if (PaintCell.dragging) {
            PaintCell.dragging = false;
            PaintCell.previewOn = false;
            for (let i = 0; i < paintCell.fillStartCoords.length; i++) {
                PaintCell.findCellsAsyncProcess(
                    paintCell,
                    cells,
                    canvasContext,
                    cells_index_map,
                    paintCell.fillStartCoords[i]
                );
            }
            paintCell.fillStartCoords = [];
            PaintCell.visitedCoordIndex = -1;
            let event_indices = [...new Set(PaintCell.selectedCellEventIndices)];
            PaintCell.selectedCellEventIndices = [];
            return event_indices;
        }
    }

    /**
     * This method is used to find the cells inside the selection box asynchronously
     */
    static findCellsAsyncProcess(
        /**@type {FillCellTool} */ paintCell,
        /**@type {CellGeometryFull} */ cells,
        /** @type {CanvasRenderingContext2D} */ canvasContext,
        cells_index_map,
        currentCoord
    ) {
        if (cells) {
            PaintCell.selectedCells = [];
            for (let i = 0; i < cells.length; i++) {
                let cell = cells[i];
                if (withinManhattanRadius(cell.g.c, currentCoord, this.maxManhattanDistance)) {
                    if (insidePolygon(cell.g.ap, currentCoord)) {
                        PaintCell.selectedCells.push(cell);
                        PaintCell.selectedCellEventIndices.push(cells_index_map[i]);
                        PaintCell.drawOnCanvas(cell, canvasContext);
                    }
                }
            }
        }
    }

    /**
     * This medthod is used to draw on Canvas
     * @param {CellGeometryUpdate} cell
     * @param {CanvasRenderingContext2D} canvasContext
     */
    static async drawOnCanvas(cell, canvasContext) {
        canvasContext.globalCompositeOperation = 'source-over';
        canvasContext.beginPath();
        canvasContext.moveTo(cell.g.ap[0], cell.g.ap[1]);
        for (let j = 0; j < cell.g.ap.length; j += 2) {
            canvasContext.lineTo(cell.g.ap[j], cell.g.ap[j + 1]);
        }
        canvasContext.fill();
        canvasContext.closePath();
        await sleep(20);
    }
    /**
     * This method is used to find the slope of 2 points
     * @param {Coord} coordA
     * @param {Coord} coordB
     * @returns
     */
    static findSlope(coordA, coordB) {
        if (coordA.x === coordB.x) return undefined;
        return (coordB.y - coordA.y) / (coordB.x - coordA.x);
    }
    /**
     * This method is used to find the intercept
     * @param {Coord} coord
     * @param {number} slope
     * @returns
     */
    static intercept(coord, slope) {
        if (slope === null) {
            return coord.x;
        }
        return coord.y - slope * coord.x;
    }
    /**
     * This method is used to generate all the coordinates between 2 given points
     * @param {Coord} coordA
     * @param {Coord} coordB
     * @returns
     */
    static interpolate(coordA, coordB) {
        let m = PaintCell.findSlope(coordA, coordB);
        let c = PaintCell.intercept(coordA, m);
        var coordinates = [];
        for (var x = Math.min(coordA.x, coordB.x); x <= Math.max(coordB.x, coordA.x); x++) {
            if (m !== undefined) {
                var y = m * x + c;
                coordinates.push(new Coord(Math.round(x), Math.round(y)));
            }

            /** @TODO Add condition for when m is undefined */
        }

        if (coordA.x < coordB.x) {
            return coordinates;
        } else {
            return coordinates.reverse();
        }
    }
}
