import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { forwardRef, useEffect, useState } from 'react';
import { Event, EventType, PresentationMode } from '../../config/Constants';
import { ScribbleEvent } from '../../event/ScribbleEvent';
import { Coord } from '../../utils/Coord';
import { parseColor } from '../../utils/Helper';
import PatternDropDown from '../patternDropDown/PatternDropDown';
import DamageLevelLUT from '../../assets/luts/DamageLevelLUT.png';
import './EditPropertiesDialog.scss';
import { loadLUTData, lookUpColor } from '../../utils/Lut';

export const EditPropertiesDialog = forwardRef(
    (
        {
            selectedEventIndices,
            eventSequence,
            annotationTranslation,
            onSave = () => {},
            onCancel = () => {},
            dmgTypePatternDefs = [],
            dmgTypePatterns = [],
            mntnScopePatternDefs = [],
            mntnScopePatterns = [],
            passThrough = false,
            resetAnnotationTranslation = () => {}
        },
        ref
    ) => {
        const [headerProperty, setHeaderProperty] = useState('Cell');

        const [eventType, setEventType] = useState();

        const [color, setColor] = useState('#ffffff');
        const [initialColor, setInitialColor] = useState('');

        const [initialTextSize, setInitialTextSize] = useState(16);
        const [textSize, setTextSize] = useState(initialTextSize);

        const [initialScribbleThickness, setInitialScribbleThickness] = useState(-1);
        const [scribbleThickness, setScribbleThickness] = useState(initialScribbleThickness);

        const [initialTextValue, setInitialTextValue] = useState('hi');
        const [textValue, setTextValue] = useState(initialTextValue);

        const [style, setStyle] = useState('');

        const [initialIsBold, setInitialIsBold] = useState(false);
        const [isBold, setIsBold] = useState(initialIsBold);

        const [initialIsItalic, setInitialIsItalic] = useState(false);
        const [isItalic, setIsItalic] = useState(initialIsItalic);

        const [initialDamageLevel, setInitialDamageLevel] = useState(-1);
        const [damageLevel, setDamageLevel] = useState(0);

        const [initialDamageType, setInitialDamageType] = useState(-1);
        const [damageType, setDamageType] = useState(0);

        const [initialMaintainenceScope, setInitialMaintainenceScope] = useState(-1);
        const [maintenanceScope, setMaintainenceScope] = useState(0);

        const [initialDamageRate, setInitialDamageRate] = useState(0.00001);
        const [damageRate, setDamageRate] = useState(0.0001);

        // Scribble and Text Common Properties
        const [isColorChecked, setIsColorChecked] = useState(false);
        // Text Properties
        const [isTextSizeChecked, setIsTextSizeChecked] = useState(false);
        const [isTextValueChecked, setIsTextValueChecked] = useState(false);
        const [isTextStyleChecked, setIsTextStyleChecked] = useState(false);
        // Scribble Properties
        const [isScribbleThicknessChecked, setIsScribbleThicknessChecked] = useState(false);
        // Cell Properties
        const [isDamageLevelChecked, setIsDamageLevelChecked] = useState(false);
        const [isDamageTypeChecked, setIsDamageTypeChecked] = useState(false);
        const [isMaintenanceScopeChecked, setIsMaintenanceScopeChecked] = useState(false);
        const [isDamageRateChecked, setIsDamageRateChecked] = useState(false);

        const [damageLevelLegend, setDamageLevelLegend] = useState("black")
        

        const setDamageLevelLUT = (_lut) => {
            let stops = [];
            const nStops = 6;
            for (let i = 0; i < 1; i += 1 / nStops) {
                stops.push(lookUpColor(_lut, i, 0, 1));
            }
            setDamageLevelLegend(`linear-gradient(90deg, ${stops.join(',')}`);
        }

        useEffect(() => {
            if (eventType===EventType.CELL){
                loadLUTData(DamageLevelLUT,setDamageLevelLUT);
            }
    
        }, [eventType]);

        useEffect(() => {
            if (selectedEventIndices && selectedEventIndices.length > 0 && eventSequence && eventSequence.length > 0) {
                let ev_index = selectedEventIndices[0];

                let /**@type {EventType} */ type = eventSequence[ev_index]['flatoutEventType'];
                setEventType(type);
                switch (type) {
                    case EventType.SCRIBBLE:
                        setHeaderProperty('Scribble');
                        setInitialScribbleThickness(eventSequence[ev_index]['scribbleProperties']['s']);
                        setScribbleThickness(eventSequence[ev_index]['scribbleProperties']['s']);

                        let _color = eventSequence[ev_index]['scribbleProperties']['c'];
                        setInitialColor(_color);
                        setColor(_color);

                        setIsColorChecked(false);
                        setIsScribbleThicknessChecked(false);

                        break;
                    case EventType.TEXT:
                        setHeaderProperty('Text');
                        setTextValue(eventSequence[ev_index]['textProperties']['t']);
                        setInitialTextValue(eventSequence[ev_index]['textProperties']['t']);

                        setInitialTextSize(eventSequence[ev_index]['textProperties']['s']);
                        setTextSize(eventSequence[ev_index]['textProperties']['s']);

                        setInitialColor(eventSequence[ev_index]['textProperties']['c']);
                        setColor(eventSequence[ev_index]['textProperties']['c']);

                        let /**@type {string} */ localStyle = eventSequence[ev_index]['textProperties']['ts'];
                        setStyle(localStyle);
                        if (localStyle.includes('italic')) {
                            setIsItalic(true);
                            setInitialIsItalic(true);
                        } else setIsItalic(false);
                        if (localStyle.includes('bold')) {
                            setIsBold(true);
                            setInitialIsBold(true);
                        } else setIsBold(false);

                        setIsColorChecked(false);
                        setIsTextSizeChecked(false);
                        setIsTextStyleChecked(false);
                        setIsTextValueChecked(false);

                        break;
                    case EventType.CELL:
                        setHeaderProperty('Cell');
                        let avg_dmg_level = 0;
                        let avg_dmg_rate = 0;
                        let n = selectedEventIndices.length;

                        for (let i = 0; i < n; i++) {
                            // avg_dmg_level += eventSequence[selectedEventIndices[i]]['properties']['damageLevel']
                            if (eventSequence[selectedEventIndices[i]]['cellProperties']['dl'] <= 1)
                                avg_dmg_level += eventSequence[selectedEventIndices[i]]['cellProperties']['dl'] * 100;
                            else avg_dmg_level += eventSequence[selectedEventIndices[i]]['cellProperties']['dl'];
                            avg_dmg_rate += eventSequence[selectedEventIndices[i]]['cellProperties']['dr'] * 100;
                        }
                        
                        avg_dmg_level /= n;
                        avg_dmg_rate /= n;

                        setInitialDamageLevel(avg_dmg_level);
                        setDamageLevel(avg_dmg_level);

                        setInitialDamageType(eventSequence[ev_index]['cellProperties']['dt']);
                        setDamageType(eventSequence[ev_index]['cellProperties']['dt']);

                        setInitialMaintainenceScope(eventSequence[ev_index]['cellProperties']['ms']);
                        setMaintainenceScope(eventSequence[ev_index]['cellProperties']['ms']);

                        setInitialDamageRate(avg_dmg_rate);
                        setDamageRate(avg_dmg_rate);

                        setIsDamageLevelChecked(false);
                        setIsDamageRateChecked(false);
                        setIsMaintenanceScopeChecked(false);
                        setIsDamageTypeChecked(false);
                        break;
                    default:
                        console.warn('Invalid event type');
                }
            }

        }, [selectedEventIndices, eventSequence]);

        /**
         * This method is called when the color of the color picker is changed
         * @param {import('primereact/colorpicker').ColorPickerChangeEvent} _color
         */
        const handleColorChange = (_color) => {
            if (_color) {
                setColor(_color);
                if (initialColor !== _color) {
                    setIsColorChecked(true);
                }
            }
        };
        /**
         * This method is called the checkbox of the color picker is changed
         */
        const handleColorCheckboxChange = () => {
            let val = !isColorChecked;
            if (!val) setColor(initialColor);
            setIsColorChecked(val);
        };
        /**
         * This method handles the scribble thickness change event
         * @param {import('primereact/checkbox').CheckboxChangeEvent} e
         */
        const handleScribbleThicknessChange = (
            /** @type {import('primereact/inputnumber').InputNumberValueChangeEvent} */ e
        ) => {
            if (e.value) {
                setScribbleThickness(e.value);
                if (initialScribbleThickness !== e.value) setIsScribbleThicknessChecked(true);
            }
        };
        /**
         * This method handles the scribble thickness checkbox event
         */
        const handleScribbleThicknessCheckBoxChange = () => {
            let val = !isScribbleThicknessChecked;
            if (!val) {
                setScribbleThickness(initialScribbleThickness);
            }
            setIsScribbleThicknessChecked(val);
        };
        /**
         * This method handles the Text size change event
         * @param {import('primereact/inputnumber').InputNumberValueChangeEvent} e
         */
        const handleTextSizeChange = (
            /** @type {import('primereact/inputnumber').InputNumberValueChangeEvent} */ e
        ) => {
            if (e.value) {
                setTextSize(e.value);
                if (initialTextSize !== e.value) setIsTextSizeChecked(true);
                else setIsTextSizeChecked(false);
            }
        };
        /**
         * This method handles the text size checkbox change
         */
        const handleTextSizeCheckboxChange = () => {
            let val = !isTextSizeChecked;
            if (!val) setTextSize(initialTextSize);
            setIsTextSizeChecked(!isTextSizeChecked);
        };
        /**
         * This method handles the text style checkbox  change
         */
        const handleTextStyleCheckboxChange = () => {
            let val = !isTextStyleChecked;
            if (!val) {
                setIsBold(initialIsBold);
                setIsItalic(initialIsItalic);
            }
            setIsTextStyleChecked(val);
        };
        /**
         * This method handles when the text value changes
         * @param {import('react').ChangeEvent<HTMLInputElement>} e
         */
        const handleTextValueChange = (/** @type {import('react').ChangeEvent<HTMLInputElement>}*/ e) => {
            if (e.target.value) {
                setTextValue(e.target.value);
                if (e.target.value !== initialTextValue) setIsTextValueChecked(true);
                else setIsTextValueChecked(false);
            }
        };
        /**
         * This method handles the change event of the checkbox of the text value
         */
        const handleTextValueCheckboxChange = () => {
            let val = !isTextValueChecked;
            if (!val) {
                setTextValue(initialTextValue);
            }
            setIsTextValueChecked(!isTextValueChecked);
        };
        /**
         * This method handles the damge rate change
         * @param {InputNumberValueChangeEvent} e
         */
        const handleDamageRateChange = (/** @type {InputNumberValueChangeEvent} */ e) => {
            if (e.value) {
                setDamageRate(e.value);
                if (e.value !== initialDamageRate) setIsDamageRateChecked(true);
                else setIsDamageRateChecked(false);
            }
        };

        useEffect(() => {
            var str = '';
            if (isBold) str += 'bold ';
            if (isItalic) str += 'italic ';
            setStyle(str.trim());
        }, [isBold, isItalic]);

        /**
         * This method is used to handle the change in damage level
         * @param {number} value
         */
        const handleDamageLevelChange = (value) => {
            if (value) {
                value=value<0?0:value
                value=value>100?100:value
                setDamageLevel(value);
                if (value !== initialDamageLevel) setIsDamageLevelChecked(true);
                else setIsDamageLevelChecked(false);
            }
        };
        /**
         * This method is used to handle the checkbox change of the damage level
         */
        const handleDamageLevelCheckboxChange = () => {
            let val = !isDamageLevelChecked;
            if (!val) setDamageLevel(initialDamageLevel);
            setIsDamageLevelChecked(val);
        };
        /**
         * This method is used to handle the damage type checkbox change
         */
        const handleDamageTypeCheckboxChange = () => {
            let val = !isDamageTypeChecked;
            if (!val) setDamageType(initialDamageType);
            setIsDamageTypeChecked(val);
        };

        /**
         * This method is used to handle the damage type change
         */
        const handleDamageTypeChange = (value) => {
            if (value || value === 0) {
                setDamageType(value);
                if (value !== initialDamageType) setIsDamageTypeChecked(true);
                else setIsDamageTypeChecked(false);
            }
        };

        /**
         * This method is used to handle the maintenance scope change
         * @param {{int}|{{id:{int},name:{string},patternBgColor:`rgb({int},{int},{int})`,patternFgColor:`rgb({int},{int},{int})`,patternStyle:{int}}} value
         */
        const handleMaintenanceScopeChange = (
            /**@type {{int}|{{id:{int},name:{string},patternBgColor:`rgb({int},{int},{int})`,patternFgColor:`rgb({int},{int},{int})`,patternStyle:{int}}}} */ value
        ) => {
            if (value || value === 0 ) {
                setMaintainenceScope(value);
                if (value !== initialMaintainenceScope) setIsMaintenanceScopeChecked(true);
                else setIsMaintenanceScopeChecked(false);
            }
        };
        /**
         * This method handles the maintenance scope checkbox change
         */
        const handleMaintenanceScopeCheckboxChange = () => {
            let val = !isMaintenanceScopeChecked;
            if (!val) setMaintainenceScope(initialMaintainenceScope);
            setIsMaintenanceScopeChecked(val);
        };
        /**
         * This method handles the damage rate checkbox change
         */
        const handleDamageRateCheckboxChange = () => {
            let val = !isDamageRateChecked;
            if (!val) setDamageRate(initialDamageRate);
            setIsDamageRateChecked(val);
        };
        /**
         * This method handles the saving mechanism
         */
        const handleProcessSave = () => {
            var finalUpdatedProperties = {};
            if (eventType === EventType.SCRIBBLE) {
                var properties = {};
                if (isColorChecked) properties['c'] = color;

                if (isScribbleThicknessChecked) properties['s'] = scribbleThickness;

                if (annotationTranslation && annotationTranslation.x !== 0 && annotationTranslation.y !== 0) {
                    var _scribbleProps = eventSequence[selectedEventIndices[0]].scribbleProperties;
                    properties['rs'] = new Coord(
                        _scribbleProps.rs.x + annotationTranslation.x,
                        _scribbleProps.rs.y + annotationTranslation.y
                    );
                    properties['re'] = new Coord(
                        _scribbleProps.re.x + annotationTranslation.x,
                        _scribbleProps.re.y + annotationTranslation.y
                    );
                    properties['sp'] = [];
                    _scribbleProps.sp.forEach((vertex) => {
                        properties.sp.push(
                            new Coord(vertex.x + annotationTranslation.x, vertex.y + annotationTranslation.y)
                        );
                    });
                }

                finalUpdatedProperties['scribbleProperties'] = properties;
            } else if (eventType === EventType.TEXT) {
                properties = {};
                if (isColorChecked) properties['c'] = color;

                if (isTextSizeChecked) properties['s'] = textSize;

                if (isTextStyleChecked) properties['ts'] = style;

                if (isTextValueChecked) properties['t'] = textValue;

                if (annotationTranslation && annotationTranslation.x !== 0 && annotationTranslation.y !== 0) {
                    var _textProps = eventSequence[selectedEventIndices[0]].textProperties;
                    properties['p'] = new Coord(
                        _textProps.p.x + annotationTranslation.x,
                        _textProps.p.y + annotationTranslation.y
                    );
                    properties['rs'] = new Coord(
                        _textProps.rs.x + annotationTranslation.x,
                        _textProps.rs.y + annotationTranslation.y
                    );
                    properties['re'] = new Coord(
                        _textProps.re.x + annotationTranslation.x,
                        _textProps.re.y + annotationTranslation.y
                    );
                }

                finalUpdatedProperties['textProperties'] = properties;
            } else if (eventType === EventType.CELL) {
                properties = {};
                if (isDamageLevelChecked) properties['dl'] = damageLevel / 100;

                if (isDamageTypeChecked) properties['dt'] = damageType;

                if (isMaintenanceScopeChecked) properties['ms'] = maintenanceScope;

                if (isDamageRateChecked) properties['dr'] = damageRate / 100;

                finalUpdatedProperties['cellProperties'] = properties;
            }
            if (Object.keys(properties).length !== 0) {
                finalUpdatedProperties['type'] = eventType;

                finalUpdatedProperties['selectedEventIndices'] = selectedEventIndices;

                onSave(finalUpdatedProperties);
                properties = {};
            }
        };

        const isSaveEnabled = () => {
            return isColorChecked ||
            isTextSizeChecked ||
            isTextStyleChecked ||
            isTextValueChecked ||
            isScribbleThicknessChecked ||
            isDamageLevelChecked ||
            isDamageTypeChecked ||
            isMaintenanceScopeChecked ||
            isDamageRateChecked ||
            (annotationTranslation && 
            (annotationTranslation.x !== 0 ||
            annotationTranslation.y !== 0))
        }

        const handleProcessReset = () => {
            if (eventType === EventType.TEXT) {
                setColor(initialColor);
                setIsColorChecked(false);
                setTextSize(initialTextSize);
                setIsTextSizeChecked(false);
                handleTextStyleCheckboxChange()
                setIsTextStyleChecked(false)
                handleTextValueCheckboxChange()
                setIsTextValueChecked(false)
                resetAnnotationTranslation()
            }
            else if (eventType === EventType.SCRIBBLE) {
                setColor(initialColor);
                setIsColorChecked(false);
                handleScribbleThicknessCheckBoxChange()
                setIsScribbleThicknessChecked(false)
                resetAnnotationTranslation()
            }
            else {
                handleDamageLevelCheckboxChange()
                setIsDamageLevelChecked(false)
                handleDamageRateCheckboxChange()
                setIsDamageRateChecked(false)
                handleDamageTypeCheckboxChange()
                setIsDamageTypeChecked(false)
                handleMaintenanceScopeCheckboxChange()
                setIsMaintenanceScopeChecked(false);
            }
        }

        return (
            <Dialog
                className={'aq-dialog' + (passThrough ? ' passthrough' : '')}
                closeIcon='pi pi-times'
                header={`Edit ${headerProperty} Properties`}
                visible={selectedEventIndices.length > 0}
                style={{ width: '50vw' }}
                maskClassName={headerProperty !== 'Cell' ? 'mask-passthrough' : ''}
                onHide={() => {
                    onCancel();
                }}
                position={'center'}
                footer={
                    <div style={{ textAlign: 'end'}}>
                        <Button
                            label="Reset"
                            icon="pi pi-undo"
                            className="aq-btn"
                            disabled={!isSaveEnabled()}
                            style={{width: 100}}
                            onClick={() => {
                                handleProcessReset();
                            }}
                        />
                        <Button
                            label="Confirm"
                            icon="pi pi-check"
                            className="aq-btn aq-primary"
                            disabled={!isSaveEnabled()}
                            style={{width: 100}}
                            onClick={() => {
                                handleProcessSave();
                            }}
                        />
                    </div>
                }
            >
                <table style={{ width: '100%' }} className='prop-control-table'>
                    <tbody>
                        {eventType !== EventType.CELL && (
                            <>
                                <tr>
                                    <td className="td-label">Color</td>
                                    <td style={{ display: 'flex', gap: 14 }}>
                                        <div style={{ flex: 'auto' }}>
                                            <div
                                                className={
                                                    'color-palette-button' + (color === 'rgb(255,0,0)' ? ' active' : '')
                                                }
                                                style={{ backgroundColor: 'red' }}
                                                onClick={() => handleColorChange('rgb(255,0,0)')}
                                            ></div>
                                        </div>
                                        <div style={{ flex: 'auto' }}>
                                            <div
                                                className={
                                                    'color-palette-button' + (color === 'rgb(0,0,255)' ? ' active' : '')
                                                }
                                                style={{ backgroundColor: 'blue' }}
                                                onClick={() => handleColorChange('rgb(0,0,255)')}
                                            ></div>
                                        </div>
                                        <div style={{ flex: 'auto' }}>
                                            <div
                                                className={
                                                    'color-palette-button' + (color === 'rgb(0,255,0)' ? ' active' : '')
                                                }
                                                style={{ backgroundColor: 'green' }}
                                                onClick={() => handleColorChange('rgb(0,255,0)')}
                                            ></div>
                                        </div>
                                        <div style={{ flex: 'auto' }}>
                                            <div
                                                className={
                                                    'color-palette-button' + (color === 'rgb(0,0,0)' ? ' active' : '')
                                                }
                                                style={{ backgroundColor: 'black' }}
                                                onClick={() => handleColorChange('rgb(0,0,0)')}
                                            ></div>
                                        </div>
                                        <div style={{ flex: 'auto' }}>
                                            <div
                                                className={
                                                    'color-palette-button' + (color === 'rgb(0,0,80)' ? ' active' : '')
                                                }
                                                style={{ backgroundColor: 'rgb(0, 0, 80)' }}
                                                onClick={() => handleColorChange('rgb(0,0,80)')}
                                            ></div>
                                        </div>
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isColorChecked}
                                            aria-label="Reset to previously chosen color"
                                            onClick={handleColorCheckboxChange}
                                        />
                                    </td>
                                </tr>
                            </>
                        )}
                        {eventType === EventType.TEXT && (
                            <>
                                <tr>
                                    <td className="td-label">Size</td>
                                    <td>
                                        <InputNumber
                                            value={textSize}
                                            onValueChange={handleTextSizeChange}
                                            showButtons
                                            buttonLayout="horizontal"
                                            inputStyle={{ borderRadius: 0 }}
                                            step={2.0}
                                            max={96}
                                            min={10}
                                            decrementButtonClassName="aq-btn"
                                            incrementButtonClassName="aq-btn"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus"
                                            suffix=" px"
                                            style={{ width: '100%' }}
                                        />
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isTextSizeChecked}
                                            aria-label="Reset text size"
                                            onClick={handleTextSizeCheckboxChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-label">Font Style</td>
                                    <td>
                                        <div className="aq-group" style={{ width: '100%' }}>
                                            <Button
                                                label="B"
                                                className={'aq-btn' + (isBold ? ' aq-primary' : '')}
                                                style={{ fontFamily: 'sans-serif', fontWeight: 'bold'}}
                                                onClick={() => {
                                                    setIsBold(!isBold);
                                                    setIsTextStyleChecked(initialIsBold === isBold || initialIsItalic !== isItalic);
                                                }}
                                            />
                                            <Button
                                                label="I"
                                                className={'aq-btn' + (isItalic ? ' aq-primary' : '')}
                                                style={{ fontFamily: 'serif', fontStyle: 'italic' }}
                                                onClick={() => {
                                                    setIsItalic(!isItalic);
                                                    setIsTextStyleChecked(initialIsItalic === isItalic || initialIsBold !== isBold);
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isTextStyleChecked}
                                            aria-label="Reset text style"
                                            onClick={handleTextStyleCheckboxChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-label">Text</td>
                                    <td>
                                        <InputText className='aq-formcontrol' value={textValue} onChange={handleTextValueChange} style={{width: '100%', maxHeight: 32}} />
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isTextValueChecked}
                                            aria-label="Reset text value"
                                            onClick={handleTextValueCheckboxChange}
                                        />
                                    </td>
                                </tr>
                            </>
                        )}
                        {eventType === EventType.SCRIBBLE && (
                            <>
                                <tr>
                                    <td className="td-label">Thickness</td>
                                    <td>
                                        {' '}
                                        <InputNumber
                                            value={scribbleThickness}
                                            onValueChange={handleScribbleThicknessChange}
                                            showButtons
                                            buttonLayout="horizontal"
                                            inputStyle={{ borderRadius: 0 }}
                                            step={1}
                                            min={ScribbleEvent.minThickness}
                                            max={ScribbleEvent.maxThickness}
                                            decrementButtonClassName="aq-btn"
                                            incrementButtonClassName="aq-btn"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus"
                                            suffix=" px"
                                            style={{ width: '100%' }}
                                        />
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isScribbleThicknessChecked}
                                            aria-label="Reset scribble thickness"
                                            onClick={handleScribbleThicknessCheckBoxChange}
                                        />
                                    </td>
                                </tr>
                            </>
                        )}
                        {eventType === EventType.CELL && (
                            <>
                                <tr>
                                    <td className="td-label" style={{width: 176}}>Wear Level {'(' + Math.round(damageLevel) + '%)'}</td>
                                    <td>
                                        <Slider
                                            value={damageLevel}
                                            min={-1}
                                            max={100}
                                            onChange={(event) => {
                                                handleDamageLevelChange(event.value);
                                            }}
                                            className={'aq-slider aq-slider-horizontal hide-range'}
                                            style={{background:damageLevelLegend}}
                                            step={5.0}
                                            tooltip={damageLevel + '%'}
                                        />
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isDamageLevelChecked}
                                            aria-label="Reset wear level"
                                            onClick={handleDamageLevelCheckboxChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-label">Damage Type</td>
                                    <td>
                                        <PatternDropDown
                                            placeholder="Select a damage type"
                                            options={dmgTypePatternDefs}
                                            patterns={dmgTypePatterns}
                                            onChange={(x) => handleDamageTypeChange(x.id)}
                                            value={dmgTypePatternDefs.find((x) => x.id === damageType)}
                                        />
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isDamageTypeChecked}
                                            aria-label="Reset damage type"
                                            onClick={handleDamageTypeCheckboxChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-label">Maintenance Scope</td>
                                    <td>
                                        <PatternDropDown
                                            placeholder="Select a maintenance scope"
                                            options={mntnScopePatternDefs}
                                            patterns={mntnScopePatterns}
                                            onChange={(x) =>  handleMaintenanceScopeChange(x.id)}
                                            value={mntnScopePatternDefs.find((x) => x.id === maintenanceScope)}
                                        />
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isMaintenanceScopeChecked}
                                            aria-label="Reset maintenance scope"
                                            onClick={handleMaintenanceScopeCheckboxChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td-label">Wear Rate</td>
                                    <td>
                                        <InputNumber
                                            value={damageRate}
                                            min={0}
                                            max={10}
                                            step={0.1}
                                            onChange={(event) => {
                                                handleDamageRateChange(event);
                                            }}
                                            showButtons
                                            buttonLayout="horizontal"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus"
                                            incrementButtonClassName="aq-btn"
                                            decrementButtonClassName="aq-btn"
                                            style={{ width: '100%' }}
                                            inputStyle={{ borderRadius: 0 }}
                                            suffix="% /month"
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                        />
                                    </td>
                                    <td>
                                        <Button icon="pi pi-undo"
                                            className='p-button-text p-button-rounded'
                                            disabled={!isDamageRateChecked}
                                            aria-label="Reset wear rate"
                                            onClick={handleDamageRateCheckboxChange}
                                        />
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </Dialog>
        );
    }
);
