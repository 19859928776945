import { AnnotationProperties } from './AnnotationProperties';

// This class stores all the Text properties as a single object

export class TextProperties extends AnnotationProperties {
    /** @type {String}TextValue */
    t = '';
    /** @type {Array}PositionOfText*/
    p = [];
    /** @type {String}TextStyle */
    ts = '';
    constructor(textValue, positionOfText, textStyle, colorValue, sizeOfText, rectStart, rectEnd) {
        super(colorValue, sizeOfText, rectStart, rectEnd);
        this.t = textValue;
        this.p = positionOfText;
        this.ts = textStyle;
    }
}
