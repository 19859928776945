import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { setTool } from '../../redux/reducer/ToolReducer';
import { ActionType } from '../../utils/Action';

export default function ToolsBarMaintenanceScheduler({ disabled = false, transforming = false }) {
    const dispatch = useDispatch();
    const selectedTool = useSelector((state) => state.tool.tool);

    function setSelected(val) {
        if (disabled) return;
        dispatch(setTool(val));
    }

    return (
        <div className="aq-floating-panel">
            <div
                className={'toolbox' + (disabled ? ' disabled' : '')}
                style={{
                    left: disabled ? -71 : 8,
                    opacity: disabled ? 0 : 1,
                    pointerEvents: disabled ? 'none' : 'auto',
                }}
            >
                <Button
                    icon="tool-icon tool-icon-transform"
                    id="transform-tool"
                    className={selectedTool === ActionType.TRANSFORM ? 'active' : ''}
                    onClick={() => setSelected(ActionType.TRANSFORM)}
                />
                <Button
                    icon="tool-icon tool-icon-select"
                    id="select-tool"
                    className={selectedTool === ActionType.SELECT ? 'active' : ''}
                    onClick={() => setSelected(ActionType.SELECT)}
                />
            </div>
            <Tooltip target="#transform-tool" position="right">
                {'Pan & Zoom '}
                <span className="accel-label">W</span>
            </Tooltip>
            <Tooltip target="#select-tool" position="right">
                Select <span className="accel-label">S</span>
            </Tooltip>
        </div>
    );
}
