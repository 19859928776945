export const StatusPill = ({
    className,
    message,
    action,
    onActionActivate = () => {}
}) => {
    return (
        <div className={"aq-status-pill " + className}>
            <div className='aq-status-pill-inner'>
                <div className="aq-status-pill-message">{message}</div>
                {action && <div className="aq-status-pill-action" onClick={() => onActionActivate()}>{action}</div>}
            </div>
        </div>
    );
}