import { createSlice } from '@reduxjs/toolkit';
export const timeReducer = createSlice({
    name: 'timeClock',
    initialState: {
        ms: 0,
    },
    reducers: {
        increment: (state, action) => {
            state.ms = action.payload ? state.ms + 1 : state.ms;
        },
    },
});
export const { increment } = timeReducer.actions;
export default timeReducer.reducer;
