import { handleError } from '../errorHandling/error';
import Axios from './Axios';

export class PredictionAPI {
    static abortController = new AbortController();
    static inProgress = false;
    static url = 'FlatoutPredictions'

    /**
     * This method is used to get the predictions
     * @param {int} siteUnitId
     * @param {int} vesselId
     * @param {int} templateVersion
     * @param {int} year
     * @param {int} month
     * @param {int} mode
     * @returns
     */
    
    static predict = (siteUnitId, vesselId, templateVersion, year, month, mode) => {
        return new Promise(async (resolve, reject) => {
            this.inProgress = true;
            try {
                let response = await Axios.get(
                    `${this.url}/${siteUnitId}/${vesselId}/${templateVersion}/${year}/${month}/${mode}`,
                    { signal: this.abortController.signal }
                );
                if (response && response.status && response.status === 200) {
                    resolve(response);
                } else if (response && response.status && response.status === 204) {
                    resolve([]);
                } else {
                    resolve(response);
                }
            } catch (err) {
                this.inProgress = false;
                reject(handleError(err));
            } finally {
                this.inProgress = false;
            }
        });
    };

      /**
     * This method is used to find the optimal time to perform maintenance 
     * @param {int} siteUnitId 
     * @param {int} vesselId 
     * @param {int} templateVersion 
     * @returns 
     */

    static optimalTime = (siteUnitId, vesselId, templateVersion) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Axios.get(
                    `${this.url}/${siteUnitId}/${vesselId}/${templateVersion}/FindOptimalMaintenanceTime`
                );
                if (response && response.status === 200) {
                    resolve(response);
                }
            } catch (err) {
                reject(handleError(err));
            }
        });
    };


      /**
     * This method is used to get the scheduleMaintenance 
     * @param {int} siteUnitId
     * @param {int} vesselId
     * @param {int} templateVersion
     * @param {int} outageId
     * @param {int} damageLevelPredictedData
     * @param {int} mntnScopePredictedData
     * @returns
     */

    static scheduleMaintenance = (siteUnitId, vesselId, templateVersion, outageId, damageLevelPredictedData, mntnScopePredictedData) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await Axios.post(`${this.url}/${siteUnitId}/${vesselId}/${templateVersion}/${outageId}/ScheduleMaintenance`,{"damageLevels":damageLevelPredictedData,"mntnScopes":mntnScopePredictedData,"fullDamageYear": 0,  "optimalStartYear": 0,  "optimalEndYear": 0})
                if (response && response.status === 200) {
                    resolve(response);
                }
            }
            catch (err) {
                reject(handleError(err))
            }
        })
    }
}
