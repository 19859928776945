import { handleError } from '../errorHandling/error';
import Axios from './Axios';

export class RevisionAPI {
    /**
     * This method is used to get the flatout revision
     * @param {int} siteUnitId 
     * @param {int} vesselId 
     * @param {int} outageId 
     * @param {int} templateVersion 
     * @param {int} version 
     * @returns 
     */
    static getFlatoutRevision = (siteUnitId, vesselId, outageId, templateVersion, version) => {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(
                `FlatoutRevision/${siteUnitId}/${vesselId}/${outageId}/${templateVersion}/${version}`
            );
            if (response && response.status) {
                resolve(response.data);
            } else {
                resolve(handleError(response));
            }
        });
    };
    
    /**
     * This method is used to add a new flatout revision to the backend
     * @param {int} siteUnitId 
     * @param {int} vesselId 
     * @param {int} outageId 
     * @param {[]} eventSequence 
     * @returns 
     */
    static add = (siteUnitId, vesselId, outageId, eventSequence = []) => {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.post(`FlatoutRevision/${siteUnitId}/${vesselId}/${outageId}`, eventSequence);
            if (response && response.status) {
                resolve(true);
            } else {
                resolve(handleError(response));
            }
        });
    };

    /**
     * This method is used to estimate the refractory materials based on the damage level and maintenance scope
     * @param {int} siteUnitId 
     * @param {int} vesselId 
     * @param {int} outageId 
     * @param {int} version 
     * @returns 
     */
    static estimateRefractoryMaterials = (siteUnitId, vesselId, outageId, version) => {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(
                `FlatoutRevision/${siteUnitId}/${vesselId}/${outageId}/${version}/UpdateMaterialEstimates`
            );
            if (response && response.status) {
                resolve(true);
            }
        });
    };
}
