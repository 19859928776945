import { setLoading, setMinimized } from '../redux/reducer/LoadingReducer';
import store from '../redux/store/Store';
/**
 * @param {boolean} props
 */
export function showLoading(props) {
    store.dispatch(setLoading(props));
}
/**
 * @param {boolean} props
 */
export function minimizeLoading(props) {
    store.dispatch(setMinimized(props));
}
