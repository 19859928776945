import { EventType, Event } from '../config/Constants';
import { TextProperties } from '../properties/TextProperties';
import { Coord } from '../utils/Coord';
export const TextStyle = {
    NORMAL: '',
    BOLD: 'bold',
    ITALIC: 'italic',
};

export class TextEvent extends Event {
    textProperties = {};
    constructor(sizeOfText, colorValue, textStyle, positionOfText = { x: 0, y: 0 }, parentEventIndex = null) {
        super();
        this.flatoutEventType = EventType.TEXT;
        this.parentEventIndex = parentEventIndex;
        this.textProperties = new TextProperties(
            '',
            new Coord(positionOfText.x, positionOfText.y),
            textStyle,
            colorValue,
            sizeOfText,
            new Coord(),
            new Coord()
        );
    }

    static start(textEvent, coord) {
        textEvent.textProperties.rs = new Coord(coord.x, coord.y);
    }

    /**
     *
     * @param {TextEvent} textEvent
     * @param {CanvasRenderingContext2D} ctx
     * @param {string} textValue
     * @param {string} textColor
     * @param {number} textSize
     * @param {TextStyle} textStyle
     * @param {int} id
     * @param {boolean} debug
     * @returns
     */


    static end(textEvent, ctx, textValue, textColor, textSize, textStyle, id, debug = false) {
        textEvent.id = id;
        textEvent.textProperties.s = textSize < 12 ? 12 : textSize;
        textEvent.textProperties.t = textValue;
        textEvent.textProperties.c = textColor;
        textEvent.textProperties.ts = textStyle;
        ctx.font = textEvent.textProperties.ts + ' ' + textEvent.textProperties.s + 'px monospace';
        ctx.fillStyle = textEvent.textProperties.c;
        ctx.fillText(
            textEvent.textProperties.t,
            textEvent.textProperties.p.x,
            textEvent.textProperties.p.y + textEvent.textProperties.s
        );
        var textMetrics = ctx.measureText(textValue);
        textEvent.textProperties.re.x = textEvent.textProperties.rs.x + Math.round(textMetrics.width);
        textEvent.textProperties.re.y = textEvent.textProperties.rs.y + Math.round(textEvent.textProperties.s * 1.5);
        if (debug) {
            ctx.strokeStyle = 'red';
            ctx.strokeRect(
                textEvent.textProperties.rs.x,
                textEvent.textProperties.rs.y,
                textEvent.textProperties.re.x - textEvent.textProperties.rs.x,
                textEvent.textProperties.re.y - textEvent.textProperties.rs.y
            );
        }
        return true;
    }

   /**
     * This method is used to create a new Text Event from the old Text Event and return it
     * @param {TextEvent} localEvent
     * @returns
     */
    static setAllValuesFromObject(localEvent) {
        let textEvent = new TextEvent(
            localEvent.textProperties.s,
            localEvent.textProperties.c,
            localEvent.textProperties.ts,
            localEvent.textProperties.p,
            localEvent.parentEventIndex
        );
        textEvent.textProperties.t = localEvent.textProperties.t;
        textEvent.textProperties.s = localEvent.textProperties.s;
        textEvent.textProperties.rs = localEvent.textProperties.rs;
        textEvent.textProperties.re = localEvent.textProperties.re;
        return textEvent;
    }

    /**
     * This method is used to draw the text from the object
     * @param {TextEvent} textEvent
     * @param {CanvasRenderingContext2D} ctx
     * @param {boolean} debug
     * @returns
     */

    static drawTextFromObject(textEvent, ctx, debug = false) {
        if (textEvent.textProperties.t === '') {
            return;
        }

        ctx.globalCompositeOperation = 'source-over';
        ctx.font = textEvent.textProperties.ts + ' ' + textEvent.textProperties.s + 'px monospace';
        ctx.fillStyle = textEvent.textProperties.c;
        ctx.fillText(
            textEvent.textProperties.t,
            textEvent.textProperties.p.x,
            textEvent.textProperties.p.y + textEvent.textProperties.s
        );
        var textMetrics = ctx.measureText(textEvent.textProperties.t);
        textEvent.textProperties.re.x = textEvent.textProperties.rs.x + Math.round(textMetrics.width);
        textEvent.textProperties.re.y = textEvent.textProperties.rs.y + Math.round(textEvent.textProperties.s * 1.5);
        if (debug) {
            ctx.strokeStyle = 'red';
            ctx.strokeRect(
                textEvent.textProperties.rs.x,
                textEvent.textProperties.rs.y,
                textEvent.textProperties.re.x - textEvent.textProperties.rs.x,
                textEvent.textProperties.re.y - textEvent.textProperties.rs.y
            );
        }

        return textEvent;
    }
}
