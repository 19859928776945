import { Coord } from './Coord';

/**
 * GeometryAnalyser provides functions for scanning
 * an image to determine geometric details about
 * objects in the image.
 */
export class GeometryAnalyser {
    /**
     * The direction in which the path finder scans
     */
    static get ScanDirection() {
        return {
            UP: 0,
            DOWN: 1,
            LEFT: 2,
            RIGHT: 3,
        };
    }

    /**
     * Finds the path along the inner wall of a closed
     * polygon which is drawn on an image.
     * @param {Uint8ClampedArray} buffer The image buffer
     * @param {Coord} seed A point within the polygon
     * @param {ScanDirection} initialScanDirection The direction to start on
     * @param {number} width Width of the image
     * @returns A 2D array of coordinates traversed by the path finder
     */
    static findPath(buffer, seed, initialScanDirection = this.ScanDirection.UP, width, height, maxIterationCount, debugCtx) {
        var x = seed.x;
        var y = seed.y;
        var path = [];
        if (!this.isWhite(buffer, x, y, width)) {
            return null;
        }

        // Linearly scan in the gien direction until a boundary (black)
        // pixel is encountered and take that coordinate.
        switch (initialScanDirection) {
            case this.ScanDirection.DOWN:
                if (debugCtx) {
                    debugCtx.fillStyle = "rgb(255, 0, 0)";
                }
                // Linear scanning downwards
                while (y + 1 < height) {
                    if (this.isWhite(buffer, x, y + 1, width)) {
                        y += 1;
                        if (debugCtx) {
                            debugCtx.fillRect(x, y, 1, 1);
                        }
                    } else {
                        break;
                    }
                }
    
                path.push(new Coord(x, y));
    
                // Now find the second point in path
                if (this.isWhite(buffer, x + 1, y, width)) {
                    x += 1;
                } else if (this.isWhite(buffer, x, y - 1, width)) {
                    y -= 1;
                } else if (this.isWhite(buffer, x - 1, y, width)) {
                    x -= 1;
                }
    
                path.push(new Coord(x, y));
                break;
            case this.ScanDirection.LEFT:
                if (debugCtx) {
                    debugCtx.fillStyle = "rgb(255, 255, 0)";
                }
                // Linear scanning on the left
                while (x - 1 >= 0) {
                    if (this.isWhite(buffer, x - 1, y, width)) {
                        x -= 1;
                        if (debugCtx) {
                            debugCtx.fillRect(x, y, 1, 1);
                        }
                    } else {
                        break;
                    }
                }
    
                path.push(new Coord(x, y));
    
                // Now find the second point in the path
                if (this.isWhite(buffer, x, y + 1, width)) {
                    y += 1;
                } else if (this.isWhite(buffer, x + 1, y, width)) {
                    x += 1;
                } else if (this.isWhite(buffer, x, y - 1, width)) {
                    y -= 1;
                }
                path.push(new Coord(x, y));
                break;
            case this.ScanDirection.RIGHT:
                if (debugCtx) {
                    debugCtx.fillStyle = "rgb(0, 255, 0)";
                }
                // Linear scanning on the right
                while (x + 1 < width) {
                    if (this.isWhite(buffer, x, y, width)) {
                        x += 1;
                        if (debugCtx) {
                            debugCtx.fillRect(x, y, 1, 1);
                        }
                    } else {
                        x -= 1;
                        break;
                    }
                }
    
                path.push(new Coord(x, y));
    
                // Now find the second point in the path
                if (this.isWhite(buffer, x, y - 1, width)) {
                    y -= 1;
                } else if (this.isWhite(buffer, x - 1, y, width)) {
                    x -= 1;
                } else if (this.isWhite(buffer, x, y + 1, width)) {
                    y += 1;
                } else if (this.isWhite(buffer, x + 1, y, width)) {
                    x += 1;
                }
                path.push(new Coord(x, y));
                break;
            default:
                if (debugCtx) {
                    debugCtx.fillStyle = "rgb(0, 0, 255)";
                }
                // Linearly scanning upwards
                while (y >= 0) {
                    if (this.isWhite(buffer, x, y, width)) {
                        y -= 1;
                        if (debugCtx) {
                            debugCtx.fillRect(x, y, 1, 1);
                        }
                    } else {
                        y += 1;
                        break;
                    }
                }
    
                path.push(new Coord(x, y));
    
                //  Now find the second point in the path
                if (this.isWhite(buffer, x - 1, y, width)) {
                    x -= 1;
                } else if (this.isWhite(buffer, x, y + 1, width)) {
                    y += 1;
                } else if (this.isWhite(buffer, x + 1, y, width)) {
                    x += 1;
                } else if (this.isWhite(buffer, x, y - 1, width)) {
                    y -= 1;
                }
                path.push(new Coord(x, y));
                break;
        }

        // If first and last points are same, abort
        if (path[0].x === path[path.length - 1].x &&
            path[0].y === path[path.length - 1].y) {
            return null;
        }

        let iterationCount = 0;

        // Finally loop around anti-clockwise until reaching the starting point
        while ((path[0].x !== path[path.length - 1].x || path[0].y !== path[path.length - 1].y)) {
            iterationCount++;

            // Check if the perimeter covered is realistic for a cell of the given scale
            if (iterationCount > maxIterationCount) {
                return null;
            }
            
            var direction = new Coord(
                path[path.length - 1].x - path[path.length - 2].x,
                path[path.length - 1].y - path[path.length - 2].y
            )

            // Current position
            x = path[path.length - 1].x;
            y = path[path.length - 1].y;

            if (direction.x === -1 && direction.y === 0) {
                if (this.isWhite(buffer, x, y - 1, width)) {
                    path.push(new Coord(x, y - 1));
                } else if (this.isWhite(buffer, x - 1, y, width)) {
                    path.push(new Coord(x - 1, y));
                } else if (this.isWhite(buffer, x, y + 1, width)) {
                    path.push(new Coord(x, y + 1));
                } else {
                    path.push(new Coord(x + 1, y));
                }
            } else if (direction.x === 0 && direction.y === 1) {
                if (this.isWhite(buffer, x - 1, y, width)) {
                    path.push(new Coord(x - 1, y));
                } else if (this.isWhite(buffer, x, y + 1, width)) {
                    path.push(new Coord(x, y + 1));
                } else if (this.isWhite(buffer, x + 1, y, width)) {
                    path.push(new Coord(x + 1, y));
                } else {
                    path.push(new Coord(x, y - 1));
                }
            } else if (direction.x === 1 && direction.y === 0) {
                if (this.isWhite(buffer, x, y + 1, width)) {
                    path.push(new Coord(x, y + 1));
                } else if (this.isWhite(buffer, x + 1, y, width)) {
                    path.push(new Coord(x + 1, y));
                } else if (this.isWhite(buffer, x, y - 1, width)) {
                    path.push(new Coord(x, y - 1));
                } else {
                    path.push(new Coord(x - 1, y));
                }
            } else if (direction.x === 0 && direction.y === -1) {
                if (this.isWhite(buffer, x + 1, y, width)) {
                    path.push(new Coord(x + 1, y));
                } else if (this.isWhite(buffer, x, y - 1, width)) {
                    path.push(new Coord(x, y - 1));
                } else if (this.isWhite(buffer, x - 1, y, width)) {
                    path.push(new Coord(x - 1, y));
                } else {
                    path.push(new Coord(x, y + 1));
                }
            }
        }

        return path;
    }

    /**
     * It finds the polygon vertices
     * @param {*} path
     * @returns polygon vertices in [{x:a,y:b}...] format
     */
    static findPolygonVertices = (path) => {
        var cornerVertices = [];
        var removal_queue = [];
        let prev_index = 1;
        for (let i = 2; i < path.length - 1; i++) {
            if (this.collinear(path[prev_index], path[i], path[i + 1])) {
                removal_queue.push(i);
            } else {
                prev_index = i;
            }
        }

        for (let i = 1; i < path.length; i++) {
            if (!removal_queue.includes(i)) {
                cornerVertices.push(path[i]);
            }
        }

        return cornerVertices;
    };

    static collinear = (point1, point2, point3) => {
        return point1.x * (point2.y - point3.y) + point2.x * (point3.y - point1.y) + point3.x * (point1.y - point2.y) === 0;
    };
    /**
     * This interleaves the coordinates such
     * that the x axis values are present in
     * even positions and y axis values in
     * odd positions
     * @param {number[]} path 2D array of coordinates
     * @returns an interleave array of coordinates
     */
    static interleaveCoords = (path) => {
        const shape = new Uint16Array(2 * path.length);
        for (let i = 0; i < path.length; i++) {
            shape[2 * i] = path[i].x;
            shape[2 * i + 1] = path[i].y;
        }

        return shape.slice();
    };

    /** 
     * Check if a pixel in a buffer at coordinate
     * x, y is close to white.
     * @param {Uint8ClampedArray} img_buffer
     * @param {number} x
     * @param {number} y
     * @param {number} width
     */
    static isWhite(img_buffer, x, y, width) {
        return img_buffer[(x + y * width) * 4] > 250 &&
            img_buffer[(x + y * width) * 4 + 1] > 250 &&
            img_buffer[(x + y * width) * 4 + 2] > 250;
    }
}
