import { EventType, Event } from '../config/Constants';
import { CellProperties } from '../properties/CellProperties';

export class CellEvent extends Event {
    id = 0;
    cellIndex = 0;
    static dragging = false;
    cellProperties = {};
    constructor() {
        super();
        this.type = EventType.CELL;
    }

    /**
     * This method is used to set all the values
     * @param {CellEvent} cellEvent
     * @param {number} id
     * @param {number} cellIndex
     * @param {number} damageLevel
     * @param {number} damageType
     * @param {number} maintenanceScope
     * @param {number} damageRate
     */

    static setAllValues(
        cellEvent,
        id,
        cellIndex,
        damageLevel,
        damageType,
        maintenanceScope,
        damageRate,
        parentEventIndex1 = null
    ) {
        cellEvent.id = id;
        cellEvent.cellIndex = cellIndex;
        cellEvent.parentEventIndex = parentEventIndex1;
        cellEvent.cellProperties = new CellProperties(damageLevel, damageRate, maintenanceScope, damageType);
    }

    /**
     * This method is used to change the damage level of a particular cell
     * @param {CellEvent} cellEvent
     * @param {int} damageLevel
     */
    static changeDamageLevel(cellEvent, damageLevel) {
        cellEvent.cellProperties.damageLevel = damageLevel;
    }

    /**
     * This method is used to change the damage type of a particular cell
     * @param {CellEvent} CellEvent
     * @param {int} damageType
     */
    static changeDamageType(cellEvent, damageType) {
        cellEvent.cellProperties.damageType = damageType;
    }
    /**
     * This method is used to set all the values of the previous cell event object to a new cell event object and return the new one
     * @param {CellEvent} localEvent 
     * @returns 
     */

    static setAllValuesFromObject(/**@type {CellEvent} */ localEvent) {
        let cellEvent = new CellEvent();
        cellEvent.id = localEvent['id'];
        cellEvent.cellIndex = localEvent['cellIndex'];
        cellEvent.parentEventIndex = localEvent['parentEventIndex'];
        cellEvent.cellProperties = new CellProperties(
            localEvent['cellProperties']['dl'],
            localEvent['cellProperties']['dr'],
            localEvent['cellProperties']['ms'],
            localEvent['cellProperties']['dt']
        );
        return cellEvent;
    }
}
