import { Dialog } from 'primereact/dialog';
import { forwardRef, useEffect, useState } from 'react';
import './ReadPropertiesDialog.scss';

export const ReadPropertiesDialog = forwardRef(
    ({ /**@type [] */ selectedCellProperties, onCancel = () => {}, isCalledFromMntnSchedular }, ref) => {
        const [damageLevel, setDamageLevel] = useState(-1);
        const [damageType, setDamageType] = useState(-1);
        const [maintenanceScope, setMaintainenceScope] = useState(0);
        const [damageRate, setDamageRate] = useState(0.0001);

        useEffect(() => {
            if (selectedCellProperties && selectedCellProperties.length > 0) {
                for (let i = 0; i < selectedCellProperties.length; i++) {
                    if (isCalledFromMntnSchedular) {
                        setDamageLevel(selectedCellProperties[i]['dl']);
                        setMaintainenceScope(selectedCellProperties[i]['ms']);
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedCellProperties]);
        return (
            <>
                <Dialog
                    className={'aq-dialog'}
                    closeIcon="pi pi-times"
                    header={`Cell Properties`}
                    visible={selectedCellProperties.length > 0}
                    style={{ width: '25vw', minWidth: 480 }}
                    onHide={() => {
                        onCancel();
                    }}
                >
                    <table style={{ width: '100%' }} className="prop-control-table">
                        <tbody>
                            <>
                                <tr>
                                    <td className="td-label" style={{ width: 176 }}>
                                        Wear Level:
                                    </td>
                                    <td>
                                        <h2>{Math.round(damageLevel) + '%'}</h2>
                                    </td>
                                </tr>
                                {!isCalledFromMntnSchedular && (
                                    <tr>
                                        <td className="td-label">Damage Type:</td>
                                        <td>
                                            <h2>{damageType}</h2>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="td-label">Maintenance Scope:</td>
                                    <td>
                                        <h2>{maintenanceScope}</h2>
                                    </td>
                                </tr>
                                {!isCalledFromMntnSchedular && (
                                    <tr>
                                        <td className="td-label">Wear Rate:</td>
                                        <td>
                                            <h2>{damageRate + '% /month'}</h2>
                                        </td>
                                    </tr>
                                )}
                            </>
                        </tbody>
                    </table>
                </Dialog>
            </>
        );
    }
);
