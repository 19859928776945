import Axios from './Axios';

/**
 * For uploading the vesseltemplate
 * @param {int} unitId
 * @param {int} vesselId
 * @return {Promise.<boolean, error>} - Returns result or error
 */
export const uploadImageForUploadTemplates = (unitId, vesselId, formData) => {
    return new Promise(async (resolve, reject) => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        let response = await Axios.post(`FlatoutImage/${unitId}/${vesselId}`, formData, config);
        if (response.status === 201 || response.status === 200) resolve(true);
    });
};

/**
 * For getting the vessel image for the uploader page
 * @param {int} unitId
 * @param {int} vesselId
 * @return {Promise.<JSON, error>} - Returns result or error
 */

export const getTemplateImage = (unitId, vesselId, version = null) => {
    var param = '';
    if (version !== null) {
        param = `?version=${version}`;
    }
    return new Promise(async (resolve, reject) => {
        let _url = `/FlatoutImage/${unitId}/${vesselId}${param}`;
        let response = await Axios.get(_url);
        if (response && response.status && (response.status === 200 || response.status === 204)) resolve(response);
    });
};

/**
 * For deleting the vessel image
 * @param {int} unitId
 * @param {int} vesselId
 * @return {Promise.<JSON, error>} - Returns result or error
 */
export const deleteImageForUploadTemplates = (unitId, vesselId) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.delete(`FlatoutImage/${unitId}/${vesselId}`);
        if (response && response.status && response.status === 200) resolve({ ...response.data });
    });
};
