import { useRef, useState, useEffect } from 'react';
import DamageLevelLUT from '../../assets/luts/DamageLevelLUT.png';
import TopPanelMaintenceScheduler from '../../components/topPanel/TopPanelMaintenceScheduler';
import './MaintenanceScheduler.scss';
import Viewport from '../../components/viewport/Viewport';
import { getTemplateImage } from '../../services/ImageAPI';
import { useDispatch, useSelector } from 'react-redux';
import { PerfMon } from '../../utils/PerfMon';
import { hideModal, showModal } from '../../modal/Modal';
import {
    convertArrayToBase64,
    convertBase64ToArray,
    convertBase64ToArrayOfArrays,
    deepCopy,
    insidePolygon,
    parseEventStream,
    sleep,
    withinManhattanRadius,
} from '../../utils/Helper';
import { FlatoutDataAPI, getOutages } from '../../services/FlatoutDataAPI';
import { TemplateAPI } from '../../services/TemplateAPI';
import MonthYearPicker from '../../components/monthYearPicker/MonthYearPicker';
import PresentationModeSwitcher2 from '../../components/presentationModeSwitcher/PresentationModeSwitcher2';
import { PresentationMode } from '../../config/Constants';
import { PredictionAPI } from '../../services/PredictionAPI';
import { useSearchParams } from 'react-router-dom';
import { setOutageId } from '../../redux/reducer/HierarchyReducer';
import { CellDetection } from '../../utils/CellDetection';
import { Coord } from '../../utils/Coord';
import { Cell } from '../../models/Cell';
import { loadLUTData, lookUpColor } from '../../utils/Lut';
import { loadPatternCanvas } from '../../utils/Pattern';
import { Tessellation } from '../../utils/Tessellation';
import { StaticStates } from '../../utils/StaticeStates';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import ToolsBarMaintenanceScheduler from '../../components/tools/ToolsBarMaintenanceScheduler';
import { SelectTool } from '../../components/tools/SelectTool';
import { ActionType } from '../../utils/Action';
import { ReadPropertiesDialog } from '../../components/readPropertiesDialog/ReadPropertiesDialog';
import { setTool } from '../../redux/reducer/ToolReducer';
import { setLoadingBlocker } from '../../redux/reducer/LoadingBlockerReducer';

export default function MaintenanceScheduler() {
    const monthYearPickerRef = useRef();
    const editOutageNameRef = useRef();
    const [imageData, setImageData] = useState(null);
    const [, setImageError] = useState(null);
    const [flatout, setFlatout] = useState(null);
    const viewportRef = useRef();
    const topPanelMaintenceSchedulerRef = useRef();
    const imageUploadInterval = useRef(0);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [viewMode, setViewMode] = useState(PresentationMode.DMG_LEVEL);
    const [outages, setOutages] = useState([]);
    const [searchParams] = useSearchParams();
    const hierarchy = useSelector((state) => state.hierarchy);
    const [dmgLevelLUT, setDmgLevelLUT] = useState(null);
    const [mntnScopePatterns, setMntnScopePatterns] = useState([]);
    const [showOutageDialog, setShowOutageDialog] = useState(false);
    const [showAltOutageDialog, setShowAltOutageDialog] = useState(false);
    const [damageLevelsData, setDamageLevelsData] = useState([]);
    const [fullDamageYear, setfullDamageYear] = useState(new Date().getFullYear());
    const [fullDamageMonth, setfullDamageMonth] = useState(new Date().getMonth());
    const [allUnits, setAllUnits] = useState([]);
    const [selectedSuffix, setSelectedSuffix] = useState('');
    const [selectedOutageStartDate, setSelectedOutageStartDate] = useState(new Date());
    const [selectedOutageEndDate, setSelectedOutageEndDate] = useState(new Date());
    const [outageNameModified, setOutageNameModified] = useState('');
    const [creatingOutage, setCreatingOutage] = useState(false);
    const [optimalOverallPeriod, setOptimalOverallPeriod] = useState({
        monthStart: 0,
        yearStart: 0,
        monthEnd: 0,
        yearEnd: 0,
    });
    const [mntnScopePredictedData, setMntnScopePredictedData] = useState([]);
    const [mntnScopePatternData, setMntnScopePatternData] = useState([]);
    const [selectTool, setSelectTool] = new useState(new SelectTool());
    const [selectedCellsIndices, setSelectedCellIndices] = useState([]);
    const [selectedCellProperties, setSelectedCellProperties] = useState([]);
    const [cellIndexMap, setCellIndexMap] = useState([]);
    const selectedTool = useSelector((state) => state.tool.tool);
    const [vesselId, setVesselId] = useState(-1);
    const [isOutageStartDateModified, setIsOutageStartDateModified] = useState(false);
    const [schedulingMaintenance, setSchedulingMaintenance] = useState(false);
    const [loadingUnits, setLoadingUnits] = useState(false);
    const dispatch = useDispatch();
    const navigateToInspectorFlatout = () => {
        window.location.hash = '/';
    };

    useEffect(() => {
        if (viewportRef.current) viewportRef.current.reset();
        if (
            hierarchy.siteId > 0 &&
            hierarchy.siteUnitId > 0 &&
            hierarchy.vesselId > 0 &&
            hierarchy.templateVersion > 0
        ) {
            if (hierarchy.vesselId !== vesselId) {
                viewportRef.current.showLoader('Loading Image', true) 
                fetchImage().then(async () => {
                    fetchOutages();
                    await fetchUnits();
                    await fetchFlatoutData();
                    await fetchTemplateData();
                    await predict(true);
                    viewportRef.current.hideLoader();
                });
                setVesselId(hierarchy.vesselId);
            }
        }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy.siteId, hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion]);

    const fetchUnits = async () => {
        try {
            dispatch(setLoadingBlocker(true));
            setLoadingUnits(true);
            var units = await FlatoutDataAPI.getAllUnitsInSite(hierarchy.siteId);
            setAllUnits(units);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingUnits(false);
            dispatch(setLoadingBlocker(false));
        }
    };

    useEffect(() => {
        if (
            damageLevelsData &&
            damageLevelsData.length > 0 &&
            mntnScopePredictedData &&
            mntnScopePredictedData.length > 0
        ) {
            drawPredictedData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMode, damageLevelsData, mntnScopePredictedData]);

    const fetchImage = async () => {
        if (imageUploadInterval.current) {
            clearInterval(imageUploadInterval.current);
        }
        console.info('Attempting to fetch image…');
        await tryFetchImage();
    };

    const tryFetchImage = async (pollCount = 0) => {
        if (pollCount > 36) {
            setImageError('Image processing timed out. Please reupload the image and try again');
            showModal({
                title: 'Something went wrong',
                message: 'Image processing timed out. Please reupload the image and try again ',
                class: 'error',
            });
            viewportRef.current.hideLoader();
            return;
        }
        dispatch(setLoadingBlocker(true));
        try {
            let imageResponse = await getTemplateImage(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.templateVersion
            );
            if (imageResponse.status === 200) {
                PerfMon.end();
                if (typeof imageResponse.data === 'string' || imageResponse.data instanceof String) {
                    setImageError(imageResponse.data);
                    showModal({
                        title: 'Oh no!',
                        message: imageResponse.data,
                        class: 'error',
                    });
                    viewportRef.current.hideLoader();
                } else {
                    console.info('Got image. Renderring…');
                    setImageData(imageResponse.data.data);
                }
            } else if (imageResponse.status === 204) {
                console.info('Still analysing. Retrying after 5 seconds…');
                PerfMon.start('Image Analysis');
                viewportRef.current.showLoader('Analysing', true);
                await sleep(5000);
                await tryFetchImage(pollCount + 1);
            }
            dispatch(setLoadingBlocker(false));
        } catch (err) {
            console.error(err);
        }
    };

    const fetchFlatoutData = async () => {
        var flatout = await FlatoutDataAPI.get(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion);
        setFlatout(flatout);
    };

    function fetchOutages() {
        let tempArray = [];
        (async function () {
            try {
                dispatch(setLoadingBlocker(true));
                let outages = await getOutages(hierarchy.siteUnitId);
                outages
                    .filter((outage) => !outage.value.includes('Nominal Lining'))
                    .map((filteredOutage) => {
                        return tempArray.push(filteredOutage);
                    });
                setOutages(tempArray);
                if (outages.length <= 0)
                    showModal({
                        title: 'No outages',
                        message:
                            'There is no outages available. Please setup outages in [Outage Setup > Outages] in RMS.',
                        class: 'warning',
                    });
                if (outages.map((v) => v.key).includes(parseInt(searchParams.get('outageId')))) {
                    dispatch(setOutageId(parseInt(searchParams.get('outageId'))));
                    if (searchParams.get('vesselId') == null) {
                        window.history.replaceState({}, document.title, '/#/');
                        searchParams.forEach((value, key) => {
                            searchParams.delete(key);
                        });
                    }
                } else if (parseInt(searchParams.get('unitId'))) {
                    showModal({
                        title: 'Outage',
                        message: 'Please select an outage to continue!',
                        class: 'warning',
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(setLoadingBlocker(false));
            }
        })();
    }

    const fetchTemplateData = async () => {
        if (viewportRef.current) viewportRef.current.showLoader('Loading Cells', true);
        dispatch(setLoadingBlocker(true));
        var template = await TemplateAPI.get(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion);
        if (template.cells) {
            loadTemplateData(template.cells, template.tessellated);
        }
        dispatch(setLoadingBlocker(false));
    };

    const loadTemplateData = (cells, isTessellated) => {
        CellDetection.detectedCells = [];
        var _cim = [];
        for (let i = 0; i < cells.length; i++) {
            var _cell = deepCopy(cells[i]);
            _cell.g.ap = convertBase64ToArray(_cell.g.ap);
            let encodedSubtractiveData = convertBase64ToArrayOfArrays(_cell.g.sPs);
            _cell.g.sPs = new Set(encodedSubtractiveData.map((data) => [...data]));

            let _centroid = [..._cell.g.c];
            _cell.g.c = new Coord(_centroid[0], _centroid[1]);

            let _scale = [..._cell.g.s];
            _cell.g.s = new Coord(_scale[0], _scale[1]);

            if (isTessellated) {
                _cell.g = Tessellation.decode(_cell.g, CellDetection.detectedCells);
            }

            CellDetection.detectedCells.push(_cell);
            _cim.push(i);
        }
        setCellIndexMap(_cim);
    };

    useEffect(() => {
        loadAllPatterns();
    }, [dispatch]);

    const loadAllPatterns = async () => {
        loadLUTData(DamageLevelLUT, setDmgLevelLUT);
        var maintenanceScopes = await FlatoutDataAPI.getMaintenanceScope();
        setMntnScopePatternData(maintenanceScopes);

        let patternCanvases = [];
        for (let i = 0; i < maintenanceScopes.length; i++) {
            patternCanvases.push(
                loadPatternCanvas(
                    `rgb(${maintenanceScopes[i].patternFgColorR},${maintenanceScopes[i].patternFgColorG},${maintenanceScopes[i].patternFgColorB})`,
                    `rgb(${maintenanceScopes[i].patternBgColorR},${maintenanceScopes[i].patternBgColorG},${maintenanceScopes[i].patternBgColorB})`,
                    maintenanceScopes[i].patternStyle,
                    maintenanceScopes[i].patternWeight
                )
            );
        }

        setMntnScopePatterns([...patternCanvases]);
    };

    const fetchOptimalMaintenanceTime = async () => {
        viewportRef.current.showLoader('Opitmising');
        dispatch(setLoadingBlocker(true));
        await PredictionAPI.optimalTime(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion).then(
            (response) => {
                try {
                    if (response.data) {
                        var predictionOptimalTime = parseEventStream(response.data);
                        if (predictionOptimalTime.status === 200) {
                            let _optimalStartYear = predictionOptimalTime.body['OptimalStartYear'];
                            let _optimalEndYear = predictionOptimalTime.body['OptimalEndYear'];
                            setOptimalOverallPeriod({
                                monthStart: Math.floor((_optimalStartYear - Math.floor(_optimalStartYear)) * 12),
                                yearStart: Math.floor(_optimalStartYear),
                                monthEnd: Math.floor((_optimalEndYear - Math.floor(_optimalEndYear)) * 12),
                                yearEnd: Math.floor(_optimalEndYear),
                            });
                            monthYearPickerRef.current.openBox();
                        }
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    viewportRef.current.hideLoader();
                    dispatch(setLoadingBlocker(false));
                }
            }
        );
    };

    const predict = async (isfirstTime = false) => {
        viewportRef.current.showLoader('Predicting', isfirstTime);
        dispatch(setLoadingBlocker(true));
        if (viewMode === PresentationMode.DMG_LEVEL || viewMode === PresentationMode.MNTN_SCOPE) {
            let _mode = 'All';
            await PredictionAPI.predict(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.templateVersion,
                selectedYear,
                selectedMonth + 1,
                _mode
            ).then((response) => {
                try {
                    if (response.data) {
                        var predictionResponse = parseEventStream(response.data);
                        if (predictionResponse.status === 200) {
                            let _fullDamageYear = predictionResponse.body['FullDamageYear'];
                            setfullDamageYear(Math.floor(_fullDamageYear));
                            let _fdm = Math.floor((_fullDamageYear - Math.floor(_fullDamageYear)) * 12);
                            setfullDamageMonth(_fdm);
                            var unMappedDamageLevelsData = [],
                                mntnScopeData = [],
                                damageLevelsData = [];
                            if (_mode === 'All') {
                                unMappedDamageLevelsData = convertBase64ToArray(
                                    predictionResponse.body['DamageLevels']
                                );
                                damageLevelsData = unMappedDamageLevelsData.map((dl) => dl / 100);
                                mntnScopeData = convertBase64ToArray(predictionResponse.body['MntnScopes']);
                            } else if (_mode === 'DamageLevels') {
                                unMappedDamageLevelsData = convertBase64ToArray(
                                    predictionResponse.body['DamageLevels']
                                );
                                damageLevelsData = unMappedDamageLevelsData.map((dl) => dl / 100);
                            } else {
                                mntnScopeData = convertBase64ToArray(predictionResponse.body['MntnScopes']);
                            }
                            setDamageLevelsData([...damageLevelsData]);
                            setMntnScopePredictedData([...mntnScopeData]);
                        } else if (predictionResponse.status === 204) {
                            showModal({
                                title: 'Prediction Unavilable',
                                message: 'Prediction not yet ready',
                                class: 'error',
                            });
                        } else if (predictionResponse.status === 400) {
                            showModal({
                                title: 'Prediction Unavilable',
                                message: predictionResponse.body,
                                class: 'error',
                            });
                        } else {
                            showModal({ title: 'Server Error', message: predictionResponse.body, class: 'error' });
                        }
                    }
                    dispatch(setLoadingBlocker(false));
                } catch (err) {
                    console.error('Prediction Unavailable');
                }
            });
        }
    };

    const drawPredictedData = async () => {
        if (viewportRef.current) {
            viewportRef.current.showLoader('Rendering');
            let ctx = viewportRef.current.getPaintContext();
            for (let i = 0; i < CellDetection.detectedCells.length; i++) {
                if (viewMode === PresentationMode.DMG_LEVEL) {
                    Cell.draw(CellDetection.detectedCells[i], getCellFill(damageLevelsData[i]), ctx);
                } else {
                    Cell.draw(CellDetection.detectedCells[i], getCellFill(mntnScopePredictedData[i], ctx), ctx);
                }
            }

            viewportRef.current.hideLoader();
        }
    };

    const getCellFill = (value, ctx = null) => {
        let color = '';
        if (viewMode === PresentationMode.DMG_LEVEL) {
            color = lookUpColor(dmgLevelLUT, value, 0, 1);
        } else {
            if (ctx != null) {
                color = ctx.createPattern(mntnScopePatterns[value], 'repeat');
            }
        }
        return color;
    };

    const handlePredict = async () => {
        await predict()
            .then(() => {
                viewportRef.current.showLoader('Rendering');
                drawPredictedData().then(() => {
                });
                viewportRef.current.hideLoader();
            })
            .catch((error) => {
                viewportRef.current.hideLoader();
            });
    };

    const onEdit = () => {
        let index = -1;
        for (let i = outages.length - 1; i >= 0; i--) {
            let _date = new Date(outages[i].outageStartDate);
            let _outageYear = _date.getFullYear();
            let _outageMonth = _date.getMonth() + 1;
            if (selectedYear === _outageYear && selectedMonth === _outageMonth) {
                dispatch(setOutageId(outages[i]['key']));
                StaticStates.PredictedData.damageLevelsData = damageLevelsData;
                StaticStates.PredictedData.mntnScopeData = mntnScopePredictedData;
                navigateToInspectorFlatout();
                index = i;
                break;
            }
        }
        if (index === -1) {
            setSelectedOutageStartDate(new Date(selectedYear, selectedMonth, 1));
            setSelectedOutageEndDate(new Date(selectedYear, selectedMonth, 1));
            updateName('');
            setShowOutageDialog(true);
        }
    };

    useEffect(() => {
        updateName(selectedSuffix);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOutageStartDate, selectedSuffix]);

    const updateName = (suffix) => {
        let _name = '';
        if (allUnits.length > 0) {
            let unit = allUnits.find((x) => x.unitId === hierarchy.siteUnitId);
            _name = unit.unitName + ' - ' + moment(selectedOutageStartDate).format('DD/MM/YYYY');
            if (suffix.length > 0) {
                _name += ' [' + suffix + ']';
            }
            setOutageNameModified(_name);
        }
        return _name;
    };

    const createOutage = async () => {
        setCreatingOutage(true);
        try {
            var outageId = await FlatoutDataAPI.createOutage(
                hierarchy.siteUnitId,
                selectedOutageStartDate,
                selectedOutageEndDate,
                outageNameModified
            );
            dispatch(setOutageId(outageId));
            StaticStates.PredictedData.damageLevelsData = damageLevelsData;
            StaticStates.PredictedData.mntnScopeData = mntnScopePredictedData;
            showModal({ title: 'Created Outage', message: 'Sucessfully Created Outage ', class: 'success' });
            return outageId;
        } catch (err) {
            console.error(err);
        } finally {
            setCreatingOutage(false);
            setShowOutageDialog(false);
        }
    };

    const onSchedule = async () => {
        let isFound = false;
        let _outageId = -1;
        for (let i = outages.length - 1; i >= 0; i--) {
            let _date = new Date(outages[i].outageStartDate);
            let _outageYear = _date.getFullYear();
            let _outageMonth = _date.getMonth();
            if (selectedYear === _outageYear && selectedMonth === _outageMonth) {
                // Found
                isFound = true;
                _outageId = outages[i]['key'];
                break;
            }
        }

        if (!isFound || isOutageStartDateModified) {
            let createdOutageId = await createOutage();
            setSchedulingMaintenance(true);
            await scheduleMaintenance(createdOutageId)
                .then(() => {
                    // Success
                })
                .catch((err) => {})
                .finally(() => {
                    setShowAltOutageDialog(false);
                    setSchedulingMaintenance(false);
                });
        } else {
            setSchedulingMaintenance(true);
            await scheduleMaintenance(_outageId)
                .then(() => { /* Success */})
                .catch((err) => {})
                .finally(() => {
                    setShowAltOutageDialog(false);
                    setSchedulingMaintenance(false);
                });
        }
    };

    const scheduleMaintenance = async (createdOutageId) => {
        let processedDL = damageLevelsData.map((dl) => dl * 100);
        let b64DL = convertArrayToBase64(processedDL);
        let b64MS = convertArrayToBase64(mntnScopePredictedData);
        if (createdOutageId > 0) {
            await PredictionAPI.scheduleMaintenance(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.templateVersion,
                createdOutageId,
                b64DL,
                b64MS
            ).then((response) => {
                showModal({
                    title: 'Successfully Scheduled Maintenance ',
                    message: 'The maintenance has been scheduled sucessfully',
                    class: 'success',
                });
            });
        }
        setShowAltOutageDialog(true);
    };

    const editOutageName = () => {
        let index = -1;
        for (let i = outages.length - 1; i >= 0; i--) {
            let _date = new Date(outages[i].outageStartDate);
            let _outageYear = _date.getFullYear();
            let _outageMonth = _date.getMonth();
            if (selectedYear === _outageYear && selectedMonth === _outageMonth) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            setSelectedOutageStartDate(new Date(outages[index].outageStartDate));
            setSelectedOutageEndDate(new Date(outages[index].outageEndDate));
            let unit = allUnits.find((x) => x.unitId === hierarchy.siteUnitId);
            let _name = unit.unitName + ' - ' + moment(outages[index].outageStartDate).format('DD/MM/YYYY');
            if (outages[index]['value'].indexOf(_name + ' [')>=0) {
                _name += ' [';
                let _suffix = outages[index]['value'].substr(
                    outages[index]['value'].indexOf('['),
                    outages[index]['value'].indexOf(']')
                );
                _suffix = _suffix.replace('[', '');
                _suffix = _suffix.replace(']', '');

                setSelectedSuffix(_suffix);
            } else if (outages[index]['value'].indexOf(_name)>=0) {
                setSelectedSuffix('');
            } else {
                setSelectedSuffix(outages[index]['value']);
            }
        } else {
            let startDate = new Date(selectedYear, selectedMonth);
            setSelectedOutageStartDate(startDate)
        }
    };

    const handleInputOnce = async (x, y) => {
        switch (selectedTool) {
            case ActionType.SELECT:
                let cellIndex = findCell(x, y);
                if (cellIndex >= 0) {
                    let cell = CellDetection.detectedCells[cellIndex];
                    SelectTool.drawOnOverlayCanvas(cell, viewportRef.current.getPreviewContext());
                    if (selectedCellsIndices.length === 0) {
                        setSelectedCellIndices([CellDetection.detectedCells[cellIndex]]);
                        let cellProperties = {
                            dl: damageLevelsData[cellIndex] * 100,
                            ms: mntnScopePatternData[mntnScopePredictedData[cellIndex]]['description'],
                        };
                        setSelectedCellProperties([cellProperties]);
                    }
                }
                break;
            default:
        }
    };

    const handleInputStart = async (x, y) => {
        switch (selectedTool) {
            case ActionType.SELECT:
                let localSelectTool = new SelectTool();
                viewportRef.current.clearPreviewCanvas();
                SelectTool.start(
                    localSelectTool,
                    new Coord(x, y),
                    viewportRef.current.getPreviewContext(),
                    selectedCellsIndices.length >= 1
                );
                setSelectTool(localSelectTool);
                break;
            default:
        }
    };

    const handleInputUpdate = async (x, y) => {
        switch (selectedTool) {
            case ActionType.SELECT:
                let /**@type {SelectTool} */ localSelectTool = { ...selectTool };
                SelectTool.move(
                    localSelectTool,
                    new Coord(x, y),
                    viewportRef.current.getPreviewContext(),
                    CellDetection.detectedCells,
                    cellIndexMap,
                    selectedCellsIndices.length > 0
                );
                setSelectTool(localSelectTool);
                break;
            default:
        }
    };

    const handleInputEnd = async (x, y) => {
        switch (selectedTool) {
            case ActionType.SELECT:
                let localSelectTool = { ...selectTool };
                let response = SelectTool.end(localSelectTool, selectedCellsIndices.length >= 1);
                if (
                    response &&
                    response.length > 0 &&
                    mntnScopePredictedData.length > 0 &&
                    damageLevelsData.length > 0
                ) {
                    let avg_dmg_level = 0;
                    let _msPredictedData = '';
                    for (let i = 0; i < response.length; i++) {
                        avg_dmg_level += damageLevelsData[response[i]];
                    }
                    let _sliceOfMntnScopePredictedData = mntnScopePredictedData.slice(
                        response[0],
                        response[response.length - 1]
                    );
                    _msPredictedData = _sliceOfMntnScopePredictedData.every(
                        (ms) => ms === _sliceOfMntnScopePredictedData[0]
                    )
                        ? mntnScopePatternData[mntnScopePredictedData[response[0]]]
                        : 1;
                    avg_dmg_level = Math.trunc((avg_dmg_level / response.length) * 100);

                    let cellProperties = {
                        dl: avg_dmg_level,
                        ms: _msPredictedData !== 1 ? _msPredictedData['description'] : 'Mixed',
                    };
                    setSelectedCellProperties([cellProperties]);
                } else {
                    viewportRef.current.clearPreviewCanvas();
                }
                break;
            default:
        }
    };

    /**
     * This method is used to find the cell
     * @param {number} x
     * @param {number} y
     * @returns
     */
    const findCell = (x, y) => {
        let index = -1;
        for (let i = 0; i < CellDetection.detectedCells.length; i++) {
            if (
                withinManhattanRadius(
                    CellDetection.detectedCells[i].g.c,
                    { x: x, y: y },
                    Math.trunc((40 * 50) / flatout.mmScale)
                )
            ) {
                if (insidePolygon(CellDetection.detectedCells[i].g.ap, { x: x, y: y })) {
                    index = i;
                    break;
                }
            }
        }
        return index;
    };

    useEffect(() => {
        if (viewportRef.current) {
            if (selectedCellsIndices.length === 0 && selectedCellProperties.length === 0)
                viewportRef.current.clearPreviewCanvas();
        }
    }, [selectedCellsIndices, selectedCellProperties]);
    const handleShowCellPropertiesClose = () => {
        setSelectedCellIndices([]);
        setSelectedCellProperties([]);
    };

    const handleKeyBoardEvents = (event) => {
        if (event) {
            let cls1 = document.body.getElementsByClassName('p-dropdown-panel');
            let cls2 = document.body.getElementsByClassName('p-dialog-header');
            let key = event.key.toLowerCase();
            hideModal();
            if (cls1.length === 0 && cls2.length === 0 && viewportRef.current.getIsImageAvailable()) {
                switch (key) {
                    case 'w':
                        dispatch(setTool(ActionType.TRANSFORM));
                        break;
                    case 's':
                        dispatch(setTool(ActionType.SELECT));
                        break;
                    case '+':
                    case '=':
                        viewportRef.current.zoomIn(); break;
                    case '-':
                    case '_':
                        viewportRef.current.zoomOut(); break;
                    case 'f':
                        viewportRef.current.fitToScreen();
                        break;
                    default:
                }
            }
        }
    };
    
    useEffect(() => {
        window.addEventListener('keypress', handleKeyBoardEvents);

        return () => {
            window.removeEventListener('keypress', handleKeyBoardEvents);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="MaintenanceSchedular-container">
            <TopPanelMaintenceScheduler
                ref={topPanelMaintenceSchedulerRef}
                onEdit={onEdit}
                onSchedule={() => {
                    setShowAltOutageDialog(true);
                    editOutageName();
                }}
                onOptimise={fetchOptimalMaintenanceTime.bind(this)}
                mntnScopePredictedData={mntnScopePredictedData}
            >
                {imageData ? (
                    <MonthYearPicker
                        ref={monthYearPickerRef}
                        month={selectedMonth}
                        year={selectedYear}
                        onChangeMonth={setSelectedMonth}
                        onChangeYear={setSelectedYear}
                        onConfirm={handlePredict}
                        dangerYear={fullDamageYear}
                        dangerMonth={fullDamageMonth}
                        disabled={fullDamageYear <= 0}
                        outages={outages}
                        optiMonthStart={optimalOverallPeriod.monthStart}
                        optiMonthEnd={optimalOverallPeriod.monthEnd}
                        optiYearStart={optimalOverallPeriod.yearStart}
                        optiYearEnd={optimalOverallPeriod.yearEnd}
                    />
                ) : (
                    ''
                )}
                {imageData && mntnScopePredictedData.length > 0 ? (
                    <PresentationModeSwitcher2
                        presentationModes={[PresentationMode.DMG_LEVEL, PresentationMode.MNTN_SCOPE]}
                        selectedMode={viewMode}
                        onSelectMode={setViewMode}
                    />
                ) : (
                    ''
                )}
            </TopPanelMaintenceScheduler>
            <Viewport
                ref={viewportRef}
                imageData={imageData}
                onInputOnce={handleInputOnce.bind(this)}
                onInputStart={handleInputStart.bind(this)}
                onInputEnd={handleInputEnd.bind(this)}
                onInputUpdate={handleInputUpdate.bind(this)}
                idleMessage="Select a vessel to View Flatout Prediction"
                leftChild={
                    <ToolsBarMaintenanceScheduler
                        disabled={damageLevelsData.length === 0 && mntnScopePredictedData.length === 0}
                    />
                }
            />
            <Dialog
                header="Create Outage?"
                className="aq-dialog"
                maskClassName="aq-dialog-backdrop"
                visible={showOutageDialog}
                style={{ width: '75vw', minWidth: 300 }}
                onHide={() => setShowOutageDialog(false)}
                closeIcon="pi pi-times"
                footer={
                    <div className="row">
                        <div className="col-auto" style={{ textAlign: 'start' }}>
                            {creatingOutage && (
                                <>
                                    <i className="pi spinner-16 pi-spin" style={{ verticalAlign: 'middle' }} />
                                    <i style={{ verticalAlign: 'middle', marginLeft: 8, color: '#333' }}>
                                        Creating Outage…
                                    </i>
                                </>
                            )}
                        </div>
                        <div className="col-50">
                            <Button
                                label="Cancel"
                                className="aq-btn"
                                onClick={() => {
                                    setShowOutageDialog(false);
                                }}
                            />
                            <Button
                                label="Create Outage and Edit"
                                className="aq-btn aq-primary"
                                onClick={() => {
                                    createOutage()
                                        .then(() => {
                                            navigateToInspectorFlatout();
                                        })
                                        .catch((e) => console.error(e));
                                }}
                            />
                        </div>
                    </div>
                }
            >
                <>
                    <p>
                        In order to edit this predicted data, an outage must be created according to the prediction
                        date.
                    </p>
                    <div className="row">
                        <div className="col-33 p-8">
                            <label style={{ marginBottom: 4 }} className="aq-formlabel">
                                Start Date:
                            </label>
                            <Calendar
                                value={selectedOutageStartDate}
                                onChange={(e) => {
                                    setSelectedOutageStartDate(e.value);
                                }}
                                // minDate={new Date(selectedYear, selectedMonth, 1)}
                                style={{ width: '100%' }}
                                // readOnlyInput
                            />
                        </div>
                        <div className="col-33 p-8">
                            <label style={{ marginBottom: 4 }} className="aq-formlabel">
                                End Date:
                            </label>
                            <Calendar
                                value={
                                    selectedOutageEndDate < selectedOutageStartDate
                                        ? selectedOutageStartDate
                                        : selectedOutageEndDate
                                }
                                onChange={(e) => {
                                    setSelectedOutageEndDate(e.value);
                                }}
                                minDate={selectedOutageStartDate}
                                style={{ width: '100%' }}
                                // readOnlyInput
                            />
                        </div>
                        <div className="col-33 p-8">
                            <label style={{ marginBottom: 4 }} className="aq-formlabel">
                                Name:&nbsp;
                                {loadingUnits ? <i>Loading…</i> : <span>{outageNameModified}</span>}
                            </label>
                            <InputText
                                className="aq-formcontrol"
                                style={{ maxHeight: 32, width: '100%', display: 'block' }}
                                placeholder="Enter suffix"
                                onChange={(e) => setSelectedSuffix(e.target.value)}
                            />
                        </div>
                    </div>
                </>
            </Dialog>
            <Dialog
                header="Schedule Maintenance"
                className="aq-dialog"
                maskClassName="aq-dialog-backdrop"
                visible={showAltOutageDialog}
                style={{ width: '75vw', minWidth: 300 }}
                onHide={() => setShowAltOutageDialog(false)}
                closeIcon="pi pi-times"
                footer={
                    <div className="row">
                        <div className="col-auto" style={{ textAlign: 'start' }}>
                            {creatingOutage && (
                                <>
                                    <i className="pi spinner-16 pi-spin" style={{ verticalAlign: 'middle' }} />
                                    <i style={{ verticalAlign: 'middle', marginLeft: 8, color: '#333' }}>
                                        Creating Outage…
                                    </i>
                                </>
                            )}
                            {schedulingMaintenance && (
                                <>
                                    <i className="pi spinner-16 pi-spin" style={{ verticalAlign: 'middle' }} />
                                    <i style={{ verticalAlign: 'middle', marginLeft: 8, color: '#333' }}>
                                        Scheduling Maintenance…
                                    </i>
                                </>
                            )}
                        </div>
                        <div className="col-50">
                            <Button
                                label="Cancel"
                                className="aq-btn"
                                onClick={() => {
                                    setShowAltOutageDialog(false);
                                }}
                            />
                            <Button
                                label="Schedule and Estimate"
                                className="aq-btn aq-primary"
                                disabled={outageNameModified === 'Loading…'}
                                onClick={onSchedule.bind(this)}
                            />
                        </div>
                    </div>
                }
            >
                <>
                    <p>
                        This will create an outage at the specified date, predict vessel damage and automatically
                        estimate refractory materials.
                    </p>
                    <div className="row">
                        <div className="col-33 p-8">
                            <label style={{ marginBottom: 4 }} className="aq-formlabel">
                                Outage Start Date:
                            </label>
                            <Calendar
                                value={selectedOutageStartDate}
                                onChange={(e) => {
                                    setSelectedOutageStartDate(e.value);
                                }}
                                // minDate={new Date(selectedYear, selectedMonth, 1)}
                                style={{ width: '100%' }}
                                // readOnlyInput
                            />
                        </div>
                        <div className="col-33 p-8">
                            <label style={{ marginBottom: 4 }} className="aq-formlabel">
                                Outage End Date:
                            </label>
                            <Calendar
                                value={
                                    selectedOutageEndDate < selectedOutageStartDate
                                        ? selectedOutageStartDate
                                        : selectedOutageEndDate
                                }
                                onChange={(e) => {
                                    setSelectedOutageEndDate(e.value);
                                    setIsOutageStartDateModified(true);
                                }}
                                minDate={selectedOutageStartDate}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-33 p-8">
                            <label style={{ marginBottom: 4 }} className="aq-formlabel">
                                Name:&nbsp;
                                {loadingUnits ? <i>Loading…</i> : <span>{outageNameModified}</span>}
                            </label>
                            <InputText
                                className="aq-formcontrol"
                                ref={editOutageNameRef}
                                style={{ maxHeight: 32, width: '100%', display: 'block' }}
                                placeholder="Enter suffix"
                                value={selectedSuffix}
                                disabled={loadingUnits}
                                onChange={(e) => setSelectedSuffix(e.target.value)}
                            />
                        </div>
                    </div>
                </>
            </Dialog>
            <>
                <ReadPropertiesDialog
                    selectedCellProperties={selectedCellProperties}
                    onCancel={handleShowCellPropertiesClose}
                    isCalledFromMntnSchedular={true}
                />
            </>
        </div>
    );
}
