import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useRef, useState } from 'react';
import { ScribbleEvent } from '../../event/ScribbleEvent';
import { Coord } from '../../utils/Coord';
import './ScribbleContextBar.scss';

export default function ScribbleContextBar({
    color,
    thickness,
    onChangeColor = () => {},
    onChangeThickness = () => {},
    hidden = false,
}) {
    const [removed, setRemoved] = useState(false);

    const scribblePreviewRef = useRef();

    useEffect(() => {
        if (hidden) {
            setTimeout(() => {
                setRemoved(true);
            }, 1000);
        } else {
            setRemoved(false);
        }
    }, [hidden]);

    useEffect(() => {
        if (!hidden && scribblePreviewRef.current != null) {
            var ctx = scribblePreviewRef.current.getContext('2d');
            ctx.clearRect(0, 0, 45, 100);
            ctx.strokeStyle = color;
            ctx.lineWidth = thickness;
            ctx.lineCap = "round";
            let points = [new Coord(20, 12), new Coord(32, 25), new Coord(10, 66), new Coord(25, 90)];
            ctx.moveTo(points[0].x, points[0].y);
            for (var i = 1; i < points.length - 2; i++) {
                var xc = (points[i].x + points[i + 1].x) / 2;
                var yc = (points[i].y + points[i + 1].y) / 2;
                ctx.quadraticCurveTo(points[i].x, points[i].y, xc, yc);
            }
            ctx.quadraticCurveTo(points[i].x, points[i].y, points[i + 1].x, points[i + 1].y);
            ctx.stroke();
        }
    }, [scribblePreviewRef, color, thickness, hidden]);

    return (
        <div
            className={'aq-floating-panel scribble-context-bar' + (hidden ? ' hide' : '')}
            style={{ visibility: removed ? 'hidden' : 'visible' }}
        >
            <div className="toolbox">
                <canvas ref={scribblePreviewRef} width={45} height={100} />
                <InputNumber
                    id="scribble-thickness-input"
                    showButtons
                    buttonLayout="vertical"
                    style={{ width: '48px' }}
                    inputStyle={{ borderRadius: 0 }}
                    suffix="px"
                    value={thickness}
                    onChange={(ev) => onChangeThickness(ev.value)}
                    min={ScribbleEvent.minThickness}
                    max={ScribbleEvent.maxThickness}
                />
                <div id="scribble-color-input" className="text-context-bar-color-container">
                    <div className={'color-palette-button' + (color === 'rgb(255,0,0)' ? ' active' : '')} style={{ backgroundColor: 'red' }} onClick={() => onChangeColor('rgb(255,0,0)')}></div>
                    <div className={'color-palette-button' + (color === 'rgb(0,0,255)' ? ' active' : '')} style={{ backgroundColor: 'blue' }} onClick={() => onChangeColor('rgb(0,0,255)')}></div>
                    <div className={'color-palette-button' + (color === 'rgb(0,255,0)' ? ' active' : '')} style={{ backgroundColor: 'green' }} onClick={() => onChangeColor('rgb(0,255,0)')}></div>
                    <div className={'color-palette-button' + (color === 'rgb(0,0,0)' ? ' active' : '')} style={{ backgroundColor: 'black' }} onClick={() => onChangeColor('rgb(0,0,0)')}></div>
                    <div className={'color-palette-button' + (color === 'rgb(0,0,80)' ? ' active' : '')} style={{ backgroundColor: '#000050' }} onClick={() => onChangeColor('rgb(0,0,80)')}></div>
                </div>
            </div>
            <Tooltip target='#scribble-thickness-input' position='left'>
              Scribble Thickness
            </Tooltip>
            <Tooltip target='#scribble-color-input' position='left' at='left-11 center'>
              Scribble Color
            </Tooltip>
        </div>
    );
}
