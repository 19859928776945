import { useDispatch, useSelector } from 'react-redux';
import { setTool } from '../../redux/reducer/ToolReducer';
import { ActionType } from '../../utils/Action';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

export default function ToolsBarInspectionFlatout({ disabled = false, transforming = false }) {
const dispatch = useDispatch();
const selectedTool = useSelector((state)=> state.tool.tool)

function setSelected(val){
    if(disabled) return;
    dispatch(setTool(val));
}
   
return(
    <div className='aq-floating-panel'>
      <div
        className={'toolbox' + (disabled ? ' disabled' : '')}
        style={{
          left: disabled ? -71 : 8,
          opacity: disabled ? 0 : 1,
          pointerEvents: disabled ? "none" : "auto",
        }}
      >
        <Button
            icon="tool-icon tool-icon-transform"
            id='transform-tool'
            className={selectedTool === ActionType.TRANSFORM ? 'active' : ''}
            onClick={() => setSelected(ActionType.TRANSFORM)}
        />
        <Button
          icon="tool-icon tool-icon-select"
          id='select-tool'
          className={selectedTool === ActionType.SELECT ? 'active' : ''}
          onClick={() => setSelected(ActionType.SELECT)}
        />
        <Button
          icon="tool-icon tool-icon-paint-cell"
          id='paint-cell-tool'
          disabled={transforming}
          className={selectedTool === ActionType.FILLCELL ? 'active' : ''}
          onClick={() => setSelected(ActionType.FILLCELL)}
        />
        <Button
            icon="tool-icon tool-icon-paint-region"
            id='paint-region-tool'
            disabled={transforming}
            className={selectedTool === ActionType.FILLREGION ? 'active' : ''}
            onClick={() => setSelected(ActionType.FILLREGION)}
        />
        <Button
            icon="tool-icon tool-icon-text"
            id='text-tool'
            disabled={transforming}
            className={selectedTool === ActionType.TEXT ? 'active' : ''}
            onClick={() => setSelected(ActionType.TEXT)}
        />
         <Button
            icon="tool-icon tool-icon-scribble"
            id='scribble-tool'
            disabled={transforming}
            className={selectedTool === ActionType.SCRIBBLE ? 'active' : ''}
            onClick={() => setSelected(ActionType.SCRIBBLE)}
        />
        <Button
            icon="tool-icon tool-icon-erase"
            id='delete-tool'
            disabled={transforming}
            className={selectedTool === ActionType.ERASE ? 'delete active' : ''}
            onClick={() => setSelected(ActionType.ERASE)}
        />
      </div> 
      <Tooltip target='#transform-tool' position='right'>
        {'Pan & Zoom '}<span className="accel-label">W</span>
      </Tooltip>
      <Tooltip target='#select-tool' position='right'>
        Select <span className="accel-label">S</span>
      </Tooltip>
      <Tooltip target='#text-tool' position='right'>
        Add Text <span className="accel-label">T</span>
      </Tooltip>
      <Tooltip target='#scribble-tool' position='right'>
        Scribble <span className="accel-label">L</span>
      </Tooltip>
      <Tooltip target='#paint-cell-tool' position='right'>
        Paint Cell <span className="accel-label">C</span>
      </Tooltip>
      <Tooltip target='#paint-region-tool' position='right'>
        Paint Region <span className="accel-label">R</span>
      </Tooltip>
      <Tooltip target='#delete-tool' position='right'>
        Erase <span className="accel-label">X</span>
      </Tooltip> 
    </div>
)


}
