import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        setUser: (state, action) => {
            state.name = action.payload.name;
            state.role = action.payload.role;
            state.id = action.payload.id;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;