import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '../../utils/Action';

export const toolActionChange = createSlice({
    name: 'toolchange',
    initialState: {
        tool : ActionType.TRANSFORM,
    },
    
    reducers: {
        setTool: (state, action) => {
            state.tool = action.payload;
        },
     
    },
});

export const { setTool } = toolActionChange.actions;
export default toolActionChange.reducer;