import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
    name:'modal',
    initialState:{
        visible : false,
        data:{},
    },
    reducers:{
        setVisible:(state,action) =>{
            state.visible=action.payload;
        },
        setData:(state,action)=>{
            state.data=action.payload;
        },
    },
});


export const { setVisible, setData } = modalSlice.actions;
export default modalSlice.reducer;