import { createSlice } from '@reduxjs/toolkit';

export const hierarchySlice = createSlice({
    name: 'hierarchy',
    initialState: {
        siteId: 0,
        siteUnitId: 0,
        vesselId: 0,
        outageId: 0,
        templateVersion: -1,
        revisionVersion: -1,
        outageStartYearMonth: { year: 0, month: 0 },
    },
    reducers: {
        setSiteId: (state, action) => {
            state.siteId = action.payload;
        },
        setUnitId: (state, action) => {
            state.siteUnitId = action.payload;
        },
        setVesselId: (state, action) => {
            state.vesselId = action.payload;
        },
        setOutageId: (state, action) => {
            state.outageId = action.payload;
        },
        setTemplateVersion: (state, action) => {
            state.templateVersion = action.payload;
        },
        setRevisionVersion: (state, action) => {
            state.revisionVersion = action.payload;
        },
        setOutageStartYearMonth: (state, action) => {
            state.outageStartYearMonth = action.payload

        },
    },
});

export const { setSiteId, setUnitId, setVesselId, setOutageId, setTemplateVersion, setRevisionVersion, setOutageStartYearMonth } =
    hierarchySlice.actions;
export default hierarchySlice.reducer;
