import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tree } from 'primereact/tree';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import './ZoneWizard.scss';

const ZoneWizard = React.forwardRef(
    ({ visible = true, onHide = () => {}, zones = [], onChangeZones = () => {}, onSave = () => {}, disableSaving = false }, ref) => {
        const [zoneTree, setZoneTree] = useState([]);
        const [expandedKeys, setExpandedKeys] = useState({});
        const [highlightNodes, setHighlightNodes] = useState(false);
        const [highlightInterNodes, setHighlightInterNodes] = useState(false);
        const [changed, setChanged] = useState(false);

        useImperativeHandle(ref, () => ({
            resetTree
        }));

        const resetTree = () => {
            let processedZones = processData(zones);
            setZoneTree(processedZones);
            setChanged(false);
        }

        const processData = (zones, parentZoneId = null) => {
            var result = [];
            for (const zone of zones) {
                if (zone.parentZoneId === parentZoneId) {
                    const children = processData(zones, zone.id);
                    if (children.length > 0) {
                        zone.children = children;
                    }

                    result.push(zone);
                }
            }

            return result;
        };

        useEffect(() => {
            expandAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [zoneTree]);

        useEffect(() => {
            if (zoneTree.length === 0) {
                let processedZones = processData(zones);
                setZoneTree(processedZones);
                setChanged(false);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [zones, setZoneTree]);

        const expandAll = () => {
            let _expandedKeys = {};

            for (let zone of zoneTree) {
                expandNode(zone, _expandedKeys);
            }

            setExpandedKeys(_expandedKeys);
        };

        const handeDragDrop = (/** @type {TreeDragDropEvent} */ event) => {
            if (event) {
                setZoneTree(event.value);
                moveNodes(event.dragNode, event.dropNode);
            }
        };

        const moveNodes = (dragNode, dropNode) => {
            let _previousZones = [...zones];

            if (dropNode && dropNode !== null)
                _previousZones[dragNode.key].parentZoneId = _previousZones[dropNode.key].id;
            else _previousZones[dragNode.key].parentZoneId = null;

            for (let i = 0; i < _previousZones.length; i++) {
                var hasChild = false;
                for (let j = 0; j < _previousZones.length; j++) {
                    if (_previousZones[j].parentZoneId === _previousZones[i].id) {
                        hasChild = true;
                    }
                }

                _previousZones[i].isParent = hasChild;
            }

            setChanged(true);

            onChangeZones([..._previousZones]);
        };

        const expandNode = (zone, _expandedKeys) => {
            if (zone.children && zone.children.length) {
                _expandedKeys[zone.key] = true;

                for (let child of zone.children) {
                    expandNode(child, _expandedKeys);
                }
            }
        };

        const zoneTemplate = (zone, option) => {
            return (
                <span key={zone.key} className={option.className}>
                    {zone.name}
                </span>
            );
        };

        const togglerTemplate = (zone, options) => {
            if (!zone) {
                return;
            }

            return <></>;
        };

        const highlightSection = (e) => {
            if (e.target.id === '0') {
                setHighlightNodes(e.type === 'mouseenter');
            } else {
                setHighlightInterNodes(e.type === 'mouseenter');
            }
        };

        return (
            <Dialog
                header="Setup Zone Hierarchy"
                className="aq-dialog"
                maskClassName='aq-dialog-backdrop'
                closeIcon='pi pi-times'
                visible={visible}
                style={{ width: '80vw', height: '80vw' }}
                onHide={() => onHide()}
            >
                <div className="row" style={{ height: '100%' }}>
                    <div className="col-50 zone-tree-container">
                        {zoneTree.length > 0 && (
                            <Tree
                                value={zoneTree}
                                nodeTemplate={zoneTemplate}
                                togglerTemplate={togglerTemplate}
                                dragdropScope="zones-swap"
                                onDragDrop={handeDragDrop}
                                expandedKeys={expandedKeys}
                                onToggle={(e) => {}}
                                className={
                                    highlightNodes
                                        ? 'highlight-nodes'
                                        : highlightInterNodes
                                        ? 'highlight-internodes'
                                        : ''
                                }
                                style={{ height: '100%', marginRight: '24px', overflowY: 'auto' }}
                            />
                        )}
                    </div>
                    <div className="col-50 zone-wizard-details">
                        <h1>
                            Please check if the zones on the left are arranged properly. This will impact area
                            calculation.
                        </h1>
                        <ul>
                            <li
                                className="zone-wizard-details-points"
                                id="0"
                                onMouseEnter={highlightSection}
                                onMouseLeave={highlightSection}
                                style={{ color: highlightNodes ? 'rgb(177, 135, 0)' : '#333' }}
                            >
                                Drag zones in the list on the left to rearrange the hierarchy. A zone which appears
                                inside another zone may be dragged inside it.
                            </li>
                            <li
                                className="zone-wizard-details-points"
                                id="1"
                                onMouseEnter={highlightSection}
                                onMouseLeave={highlightSection}
                                style={{ color: highlightInterNodes ? 'rgb(177, 135, 0)' : '#333' }}
                            >
                                Conversely, if a zone is independent of a parent, drag it outside the parent item (or in
                                between the items)
                            </li>
                        </ul>
                        <div style={{ position: 'absolute', right: 24, bottom: 24 }}>
                            <Button
                                label="Done"
                                className="aq-btn aq-primary"
                                icon="pi pi-check"
                                disabled={disableSaving}
                                onClick={() => {
                                    onSave(changed);
                                    setChanged(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
);

export default ZoneWizard;
