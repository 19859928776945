// This class stores all the common annotation properties of both text and scribble events
export class AnnotationProperties {
    /** @type {String}CSS Color Value */
    c ='';
    /** @type {int}Size of text or thickness of scribble */
    s = 0;
    /** @type {Array}Rect Start */
    rs = [];
    /** @type {Array} Rect End */
    re = [];

    constructor(colorValue, sizeOfText, rectStart, rectEnd) {
        this.c = colorValue;
        this.s = sizeOfText;
        this.rs = rectStart;
        this.re = rectEnd;
    }
}
