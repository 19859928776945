import './App.scss';
import HeaderBar from './components/headerBar/HeaderBar';
import { HashRouter } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import RequestInterceptor from './interceptors/RequestInterceptor';
import AppRoutes from './routes/AppRoutes';
import SideBar from './components/sideBar/SideBar';
import { useEffect, useState } from 'react';
import Splash from './components/splash/Splash';
import AlertModal from './components/alertModal/AlertModal';
import { showLoading } from './utils/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { increment } from './redux/reducer/TimeReducer';
import { setNetworkConnectionStatus } from './redux/reducer/NetworkConnectionReducer';
import { enterFullscreenMode, exitFullscreenMode } from './redux/reducer/FullScreenReducer';

function App() {
    const { instance, accounts, inProgress } = useMsal();
    const dispatch = useDispatch();
    let _ms = 0;

    const handleNetworkConnectionStatusChange=()=>{
        dispatch(setNetworkConnectionStatus(navigator.onLine))
    }

    if (accounts.length <= 0 && inProgress === 'none') {
        instance.loginRedirect();
    }

    useEffect(() => {
        window.addEventListener("online",handleNetworkConnectionStatusChange)
        window.addEventListener("offline",handleNetworkConnectionStatusChange)
        showLoading(true);
        const interval = setInterval(() => {
            ++_ms;
            dispatch(increment(_ms));
        }, 200000);
        return () => {
            clearInterval(interval);
            window.removeEventListener("online",handleNetworkConnectionStatusChange)
            window.removeEventListener("offline",handleNetworkConnectionStatusChange)
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [path, setPath] = useState('');

    const fullscreen = useSelector((state) => state.fullscreen.fullscreen);
 
    useEffect(() => {
        if (document) {
            if (fullscreen) {
                document.body.requestFullscreen();
            } else if (document.fullscreenElement) {
                document.exitFullscreen();
            }
        }
    }, [fullscreen]);

    useEffect(() => {
        document.onfullscreenchange = () => {
            if (!document.fullscreenElement) {
                dispatch(exitFullscreenMode());
            }else{
                dispatch(enterFullscreenMode())
            }
        };

        window.onresize = () => {
            var maxHeight = window.screen.height,
            curHeight = window.innerHeight * window.devicePixelRatio;

            if (maxHeight === curHeight) {
                dispatch(enterFullscreenMode());
            }
        }
    }, [dispatch]);
 

    return (
        <div className="App">
            {accounts.length > 0 && inProgress === 'none' && (
                <RequestInterceptor>
                    {!fullscreen && <HeaderBar title="Refractory Management System" titleSmall="RMS" subtitle="Flatout" />}
                    {!fullscreen && <SideBar currentPath={path} />}
                    <HashRouter>
                        <AppRoutes onRouteChange={setPath} />
                    </HashRouter>
                    <Splash />
                    <ConfirmDialog
                        className="aq-dialog"
                        maskClassName="aq-dialog-backdrop"
                        acceptClassName="aq-btn aq-primary"
                        rejectClassName="aq-btn"
                    />
                </RequestInterceptor>
            )}
            <AlertModal />
        </div>
    );
}

export default App;
