// This class stores all the Cell properties as a single object
export class CellProperties {
    /** @type {int}DamageLevel */
    dl = 0;
    /** @type {int}DamageRate */
    dr = 0;
    /** @type {positiveInt}MaintenanceScope */
    ms = 1;
    /** @type {positiveInt}DamageType */
    dt = 1;

    constructor(damageLevel, damageRate, maintenanceScope, damageType) {
        this.dl = damageLevel;
        this.dr = damageRate;
        this.ms = maintenanceScope;
        this.dt = damageType;
    }
}
