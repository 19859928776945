import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Avatar } from 'primereact/avatar';
import { useEffect, useState } from 'react';
import { showLoading } from '../../utils/Loading';
export default function ThemeDemo() {
    const [animating, setAnimating] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const openSidebar = () => {
        setAnimating(true);
        setTimeout(() => {
            setAnimating(false);
        }, 500);

        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        setTimeout(() => {
            showLoading(false);
        }, 1000);
    }, [])

    return (
        <div className="container-fluid p-8" style={{ overflowY: 'auto', height: '100%' }}>
            <h1 style={{ fontSize: 'x-large', color: 'royalblue' }}>Aqua Theme</h1>
            <hr />
            <h2>
                <u>Text</u>
            </h2>
            <h1>H1 Lorem Ipsum</h1>
            <h2>H2 Lorem Ipsum</h2>
            <h3>H3 Lorem Ipsum</h3>
            <div className="text1">Text 1 Lorem Ipsum</div>
            <div className="text2">Text 2 Lorem Ipsum</div>
            <hr />
            <h2>
                <u>Buttons</u>
            </h2>
            <button className="aq-btn">Normal</button>&nbsp;
            <button className="aq-btn aq-primary">Primary</button>&nbsp;
            <button className="aq-btn aq-suggestive">Suggestive</button>&nbsp;
            <button className="aq-btn aq-secondary">Secondary</button>&nbsp;
            <button className="aq-btn aq-destructive">Destructive</button>
            <br />
            <br />
            <Button className="aq-btn" label="Prime Button" icon="pi pi-save"></Button>&nbsp;
            <Button className="aq-btn aq-rounded" label="Prime Button" icon="pi pi-save"></Button>&nbsp;
            <Button className="aq-btn aq-suggestive" label="Prime Button" icon="pi pi-save"></Button>
            <hr />
            <h2>
                <u>Dropdowns</u>
            </h2>
            <select>
                <option>Normal</option>
                <option>Lorem Ipsum</option>
            </select>
            &nbsp;
            <Dropdown className="aq-select" options={['Prime', 'Lorem Ipsum']}></Dropdown>&nbsp;
            <Dropdown className="aq-select" options={['Prime', 'Lorem Ipsum']} filter showClear></Dropdown>
            <hr />
            <h2>
                <u>Header Bar</u>
            </h2>
            <div style={{ height: 200 }}>
                <div className="aq-headerbar">
                    <div className="row">
                        <div className="col-auto"></div>
                        <div className="col-50" style={{ textAlign: 'center' }}>
                            <span className="logo-small"></span>
                            <span className="title">
                                <span className='title-main'>Refactory Management System</span>
                                <span className='title-small'>RMS</span>&nbsp;
                                <span style={{ fontWeight: 'lighter', opacity: 0.75 }}>Flatout</span>
                            </span>
                        </div>
                        <div className="col-25">
                            <div className="aq-avatar-container">
                                <Avatar className="aq-avatar-profile-pic" label="SJ" shape="circle" />
                                <div className="aq-avatar-info">
                                    <div className="aq-avatar-info-greeting">Welcome</div>
                                    <div className="aq-avatar-info-name">Jasu, Subhadeep</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'aq-nav ' + (sidebarOpen ? '' : 'hide')}>
                    <div className="aq-nav-current">
                        <Button
                            className={'aq-nav-current-btn ' + (animating ? 'animate' : '')}
                            icon="pi pi-bars"
                            onClick={openSidebar}
                        ></Button>
                        <span className="aq-nav-current-title">Page Title</span>
                    </div>
                    <nav>
                        <a href="/theme">
                            <i className="aq-nav-icon pi pi-upload" />
                            Upload Templates
                        </a>
                        <a href="/theme" className="active">
                            <i className="aq-nav-icon pi pi-upload" />
                            Inspection Flatouts
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    );
}
