import { useEffect, useRef, useState } from 'react';
import { PresentationMode } from '../../config/Constants';
import './PresentationModeSwitcher2.scss';

/**
 * Presentation Mode Switcher for Flatout drawings
 */
export default function PresentationModeSwitcher2({
    presentationModes = [PresentationMode.DMG_LEVEL, PresentationMode.DMG_TYPE],
    selectedMode = PresentationMode.DMG_LEVEL,
    onSelectMode = () => {},
    disabled = false,
}) {
    const viewModes = [];

    for (let i = 0; i < presentationModes.length; i++) {
        switch (presentationModes[i]) {
            case PresentationMode.DMG_LEVEL:
                viewModes.push('Wear Level');
                break;
            case PresentationMode.DMG_TYPE:
                viewModes.push('Damage Type');
                break;
            case PresentationMode.MNTN_SCOPE:
                viewModes.push('Mntn Scope');
                break;
            case PresentationMode.DMG_RATE:
                viewModes.push('Wear Rate');
                break;
            default:
        }
    }

    return (
        <div className={'presentation-mode-switcher' + (disabled ? ' disabled' : '')}>
            {viewModes.map((item, i) => {
                    return (
                        <div
                            id={'mode-' + presentationModes[i]}
                            className={'presentation-mode-switcher-item' + (selectedMode === presentationModes[i] ? ' active' : '')}
                            key={i}
                            onClick={() => {
                                onSelectMode(presentationModes[i]);
                                // navigator.vibrate(200);
                            }}
                           
                        >
                            {item}
                        </div>
                    );
                })
            }
        </div>
    )
}