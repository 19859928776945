import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from 'react';
import { Button } from 'primereact/button';
import CustomDropdown from '../dropdown/CustomDropdown';
import { Timeline } from 'primereact/timeline';
import { Menu } from 'primereact/menu';
import {
    getSites,
    getUnits,
    getVessels,
    getOutages,
    getTemplateVersions,
    getHistory,
} from '../../services/FlatoutDataAPI';
import { useSearchParams } from 'react-router-dom';

import './TopPanelInspectionFlatout.scss';
import { showModal } from '../../modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import Moment from 'moment';
import {
    setOutageId,
    setOutageStartYearMonth,
    setRevisionVersion,
    setSiteId,
    setTemplateVersion,
    setUnitId,
    setVesselId,
} from '../../redux/reducer/HierarchyReducer';
import { ContextLockAPI } from '../../services/ContextLockAPI';
import IdleTimer from '../idleTimer/IdleTimer';
import { Dialog } from 'primereact/dialog';
import { StaticStates } from '../../utils/StaticeStates';
import { confirmDialog } from 'primereact/confirmdialog';
import { StatusPill } from '../statusPill/StatusPill';
import { setTool } from '../../redux/reducer/ToolReducer';
import { ActionType } from '../../utils/Action';
import { setLoadingBlocker } from '../../redux/reducer/LoadingBlockerReducer';

const TopPanelInspectionFlatout = forwardRef(
    (
        {
            disabled,
            onActionClick = () => {},
            draw,
            print,
            onUndo = () => {},
            onRedo = () => {},
            undoEnabled = false,
            redoEnabled = false,
            onClickSave = () => {},
            onClickSaveAndEstimate = () => {},
            onClickEstimate = () => {},
            locked, // Locked by me not the other user
            onSetLock = () => {},

            children = <></>,
        },
        ref
    ) => {
        const [sites, setSites] = useState([]);
        const [units, setUnits] = useState([]);
        const [vessels, setVessels] = useState([]);
        const [searchParams, setSearchParams] = useSearchParams();
        const [versions, setVersions] = useState([]);
        const [history, setHistory] = useState([]);
        const [showHistory, setShowHistory] = useState(false);
        const [outages, setOutages] = useState([]);
        const [loadingSites, setLoadingSites] = useState(false);
        const [loadingUnits, setLoadingUnits] = useState(false);
        const [loadingVessels, setLoadingVessels] = useState(false);
        const [loadingOutages, setLoadingOutages] = useState(false);
        const [loadingVersions, setLoadingVersions] = useState(false);
        const [loadingLock, setLoadingLock] = useState(false);
        const [lockedBy, setLockedBy] = useState(null);
        const [showLockDetails, setShowLockDetails] = useState(false);
        const hierarchy = useSelector((state) => state.hierarchy);
        let dispatch = useDispatch();
        const CurrentUser = useSelector((state) => state.user);
        const milliScecondsElapsed = useSelector((state) => state.timeClock.ms);
        const fullscreen = useSelector((state) => state.fullscreen.fullscreen);
        const isOnline = useSelector((state) => state.networkConnectionStatus.isOnline);
        const loadingBlocker = useSelector((state) => state.loadingblocker.loadingblocker);

        const saveMenuRef = useRef();
        let saveMenuItems = [
            {
                label: 'Save',
                icon: 'pi pi-save',
                command: () => onClickSave(),
                disabled: history.length === 0 || !undoEnabled,
            },
            {
                label:
                    history.length === 0 || !undoEnabled
                        ? 'Estimate Refractory Materials'
                        : 'Save & Estimate Refractory Materials',
                icon: 'pi pi-dollar',
                command: () => {
                    if (history.length > 0 && undoEnabled) {
                        onClickSaveAndEstimate();
                    } else {
                        onClickEstimate();
                    }
                },
            },
        ];

        /**
         * This useEffect function render once the page loaded and fetch site data.
         * dropdown button will be disable when page is loading and offline scenario.
         */
        useEffect(() => {
            async function fetchSiteData() {
                // showLoading(true);
                setLoadingSites(true);
                try {
                    if (isOnline) {
                        let sites = await getSites(true);
                        let tempSites = [];
                        sites.map(function (site) {
                            return tempSites.push({ key: site.id, value: site.name });
                        });
                        setSites(tempSites);
                        tempSites.length <= 0 &&
                            showModal({
                                title: 'No Flatouts',
                                message:
                                    'There are no published flatouts. Publish a flatout in Uploader to view it here.',
                                class: 'warning',
                            });
                        if (tempSites.map((v) => v.key).includes(parseInt(searchParams.get('siteId')))) {
                            dispatch(setSiteId(parseInt(searchParams.get('siteId'))));
                            if (
                                searchParams.get('unitId') == null &&
                                searchParams.get('vesselId') == null &&
                                searchParams.get('outageId') == null
                            ) {
                                window.history.replaceState({}, document.title, '/#/');
                                searchParams.forEach((value, key) => {
                                    searchParams.delete(key);
                                });
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoadingSites(false);
                }
            }
            fetchSiteData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, searchParams]);

        /**
         * This function fetching the units data when the user click over the drop-down.
         * dropdown button will be disable when page is loading and offline scenario.
         */

        function fetchUnits() {
            (async function () {
                setLoadingUnits(true);
                dispatch(setLoadingBlocker(true));
                try {
                    if (isOnline) {
                        let units = await getUnits(hierarchy.siteId, true);
                        let tempUnits = [];
                        units.map(function (unit) {
                            return tempUnits.push({ key: unit.unitId, value: unit.unitName });
                        });
                        setUnits(tempUnits);
                        if (tempUnits.map((v) => v.key).includes(parseInt(searchParams.get('unitId')))) {
                            dispatch(setUnitId(parseInt(searchParams.get('unitId'))));
                            if (searchParams.get('vesselId') == null) {
                                window.history.replaceState({}, document.title, '/#/');
                                searchParams.forEach((value, key) => {
                                    searchParams.delete(key);
                                });
                            }
                        } else if (parseInt(searchParams.get('siteId'))) {
                            showModal({
                                title: 'Unit required',
                                message: 'Please select an unit to continue!',
                                class: 'warning',
                            });
                        }
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoadingUnits(false);
                    dispatch(setLoadingBlocker(false));
                }
            })();
        }

        /**
         * This function fetching the vessels data when the user click over the drop-down.
         * dropdown button will be disable when page is loading and offline scenario.
         */

        function fetchVessels() {
            (async function () {
                setLoadingVessels(true);
                dispatch(setLoadingBlocker(true));
                try {
                    if (isOnline) {
                        let vessels = await getVessels(hierarchy.siteUnitId, true);
                        let tempVessels = [];
                        vessels.map(function (vessel) {
                            return tempVessels.push({ key: vessel.id, value: vessel.name });
                        });
                        setVessels(tempVessels);
                        tempVessels.length <= 0 &&
                            showModal({
                                title: 'No Vessels',
                                message: 'The selected unit has no vessels.',
                                class: 'warning',
                            });
                        if (tempVessels?.map((v) => v.key).includes(parseInt(searchParams.get('vesselId')))) {
                            dispatch(setVesselId(parseInt(searchParams.get('vesselId'))));
                            window.history.replaceState({}, document.title, '/#/');
                            searchParams.forEach((value, key) => {
                                searchParams.delete(key);
                            });
                            searchParams.delete('unitId');
                        } else if (parseInt(searchParams.get('outageId')))
                            showModal({
                                title: 'Vessel required',
                                message: 'Please select a vessel to continue!',
                                class: 'warning',
                            });
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoadingVessels(false);
                    dispatch(setLoadingBlocker(false));
                }
            })();
        }

        /**
         * This function fetching the outages dates when the user click over the drop-down.
         * dropdown button will be disable when page is loading and offline scenario.
         */

        function fetchOutages() {
            let tempArray = [];
            (async function () {
                setLoadingOutages(true);
                dispatch(setLoadingBlocker(true));
                try {
                    if (isOnline) {
                        let outages = await getOutages(hierarchy.siteUnitId);
                        outages
                            .filter((outage) => !outage.value.includes('Nominal Lining'))
                            .map((filteredOutage) => {
                                return tempArray.push(filteredOutage);
                            });
                        setOutages(tempArray);
                        if (outages.length <= 0)
                            showModal({
                                title: 'No outages',
                                message:
                                    'There is no outages available. Please setup outages in [Outage Setup > Outages] in RMS.',
                                class: 'warning',
                            });
                        if (outages.map((v) => v.key).includes(parseInt(searchParams.get('outageId')))) {
                            dispatch(setOutageId(parseInt(searchParams.get('outageId'))));
                            if (searchParams.get('vesselId') == null) {
                                window.history.replaceState({}, document.title, '/#/');
                                searchParams.forEach((value, key) => {
                                    searchParams.delete(key);
                                });
                            }
                        } else if (parseInt(searchParams.get('unitId')))
                            showModal({
                                title: 'Outage',
                                message: 'Please select an outage to continue!',
                                class: 'warning',
                            });
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoadingOutages(false);
                    dispatch(setLoadingBlocker(false));
                }
            })();
        }

        /**
         * This function fetching the history when the user click over the drop-down.
         * dropdown button will be disable when page is loading and offline scenario.
         */

        function fetchHistory() {
            (async function () {
                setLoadingVersions(true);
                dispatch(setLoadingBlocker(true));
                try {
                    if (isOnline) {
                        let result = await getHistory(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.outageId);
                        setHistory(result);
                        let highestTemplateVersion = result[0].templateVersion;

                        var requiredVersions = result.filter((x) => x.templateVersion === highestTemplateVersion);
                        var versionStrings = [];

                        for (let i = 0; i < requiredVersions.length; i++) {
                            versionStrings.push({
                                id: requiredVersions[i].revisionVersion,
                                key: requiredVersions[i].revisionVersion,
                                name: requiredVersions[i].templateVersion + '.' + requiredVersions[i].revisionVersion,
                                value: requiredVersions[i].templateVersion + '.' + requiredVersions[i].revisionVersion,
                                templateVersion: requiredVersions[i].templateVersion,
                            });
                        }

                        setVersions(versionStrings);
                        dispatch(setTemplateVersion(requiredVersions[0].templateVersion));
                        dispatch(setRevisionVersion(versionStrings[0].id));
                    }
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoadingVersions(false);
                    dispatch(setLoadingBlocker(false));
                }
            })();
        }

        useEffect(() => {
            setHistory([]);
            if (hierarchy.outageId) {
                fetchHistory();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy.outageId]);

        useEffect(() => {
            setHistory([]);
            if (hierarchy.siteId) {
                fetchUnits();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy.siteId]);

        useEffect(() => {
            setHistory([]);
            if (hierarchy.siteUnitId) {
                fetchVessels();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy.siteUnitId]);

        useEffect(() => {
            setHistory([]);
            if (hierarchy.vesselId) {
                fetchOutages();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy.vesselId]);

        useImperativeHandle(ref, () => ({
            fetchHistory,
        }));

        //handle site change
        const handleChangeSite = (siteId) => {
            dispatch(setSiteId(siteId));
            dispatch(setUnitId(0));
            dispatch(setOutageId(0));
            dispatch(setVesselId(0));
            dispatch(setTemplateVersion(-1));
            dispatch(setRevisionVersion(-1));
        };

        //handle unit change
        const handleChangeUnit = (unitId) => {
            dispatch(setUnitId(unitId));
            dispatch(setOutageId(0));
            dispatch(setVesselId(0));
            dispatch(setTemplateVersion(-1));
            dispatch(setRevisionVersion(-1));
        };

        //handle vessel change
        const handleChangeVessel = (vesselId) => {
            dispatch(setVesselId(vesselId));
            dispatch(setOutageId(0));
            dispatch(setTemplateVersion(-1));
            dispatch(setRevisionVersion(-1));
        };

        //handle Outage change
        const handleChangeOutage = (outageId) => {
            dispatch(setOutageId(outageId));
            let requiredOutage = outages.find((outage) => outage.key === outageId);
            extractMonthYearFromDate(requiredOutage.outageStartDate);
            dispatch(setTemplateVersion(-1));
            dispatch(setRevisionVersion(-1));
        };

        // handle version change
        const handleChangeVersion = (version) => {
            var _version = versions[0];
            dispatch(setTemplateVersion(_version.templateVersion));
            dispatch(setRevisionVersion(version));
        };
        const extractMonthYearFromDate = (/**@type {string} */ date) => {
            const parsedDate = new Date(date);
            dispatch(setOutageStartYearMonth({ year: parsedDate.getFullYear(), month: parsedDate.getMonth() }));
        };
        function idleTimerHandler(time) {
            if (time === 0) {
                sessionStorage.setItem('idleTimer', '');
            } else {
                sessionStorage.setItem('idleTimer', time);
            }
        }
        /**
         * This function is used to lock a particular image if there is any issue then error modal comes on the screen.
         */
        async function attemptLock() {
            try {
                return await ContextLockAPI.lock(
                    hierarchy.siteUnitId,
                    hierarchy.vesselId,
                    hierarchy.outageId,
                    'INSPECTOR'
                );
            } catch (e) {
                if (StaticStates.InspectorLockStatusAcquired) {
                    console.warn(e);
                } else {
                    console.error(e);
                    setLockedBy(null);
                    onSetLock(false);
                    showModal({
                        title: 'Critical Error',
                        message: 'Unable to acquire lock due to some unknown error',
                        class: 'error',
                    });
                }

                return false;
            }
        }
        /**
         * This function is used for checking the locking status and set the lock for particular image.
         */
        function inspectorLock() {
            setLoadingLock(true);
            attemptLock().then((lockResponse) => {
                if (isOnline) {
                    if (lockResponse) {
                        StaticStates.InspectorLockStatusAcquired = true;
                        setLoadingLock(false);
                        onSetLock(true);
                        StaticStates.InspectorLockTimerActive = true;
                        setLockedBy(null);
                        StaticStates.BackupHierarchy.siteUnitId = hierarchy.siteUnitId;
                        StaticStates.BackupHierarchy.vesselId = hierarchy.vesselId;
                        StaticStates.BackupHierarchy.outageId = hierarchy.outageId;
                    } else {
                        onSetLock(false);
                        StaticStates.InspectorLockTimerActive = false;
                        ContextLockAPI.getOwner(
                            hierarchy.siteUnitId,
                            hierarchy.vesselId,
                            hierarchy.outageId,
                            'INSPECTOR'
                        ).then((userId) => {
                            StaticStates.InspectorLockStatusAcquired = true;
                            setLoadingLock(false);
                            if (userId !== CurrentUser) {
                                console.warn('Already locked by ' + userId);
                                setLockedBy(userId);
                                confirmDialog({
                                    // {{username}} currently  has this flatout image open and therefore cannot be edited<i>{(${userId})}</i>
                                    message: `Another user (${userId}) currently  has this flatout image open and therefore cannot be edited.`,
                                    header: 'Flatout Locked',
                                    icon: 'pi pi-lock',
                                    className: 'aq-dialog aq-dialog-danger',
                                    maskClassName: 'aq-dialog-backdrop',
                                    style: { minWidth: 300, width: '40vw' },
                                    accept: async () => {
                                        try {
                                            inspectorLock();
                                        } catch (error) {}
                                    },
                                    acceptClassName: 'aq-btn aq-primary',
                                    acceptLabel: 'Try again',
                                    rejectLabel: 'Cancel',
                                    rejectClassName: 'aq-btn',
                                });
                            }
                        });
                    }
                }
            });
        }

        /**
         * This function is used for polling the status of image for every interval which is set by API if the user switch and refresh the page then after particular page will be unlock and avilable for all the user.
         */

        async function polling() {
            if (isOnline) {
                if (hierarchy.siteUnitId > 0 && hierarchy.vesselId > 0 && hierarchy.outageId > 0) {
                    if (
                        hierarchy.siteUnitId !== StaticStates.BackupHierarchy.siteUnitId ||
                        hierarchy.vesselId !== StaticStates.BackupHierarchy.vesselId ||
                        hierarchy.outageId !== StaticStates.BackupHierarchy.outageId
                    ) {
                        await ContextLockAPI.unlock(
                            StaticStates.BackupHierarchy.siteUnitId,
                            StaticStates.BackupHierarchy.vesselId,
                            StaticStates.BackupHierarchy.outageId,
                            'INSPECTOR'
                        ).then((response) => {
                            console.info('Unlocked Successfully');
                        });
                        StaticStates.BackupHierarchy.siteUnitId = hierarchy.siteUnitId;
                        StaticStates.BackupHierarchy.vesselId = hierarchy.vesselId;
                        StaticStates.BackupHierarchy.outageId = hierarchy.outageId;
                    }
                    await ContextLockAPI.lock(
                        StaticStates.BackupHierarchy.siteUnitId,
                        StaticStates.BackupHierarchy.vesselId,
                        StaticStates.BackupHierarchy.outageId,
                        'INSPECTOR'
                    ).then((lockSuccess) => {
                        if (!lockSuccess) {
                            onSetLock(false);
                            StaticStates.InspectorLockTimerActive = false;
                            ContextLockAPI.getOwner(
                                hierarchy.siteUnitId,
                                hierarchy.vesselId,
                                hierarchy.outageId,
                                'INSPECTOR'
                            ).then((userId) => {
                                StaticStates.InspectorLockStatusAcquired = true;
                                if (userId !== CurrentUser) {
                                    console.warn('Already locked by ' + userId);
                                    setLockedBy(userId);
                                    confirmDialog({
                                        //<i>{(${userId})}</i>
                                        message: `Another user (${userId}) currently  has this flatout image open and therefore cannot be edited.`,
                                        header: 'Flatout Locked',
                                        icon: 'pi pi-lock',
                                        className: 'aq-dialog aq-dialog-danger',
                                        maskClassName: 'aq-dialog-backdrop',
                                        style: { minWidth: 300, width: '40vw' },
                                        accept: async () => {
                                            try {
                                                inspectorLock();
                                            } catch (error) {}
                                        },
                                        acceptClassName: 'aq-btn aq-primary',
                                        acceptLabel: 'Try again',
                                        rejectLabel: 'Cancel',
                                        rejectClassName: 'aq-btn',
                                    });
                                }
                            });
                        } else {
                            StaticStates.InspectorLockStatusAcquired = true;
                        }
                    });
                } else {
                    await ContextLockAPI.unlock(
                        StaticStates.BackupHierarchy.siteUnitId,
                        StaticStates.BackupHierarchy.vesselId,
                        StaticStates.BackupHierarchy.outageId,
                        'INSPECTOR'
                    ).then((response) => {
                        console.info('Unlocked Successfully');
                        onSetLock(false);
                        setLockedBy(null);
                        StaticStates.InspectorLockTimerActive = false;
                    });
                }
            }
        }

        useEffect(() => {
            setLockedBy(null);
            StaticStates.InspectorLockStatusAcquired = false;
            if (hierarchy.siteUnitId > 0 && hierarchy.vesselId > 0 && hierarchy.outageId > 0) {
                if (!locked) {
                    inspectorLock();
                } else {
                    polling();
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hierarchy]);

        useEffect(() => {
            return () => {
                StaticStates.InspectorLockStatusAcquired = false;
                dispatch(setTool(ActionType.TRANSFORM));

                if (
                    StaticStates.BackupHierarchy.siteUnitId > 0 &&
                    StaticStates.BackupHierarchy.vesselId > 0 &&
                    StaticStates.BackupHierarchy.outageId > 0 &&
                    isOnline
                ) {
                    ContextLockAPI.unlock(
                        StaticStates.BackupHierarchy.siteUnitId,
                        StaticStates.BackupHierarchy.vesselId,
                        StaticStates.BackupHierarchy.outageId,
                        'INSPECTOR'
                    ).then((response) => {
                        console.info('Unlocked Successfully');
                        StaticStates.InspectorLockTimerActive = false;
                    });
                }
            };
        }, []);

        useEffect(() => {
            if (StaticStates.InspectorLockTimerActive) {
                polling();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [milliScecondsElapsed]);
        return (
            <div className="top-panel-inpection-flatout">
                <div className="row m-12 mb-0" style={{ display: fullscreen ? 'none' : 'flex' }}>
                    <div className="col-20 pe-14">
                        <CustomDropdown
                            label={'Site:'}
                            options={sites}
                            onChange={handleChangeSite}
                            placeholder="Select a site"
                            selected={hierarchy.siteId}
                            loading={loadingSites}
                            disabled={
                                sites.length === 0 ||
                                loadingUnits ||
                                loadingVessels ||
                                loadingOutages ||
                                loadingVersions ||
                                !isOnline ||
                                loadingBlocker
                            }
                            focus={sites.length !== 0}
                        />
                    </div>
                    <div className="col-20 pe-14">
                        <CustomDropdown
                            label={'Unit:'}
                            options={units}
                            onChange={handleChangeUnit}
                            placeholder="Select a unit"
                            selected={hierarchy.siteUnitId}
                            loading={loadingUnits}
                            disabled={
                                hierarchy.siteId === 0 ||
                                loadingVessels ||
                                loadingVersions ||
                                !isOnline ||
                                loadingBlocker
                            }
                            focus={units.length !== 0}
                        />
                    </div>

                    <div className="col-20 pe-14">
                        <CustomDropdown
                            label={'Vessel:'}
                            options={vessels}
                            onChange={handleChangeVessel}
                            placeholder="Select a vessel"
                            selected={hierarchy.vesselId}
                            loading={loadingVessels}
                            disabled={
                                hierarchy.siteUnitId === 0 ||
                                loadingOutages ||
                                loadingVersions ||
                                !isOnline ||
                                loadingBlocker
                            }
                            focus={vessels.length !== 0}
                        />
                    </div>

                    <div className="col-20 pe-14">
                        <CustomDropdown
                            label={'Outage: '}
                            options={outages}
                            onChange={handleChangeOutage}
                            placeholder="Select a outage"
                            selected={hierarchy.outageId}
                            loading={loadingOutages}
                            disabled={
                                hierarchy.siteUnitId === 0 ||
                                loadingVersions ||
                                outages.length === 0 ||
                                !isOnline ||
                                loadingBlocker
                            }
                            focus={outages.length !== 0}
                        />
                    </div>

                    <div className="col-20 ">
                        <CustomDropdown
                            label={'Version:'}
                            options={versions}
                            onChange={handleChangeVersion}
                            placeholder="Select a version"
                            selected={hierarchy.revisionVersion}
                            loading={loadingVersions}
                            disabled={hierarchy.outageId === 0 || versions.length === 0 || !isOnline || loadingBlocker}
                            focus={versions.length !== 0}
                        />
                    </div>
                </div>

                <div className="row m-12">
                    <div className="col-60">
                        <div className="row" style={{ minWidth: 220, gap: 14 }}>
                            <div
                                className={fullscreen ? 'col-30' : 'col-25'}
                                style={{ maxWidth: fullscreen ? 120 : 100, minWidth: fullscreen ? 120 : 80 }}
                            >
                                <div className="row" style={{ gap: 8 }}>
                                    {fullscreen && <div className="alcoa-logo-blue" style={{ flex: 'none' }} />}
                                    <div className="aq-group col-auto">
                                        <Button
                                            title="Undo last action. [Z]"
                                            icon="tool-icon tool-icon-undo"
                                            className="aq-btn"
                                            style={{ maxHeight: 38, minWidth: 12 }}
                                            disabled={!undoEnabled}
                                            onClick={onUndo}
                                        />
                                        <Button
                                            title="Redo what was undone. [Y]"
                                            icon="tool-icon tool-icon-redo"
                                            className="aq-btn"
                                            style={{ maxHeight: 38, minWidth: 12 }}
                                            disabled={!redoEnabled}
                                            onClick={onRedo}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={fullscreen ? 'col-70' : 'col-75'}>{children}</div>
                        </div>
                    </div>

                    <div className="col-40" style={{ textAlign: 'right' }}>
                        <div className="status-pill-panel">
                            {!isOnline && <StatusPill message="Offline" className="warning" />}
                            {lockedBy !== null && !loadingLock ? (
                                <StatusPill
                                    message="Locked"
                                    className="danger"
                                    action="Details"
                                    onActionActivate={() => {
                                        setShowLockDetails(true);
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {loadingLock && <StatusPill message="Acquiring Lock…" className="warning" />}
                        </div>
                        <Button
                            icon="pi pi-history"
                            title="Revision history"
                            className="aq-btn"
                            style={{ height: 32, width: 32, verticalAlign: 'middle' }}
                            onClick={() => setShowHistory(true)}
                            disabled={disabled || history.length === 0}
                        />
                        <Button
                            icon="pi pi-print"
                            title="Print"
                            className="aq-btn aq-primary m-12 mt-0 mr-0 mb-0"
                            style={{ height: 32, width: 32, verticalAlign: 'middle' }}
                            onClick={print}
                            disabled={disabled || history.length === 0 || loadingBlocker}
                        />
                        {StaticStates.InspectorLockStatusAcquired && isOnline && locked ? (
                            <>
                                <div
                                    className="aq-group m-12 mt-0 mr-0 mb-0 show-on-mobile"
                                    style={{ verticalAlign: 'middle' }}
                                >
                                    <Button
                                        icon="pi pi-save"
                                        title="Save changes in a new revision"
                                        className="aq-btn aq-suggestive"
                                        style={{ height: 32, maxWidth: 32, verticalAlign: 'middle' }}
                                        onClick={onClickSave}
                                        disabled={disabled || history.length === 0 || !undoEnabled}
                                    />
                                    <Button
                                        icon="pi pi-angle-down"
                                        className="aq-btn aq-suggestive"
                                        style={{ maxHeight: 32, maxWidth: 16, verticalAlign: 'middle' }}
                                        disabled={disabled}
                                        onClick={(event) => saveMenuRef.current.toggle(event)}
                                        aria-haspopup
                                    />
                                </div>
                                <div
                                    className="aq-group m-12 mt-0 mr-0 mb-0 dont-show-on-mobile"
                                    style={{ verticalAlign: 'middle' }}
                                >
                                    <Button
                                        icon="pi pi-save"
                                        label="Save"
                                        title="Save changes in a new revision"
                                        className="aq-btn aq-suggestive"
                                        style={{ height: 32, width: 75, verticalAlign: 'middle' }}
                                        onClick={onClickSave}
                                        disabled={disabled || history.length === 0 || !undoEnabled}
                                    />
                                    <Button
                                        icon="pi pi-angle-down"
                                        className="aq-btn aq-suggestive"
                                        style={{ maxHeight: 32, maxWidth: 16, verticalAlign: 'middle' }}
                                        disabled={disabled}
                                        onClick={(event) => saveMenuRef.current.toggle(event)}
                                        aria-haspopup
                                    />
                                </div>
                                <Menu
                                    model={saveMenuItems}
                                    popup
                                    ref={saveMenuRef}
                                    id="popup_menu_left"
                                    className="aq-menu"
                                />
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <IdleTimer
                    startTime={120}
                    onTimer={(time) => {
                        idleTimerHandler(time);
                    }}
                />
                <Dialog
                    visible={showHistory}
                    header="Revision History"
                    style={{ width: '50vw' }}
                    className="aq-dialog"
                    maskClassName="aq-dialog-backdrop"
                    onHide={() => setShowHistory(false)}
                    closeIcon="pi pi-times"
                >
                    <Timeline
                        value={history}
                        align="alternate"
                        style={{ width: '100%', marginTop: 14 }}
                        content={(item) => {
                            return (
                                <div style={{ marginTop: -8 }}>
                                    <h1
                                        style={{
                                            color:
                                                item.templateVersion === hierarchy.templateVersion &&
                                                item.revisionVersion === hierarchy.revisionVersion
                                                    ? '#165688'
                                                    : '#000',
                                        }}
                                    >
                                        {'v' + item.templateVersion + '.' + item.revisionVersion}
                                    </h1>
                                    <small>
                                        {item.revisionVersion === 0 ? `Published` : `Edited by: ${item.createdBy}`}
                                    </small>
                                    <h2>{'on ' + Moment(item.createdOn).format('LL')}</h2>
                                </div>
                            );
                        }}
                        marker={(item) => {
                            if (item.revisionVersion === 0) {
                                return (
                                    <div
                                        className="pi pi-angle-double-up"
                                        style={{ borderRadius: 50, background: '#03827E', padding: 8, color: '#fff' }}
                                    ></div>
                                );
                            }
                            return (
                                <div
                                    className="pi pi-pencil"
                                    style={{ borderRadius: 50, background: '#165688', padding: 8, color: '#fff' }}
                                ></div>
                            );
                        }}
                    />
                </Dialog>

                <Dialog
                    header="Flatout Locked"
                    className="aq-dialog aq-dialog-danger"
                    maskClassName="aq-dialog-backdrop"
                    visible={showLockDetails}
                    style={{ width: '50vw' }}
                    onHide={() => setShowLockDetails(false)}
                    closeIcon="pi pi-times"
                    footer={
                        <>
                            <Button
                                label="Cancel"
                                className="aq-btn"
                                onClick={() => {
                                    setShowLockDetails(false);
                                }}
                            />
                            <Button
                                label="Try Again"
                                className="aq-btn aq-primary"
                                onClick={() => {
                                    try {
                                        inspectorLock();
                                        setShowLockDetails(false);
                                    } catch (e) {}
                                }}
                            />
                        </>
                    }
                >
                    <>
                        <p>
                            Another user <i>{`(${lockedBy})`}</i> currently has this flatout image open and therefore
                            cannot be edited.
                        </p>
                        <p>Please get in touch with an admin for any queries.</p>
                    </>
                </Dialog>
            </div>
        );
    }
);

export default TopPanelInspectionFlatout;
