import { createSlice } from "@reduxjs/toolkit";
export const networkConnectionStatusSlice = createSlice({
    name: 'networkConnectionStatus',
    initialState: {
        isOnline: navigator.onLine,
    },
    reducers: {
        setNetworkConnectionStatus: (state, action) => {
            state.isOnline = action.payload;
        },
    },
});

export const { setNetworkConnectionStatus } = networkConnectionStatusSlice.actions;
export default networkConnectionStatusSlice.reducer;