// This class stores all the static states that is used in the application
export class StaticStates {
    // It is using for locking mechnism
    static InspectorLockTimerActive = false;
    static InspectorLockStatusAcquired = false;
    static UploaderLockTimerActive = false;
    static UploaderLockStatusAcquired = false;
    static FlatoutDataAcquired = false;
    static FlatoutIsPublished = false;

    static BackupHierarchy = {
        siteUnitId: 0,
        vesselId: 0,
        outageId: 0,
    };

    static PredictedData={
        damageLevelsData:[],
        mntnScopeData:[]
    };
}
