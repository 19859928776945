import { Event, EventType } from '../config/Constants';
import { ScribbleProperties } from '../properties/ScribbleProperties';
import { Coord } from '../utils/Coord';

export class ScribbleEvent extends Event {
    scribbleProperties = {};
    static scribbling = false;
    static minThickness = 2;
    static maxThickness = 8;
    static allowance = ScribbleEvent.maxThickness + 3;

    constructor(colorValue, lineThickness) {
        super();
        this.flatoutEventType = EventType.SCRIBBLE;
        this.scribbleProperties = new ScribbleProperties([], colorValue, lineThickness, new Coord(), new Coord());
    }

    /**
     * This method is used to initialise the Scribble Event
     * @param {ScribbleEvent} scribbleEvent 
     * @param {CanvasRenderingContext2D} ctx 
     */
    static start(scribbleEvent, ctx) {
        ScribbleEvent.scribbling = true;
        scribbleEvent.scribbleProperties.rs = new Coord(4000, 2666);
        ctx.globalCompositeOperation = 'source-over';
        ctx.strokeStyle = scribbleEvent.scribbleProperties.c;
        ctx.lineWidth = scribbleEvent.scribbleProperties.s;
        ctx.lineCap = 'round';
        ctx.beginPath();
    }

    /**
     * This method is called during Scribble Event move 
     * @param {ScribbleEvent} scribbleEvent 
     * @param {Coord} coord 
     * @param {CanvasRenderingContext2D} ctx 
     */
    static move(scribbleEvent, coord, ctx) {
        if (ScribbleEvent.scribbling) {
            ctx.lineTo(coord.x, coord.y);
            ctx.stroke();
            scribbleEvent.scribbleProperties.sp.push(new Coord(coord.x, coord.y));

            if (coord.x - ScribbleEvent.allowance < scribbleEvent.scribbleProperties.rs.x) {
                scribbleEvent.scribbleProperties.rs.x = coord.x - ScribbleEvent.allowance;
            }
            if (coord.x + ScribbleEvent.allowance > scribbleEvent.scribbleProperties.re.x) {
                scribbleEvent.scribbleProperties.re.x = coord.x + ScribbleEvent.allowance;
            }

            if (coord.y - ScribbleEvent.allowance < scribbleEvent.scribbleProperties.rs.y) {
                scribbleEvent.scribbleProperties.rs.y = coord.y - ScribbleEvent.allowance;
            }
            if (coord.y + ScribbleEvent.allowance > scribbleEvent.scribbleProperties.re.y) {
                scribbleEvent.scribbleProperties.re.y = coord.y + ScribbleEvent.allowance;
            }
        }
    }

    /**
     * This method is called during mouse up 
     * @param {ScribbleEvent} scribbleEvent
     * @param {CanvasRenderingContext2D} ctx
     * @param {boolean} debug
     * @returns
     */

    static end(scribbleEvent, ctx, debug = false) {
        if (ScribbleEvent.scribbling) {
            ScribbleEvent.scribbling = false;
            ctx.stroke();
            ctx.closePath();
            if (debug) {
                ctx.strokeStyle = 'blue';
                ctx.strokeRect(
                    scribbleEvent.scribbleProperties.rs.x,
                    scribbleEvent.scribbleProperties.rs.y,
                    scribbleEvent.scribbleProperties.re.x - scribbleEvent.scribbleProperties.rs.x,
                    scribbleEvent.scribbleProperties.re.y - scribbleEvent.scribbleProperties.rs.y
                );
            }

            return scribbleEvent.scribbleProperties.sp.length >= 4;
        }
        return false;
    }

    /**
     * This method is used to draw the scribble path when the path is available
     * @param {ScribbleEvent} scribbleEvent
     * @param {CanvasRenderingContext2D} ctx
     * @param {Uint8ClampedArray} path
     * @param {number} id
     * @param {Coord} rectStart
     * @param {Coord} rectEnd
     * @param {string} color
     * @param {number} thickness
     */
    static drawWhenPathAvailable(scribbleEvent, ctx, path, id, rectStart, rectEnd, color, size, debug = false) {
        scribbleEvent.scribbleProperties.sp = path;
        scribbleEvent.scribbleProperties.rs = rectStart;
        scribbleEvent.scribbleProperties.re = rectEnd;
        scribbleEvent.scribbleProperties.c = color;
        scribbleEvent.id = id;
        ctx.globalCompositeOperation = 'source-over';
        ctx.strokeStyle = scribbleEvent.scribbleProperties.c;
        scribbleEvent.scribbleProperties.s = size;
        ctx.lineWidth = scribbleEvent.scribbleProperties.s;
        ctx.lineCap = 'round';
        ctx.beginPath();
        ctx.moveTo(scribbleEvent.scribbleProperties.sp[0].x, scribbleEvent.scribbleProperties.sp[0].y);
        for (var i = 1; i < scribbleEvent.scribbleProperties.sp.length - 2; i++) {
            var xc = (scribbleEvent.scribbleProperties.sp[i].x + scribbleEvent.scribbleProperties.sp[i + 1].x) / 2;
            var yc = (scribbleEvent.scribbleProperties.sp[i].y + scribbleEvent.scribbleProperties.sp[i + 1].y) / 2;
            ctx.quadraticCurveTo(
                scribbleEvent.scribbleProperties.sp[i].x,
                scribbleEvent.scribbleProperties.sp[i].y,
                xc,
                yc
            );
        }
        ctx.quadraticCurveTo(
            scribbleEvent.scribbleProperties.sp[i].x,
            scribbleEvent.scribbleProperties.sp[i].y,
            scribbleEvent.scribbleProperties.sp[i + 1].x,
            scribbleEvent.scribbleProperties.sp[i + 1].y
        );
        ctx.stroke();
        ctx.closePath();
        if (debug) {
            ctx.strokeStyle = 'blue';
            ctx.lineWidth = 5;
            ctx.strokeRect(
                scribbleEvent.scribbleProperties.rs.x,
                scribbleEvent.scribbleProperties.rs.y,
                scribbleEvent.scribbleProperties.re.x - scribbleEvent.scribbleProperties.rs.x,
                scribbleEvent.scribbleProperties.re.y - scribbleEvent.scribbleProperties.rs.y
            );
        }
    }

    /**
     * This method is used to create a new Scribble Event from the old Scribble Event
     * @param {ScribbleEvent} localEvent
     * @returns
     */
    static setAllValuesFromObject(localEvent) {
        let scribbleEvent = new ScribbleEvent();
        scribbleEvent.parentEventIndex = localEvent.parentEventIndex;
        scribbleEvent.id = localEvent.id;
        let properties = new ScribbleProperties(
            localEvent.scribbleProperties.sp,
            localEvent.scribbleProperties.c,
            localEvent.scribbleProperties.s,
            localEvent.scribbleProperties.rs,
            localEvent.scribbleProperties.re
        );
        scribbleEvent.scribbleProperties = properties;
        return scribbleEvent;
    }

    /**
     * This method is used to draw the scribble from the Scribble Event object
     * @param {ScribbleEvent} scribbleEvent
     * @param {CanvasRenderingContext2D} displayContext
     */
    static drawFromObject(scribbleEvent, ctx, debug = false) {
        if (scribbleEvent.scribbleProperties.sp.length === 0 ||
            scribbleEvent.scribbleProperties.re.x === 0 ||
            scribbleEvent.scribbleProperties.re.y === 0) {
            return;
        }

        ctx.globalCompositeOperation = 'source-over';
        ctx.strokeStyle = scribbleEvent.scribbleProperties.c;
        ctx.lineWidth = scribbleEvent.scribbleProperties.s;
        ctx.lineCap = 'round';
        ctx.beginPath();
        ctx.moveTo(scribbleEvent.scribbleProperties.sp[0].x, scribbleEvent.scribbleProperties.sp[0].y);
        for (var i = 1; i < scribbleEvent.scribbleProperties.sp.length - 2; i++) {
            var xc = (scribbleEvent.scribbleProperties.sp[i].x + scribbleEvent.scribbleProperties.sp[i + 1].x) / 2;
            var yc = (scribbleEvent.scribbleProperties.sp[i].y + scribbleEvent.scribbleProperties.sp[i + 1].y) / 2;
            ctx.quadraticCurveTo(
                scribbleEvent.scribbleProperties.sp[i].x,
                scribbleEvent.scribbleProperties.sp[i].y,
                xc,
                yc
            );
        }
        if (scribbleEvent.scribbleProperties.sp.length > 2) {
            ctx.quadraticCurveTo(
                scribbleEvent.scribbleProperties.sp[i].x,
                scribbleEvent.scribbleProperties.sp[i].y,
                scribbleEvent.scribbleProperties.sp[i + 1].x,
                scribbleEvent.scribbleProperties.sp[i + 1].y
            );
            ctx.stroke();
            ctx.closePath();
            if (debug) {
                ctx.strokeStyle = 'blue';
                ctx.lineWidth = 5;
                ctx.strokeRect(
                    scribbleEvent.scribbleProperties.rs.x,
                    scribbleEvent.scribbleProperties.rs.y,
                    scribbleEvent.scribbleProperties.re.x - scribbleEvent.scribbleProperties.rs.x,
                    scribbleEvent.scribbleProperties.re.y - scribbleEvent.scribbleProperties.rs.y
                );
            }
        }
    }
}
