import { getPatternCanvas, getZoneColor, hexToRgb, sleep } from '../utils/Helper';
import { Geometry } from './Geometry';
import { MetaData } from './MetaData';

// const MAX_CONSEQUENT_CELL_DRAW = 20;

export class Cell {
    /** Zone Id */
    zId = 0;
    /** Geometry */
    g = new Geometry();
    metadata = new MetaData();

    /**
     * @param {int} zId
     * @param {null} g
     */

    constructor(
        zoneId,
        additivePath,
        additivePathLength,
        subtractivePaths,
        centroid,
        scale,
        area,
        tessellationSourceIndex,
        metadata
    ) {
        this.zId = zoneId;
        this.g = new Geometry(
            additivePath,
            additivePathLength,
            subtractivePaths,
            centroid,
            scale,
            area,
            tessellationSourceIndex
        );
        this.metadata = metadata;
    }

    /**
     * This method is used to draw the cell with the given fill.
     */
    static async draw(cell, fill, ctx) {
        // Draw full cell
        ctx.globalCompositeOperation ='source-over';
        ctx.fillStyle = fill;
        ctx.strokeStyle = fill;
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(cell.g.ap[0], cell.g.ap[1]);
        for (let j = 0; j < cell.g.ap.length / 2; j++) {
            ctx.lineTo(cell.g.ap[2 * j], cell.g.ap[2 * j + 1]);
        }
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

        // Draw holes
        if (cell.g.sPs && cell.g.sPs.length > 0) {
            ctx.globalCompositeOperation ='destination-out';
            ctx.fillStyle = 'rgb(255, 255, 255)';
            ctx.strokeStyle = 'rgb(255,255,255)';
            ctx.lineWidth = 2;

            for (let i = 0; i < cell.g.sPs.length; i++) {
                ctx.beginPath();
                ctx.moveTo(cell.g.sPs[i][0], cell.g.sPs[i][1]);
                for (let j = 0; j < cell.g.sPs[i].length / 2; j++) {
                    ctx.lineTo(cell.g.sPs[i][2 * j], cell.g.sPs[i][2 * j + 1]);
                }
                
                ctx.closePath();
                ctx.fill();
                ctx.stroke();
            }

            ctx.globalCompositeOperation = 'source-over';
            await sleep(1);
        }
    }

    /**
     * This method is used to draw the cell according to it's zone
     */
    static async drawZone(cell, zoneColorsMap, ctx, composite = 'source-over') {
        ctx.globalCompositeOperation = composite;
        ctx.lineWidth = 3;
        if (composite === 'source-over') {
            if (cell.metadata.isPatternFilled) {
                let patternCanvas = getPatternCanvas(zoneColorsMap, cell.zId);
                const pattern = ctx.createPattern(patternCanvas, 'repeat');
                ctx.fillStyle = pattern;
                ctx.strokeStyle = pattern;
            } else {
                let _fill = hexToRgb(getZoneColor(zoneColorsMap, cell.zId));
                ctx.fillStyle = _fill;
                ctx.strokeStyle = _fill;
            }
        } else {
            ctx.fillStyle = 'rgb(255,255,255)';
        }

        ctx.beginPath();
        ctx.moveTo(cell.g.ap[0], cell.g.ap[1]);
        for (let j = 0; j < cell.g.ap.length / 2; j++) {
            ctx.lineTo(cell.g.ap[2 * j], cell.g.ap[2 * j + 1]);
        }
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
    }

    /**
     * This method is used to fill the holes present in cell
     */

    static async drawHoles(cell, ctx, composite = 'destination-out') {
        ctx.globalCompositeOperation = composite;
        ctx.fillStyle = 'rgb(255,255,255)';
        ctx.strokeStyle = 'rgb(255,255,255)';
        ctx.lineWidth = 2;
        let tempSubtractiveDataArray = [...cell.g.sPs];
        for (let j = 0; j < tempSubtractiveDataArray.length; j++) {
            ctx.beginPath();
            ctx.moveTo(tempSubtractiveDataArray[j][0], tempSubtractiveDataArray[j][1]);
            for (let k = 0; k < tempSubtractiveDataArray[j].length / 2; k++) {
                ctx.lineTo(tempSubtractiveDataArray[j][2 * k], tempSubtractiveDataArray[j][2 * k + 1]);
            }

            ctx.closePath();
            ctx.fill();
            ctx.stroke();
        }

        await sleep(1);
        ctx.globalCompositeOperation = 'source-over';
    }
}
