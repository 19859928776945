import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Splash.scss';

export default function Splash() {
    const loading = useSelector((state) => state.loading.loading);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showBlocker, setShowBlocker] = useState(false);

    useEffect(() => {
        if (loading <= 0) {
            setShowSpinner(false);
            setTimeout(() => {
                setShowBlocker(false);
            }, 400);
        } else {
            setShowBlocker(true);
            setShowSpinner(true);
        }
    }, [loading]);

    return (
        <>
            {showBlocker && (
                <div className={'splash-screen' + (showSpinner ? ' show' : '')}>
                    <div className='splash-screen-inner'>
                        <div className="splash-screen-logo"></div>
                        <br />
                        <ProgressSpinner
                            pt={{
                                spinner: { style: { animationDuration: '1.4s' } },
                                circle: {
                                    style: {
                                        stroke: '#FFFFFF',
                                        strokeWidth: 8,
                                        animation: 'p-progress-spinner-dash 4.2s infinite',
                                    },
                                },
                            }}
                            className="aq-spinner-dark"
                            style={{ width: '50px', height: '50px' }}
                            strokeWidth="8"
                        />
                    </div>
                </div>
            )}
        </>
    );
}
