import Axios from './Axios';

export class TemplateAPI {
    /**
     * This method is used to get the template
     * @param {int} siteUnitId 
     * @param {int} vesselId 
     * @param {int} version 
     * @returns 
     */
    static get(siteUnitId, vesselId, version) {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(`/FlatoutTemplate/${siteUnitId}/${vesselId}/${version}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            });
            if (response && response.status) {
                resolve(response.data);
            }
        });
    }
    /**
     * This method is used to either add or replace the cells present in the vessel to the backend
     * @param {int} siteUnitId 
     * @param {int} vesselId 
     * @param {int} version 
     * @param {[]} cells 
     * @returns 
     */
    static addOrReplaceCells(siteUnitId, vesselId, version, cells) {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.post(`/FlatoutTemplate/${siteUnitId}/${vesselId}/${version}/Cells`, cells, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            });
            if (response && response.status) {
                resolve(response);
            }
        });
    }
}
