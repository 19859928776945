import { deepCopy } from "./Helper";

/**
 * Geometry compression by eleminating like geometry.
 */
export class Tessellation {
    /**
     * Decode tessellated cell geometry
     * @param {*} geometry The encoded cell geometry
     * @param {*} cells All cells
     * @returns The decoded geometry
     */
    static decode(geometry, cells = []) {
        if (geometry.tsi != null) {
            let parentCell = cells[geometry.tsi];
            geometry.tsi = null;
            geometry.ap = []
            for (let i = 0; i < parentCell.g.ap.length; i++) {
                if (!(i&1)) {
                    geometry.ap.push(Math.round((parentCell.g.ap[i] - parentCell.g.c.x) * geometry.s.x) + geometry.c.x)
                } else {
                    geometry.ap.push(Math.round((parentCell.g.ap[i] - parentCell.g.c.y) * geometry.s.y) + geometry.c.y)
                }
            }
        }

        return deepCopy(geometry);
    }
}
