import { PresentationMode } from '../config/Constants';
import { mapRange } from './Helper';

/**
 * Load LUT (Look Up Table) textures.
 *
 * @param {string} lutTexture the LUT Texture Base64 to load
 * @param {() => {}} setLUTCallBack the call back function to use after the LUT is loaded
 */
export const loadLUTData = (lutTexture, setLUTCallBack = () => {}) => {
    let /**@type{HTMLCanvasElement}*/ lutCanvas = document.createElement('canvas');
    let lutCtx = lutCanvas.getContext('2d');
    let image = new Image();
    image.onload = function () {
        lutCanvas.width = image.width;
        lutCanvas.height = image.height;
        lutCtx.drawImage(image, 0, 0);
        setLUTCallBack(lutCtx.getImageData(0, 0, lutCanvas.width, lutCanvas.height).data);
    };

    image.src = lutTexture;
};

/**
 * Get the CSS color for the given value from the given LUT texture
 * @param {Uint8ClampedArray} lutData The LUT texture to refer
 * @param {number} value The value to get the color for
 * @param {number} minValue The minimum value possible
 * @param {number} maxValue The maximum value possible
 * @returns A string representing CSS color value
 */
export const lookUpColor = (lutData, value = 0, minValue = 0, maxValue = 1, mode = PresentationMode.DMG_LEVEL) => {
    let linearValue = 0;
    if (mode === PresentationMode.DMG_LEVEL) {
        linearValue = Math.floor(mapRange(minValue, maxValue, 0, 100, value)) * 4;
        if (linearValue > 396) {
            linearValue = 396;
        }
    } else{
        linearValue = Math.floor(mapRange(minValue, maxValue, 0, 100, value)) * 4;
        if (linearValue > 396) {
            linearValue = 396;
        }
    }

    let colorR = lutData[linearValue];
    let colorG = lutData[linearValue + 1];
    let colorB = lutData[linearValue + 2];
    return `rgb(${colorR}, ${colorG}, ${colorB})`;
};
