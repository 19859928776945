import { Dropdown } from 'primereact/dropdown';
import { forwardRef } from "react";
import { getBase64 } from '../../utils/Helper';
import './PatternDropDown.scss';


const PatternDropDown = forwardRef(({ placeholder, options = [], patterns=[], onChange = () => { }, value}, ref) => {


    const handleChange = (e) => {
        if (e.value) {
            onChange(e.value);
        }

    }

    const selectedOptionTypeTemplate = (option, props) => {
        if (option) {
            let src = patterns[option.id].toDataURL();
            return (
                <div className="flex align-items-censter orientation" >
                    <div className="mr-2 flag item-img" style={{ width: '40px', height: '40px', background: `url(${src})` }}></div>
                    <div className="modified-text">{option.description}</div>
                </div>
            );
        }

        return <span>{placeholder}</span>;
    };
    const optionTypeTemplate = (option) => {
        let src = patterns[option.id].toDataURL();
        return (<div className="flex align-items-center orientation" >
            <div className="mr-2 flag item-img" style={{ width: '40px', height: '40px', background: `url(${src})` }}></div>
            <div className="modified-text">{option.description}</div>

        </div>)
    }

    return <>
        <div className="card flex justify-content-center">
            <span className="p-float-label">
                <Dropdown
                    inputId="dropdown"
                    value={value}
                    onChange={handleChange}
                    itemTemplate={optionTypeTemplate}
                    placeholder={placeholder}
                    className='aq-select'
                    options={options}
                    optionLabel="description"
                    // panelFooterTemplate={panelFooterTemplate}
                    valueTemplate={selectedOptionTypeTemplate}
                    style={{ width: '100%', height: 56 }}
                    showClear
                    filter
                    filterPlaceholder="Search…"
                    resetFilterOnHide={true}
                ></Dropdown>

            </span>
        </div>
    </>

}
)

export default PatternDropDown; 