import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Slider } from 'primereact/slider';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import { PresentationMode } from '../../config/Constants';
import './CellContextBar.scss';
import { useSelector } from 'react-redux';
import { loadLUTData, lookUpColor } from '../../utils/Lut';
import DamageLevelLUT from '../../assets/luts/DamageLevelLUT.png';
import DamageRateLUT from '../../assets/luts/DamageRateLUT.png';

export default function CellContextBar({
    presentationMode = PresentationMode.DMG_LEVEL,
    value = 0,
    defaultValue = 0,
    minValue = 0,
    maxValue = 1,
    dmgTypePatternDefs = [],
    dmgTypePatterns = [],
    mntnScopePatternDefs = [],
    mntnScopePatterns = [],
    hidden = false,
    onChange = () => { },
    predict = () => { },
    predicting = false
}) {
    const [removed, setRemoved] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [maxWidthTypeText, setMaxWidthTypeText] = useState(200);
    const [maxWidthScopeText, setMaxWidthScopeText] = useState(200);
    const isOnline = useSelector((state) => state.networkConnectionStatus.isOnline)

    // const [LUT, setLUT] = useState(null);
    const [legend, setLegend] = useState('black')

    const setLUT = (_lut) => {
        let stops = [];
        const nStops = 6;
        for (let i = 0; i < 1; i += 1 / nStops) {
            stops.push(lookUpColor(_lut, i, 0, 1));
        }
        setLegend(`linear-gradient(0deg, ${stops.join(',')}`);
    }

    useEffect(() => {
        if (hidden) {
            setTimeout(() => {
                setRemoved(true);
            }, 1000);
        } else {
            setRemoved(false);
        }
    }, [hidden]);

    useEffect(() => {
        switch (presentationMode) {
            case PresentationMode.DMG_LEVEL:
                loadLUTData(DamageLevelLUT, setLUT);
                break;
            case PresentationMode.DMG_RATE:
                loadLUTData(DamageRateLUT, setLUT)
                break;
            default:
        }


    }, [presentationMode]);

    useEffect(() => {
        var maxlength = 0;
        for (let i = 0; i < dmgTypePatternDefs.length; i++) {
            if (dmgTypePatternDefs[i].description.length > maxlength) {
                maxlength = dmgTypePatternDefs[i].description.length;
            }
        }

        setMaxWidthTypeText(maxlength * 7);
    }, [dmgTypePatternDefs]);

    useEffect(() => {
        var maxlength = 0;
        for (let i = 0; i < mntnScopePatternDefs.length; i++) {
            if (mntnScopePatternDefs[i].description.length > maxlength) {
                maxlength = mntnScopePatternDefs[i].description.length;
            }
        }

        setMaxWidthScopeText(maxlength * 7);
    }, [mntnScopePatternDefs]);

    return (
        <>
            <div
                className={'aq-floating-panel cell-context-bar' + (hidden ? ' hide' : '')}
                style={{ visibility: removed ? 'hidden' : 'visible' }}
            >
                <div className="toolbox" style={{ overflowY: 'auto' }}>
                    {presentationMode === PresentationMode.DMG_LEVEL && (
                        <div className='tolbox-inner'>
                            <Button disabled={!isOnline} icon="tool-icon tool-icon-predict" tooltip='Predict Damage Levels'
                                tooltipOptions={{ position: 'left' }} onClick={predict}
                                style={{ pointerEvents: predicting ? 'none' : 'auto' }} className={predicting ? 'predict-button' : ''} />
                            <div className="toolbox-inner toolbox-inner-damage-level">
                                <Slider
                                    value={value * 100}
                                    min={0}
                                    max={100}
                                    onChange={(event) => {
                                        onChange(event.value / 100);
                                    }}
                                    className={'aq-slider aq-slider-vertical hide-range'}
                                    style={{ background: legend }}
                                    step={5}
                                    tooltip={value * 100 + '%'}
                                    orientation="vertical"
                                />
                                {/* <div style={{ marginTop: 8 }}>{Math.round(value * 100) + '%'}</div> */}
                                <InputNumber
                                    id="text-size-input"
                                    showButtons
                                    buttonLayout="vertical"
                                    style={{ width: '48px' }}
                                    inputStyle={{ borderRadius: 0 }}
                                    value={value * 100}
                                    onChange={(event) => {
                                        onChange(event.value / 100);
                                    }}
                                    min={minValue * 100}
                                    max={maxValue * 100}
                                    step={5}
                                    minFractionDigits={1}
                                    maxFractionDigits={1}
                                />
                                <div style={{ fontSize: '1rem', marginTop: 8, marginBottom: 8 }}>% </div>
                            </div>
                        </div>
                    )}
                    {presentationMode === PresentationMode.DMG_TYPE && (
                        <div className="toolbox-inner toolbox-inner-patterns">
                            <Button
                                className="type-header-button"
                                icon={expanded ? 'pi pi-arrow-right' : 'pi pi-ellipsis-h'}
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                                label={expanded ? 'DAMAGE TYPES' : null}
                            ></Button>
                            <div
                                className="cell-context-bar-thumb-container"
                                style={{ maxWidth: maxWidthTypeText, width: expanded ? maxWidthTypeText : 40 }}
                            >
                                <div className="cell-context-bar-thumb-container-inner">
                                    {dmgTypePatternDefs.map((typeData, i) => {
                                        return (
                                            <div
                                                data-pr-tooltip={typeData.description}
                                                className="flex align-items-center orientation cell-context-bar-button"
                                                key={i}
                                                onClick={() => {
                                                    onChange(i);
                                                    setExpanded(false);
                                                }}
                                            >
                                                <div
                                                    className={
                                                        'cell-context-bar-thumb' + (i === value ? ' active' : '')
                                                    }
                                                    style={{ background: `url(${dmgTypePatterns[i].toDataURL()})` }}
                                                ></div>
                                                <div style={{ fontSize: 12, margin: 8 }}>{typeData.description}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {!expanded && <Tooltip target=".cell-context-bar-button" position="left" />}
                        </div>
                    )}
                    {presentationMode === PresentationMode.MNTN_SCOPE && (
                        <div className="toolbox-inner toolbox-inner-patterns">
                            <Button disabled={!isOnline} onClick={predict} icon="tool-icon tool-icon-predict" tooltip='Predict Maintenance Scope'
                                tooltipOptions={{ position: 'left' }}
                                style={{ pointerEvents: predicting ? 'none' : 'auto' }} className={predicting ? 'predict-button' : ''} />
                            <Button
                                className="type-header-button"
                                icon={expanded ? 'pi pi-arrow-right' : 'pi pi-ellipsis-h'}
                                onClick={() => {
                                    setExpanded(!expanded);
                                }}
                                label={expanded ? 'MAINTENANCE SCOPES' : null}
                            ></Button>
                            <div
                                className="cell-context-bar-thumb-container"
                                style={{ maxWidth: maxWidthScopeText, width: expanded ? maxWidthScopeText : 40 }}
                            >
                                <div className="cell-context-bar-thumb-container-inner">
                                    {mntnScopePatternDefs.map((scopeData, i) => {
                                        return (
                                            <div
                                                data-pr-tooltip={scopeData.description}
                                                className="flex align-items-center orientation cell-context-bar-button"
                                                key={i}
                                                onClick={() => {
                                                    onChange(i);
                                                    setExpanded(false);
                                                }}
                                            >
                                                <div
                                                    className={
                                                        'cell-context-bar-thumb' + (i === value ? ' active' : '')
                                                    }
                                                    style={{ background: `url(${mntnScopePatterns[i].toDataURL()})` }}
                                                ></div>
                                                <div style={{ fontSize: 12, margin: 8 }}>{scopeData.description}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {!expanded && <Tooltip target=".cell-context-bar-button" position="left" />}
                        </div>
                    )}
                    {presentationMode === PresentationMode.DMG_RATE && (
                        <div className="toolbox-inner" style={{ width: 48 }}>
                            <div className="toolbox-inner toolbox-inner-damage-rate">
                                <Slider
                                    value={value * 100}
                                    min={0}
                                    max={10}
                                    onChange={(event) => {
                                        onChange(event.value / 100);
                                    }}
                                    className={'aq-slider aq-slider-vertical hide-range'}
                                    style={{ background: legend }}
                                    step={0.1}
                                    tooltip={value * 100 + '%'}
                                    orientation="vertical"
                                />
                                {/* <div style={{ marginTop: 8 }}>{Math.round(value * 100) + '%'}</div> */}
                            </div>
                            <InputNumber
                                id="text-size-input"
                                showButtons
                                buttonLayout="vertical"
                                style={{ width: '48px' }}
                                inputStyle={{ borderRadius: 0 }}
                                value={value * 100}
                                onChange={(event) => {
                                    onChange(event.value / 100);
                                }}
                                min={minValue * 10}
                                max={maxValue * 10}
                                step={0.1}
                                minFractionDigits={2}
                                maxFractionDigits={2}
                            />
                            <div style={{ fontSize: '12px', marginTop: 8, marginBottom: 8 }}>% / mo</div>
                        </div>
                    )}
                </div>
            </div>
            {expanded && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                    onClick={() => setExpanded(false)}
                />
            )}
        </>
    );
}
