import { PatternStyle, SelectToolColors } from '../../config/Constants';
import { Coord } from '../../utils/Coord';
import { checkWhetherInsideBoundBox, configurePattern, sleep } from '../../utils/Helper';

export class SelectTool {
    static dragging = false;
    static SelectionOn = false;
    static selectedCells = [];
    static selectedCellEventIndices = [];
    static allowance = 20;
    /**@type {Coord}  */ rectStartCoord = undefined;
    /**@type {Coord}  */ rectEndCoord = undefined;
    /**@type {Coord}  */ mouseStartCoord = undefined;
    /**@type {Uint8ClampedArray} */ imageBuffer = undefined;
    /**@type {CanvasRenderingContext2D} */ overlayContextMain = undefined;
    width = 4000;
    height = 2666;
    intervalId = null;

    static relativeTranslation = new Coord();

    constructor() {
        this.dragging = false;
        this.rectStartCoord = new Coord();
        this.rectEndCoord = new Coord();
        this.imageBuffer = undefined;
        this.overlayContextMain = undefined;
        this.width = 4000;
        this.height = 2666;
    }
    /**
     * This is the entry point for Select Tool
     * @param {SelectTool} self
     * @param {Coord} startingCoord
     * @param {CanvasRenderingContext2D} overlayContext
     */
    static start(
        /**@type {SelectTool} */ self,
        startingCoord,
        /** @type {CanvasRenderingContext2D} */ overlayContext,
        isModalOpen
    ) {
        self.mouseStartCoord = new Coord(startingCoord.x, startingCoord.y);
        SelectTool.dragging = true;
        if (isModalOpen) return;

        self.rectStartCoord = new Coord(startingCoord.x, startingCoord.y);

        self.rectEndCoord = new Coord(startingCoord.x, startingCoord.y);
        self.overlayContextMain = overlayContext;
        self.imageBuffer = overlayContext.getImageData(0, 0, 4000, 2666);
        SelectTool.SelectionOn = true;
        self.intervalId = setInterval(() => {
            SelectTool.SelectionOn = true;
        }, 20);
    }
    /**
     * This method is called during mouse move
     * @param {SelectTool} self
     * @param {Coord} coord
     * @param {CanvasRenderingContext2D} previewContext
     * @param {*} cells_index_map
     * @returns
     */

    static move(
        /**@type {SelectTool} */ self,
        /**@type {Coord} */ coord,
        /** @type {CanvasRenderingContext2D} */ previewContext,
        cells,
        cells_index_map,
        isModalOpen
    ) {
        if (SelectTool.dragging) {
            if (isModalOpen) {
                SelectTool.relativeTranslation.x = coord.x - self.mouseStartCoord.x;
                SelectTool.relativeTranslation.y = coord.y - self.mouseStartCoord.y;
                return;
            }

            if (SelectTool.SelectionOn) {
                previewContext.clearRect(0, 0, self.width, self.height);

                if (self.imageBuffer) previewContext.putImageData(self.imageBuffer, 0, 0);

                self.rectStartCoord.x = Math.min(self.mouseStartCoord.x, coord.x);
                self.rectStartCoord.y = Math.min(self.mouseStartCoord.y, coord.y);
                self.rectEndCoord.x = Math.max(self.mouseStartCoord.x, coord.x);
                if (SelectTool.SelectionOn) {
                }
                self.rectEndCoord.y = Math.max(self.mouseStartCoord.y, coord.y);

                previewContext.setLineDash([]);

                previewContext.strokeStyle = SelectToolColors.GOLD;
                previewContext.strokeRect(
                    self.rectStartCoord.x,
                    self.rectStartCoord.y,
                    self.rectEndCoord.x - self.rectStartCoord.x,
                    self.rectEndCoord.y - self.rectStartCoord.y
                );

                previewContext.fillStyle = SelectToolColors.YELLOW;
                previewContext.fillRect(
                    self.rectStartCoord.x,
                    self.rectStartCoord.y,
                    self.rectEndCoord.x - self.rectStartCoord.x,
                    self.rectEndCoord.y - self.rectStartCoord.y
                );

                self.rectStartCoord.x -= this.allowance;
                self.rectStartCoord.y -= this.allowance;
                self.rectEndCoord.x += this.allowance;
                self.rectEndCoord.y += this.allowance;

                SelectTool.findCellsAsyncProcess(/**@type {SelectTool}*/ self, cells, previewContext, cells_index_map);
                return true;
            }
        }
    }
    /**
     * This method is called during mouse up
     * @param {SelectTool} self
     * @returns
     */
    static end(/**@type {SelectTool} */ self, isModalOpen) {
        SelectTool.dragging = false;
        SelectTool.SelectionOn = false;
        if (isModalOpen) {
            return SelectTool.relativeTranslation;
        }
        if (self !== undefined) {
            self.imageBuffer = null;
            let event_indices = [...SelectTool.selectedCellEventIndices];
            SelectTool.selectedCells = [];
            SelectTool.selectedCellEventIndices = [];
            clearInterval(self.intervalId);
            return event_indices;
        }
    }

    /**
     * This method is used to find the cells inside the selection box asynchronously
     */
    static findCellsAsyncProcess(
        /**@type {SelectTool} */ self,
        /**@type {CellGeometryFull} */ cells,
        /** @type {CanvasRenderingContext2D} */ overlayContext1,
        cells_index_map
    ) {
        if (cells) {
            SelectTool.selectedCellEventIndices = [];
            SelectTool.selectedCells = [];
            for (let i = 0; i < cells.length; i++) {
                let /**@type {CellGeometryUpdate} */ cell = cells[i];
                if (checkWhetherInsideBoundBox(self.rectStartCoord, self.rectEndCoord, cell.g.c)) {
                    SelectTool.selectedCells.push(cell);
                    SelectTool.selectedCellEventIndices.push(cells_index_map[i]);
                    SelectTool.drawOnOverlayCanvas(cell, overlayContext1);
                }
            }
        }
    }

    /**
     *
     * @param {CellGeometryUpdate} cell
     * @param {CanvasRenderingContext2D} overlayContext
     */
    static async drawOnOverlayCanvas(cell, overlayContext) {
        var canvas = configurePattern('', '', PatternStyle.CHECKER_BOARD);
        var createdPattern = overlayContext.createPattern(canvas, 'repeat');
        overlayContext.globalCompositeOperation = 'source-over';
        overlayContext.fillStyle = createdPattern;
        overlayContext.strokeStyle = `rgb(255,255,0)`;

        overlayContext.beginPath();
        overlayContext.moveTo(cell.g.ap[0], cell.g.ap[1]);
        for (let j = 0; j < cell.g.ap.length; j += 2) {
            overlayContext.lineTo(cell.g.ap[j], cell.g.ap[j + 1]);
        }
        overlayContext.fill();
        overlayContext.stroke();
        overlayContext.closePath();

        await sleep(100);
    }
}
