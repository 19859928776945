import { Coord } from '../../utils/Coord';
import { checkWhetherInsideBoundBox, sleep } from '../../utils/Helper';

export class PaintRegion {
    static dragging = false;
    static allowance = 20;
    static selectedCells = [];
    static selectedCellEventIndices = [];
    static PreviewOn = false;
    /**@type {Coord}  */ rectStartCoord = undefined;
    /**@type {Coord}  */ rectEndCoord = undefined;
    /**@type {Coord}  */ mouseStartCoord = undefined;
    /**@type {CanvasRenderingContext2D} */ overlayContextMain = undefined;
    width = 4000;
    height = 2666;
    intervalId = null;

    constructor() {
        this.dragging = false;
        this.rectStartCoord = new Coord();
        this.rectEndCoord = new Coord();
        this.overlayContextMain = undefined;
        this.width = 4000;
        this.height = 2666;
    }

    /**
     * This is the entry point for Fill Region Tool
     * @param {PaintRegion} paintRegion
     * @param {Coord} startingCoord
     * @param {CanvasRenderingContext2D} overlayContext
     */
    static start(paintRegion, startingCoord, overlayContext) {
        PaintRegion.dragging = true;
        paintRegion.rectStartCoord = new Coord(startingCoord.x, startingCoord.y);
        paintRegion.mouseStartCoord = new Coord(startingCoord.x, startingCoord.y);
        paintRegion.rectEndCoord = new Coord(startingCoord.x, startingCoord.y);
        paintRegion.overlayContextMain = overlayContext;
        paintRegion.intervalId = setInterval(() => {
            PaintRegion.PreviewOn = true;
        }, 20);
    }
    /**
     * This method is called during mouse move
     * @param {PaintRegion} paintRegion
     * @param {Coord} coord
     * @param {CanvasRenderingContext2D} previewContext
     * @param {*} cells_index_map
     * @returns
     */

    static move(paintRegion, coord, previewContext, cells, cells_index_map) {
        if (PaintRegion.dragging && PaintRegion.PreviewOn) {
            previewContext.clearRect(0, 0, paintRegion.width, paintRegion.height);
            PaintRegion.PreviewOn = false;
            paintRegion.rectStartCoord.x -= this.allowance;
            paintRegion.rectStartCoord.y -= this.allowance;
            paintRegion.rectEndCoord.x += this.allowance;
            paintRegion.rectEndCoord.y += this.allowance;
            PaintRegion.findCellsAsyncProcess(paintRegion, cells, previewContext, cells_index_map);
            paintRegion.rectStartCoord.x = Math.min(paintRegion.mouseStartCoord.x, coord.x);
            paintRegion.rectStartCoord.y = Math.min(paintRegion.mouseStartCoord.y, coord.y);
            paintRegion.rectEndCoord.x = Math.max(paintRegion.mouseStartCoord.x, coord.x);
            paintRegion.rectEndCoord.y = Math.max(paintRegion.mouseStartCoord.y, coord.y);
            previewContext.strokeStyle = `rgb(80,80,80)`;
            previewContext.setLineDash([4, 1]);
            previewContext.strokeRect(
                paintRegion.rectStartCoord.x,
                paintRegion.rectStartCoord.y,
                paintRegion.rectEndCoord.x - paintRegion.rectStartCoord.x,
                paintRegion.rectEndCoord.y - paintRegion.rectStartCoord.y
            );
            previewContext.setLineDash([]);
            return true;
        }
    }
    /**
     *
     * @param {PaintRegion} paintRegion
     * @param {CanvasRenderingContext2D} overlayContext1
     * @param {*} cells
     * @param {*} cells_index_map
     * @returns
     */
    static end(paintRegion) {
        PaintRegion.dragging = false;
        if (paintRegion) {
            PaintRegion.PreviewOn = false;
            // paintRegion.overlayContextMain.clearRect(0, 0, 4000, 2666);
            // PaintRegion.findCellsAsyncProcess(paintRegion, cells, overlayContext1, cells_index_map);
            paintRegion.dragging = false;
            let event_indices = [...PaintRegion.selectedCellEventIndices];
            PaintRegion.selectedCells = [];
            PaintRegion.selectedCellEventIndices = [];
            clearInterval(paintRegion.intervalId);
            return event_indices;
        }
    }

    /**
     *  This method is used to find the cells inside the selection box asynchronously
     * @param {PaintRegion} paintRegion
     * @param {CellGeometryFull} cells
     * @param {CanvasRenderingContext2D} previewContext
     * @param {*} cells_index_map
     */
    static findCellsAsyncProcess(paintRegion, cells, previewContext, cells_index_map) {
        if (cells) {
            PaintRegion.selectedCellEventIndices = [];
            PaintRegion.selectedCells = [];
            for (let i = 0; i < cells.length; i++) {
                let cell = cells[i];
                if (checkWhetherInsideBoundBox(paintRegion.rectStartCoord, paintRegion.rectEndCoord, cell.g.c)) {
                    PaintRegion.selectedCells.push(cell);
                    PaintRegion.selectedCellEventIndices.push(cells_index_map[i]);
                    PaintRegion.drawPreview(cell, previewContext);
                }
            }
        }
    }

    /**
     *
     * @param {CellGeometryUpdate} cell
     * @param {CanvasRenderingContext2D} overlayContext
     */
    static async drawPreview(cell, overlayContext) {
        overlayContext.globalCompositeOperation = 'source-over';
        overlayContext.beginPath();
        overlayContext.moveTo(cell.g.ap[0], cell.g.ap[1]);
        for (let j = 0; j < cell.g.ap.length; j += 2) {
            overlayContext.lineTo(cell.g.ap[j], cell.g.ap[j + 1]);
        }
        overlayContext.fill();
        overlayContext.closePath();
        await sleep(100);
    }
}
