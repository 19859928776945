import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Axios from '../services/Axios';
import { AppRole, loginRequest } from '../config/AuthConfig';
import { setUser } from '../redux/reducer/UserReducer';
import { showModal } from '../modal/Modal';
import { handleError } from '../errorHandling/error';

/**
 * This method is used to intercept the request
 * @param {*} props 
 * @returns 
 */
export default function RequestInterceptor(props) {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const username = account.name;
    var role = 'No Role Tagged!';
    const id = account.username;
    if (account.idTokenClaims && account.idTokenClaims.roles && account.idTokenClaims.roles.length > 0) {
        const roles = [AppRole.ADMINISTRATOR, AppRole.STANDARD_USER, AppRole.CONTRACTOR, AppRole.READONLY];
        for (let i = 0; i < roles.length; i++) {
            if (account.idTokenClaims.roles.includes(roles[i])) {
                role = roles[i];
                break;
            }
        }
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setUser({ name: username, role: role, id: id }));
    }, [dispatch, id, role, username]);

    /* eslint-disable no-param-reassign */
    Axios.interceptors.request.use(async (config) => {
        if (!account) {
            throw Error('No sign on');
        }

        const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
        });

        const bearer = 'Bearer ' + response.accessToken;
        if (config.headers !== undefined) {
            config.headers.Authorization = bearer;
        }

        return config;
    });
    /* eslint-enable no-param-reassign */

    Axios.interceptors.response.use(
        async (response) => {
            if (response) {
                if (response.status === 200|| response.status === 201 || response.status === 204) return response;
                else return handleError(response);
            } else
                showModal({
                    title: 'Network Error',
                    message: 'Aw snap ! Connection to API Server failed',
                    class: 'error',
                });
        },
        async (errorResponse) => {
            const regexPattern = new RegExp('BrowserAuthError');

            if (!regexPattern.test(errorResponse)) return handleError(errorResponse);
            else return { status: 0, title: 'Network Error', message: 'Network Not Available' };
        }
    );

    return <>{props.children}</>;
}
