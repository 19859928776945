import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import './TextContextBar.scss';

export default function TextContextBar({
    color,
    size,
    style = { bold: false, italic: false },
    onChangeColor = () => {},
    onChangeSize = () => {},
    onChangeStyle = () => {},
    hidden = false,
}) {
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        if (hidden) {
            setTimeout(() => {
                setRemoved(true);
            }, 1000);
        } else {
            setRemoved(false);
        }
    }, [hidden]);

    return (
        <div
            className={'aq-floating-panel text-context-bar' + (hidden ? ' hide' : '')}
            style={{ visibility: removed ? 'hidden' : 'visible' }}
        >
            <div className="toolbox">
                <Button
                    id="bold-text-button"
                    label="B"
                    className={'format-button-bold' + (style.bold ? ' active' : '')}
                    onClick={() => {
                        onChangeStyle({ bold: !style.bold, italic: style.italic });
                    }}
                />
                <Button
                    id="italic-text-button"
                    label="I"
                    className={'format-button-italic' + (style.italic ? ' active' : '')}
                    onClick={() => {
                        onChangeStyle({ bold: style.bold, italic: !style.italic });
                    }}
                />
                <InputNumber
                    id="text-size-input"
                    showButtons
                    buttonLayout="vertical"
                    style={{ width: '48px' }}
                    inputStyle={{ borderRadius: 0 }}
                    suffix="px"
                    value={size}
                    onChange={(ev) => onChangeSize(ev.value)}
                    min={12}
                    max={96}
                    step={4}
                />
                <div id="text-color-input" className="text-context-bar-color-container">
                    <div
                        className={'color-palette-button' + (color === 'rgb(255,0,0)' ? ' active' : '')}
                        style={{ backgroundColor: 'red' }}
                        onClick={() => onChangeColor('rgb(255,0,0)')}
                    ></div>
                    <div
                        className={'color-palette-button' + (color === 'rgb(0,0,255)' ? ' active' : '')}
                        style={{ backgroundColor: 'blue' }}
                        onClick={() => onChangeColor('rgb(0,0,255)')}
                    ></div>
                    <div
                        className={'color-palette-button' + (color === 'rgb(0,255,0)' ? ' active' : '')}
                        style={{ backgroundColor: 'green' }}
                        onClick={() => onChangeColor('rgb(0,255,0)')}
                    ></div>
                    <div
                        className={'color-palette-button' + (color === 'rgb(0,0,0)' ? ' active' : '')}
                        style={{ backgroundColor: 'black' }}
                        onClick={() => onChangeColor('rgb(0,0,0)')}
                    ></div>
                    <div
                        className={'color-palette-button' + (color === 'rgb(0,0,80)' ? ' active' : '')}
                        style={{ backgroundColor: '#000050' }}
                        onClick={() => onChangeColor('rgb(0,0,80)')}
                    ></div>
                </div>
            </div>

            <Tooltip target='#bold-text-button' position='left'>
              Bold <span className="accel-label">B</span>
            </Tooltip>
            <Tooltip target='#italic-text-button' position='left'>
              Italic <span className="accel-label">I</span>
            </Tooltip>
            <Tooltip target='#text-size-input' position='left'>
              Text Size
            </Tooltip>
            <Tooltip target='#text-color-input' position='left' at='left-11 center'>
              Text Color
            </Tooltip>
        </div>
    );
}
