import React, { useRef, useEffect, useState } from 'react';
import './HeaderBar.scss';
import { useMsal } from "@azure/msal-react";
import { useSelector } from 'react-redux';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { StaticStates } from '../../utils/StaticeStates';
import { ContextLockAPI } from '../../services/ContextLockAPI';

export default function HeaderBar({ title = 'Title', titleSmall = 'T', subtitle = 'Subtitle' }) {
    const user = useSelector((state) => state.user);
    const [userInitials, setUserInitials] = useState('');
    var envName = process.env.REACT_APP_ENV_NAME;
    const profileOverLayPanel = useRef();
    const [profileBackground, setProfileBackground] = useState('#ccc');
    const { instance } = useMsal();

    useEffect(() => {
        let user_name = user.name + '';
        let alphabetics = user_name.replace(/[^A-Za-z ]/gi, '');
        let initials = alphabetics
            .match(/(\b\S)?/g)
            .join('')
            .toUpperCase()
            .substring(0, 2)
            .split('')
            .reverse();

        setUserInitials(initials.join(''));

        setProfileBackground(getRandomColor());
    }, [user]);

    function getRandomColor() {
        const color = 'hsl(' + Math.random() * 360 + ', 75%, 80%)';
        return color;
    }

    function accountSettings() {
        var hint = encodeURIComponent(user.id);
        var _w = window.open('https://myaccount.microsoft.com/?login_hint=' + hint);
        _w.focus();
    }

    async function signOut() {
        // If we have locked the image either in the inspection page or the uploader page , we first unlock the required vessel and then sign out the user , else we just sign out the user
        if (StaticStates.InspectorLockStatusAcquired || StaticStates.UploaderLockStatusAcquired) {
            if (StaticStates.InspectorLockStatusAcquired) {
                await ContextLockAPI.unlock(StaticStates.BackupHierarchy.siteUnitId, StaticStates.BackupHierarchy.vesselId, StaticStates.BackupHierarchy.outageId, "INSPECTOR");
            }
            else if (StaticStates.UploaderLockStatusAcquired) {
                await ContextLockAPI.unlock(StaticStates.BackupHierarchy.siteUnitId, StaticStates.BackupHierarchy.vesselId);
            }
        }
        
        instance.logoutRedirect();
    }
    
    return (
        <>
            <div className="aq-headerbar">
                <div className="row">
                    <div className="col-auto" style={{ height: 33 }}>
                        {/* Need this for alignment */}
                    </div>
                    <div className="col-50" style={{ textAlign: 'center' }}>
                        <span className="logo-small"></span>
                        <span className="title">
                            <span className="title-main">{title}</span>
                            <span className="title-small">{titleSmall}</span>&nbsp;
                            <span style={{ fontWeight: 'lighter', opacity: 0.75 }}>{subtitle}</span>
                            {envName !== 'Prod' && <span className='aq-headerbar-env'>{envName}</span>}
                        </span>
                    </div>
                    <div className="col-25">
                        <div className="aq-avatar-container" onClick={(e) => profileOverLayPanel.current.toggle(e)}
                        title="Profile Menu" aria-label="button">
                            <Avatar
                                className="aq-avatar-profile-pic"
                                label={userInitials}
                                shape="circle"
                                style={{ backgroundColor: profileBackground, color: '#0008' }}
                            />
                            <div className="aq-avatar-info">
                                <div className="aq-avatar-info-greeting">Welcome</div>
                                <div className="aq-avatar-info-name">{user.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayPanel ref={profileOverLayPanel} className="aq-profile-menu">
                <center>
                    <Avatar size="xlarge" style={{backgroundColor:'#FFF', background: profileBackground, color: '#0008'}} label={userInitials} shape="circle" className="aq-avatar-profile-pic" />
                    <h1>{user.name}</h1>
                    <h2>{user.role}</h2>
                    <Button
                        onClick={accountSettings}
                        label="Account Settings"
                        className="aq-btn"
                        style={{ width: '100%', marginBottom: 8 }}
                        icon="pi pi-user-edit"
                    />
                    <Button
                        onClick={signOut}
                        label="Sign Out"
                        className="aq-btn aq-primary"
                        style={{ width: '100%' }}
                        icon="pi pi-sign-out"
                    />
                </center>
            </OverlayPanel>
        </>
    );
}
