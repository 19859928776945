import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import './TransformControls.scss';
import '../../styles/icons.scss';

/**
 * This component has a transform controls button i.e full-screen , zoom-in , zoom-out and fit-to-screen
 */
export const TransformControls = ({
    hidden,
    onZoomIn = () => {},
    onZoomOut = () => {},
    onFitToScreen = () => {},
    onFullScreen = () => {},
}) => {
    const fullscreen = useSelector((state) => state.fullscreen.fullscreen);

    return (
        <div className={'transform-controls aq-floating-panel dark' + (hidden ? ' hidden' : '')}>
            <div className="aq-floating-panel-group">
                <Button
                    icon={'pi ' + (fullscreen ? 'pi-arrow-down-left' : 'pi-arrows-v slant')}
                    title={fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
                    onClick={() => {
                        onFullScreen();
                    }}
                />
            </div>
            <div className="aq-floating-panel-group">
                <Button
                    icon="pi pi-search-plus"
                    title="Zoom In [+]"
                    onClick={() => {
                        onZoomIn();
                    }}
                />
                <Button icon="pi pi-search-minus" title="Zoom Out [-]" onClick={() => onZoomOut()} />
                <Button icon="fit-to-screen-icon" title="Fit to Screen [F]" onClick={() => onFitToScreen()} />
            </div>
        </div>
    );
};
