import { PatternStyle } from "../config/Constants";

export const loadPatternData = (fgColor, bgColor, style) => {
    var canvas = null
    switch (style) {
        case PatternStyle.LEFT_DIAGONAL:
            canvas = createLeftDiagonalPattern(fgColor, bgColor);
            break;
        case PatternStyle.RIGHT_DIAGONAL:
            canvas = createRightDiagonalPattern(fgColor, bgColor);
            break;
        case PatternStyle.VERTICAL:
            canvas = createVerticalPattern(fgColor, bgColor);
            break;
        case PatternStyle.HORIZONTAL:
            canvas = createHorizontalPattern(fgColor, bgColor);
            break;
        case PatternStyle.SOLID:
            canvas = createSolidPattern(fgColor);
            break;
        default:
            console.error("Required Pattern Type not found");
    }
    return canvas.toDataURL();
}

// Uploader only
export const loadPatternCanvas = (fgColor, bgColor, style, size) => {
    var canvas = null
    switch (style) {
        case PatternStyle.LEFT_DIAGONAL:
            canvas = createLeftDiagonalPattern(fgColor, bgColor, size);
            break;
        case PatternStyle.RIGHT_DIAGONAL:
            canvas = createRightDiagonalPattern(fgColor, bgColor, size);
            break;
        case PatternStyle.VERTICAL:
            canvas = createVerticalPattern(fgColor, bgColor, size);
            break;
        case PatternStyle.HORIZONTAL:
            canvas = createHorizontalPattern(fgColor, bgColor, size);
            break;
        case PatternStyle.SOLID:
            canvas = createSolidPattern(fgColor);
            break;
        case PatternStyle.DOTTED:
            canvas = createDrawSquareCirclePattern(fgColor, bgColor, size);
            break;
        default:
            console.error("Required Pattern Type not found");
    }
    return canvas;
}


export const radialCoordToCartesian = (c, r, a) => {
    return { x: c + Math.cos(a) * r, y: c + Math.sin(a) * r }
}

/**
 * This method is used to create the left diagonal pattern object 
 * @param fgColor  It takes an rgb color string to set it as the foreground color
 * @param bgColor It takes an rgb color string to set it as the background color
 * @param size  It takes an integer value to determine the width of a single line
 * @returns canvas
 */
 export const createLeftDiagonalPattern = (fgColor = `rgb(0,0,0,255)`, bgColor = `rgb(0,255,255)`, size = 4) => {
    var dpi = Math.ceil(window.devicePixelRatio)
    var leftDiagonalCanvas = document.createElement('canvas')
    var leftDiagonalContext = leftDiagonalCanvas.getContext('2d')
    var leftDiagonalCanvasSize = 8 * dpi

    leftDiagonalCanvas.width = leftDiagonalCanvasSize
    leftDiagonalCanvas.height = leftDiagonalCanvasSize

    const x1 = 0
    const y1 = 0
    const x2 = leftDiagonalCanvasSize
    const y2 = leftDiagonalCanvasSize
    const spacing = 4 * dpi
    const lineWidth = size * dpi

    leftDiagonalContext.fillStyle = bgColor
    leftDiagonalContext.fillRect(0, 0, leftDiagonalCanvasSize, leftDiagonalCanvasSize)

    leftDiagonalContext.lineWidth = lineWidth
    leftDiagonalContext.fillStyle = fgColor

    leftDiagonalContext.beginPath()
    for (let y = y1; y < y2; y++) {
        for (let x = x1; x < x2; x++)
            if ((x + y) % spacing === 0)
                leftDiagonalContext.fillRect(x, y, 1, 1)
    }
    leftDiagonalContext.fill()
    leftDiagonalContext.closePath()

    return leftDiagonalCanvas

}
/**
 * This method is used to create the right diagonal pattern
 * @param fgColor  It takes an rgb color string to set it as the foreground color
 * @param bgColor It takes an rgb color string to set it as the background color
 * @param size  It takes an integer value to determine the width of a single line
 * @returns canvas
 */
export const createRightDiagonalPattern = (fgColor = `rgb(0,0,0,255)`, bgColor = `rgb(0,255,255)`, size = 2) => {
    var dpi = Math.ceil(window.devicePixelRatio)
    var rightDiagonalCanvas = document.createElement('canvas')
    var rightDiagonalContext = rightDiagonalCanvas.getContext('2d')
    var rightDiagonalCanvasSize = 8 * dpi

    rightDiagonalCanvas.width = rightDiagonalCanvasSize
    rightDiagonalCanvas.height = rightDiagonalCanvasSize

    const x1 = 0
    const y1 = 0
    const x2 = rightDiagonalCanvasSize
    const y2 = rightDiagonalCanvasSize
    const spacing = 4 * dpi
    const lineWidth = size * dpi

    rightDiagonalContext.fillStyle = bgColor
    rightDiagonalContext.fillRect(0, 0, rightDiagonalCanvasSize, rightDiagonalCanvasSize)
    rightDiagonalContext.lineWidth = lineWidth
    rightDiagonalContext.fillStyle = fgColor


    rightDiagonalContext.beginPath()
    for (let y = y1; y < y2; y++) {
        for (let x = x1; x < x2; x++)
            if ((y + (rightDiagonalCanvasSize - x)) % spacing === 0) {
                rightDiagonalContext.fillRect(x, y, 1, 1)
            }
    }
    rightDiagonalContext.closePath()
    return rightDiagonalCanvas
}

/**
 * This method is used to create the vertical line pattern
 * @param fgColor  It takes an rgb color string to set it as the foreground color
 * @param bgColor It takes an rgb color string to set it as the background color
 * @param size  It takes an integer value to determine the width of a single line
 * @returns canvas
 */
export const createVerticalPattern = (fgColor = `rgb(0,0,0,255)`, bgColor = `rgb(255,255,255)`, size = 4) => {
    // size=2
    var dpi = Math.ceil(window.devicePixelRatio)
    var verticalPatternCanvas = document.createElement('canvas')
    var verticalPatternContext = verticalPatternCanvas.getContext('2d')
    var verticalPatternCanvasSize = size * dpi

    const lineWidth = size * dpi

    verticalPatternCanvas.width = verticalPatternCanvasSize
    verticalPatternCanvas.height = verticalPatternCanvasSize

    verticalPatternContext.fillStyle = bgColor
    verticalPatternContext.fillRect(0, 0, verticalPatternCanvasSize, verticalPatternCanvasSize)
    verticalPatternContext.lineWidth = lineWidth
    verticalPatternContext.fillStyle = fgColor

    verticalPatternContext.beginPath()
    verticalPatternContext.fillRect(0, 0, verticalPatternCanvasSize / 2, verticalPatternCanvasSize)
    verticalPatternContext.closePath()

    return verticalPatternCanvas

}


/**
 * This method is used to create the horizontal line pattern
 * @param fgColor  It takes an rgb color string to set it as the foreground color
 * @param bgColor It takes an rgb color string to set it as the background color
 * @param size  It takes an integer value to determine the width of a single line
 * @returns canvas
 */
export const createHorizontalPattern = (fgColor = `rgb(0,0,0,255)`, bgColor = `rgb(0,255,255)`, size = 4) => {
    var dpi = Math.ceil(window.devicePixelRatio)
    var horizontalPatternCanvas = document.createElement('canvas')
    var horizontalPatternContext = horizontalPatternCanvas.getContext('2d')
    var horizontalPatternCanvasSize = size * dpi

    const lineWidth = size * dpi

    horizontalPatternCanvas.width = horizontalPatternCanvasSize
    horizontalPatternCanvas.height = horizontalPatternCanvasSize

    horizontalPatternContext.fillStyle = bgColor
    horizontalPatternContext.fillRect(0, 0, horizontalPatternCanvasSize, horizontalPatternCanvasSize)
    horizontalPatternContext.lineWidth = lineWidth
    horizontalPatternContext.fillStyle = fgColor

    horizontalPatternContext.beginPath()
    horizontalPatternContext.fillRect(0, 0, horizontalPatternCanvasSize, horizontalPatternCanvasSize / 2)


    return horizontalPatternCanvas

}

/**
 * This method is used to create the checkerboard pattern
 * @returns canvas
 */
export const createCheckerboardPattern = () => {
    var dpi = Math.ceil(window.devicePixelRatio)
    var checkerboardCanvas = document.createElement('canvas')
    var checkerboardContext = checkerboardCanvas.getContext('2d')
    var checkerboardCanvasSize = 2 * dpi
    const lineWidth = 1 * dpi

    checkerboardCanvas.height = checkerboardCanvasSize
    checkerboardCanvas.width = checkerboardCanvasSize
    checkerboardContext.lineWidth = lineWidth
    checkerboardContext.fillStyle = `rgb(255,200,0)`

    checkerboardContext.beginPath()
    checkerboardContext.fillRect(0, 0, checkerboardCanvasSize / 2, checkerboardCanvasSize / 2)
    checkerboardContext.fillRect(checkerboardCanvasSize / 2, checkerboardCanvasSize / 2, checkerboardCanvasSize, checkerboardCanvasSize)
    checkerboardContext.closePath()

    return checkerboardCanvas
}

/**
 * This method is used to create the draw square circle pattern
 * @returns canvas
 */
export const createDrawSquareCirclePattern = (fgColor, bgColor, size) => {
    var dpi = Math.ceil(window.devicePixelRatio)
    var squareCirclePatternCanvas = document.createElement('canvas')
    var squareCirclePatternContext = squareCirclePatternCanvas.getContext('2d')
    var squareCirclePatternCanvasSize = size * dpi

    squareCirclePatternCanvas.height = squareCirclePatternCanvasSize
    squareCirclePatternCanvas.width = squareCirclePatternCanvasSize

    const x1 = 0
    const y1 = 0
    const x2 = squareCirclePatternCanvasSize
    const y2 = squareCirclePatternCanvasSize
    const radius = 1.2 * dpi
    squareCirclePatternContext.fillStyle = bgColor
    squareCirclePatternContext.fillRect(0, 0, squareCirclePatternCanvasSize, squareCirclePatternCanvasSize)
    squareCirclePatternContext.fillStyle = fgColor

    squareCirclePatternContext.beginPath()
    squareCirclePatternContext.arc(x1, y1, radius, 0, 2 * Math.PI)
    squareCirclePatternContext.fill()
    squareCirclePatternContext.closePath()

    squareCirclePatternContext.beginPath()
    squareCirclePatternContext.arc(x1, y2, radius, 0, 2 * Math.PI)
    squareCirclePatternContext.fill()
    squareCirclePatternContext.closePath()

    squareCirclePatternContext.beginPath()
    squareCirclePatternContext.arc(x2, y1, radius, 0, 2 * Math.PI)
    squareCirclePatternContext.fill()
    squareCirclePatternContext.closePath()

    squareCirclePatternContext.beginPath()
    squareCirclePatternContext.arc(x2, y2, radius, 0, 2 * Math.PI)
    squareCirclePatternContext.fill()
    squareCirclePatternContext.closePath()

    squareCirclePatternContext.beginPath()
    squareCirclePatternContext.arc((x1 + x2) / 2, (y1 + y2) / 2, radius, 0, 2 * Math.PI)
    squareCirclePatternContext.fill()
    squareCirclePatternContext.closePath()

    return squareCirclePatternCanvas

}
/**
 *  devicePixelRatio
 * This method is used to create a solid fill of the color given
 * @param  fgColor This is the color to fill the area with
 * @returns canvas
 */

export const createSolidPattern = (fgColor = `rgb(0,0,0,255)`) => {
    var size = 1
    var dpi = Math.ceil(window.devicePixelRatio)
    var solidPatternCanvas = document.createElement('canvas')
    var solidPatternContext = solidPatternCanvas.getContext('2d')
    var solidPatternCanvasSize = size * dpi

    solidPatternCanvas.height = solidPatternCanvasSize
    solidPatternCanvas.width = solidPatternCanvasSize

    solidPatternContext.fillStyle = fgColor
    solidPatternContext.fillRect(0, 0, solidPatternCanvasSize, solidPatternCanvasSize)
    return solidPatternCanvas
}