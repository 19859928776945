/**
 * This stores a list of colors as hexadecimal string values 
 */
 export const Colors = ["#fff","#ff00ff", "#696969", "#2e8b57", "#800000", "#191970", "#808000",
 "#ff4500", "#ffa500", "#ffff00", "#0000cd", "#7fff00", "#ba55d3", "#00fa9a",
 "#00ffff", "#1e90ff", "#fa8072", "#dda0dd", "#00ced1", "#87cefa", "#fdf5e6", "#ffdead"]



// export const FillColors={
//  GRAY:`rgb(80,80,80)`,
//  RED:`rgb(255,0,0)`,
//  GREEN:`rgb(0,255,0)`,
//  ORANGE:`rgb(255,167,0)`,
//  WHITE:`rgb(255,255,2555)`
// }


// export const TextColors={
//  BLACK:`rgb(0,0,0)`,
//  GREEN:`rgb(200,0,0)`
// }