import React, { useEffect, useState } from 'react';

const IdleTimer = (
    props = {
        startTime: 30,
        onTimer: null,
    }
) => {
    const [timer, setTimer] = useState(props.startTime);
    useEffect(() => {
        const myInterval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);
        const resetTimeout = () => {
            setTimer(props.startTime);
        };
        const events = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'load','TouchMove','TouchStart','OnWheel'];
        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }
        return () => {
            clearInterval(myInterval);
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
            }
        };
    });
    useEffect(() => {
        if (props.onTimer && (timer === 0 || timer === props.startTime)) props.onTimer(timer);
    }, [timer]);
    return <></>;
};

export default IdleTimer;
